import React, { useState, useEffect, useCallback } from "react";
import {
  faBell as faAlarmOff,
  faBellSlash as faAlarmDisabled,
  faBookmark as faNotWantToTry,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCheck as faHasTried,
  faCheck as faHasNotTried,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBookmark as faWantsToTry,
  faBell as faAlarmOn,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useDispatch, useStore, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "src/store";
import styled from "styled-components";
import ReminderSettings from "./ReminderSetting";
import { LightGreen } from "./WantToTry";
import { TrackAdsEvent } from "../../../../../src/api/tracker";
import { LOGGING } from "../../../../../src/constants";
import {
  setRestaurantWantsToTry,
  setRestaurantHasTried,
} from "../../../../../src/store/actions";
import {
  BackgroundGrey,
  BorderGrey,
  card,
  Centered,
  centered,
  text12300,
  TextGrey,
  thinShadow,
} from "../Shared";

const width = 40;
const Container = styled.button<{
  isChecked?: boolean;
  iconSize?: number;
  background?: string;
}>`
  ${centered}
  margin: 0px;
  width: ${width}px;
  height: ${width}px;
  border-radius: ${width / 2}px;
  flex-grow: 0;
  flex-shrink: 0;
  /* border: 1px solid ${BorderGrey};     */
  background-color: ${(props) =>
    props.isChecked ? LightGreen : props.background || "rgba(255,255,255,0.7)"};
  /* margin-right: 12px; */
  svg {
    font-size: ${(props) => (props.iconSize ? `${props.iconSize}px` : "12px")};
  }

  @media screen and (min-width: 1200px) {
    background-color: ${(props) =>
      props.isChecked
        ? LightGreen
        : props.background || "rgba(255,255,255,0.7)"};
  }
`;

const ReminderContainer = styled(Container)`
  svg {
    font-size: 14px;
  }
`;

const ReminderTime = styled.span<{ isActive: boolean }>`
  ${text12300}
  font-size: 11px;
  line-height: 20px;
  font-weight: 600;
  /* margin-right: 5px; */
  color: ${(props) => (props.isActive ? "green" : TextGrey)};
  position: absolute;
  top: -24px;
  background-color: ${(props) =>
    props.isActive ? LightGreen : "rgba(255,255,255,0.7)"};
  padding: 0px 5px;
  border-radius: 5px;
  @media screen and (min-width: 1200px) {
    box-shadow: ${thinShadow};
    border: 1px solid ${BorderGrey};
    top: calc(100% + 5px);
  }
`;
const reminderFormat = "h:mma";
const reminderHourFormat = "ha";

export const Reminder = ({
  mealId,
  restaurantName,
  windowStart,
  mealType,
  reminder,
  onShowReminderSettings,
  dayIndex,
  mealIndex,
  source,
  fbclid,
}) => {
  const userId = useSelector(
    (state: RootState) => state.currentUser?.user?._id
  );
  const history = useHistory();
  const {
    textOn,
    emailOn,
    appNotificationOn,
    isActive,
    alertTimestamp: reminderTime,
  } = reminder || {};

  let formats = [];
  if (textOn) {
    formats.push("text");
  }
  if (emailOn) {
    formats.push("email");
  }
  if (appNotificationOn) {
    formats.push("appNotification");
  }

  return (
    <ReminderContainer
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        // @ts-ignore
        if (window.fbq && fbclid) {
          // @ts-ignore
          window.fbq("track", "ViewContent", {
            content_name: `reminder for ${restaurantName} to sign up`,
            content_category: "pageGuest",
            value: 0,
            currency: "USD",
          });

          // Track Ads Event
          TrackAdsEvent(fbclid, {
            source,
            eventCode: "ViewContent",
            page: "pageGuest",
            contentName: `reminder for ${restaurantName} to sign up`,
          });
        }

        if (!userId) {
          //redirect to /auth
          return history.push("/auth");
        }
        onShowReminderSettings({
          windowStart,
          restaurantName,
          reminderTime,
          mealType,
          mealId,
          dayIndex,
          mealIndex,
          isActive,
          formats,
        });
      }}
      isChecked={isActive}
    >
      {reminderTime && (
        <ReminderTime isActive={isActive}>
          {moment(reminderTime).format(reminderFormat)}
        </ReminderTime>
      )}
      <FontAwesomeIcon
        color={isActive ? "green" : "black"}
        icon={
          isActive ? faAlarmOn : reminderTime ? faAlarmDisabled : faAlarmOff
        }
      />
    </ReminderContainer>
  );
};

const BookMarkContainer = styled(Container)``;

export const Bookmark = ({
  restaurant,
  background = "rgba(255,255,255,0.7)",
  iconSize = 12,
  mealId,
  mealType,
  source,
  fbclid,
}) => {
  const [wantsToTry, setWantsToTry] = useState(false);
  const userId = useSelector(
    (state: RootState) => state.currentUser?.user?._id
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    setWantsToTry(restaurant?.wantsToTry);
  }, [restaurant?.wantsToTry]);

  return (
    <BookMarkContainer
      //   disabled={loading}
      iconSize={iconSize}
      isChecked={wantsToTry}
      background={background}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        // @ts-ignore
        if (window.fbq && fbclid) {
          // @ts-ignore
          window.fbq("track", "ViewContent", {
            content_name: `bookmark for ${restaurant?.name} to sign up`,
            content_category: "pageGuest",
            value: 0,
            currency: "USD",
          });

          // Track Ads Event
          TrackAdsEvent(fbclid, {
            source,
            eventCode: "ViewContent",
            page: "pageGuest",
            contentName: `bookmark for ${restaurant?.name} to sign up`,
          });
        }

        if (!userId) {
          //redirect to /auth
          return history.push("/auth");
        }
        setWantsToTry(!wantsToTry);
        setRestaurantWantsToTry(
          restaurant?.restaurantId || restaurant?._id,
          !wantsToTry,
          restaurant?.name,
          mealId,
          mealType
        )(dispatch, store.getState).then((res) => {
          // setLoading(false);
          LOGGING && console.log("setRestaurantWantsToTry response: ", res);
        });
      }}
    >
      {/* {loading && <LoadingCircle size={30} margin="0px" />} */}

      {/* <span>{loading ? "saving..." : wantsToTry?"saved":"save"}</span> */}
      <FontAwesomeIcon
        color={wantsToTry ? "green" : "black"}
        icon={wantsToTry ? faWantsToTry : faNotWantToTry}
      />
    </BookMarkContainer>
  );
};
