import { ChangeEvent, Fragment, useMemo } from "react";
import { SerializedRestaurantDishSelection } from "src/api/meals";
import { LOGGING } from "src/constants";
import { Prices } from "src/screens/tasting_menu/components/customers/mealMenu/Prices";
import {
  Centered,
  CenteredByColumn,
  Text12300,
  text12300,
} from "src/screens/tasting_menu/components/Shared";
import styled from "styled-components";
import { Divider } from "../Divider";
import { Checkbox, Radio } from "../Form";
import { Flex } from "../Layout";
const MAX_SELECTION = 2147483647;

const ItemPrice = styled(CenteredByColumn)`
  ${text12300}
  font-weight: ${(props) => (props.theme === "selected" ? 500 : 300)};
`;
type DishSelectProps = {
  selection: SerializedRestaurantDishSelection;
  selectedItems: string[];
  onChange: (selectedItems: string[]) => void;
  isMember: boolean;
  onStartMembership?: () => void;
};

export const DishSelection = (props: DishSelectProps) => {
  const { selection, selectedItems, onChange, isMember, onStartMembership } =
    props;
  // LOGGING &&
  //   console.log("DishSelection rendering with: ", {
  //     selection,
  //     selectedItems,
  //     onChange,
  //     isMember,
  //     onStartMembership,
  //   });

  const onSelectionItemChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.currentTarget;
    // console.log("onSelectionItemChange called:", { checked, value });
    if (
      selection.maxSelection > 1 ||
      !selection.maxSelection ||
      !selection.minSelection
    ) {
      if (checked) {
        onChange([...selectedItems, value]);
      } else {
        onChange(selectedItems.filter((item) => item !== value));
      }
    } else {
      onChange([value]);
    }
  };

  const selectionNotice = useMemo(() => {
    if (selection.maxSelection === MAX_SELECTION) {
      return "Please choose";
    }
    return `Please choose
      ${
        selection.minSelection === selection.maxSelection
          ? selection.minSelection
          : `${
              selection.minSelection ? `${selection.minSelection}` : ""
            } up to ${selection.maxSelection}`
      }`;
  }, [selection.minSelection, selection.maxSelection]);

  return (
    <DishSelectionContainer>
      <DishSelectionHeader>
        <Flex justify="space-between" align="center">
          <Flex direction="column" gap={3}>
            <h4>{selection.name}</h4>
            {selection.maxSelection > 0 && <span>{selectionNotice}</span>}
          </Flex>
          {selection.minSelection > 0 && (
            <DishSelectionRequired>Required</DishSelectionRequired>
          )}
        </Flex>
      </DishSelectionHeader>
      <DishSelectionItems>
        {selection.items.map((item, index) => {
          const memberOnly = item.priceDoordashListed === 0 && item.price > 0;
          const isLastItem = index === selection.items.length - 1;
          return (
            <Fragment key={item._id}>
              <Flex justify="space-between" align="center" gap={6}>
                {selection.maxSelection > 1 ||
                !selection.maxSelection ||
                !selection.minSelection ? (
                  <Checkbox
                    name={selection._id}
                    value={item._id}
                    label={item.name}
                    checked={selectedItems.includes(item._id)}
                    disabled={
                      (selection.maxSelection &&
                        selectedItems.length >= selection.maxSelection &&
                        !selectedItems.includes(item._id)) ||
                      item.outOfStock ||
                      (memberOnly && !isMember)
                    }
                    onChange={onSelectionItemChange}
                  />
                ) : (
                  <Radio
                    name={selection._id}
                    value={item._id}
                    label={item.name}
                    checked={selectedItems.includes(item._id)}
                    disabled={item.outOfStock}
                    onChange={onSelectionItemChange}
                  />
                )}
                <DishSelectionItemDesc
                  $disabled={
                    (memberOnly && !isMember) ||
                    item.outOfStock ||
                    (selection.maxSelection > 1 &&
                      selectedItems.length >= selection.maxSelection &&
                      !selectedItems.includes(item._id))
                  }
                >
                  {
                    item.outOfStock ? (
                      "Out of stock"
                    ) : item?.price > 0 || item?.priceDoordashListed > 0 ? (
                      <Prices
                        priceDoordashListed={item.priceDoordashListed}
                        priceFoodieNonMember={item.priceFoodieNonMember}
                        priceFoodieListed={item.price}
                        isMember={isMember}
                      />
                    ) : // <>
                    //   <ItemPrice theme={isMember ? "" : "selected"}>{`$${(
                    //     item?.priceDoordashListed || item?.priceFoodieNonMember
                    //   )?.toFixed(2)} Regular`}</ItemPrice>
                    //   <ItemPrice
                    //     theme={isMember ? "selected" : ""}
                    //   >{`$${item?.price?.toFixed(2)} member`}</ItemPrice>
                    // </>
                    null
                    // ? `$${(
                    //     item?.priceDoordashListed || item?.priceFoodieNonMember
                    //   ).toFixed(2)} regular $${item?.price?.toFixed(2)} member`
                    // : ""
                  }
                </DishSelectionItemDesc>
              </Flex>
              {!isLastItem && <Divider />}
            </Fragment>
          );
        })}
      </DishSelectionItems>
    </DishSelectionContainer>
  );
};

const DishSelectionHeader = styled.div`
  background: #f2f2f2;
  padding: 6px 12px;
  width: 100%;
  h4 {
    margin: 0;
  }

  span {
    font-size: 13px;
  }
`;
const DishSelectionContainer = styled(CenteredByColumn)`
  width: 100%;
`;
const DishSelectionRequired = styled.div`
  font-size: 13px;
  color: black;
  background: #ffe352;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
`;

const DishSelectionItems = styled.div`
  padding: 12px;
  text-transform: capitalize;
  width: 100%;
`;

const DishSelectionItemDesc = styled.span<{ $disabled?: boolean }>`
  flex-shrink: 0;
  font-size: 14px;

  ${({ $disabled }) => ($disabled ? "color: #888888;" : "")}
`;
