import * as Components from ".";

const Sprinkles = ({ onClick }) => {
  const linkLunch = "/meal/02-14-2025/lunch/sprinkles-cupcakes";
  const image =
    "https://sprinkles-dyo-api-production.s3.amazonaws.com/ec438807-24a7-4853-b238-4b64d4b47fe9.png";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Sprinkles Cupcakes
        </Components.RestaurantName>
        <Components.MenuName>Valentine's Boutique</Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Pre-Order by&nbsp;<b>Thursday, Feb 13</b>
          </Components.DeliveryTime>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>12-1P Friday, Valentine's Day</b>&nbsp;
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>I Love You Bundle</Components.DishName>
              <Components.AlaCartePrice>$85</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>XOX Bundle</Components.DishName>
              <Components.AlaCartePrice>$84</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>XOX 6 Box Bundle</Components.DishName>
              <Components.AlaCartePrice>$46</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Valentine's Day Dozen Mini Cupcakes
              </Components.DishName>
              <Components.AlaCartePrice>$28</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Valentine's Day Mini Sampler
              </Components.DishName>
              <Components.AlaCartePrice>$9</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Heartthrob Reb Velvet</Components.DishName>
              <Components.AlaCartePrice>$7.5</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Rasberry Chocolate Chip</Components.DishName>
              <Components.AlaCartePrice>$7.5</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
          >
            pre-order Valentine's Day lunch
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Sprinkles;
