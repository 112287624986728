import { useState } from "react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useStore } from "react-redux";
import { GetMealLink, LOGGING } from "src/constants";
import { RootState } from "src/store";
import { updateOrderDue } from "src/store/actions";
import styled from "styled-components";
import { Title } from "./Contact";
import { PayButton } from "./PayButton";
import { AuthEntry } from "../../auth/AuthEntry";
import { FastEmail, FastGoogle, EmailForms } from "../../auth/FastAuth";
import { GetEmailOrGoogle } from "../../auth/GetEmailOrGoogle";
import {
  BackgroundGrey,
  BorderGrey,
  centered,
  Centered,
  centeredByColumn,
  CenteredByColumn,
  NavHeight,
  UpwardShadow,
} from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";
export const NavContainer = styled(Centered)<{ isAuth?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${NavHeight}px;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${BreakPoint}px) {
    padding: 0 20px;
    justify-content: center;
  }
  @media screen and (min-width: 1200px) {
    padding: 0 10vw;
    /* background-color: ${(props) =>
      props.isAuth ? "white" : BackgroundGrey}; */
  }
`;
export const CheckoutTitle = styled(Title)`
  display: none;
  @media screen and (max-width: ${BreakPoint}px) {
    display: flex;
  }
`;
const BackButton = styled.button`
  ${centered}
  height: ${NavHeight}px;
  svg {
    margin-right: 10px;
    font-size: 20px;
  }
  @media screen and (max-width: ${BreakPoint}px) {
    position: fixed;
    top: 0px;
    left: 0px;
    width: ${NavHeight}px;
    > span {
      display: none;
    }
    svg {
      font-size: 24px;
    }
  }
`;

const AuthContainer = styled(UpwardShadow)`
  ${centeredByColumn}
  position: fixed;
  background-color: white;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  padding: 30px 40px 40px 40px;
  gap: 10px;
  border-top: 1px solid ${BorderGrey};
  align-items: stretch;
  background-color: ${BackgroundGrey};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid ${BorderGrey};
  @media screen and (min-width: 1200px) {
    /* padding: 30px calc(50vw - 400px); */
    flex-direction: row;
    align-items: center;
    gap: 20px;
    top: 0px;
    right: 10vw;
    left: auto;
    height: ${NavHeight}px;
    width: auto;
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0px;
    align-items: auto;
    /* height: 150px; */
  }
  /* height: 30vw; */
`;
export const CheckoutNav = ({
  onBack,
  onSubmit,
  onSubmitFreeMeal,
  paying,
  initializing,
  isCartValid,
  isAuthenticated,
  error,
  updateUserInfo,
}) => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const [continueWithEmail, setContinueWithEmail] = useState(false);
  const { meal } = cart;
  const mealLink = GetMealLink(meal);
  const dispatch = useDispatch();
  const store = useStore();
  const [isUpdating, setIsUpdating] = useState(false);
  // const onBack = () => {
  //   // window.location.href = `/${mealLink}`;
  //   window.history.back();
  // };
  const onAuth = () => {
    setIsUpdating(true);
    updateOrderDue()(dispatch, store.getState).then(() => {
      updateUserInfo();
      setIsUpdating(false);
    });
  };
  const onContinueWithEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setContinueWithEmail(true);
  };
  return (
    <NavContainer isAuth={isAuthenticated}>
      <BackButton onClick={onBack}>
        <FontAwesomeIcon icon={faChevronLeft} />
        {/* <span>back to menu</span> */}
      </BackButton>
      <CheckoutTitle>check out</CheckoutTitle>
      {error && error.message && (
        <div className="error-message">{error.message}</div>
      )}
      {isAuthenticated ? (
        <PayButton
          onSubmit={cart?.due > 0 ? onSubmit : onSubmitFreeMeal}
          paying={paying}
          initializing={initializing || isUpdating}
          isCartValid={isCartValid}
          isAuthenticated={isAuthenticated}
        />
      ) : (
        <PayButton
          onSubmit={cart?.due > 0 ? onSubmit : onSubmitFreeMeal}
          paying={paying}
          initializing={initializing || isUpdating}
          isCartValid={isCartValid}
          isAuthenticated={isAuthenticated}
        />
        // <AuthContainer>
        //   {continueWithEmail ? (
        //     <EmailForms
        //       onBack={(e) => {
        //         e.preventDefault();
        //         e.stopPropagation();
        //         setContinueWithEmail(false);
        //       }}
        //     />
        //   ) : (
        //     <FastEmail onClick={onContinueWithEmail} />
        //     <FastGoogle onSuccessByParent={onAuth} />
        // )}
        // </AuthContainer>
      )}
    </NavContainer>
  );
};
