import axios from "axios";
import { LOGGING, TOKEN_KEY } from "../constants";

export function apiCall(method, path, data, currentUser, options) {
  // forcing local dev server for local test, as npm run build automatically sets NODE_ENV to production
  // const serverPath = process.env.REACT_APP_DEV_SERVER;

  const serverPath =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROD_SERVER
      : process.env.REACT_APP_DEV_SERVER;

  const fullPath = serverPath + path;

  const headers = {
    "Requester-Id": currentUser?.user?._id || "",
    "Requester-Token": localStorage.getItem(TOKEN_KEY) || "",
  };
  return new Promise((resolve, reject) => {
    axios
      .request({
        url: fullPath,
        method: method.toLowerCase(),
        data,
        headers,
        responseType: options?.responseType ?? "json", // default
      })
      .then((res) => {
        // LOGGING && console.log('api got res:', res);
        return resolve(res.data);
      })
      .catch((err) => {
        LOGGING && console.log("api got err:", err);
        return reject(err.response?.data?.error);
      });
  });
}
