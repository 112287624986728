import "./MealMenuAdmin.scss";
import { faCheck, faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "src/components/Button";
import { DishTypeChecker } from "src/components/DishTypeChecker";
import { getDishesByCategoryName } from "src/util/categories";
import { getSortedCategoryNamesFromDishes } from "src/util/categories";
import { LOGGING, Close } from "..";

const CategoryOfDishes = ({
  restaurant,
  dishes,
  category,
  selectedPreOrderedDishes,
  selectedDishes,
  preOrderOnly,
  onChangePreOrderQuantity,
  onToggleDish,
  onSelectAll,
  onDeselectAll,
  onEditDish,
  onSetHeroImage,
}) => {
  return (
    <div className="dish-category">
      <div className="category-name">
        <span>{category}</span>
      </div>
      <div className="category-actions">
        <button onClick={onSelectAll}>select all</button>
        <button onClick={onDeselectAll}>clear all</button>
      </div>
      <div className="dish-list">
        {dishes.map((d, index) => {
          const {
            _id,
            name,
            imageURL,
            priceDoordashListed,
            priceFoodieListed,
            priceFoodieRestaurant,
          } = d;
          const preOrderIndex = selectedPreOrderedDishes
            ? selectedPreOrderedDishes.findIndex((o) =>
                o.dish._id
                  ? String(o.dish._id) === String(_id)
                  : String(o.dish) === String(_id)
              )
            : -1;
          const selected = preOrderOnly
            ? preOrderIndex > -1
            : selectedDishes.includes(_id);
          return (
            <div
              className={`dish-item ${selected ? "selected" : ""}`}
              key={index}
            >
              {selected &&
                (preOrderOnly ? (
                  <span>{preOrderIndex + 1}</span>
                ) : (
                  <span>{selectedDishes.indexOf(_id) + 1}</span>
                ))}
              <DishTypeChecker dish={d} />
              <img className="dish-item-image" src={imageURL} alt="" />
              <div className="dish-name">{name}</div>
              <div className="dish-price">
                D: ${priceDoordashListed?.toFixed(2) || `0.00`}
              </div>
              <div className="dish-price">
                F: ${priceFoodieListed?.toFixed(2) || `0.00`}
              </div>
              <div className="dish-price">
                R: ${priceFoodieRestaurant?.toFixed(2) || `0.00`}
              </div>
              <Button
                variant="link"
                icon={faEdit}
                onClick={() => onEditDish(d)}
              >
                Edit
              </Button>
              <button
                className="select-dish"
                onClick={onToggleDish.bind(this, _id)}
              >
                <span>{`${selected ? "selected" : "select"}`}</span>
                <FontAwesomeIcon icon={faCheck} />
              </button>
              {preOrderOnly && selected ? (
                <div className="preorder-quantity">
                  <label>quantity</label>
                  <input
                    defaultValue={
                      selectedPreOrderedDishes[preOrderIndex].quantity
                    }
                    onChange={onChangePreOrderQuantity.bind(this, _id)}
                  />
                </div>
              ) : null}
              {d.imageURL && (
                <>
                  {restaurant?.heroImage === d.imageURL ? (
                    <div className="hero-image">Current hero image</div>
                  ) : (
                    <Button
                      className="set-hero-image"
                      size="small"
                      variant="secondary"
                      onClick={() => {
                        onSetHeroImage(restaurant?._id, d.imageURL);
                      }}
                    >
                      Set hero image2
                    </Button>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

type MealMenuAdminProps = {
  restaurant: any;
  dishes: any[];
  selectedDishes: any[];
  categoriesForRestaurant: string[];
  onSelectAllDishes: () => void;
  onDeselectAllDishes: () => void;
  onToggleDish: (dishId: string) => void;
  onSelectAllDishesInCategory: (categoryName: string) => void;
  onDeselectAllDishesInCategory: (categoryName: string) => void;
  onEditDish: (dishId: string) => void;
  onSetHeroImage: (restaurantId: string, imageUrl: string) => void;
  // Optional
  onClose?: () => void;
  selectedPreOrderedDishes?: any[];
  isLunch?: boolean;
  onToggleSupportEarlyDelivery?: () => void;
  onChangePreOrderQuantity?: () => void;
};

export const MealMenuAdmin: React.FC<MealMenuAdminProps> = ({
  restaurant,
  dishes,
  selectedDishes,
  onSelectAllDishes,
  onDeselectAllDishes,
  categoriesForRestaurant,
  onToggleDish,
  onChangePreOrderQuantity,
  onSelectAllDishesInCategory,
  onDeselectAllDishesInCategory,
  onEditDish,
  onSetHeroImage,
  selectedPreOrderedDishes,
  isLunch,
  onClose,
  onToggleSupportEarlyDelivery,
}) => {
  LOGGING &&
    console.log("RestaurantListDetails rendering for:", {
      restaurant,
      dishes,
      selectedPreOrderedDishes,
      selectedDishes,
      categoriesForRestaurant,
    });

  const categorizedDishes = getDishesByCategoryName(dishes);
  LOGGING &&
    console.log(
      "RestaurantListDetails got categorizedDishes:",
      categorizedDishes
    );

  const orderedCategoryNames = getSortedCategoryNamesFromDishes(dishes);
  LOGGING &&
    console.log(
      "RestaurantListDetails got orderedCategoryNames:",
      orderedCategoryNames
    );

  return (
    <div className="restaurant-details">
      {isLunch && (
        <button
          className="early-delivery"
          onClick={onToggleSupportEarlyDelivery}
        >
          Early Delivery
          {restaurant?.supportEarlyDelivery && (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </button>
      )}
      <div className="restaurant-actions">
        {orderedCategoryNames.map((category, index) => (
          <button>{category + " (" + (index + 1)?.toString() + ")"}</button>
        ))}
      </div>
      <div className="restaurant-actions">
        <button onClick={onSelectAllDishes}>select all</button>
        <button onClick={onDeselectAllDishes}>clear all</button>
        {onClose != null ? <Close onClick={onClose} /> : null}
      </div>
      <div className="restaurant-categories" style={{ overflow: "auto" }}>
        {categoriesForRestaurant.map((category) => {
          return (
            <CategoryOfDishes
              key={category}
              restaurant={restaurant}
              category={category}
              dishes={categorizedDishes[category] ?? []}
              onChangePreOrderQuantity={onChangePreOrderQuantity}
              onToggleDish={onToggleDish}
              selectedDishes={selectedDishes}
              selectedPreOrderedDishes={selectedPreOrderedDishes}
              preOrderOnly={restaurant?.preOrderOnly}
              onSelectAll={onSelectAllDishesInCategory.bind(this, category)}
              onDeselectAll={onDeselectAllDishesInCategory.bind(this, category)}
              onEditDish={onEditDish}
              onSetHeroImage={onSetHeroImage}
            />
          );
        })}
      </div>
    </div>
  );
};

MealMenuAdmin.defaultProps = {
  selectedPreOrderedDishes: [],
  isLunch: false,
  onToggleSupportEarlyDelivery: () => {},
  onChangePreOrderQuantity: () => {},
};
