import { apiCall } from "../api";

export const sendPhoneVerificationCode = (phone) => {
  console.log("sendPhoneVerificationCode called");
  return async (dispatch, getState) => {
    const code = await apiCall("POST", `/verification/sendPhoneCode/`, {
      phone: phone,
      channel: "sms",
    });
    return String(code);
  };
};

export const sendEmailVerificationCode = (email) => {
  console.log("sendEmailVerificationCode called");
  return (dispatch, getState) => {
    return apiCall("POST", `/verification/sendEmailCode/`, { email });
  };
};
