import * as Components from ".";

const Teleferic = ({ onClick }) => {
  const linkLunch = "/meal/02-14-2025/dinner/telef-ric-barcelona";
  const image =
    "https://img.cdn4dd.com/p/fit=cover,width=800,height=600,format=auto,quality=50/media/photosV2/0c439d1b-22c4-4939-bfa5-0fadc1f1e0b1-retina-large.jpeg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Teleferic Barcelona
        </Components.RestaurantName>
        <Components.MenuName>Valentine's Day Pack</Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Pre-Order by&nbsp;<b>noon Friday, Feb 14</b>.
          </Components.DeliveryTime>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>6-7P Friday, Valentine's Day</b>&nbsp;
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Valentine's Day Pack</Components.DishName>
              <Components.AlaCartePrice>$99</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Embrace the universal language of love through our delectable
              romantic meal designed to be savored at home. Our Valentine's Day
              Pack is the key to an intimate culinary journey for two—ideal to
              share with your bestie or significant other.
              <br></br>
              <br></br>- Jamón Ibérico de Bellota: Exquisite 38 months cured
              Iberian Pata Negra ham 38 (gf) (df)
              <br></br>- Pan con Tomate: Traditional Spanish dish of toasted
              bread with tomato dressing & chorizo butter
              <br></br>- Patatas Bravas: Crispy potatoes topped with homemade
              brava sauce & aioli (gfa, v)
              <br></br>- Tuna Taquitos (3 Units): Marinated ahi tuna, mashed
              avocado & wonton shells (gfa)
              <br></br>- Ham Croquetas (4 Units) : Croquettes with Iberian ham &
              aioli
              <br></br>- Choice of Paella Medium: Negra, lobster or veggie
              Churros con
              <br></br>- Nutella: Barcelona street fried dough filled with
              Nutella (nuts, v)
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
          >
            pre-order Valentine's Day Dinner
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Teleferic;
