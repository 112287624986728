import "./Back.scss";
import { faChevronLeft, faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Back = ({
  onClick,
  icon = faChevronLeft,
  leftEnd = true,
  style = {},
  isAuthenticated = true,
}) => (
  <button
    className={`back-button ${leftEnd ? "left-end" : ""}`}
    type="button"
    onClick={onClick}
    style={style}
  >
    <FontAwesomeIcon icon={isAuthenticated ? icon : faHome} />
  </button>
);
export default Back;
