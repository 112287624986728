import { time } from "console";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { orderBy } from "../..";
import { Centered, text12300 } from "../../Shared";

// give a boolean input isClosed
const CountDownContainer = styled.div<{ isClosed: boolean }>`
  ${text12300}
  /* position: absolute; */
  /* top: 13px; */
  background-color: red;
  /* padding: 0px 20px; */
  color: white;
  // is closed width auto, otherwise 190px;
  width: ${(props) => (props.isClosed ? "auto" : "190px")};
  height: 25px;
  border-radius: 5px;
  label {
    position: absolute;
    left: 12px;
    font-weight: 400;
  }
`;
const OrderBy = styled.span`
  ${text12300}
  font-weight: 500;
  padding: 0px 10px;
`;
const Hour = styled.span`
  position: absolute;
  right: 73px;
  ${text12300}
  font-weight: 600;
  letter-spacing: 1px;
`;
const Closed = styled.span`
  ${text12300}
  font-weight: 500;
  letter-spacing: 1px;
  color: white;
  padding: 0px 15px;
  border-radius: 5px;
`;
const Minute = styled(Hour)`
  right: 38px;
`;
// give a boolean input isDoubleDigit
const Second = styled(Hour)<{ isDoubleDigit: boolean }>`
  right: ${(props) => (props.isDoubleDigit ? "21px" : "29px")};
`;
const Seconds = styled(Hour)<{ isDoubleDigit: boolean }>`
  right: ${(props) => (props.isDoubleDigit ? "13px" : "21px")};
  text-transform: lowercase;
`;

// create a widget the input is the deadline in epoch time
// display the time left in minutes, seconds
// when the time is up, display a message
export const CountDown = ({ deadline }) => {
  const [timeLeft, setTimeLeft] = useState(deadline - Date.now());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(deadline - Date.now());
    }, 1000);
    return () => clearInterval(timer);
  }, [deadline]);

  const hours = Math.floor(timeLeft / 3600000);
  const minutes = Math.floor((timeLeft / 60000) % 60);
  const seconds = Math.floor((timeLeft % 60000) / 1000);
  return (
    <CountDownContainer isClosed={timeLeft <= 0 || hours > 24}>
      {hours > 24 ? (
        <OrderBy>
          Order by {moment(orderBy(deadline)).format("ddd M/D, HH:mmA")}
        </OrderBy>
      ) : timeLeft > 0 ? (
        <>
          <label>Last call in</label>
          <Hour>{` ${hours}h:`}</Hour>
          <Minute>{`${minutes}m:`}</Minute>
          <Second isDoubleDigit={seconds > 9}>{`${seconds}`}</Second>
          <Seconds isDoubleDigit={seconds > 9}>s</Seconds>
        </>
      ) : (
        <Closed>Closed at {moment(deadline).format("HH:mmA")}</Closed>
      )}
    </CountDownContainer>
  );
};
