import React, { useState, useEffect } from "react";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rest, set } from "lodash";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import { useDispatch, useStore } from "react-redux";
import { saveMealReminder } from "src/store/actions";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { LightGreen } from "./WantToTry";
import {
  CenteredByColumn,
  Centered,
  centeredByColumn,
  CenteredByColumnLeft,
  text12300,
  BackgroundGrey,
  Text12300,
  text13500,
  textOverflow,
} from "../Shared";

const Row = styled(Centered)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const DatePickerWrapper = styled(Centered)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  .react-datepicker-wrapper {
    position: absolute;
    right: 0px;
    top: 0px;
    .react-datepicker__input-container {
      /* width: 140px; */
      position: absolute;
      right: 0px;
      top: 0px;

      input {
        margin-right: 0px;
        /* padding: 5px 0px; */
        ${text12300}
        font-weight: 500;
        padding: 0px;
        width: 165px;
        box-sizing: border-box;
        text-align: center;
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: ${LightGreen};
        color: green;
        border-bottom: none;
        font-weight: 500;
        border-radius: 5px;
      }
    }
  }
`;
const FieldName = styled.span`
  ${text12300}
  font-size: 14px;
  font-weight: 500 !important;
  text-transform: capitalize;
  ${textOverflow}
`;
const Time = styled(Centered)`
  justify-content: flex-end;
  ${text12300}
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
`;

const Change = styled.button``;
const ChangeDate = styled(Change)`
  margin-right: 10px;
`;
const ChangeTime = styled(Change)``;

const InputContainer = styled(CenteredByColumn)`
  align-items: flex-end;
`;
type Props = {
  reminderSetting: {
    windowStart: number; // timestamp in milliseconds
    mealType: string;
    reminderTime: number;
    restaurantName: string;
    mealId: string;
    dayIndex: number;
    mealIndex: number;
    isActive: boolean;
    formats: string[];
  };
  onCancel: () => void;
};
const CancelButton = styled.button`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  svg {
    font-size: 24px;
  }
  /* &:hover {
        color: red;
    } */
  &:focus {
    outline: none;
  }
`;

const DateInput = React.forwardRef<
  HTMLButtonElement,
  { value?: string; onClick?: () => void }
>(({ value, onClick }, ref) => (
  <StyledDateInput onClick={onClick} ref={ref}>
    {value}
    <FontAwesomeIcon icon={faPencil} />
  </StyledDateInput>
));

const StyledDateInput = styled.button`
  ${text12300}
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  padding: 0px 8px;
  /* width: 200px; */
  text-align: center;
  background-color: ${LightGreen};
  color: green;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  svg {
    font-size: 12x;
    margin-left: 5px;
  }
`;
const ReminderSettings: React.FC<Props> = ({ onCancel, reminderSetting }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const [formats, setFormats] = useState<string[]>(
    reminderSetting?.formats?.length > 0
      ? reminderSetting?.formats
      : ["appNotification", "email", "text"]
  );
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState(
    moment(
      reminderSetting?.reminderTime ||
        reminderSetting?.windowStart - 7200 * 1000
    ).toDate()
  );
  const [saving, setSaving] = useState(false);
  const [enabled, setEnabled] = useState(
    reminderSetting?.reminderTime === undefined
      ? true
      : reminderSetting?.isActive
  );
  useEffect(() => {
    // if (editedReminderTime < now) {
    //   setError("editedReminderTime time must be in the future.");
    // } else if (editedReminderTime > latestAllowedReminder) {
    //   setError(
    //     "Reminder time must be at least 1 hour before the window start."
    //   );
    // } else {
    //   setError(null);
    // }
    if (formats.length === 0) {
      setError("Please select at least one reminder format.");
    } else {
      setError(null);
    }
  }, [formats]);

  useEffect(() => {
    console.log("reminderSetting", reminderSetting);
  }, [reminderSetting]);

  if (!reminderSetting) return null;

  const { windowStart, mealType, restaurantName, mealId, dayIndex, mealIndex } =
    reminderSetting;
  const latestAllowedReminder = windowStart - 3600 * 1000 - 10 * 60 * 1000; // windowStart minus 1 hour

  const toggleFormat = (format: string) => {
    setFormats((prev) =>
      prev.includes(format)
        ? prev.filter((f) => f !== format)
        : [...prev, format]
    );
  };

  const handleSave = () => {
    if (!error) {
      setSaving(true);
      //mealId, reminderTime, reminderFormats
      saveMealReminder(
        mealId,
        startDate.getTime(),
        formats,
        dayIndex,
        mealType,
        mealIndex,
        enabled,
        restaurantName,
        windowStart
      )(dispatch, store.getState)
        .then((res) => {
          setSaving(false);
          onCancel();
        })
        .catch((e) => {
          setSaving(false);
          setError(e.message);
        });
    }
  };

  return (
    <Container>
      <Content>
        <CancelButton
          disabled={saving}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCancel();
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} color="black" fontSize={24} />
        </CancelButton>
        <Row>
          <FieldName>{restaurantName?.split("-")?.[0]?.trim()}</FieldName>
          <Time>
            <span>
              {/* {moment(windowStart).format("ddd MM/DD/YYYY, ")} */}
              {mealType === "earlyDinner" ? "early dinner" : mealType}
            </span>
          </Time>
        </Row>
        <Row>
          <FieldName>Last call</FieldName>
          <Time>
            <span>
              {moment(windowStart).add(-1, "hour").format("ddd MM/DD, h:mm A")}
            </span>
          </Time>
        </Row>
        <DatePickerWrapper>
          <FieldName>Remind me at</FieldName>
          <DatePicker
            selected={startDate}
            minDate={moment().add(1, "h").toDate()}
            maxDate={moment(latestAllowedReminder).toDate()}
            onChange={(date) => setStartDate(date)}
            showTimeSelect
            dateFormat={"eee MM/dd, h:mm aa"}
            popperPlacement="bottom-end"
            customInput={<DateInput />}
            timeIntervals={15}
          />
        </DatePickerWrapper>
        {error && (
          <Error>
            <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
            {error}
          </Error>
        )}
        <Section>
          <Row>
            <FieldName>Remind me by</FieldName>
            <InputContainer>
              <Label>
                App Notification
                <input
                  type="checkbox"
                  checked={formats.includes("appNotification")}
                  onChange={() => toggleFormat("appNotification")}
                />
              </Label>

              <Label>
                Email
                <input
                  type="checkbox"
                  checked={formats.includes("email")}
                  onChange={() => toggleFormat("email")}
                />
              </Label>

              <Label>
                Text
                <input
                  type="checkbox"
                  checked={formats.includes("text")}
                  onChange={() => toggleFormat("text")}
                />
              </Label>
            </InputContainer>
          </Row>
        </Section>
        <Row>
          <FieldName>Reminder Status</FieldName>
          <ToggleSwitch>
            <input
              type="checkbox"
              checked={enabled}
              onChange={() => setEnabled(!enabled)}
            />
            <Slider />
          </ToggleSwitch>
        </Row>
        <ButtonRow>
          <Button
            disabled={saving}
            type="button"
            onClick={onCancel}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleSave}
            disabled={!!error || saving}
            variant="primary"
          >
            {`${saving ? "Saving..." : "Save"}`}
          </Button>
        </ButtonRow>
      </Content>
    </Container>
  );
};

// Styled Components

const Container = styled.div`
  ${centeredByColumn}
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  align-items: stretch;
  @media screen and (min-width: 1200px) {
    justify-content: center;
    align-items: center;
  }
`;

const Content = styled(CenteredByColumn)`
  width: 100vw;
  background-color: white;
  padding: 60px 20px 40px 20px;
  align-items: stretch;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: relative;
  @media screen and (min-width: 1200px) {
    width: 600px;
    padding: 60px 40px 30px 40px;
    border-radius: 20px;
  }
`;

const Section = styled(CenteredByColumnLeft)`
  margin-bottom: 5px;
  span {
    position: relative;
    ${text12300}
    font-weight: 500;
    font-size: 14px;
    color: black;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 14px;
`;

const Error = styled.p`
  color: red;
  ${text12300}
  font-weight: 500;
  font-size: 13px;
  /* margin-top: 8px; */
  svg {
    margin-right: 5px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  /* gap: 8px; */
  ${text12300}
  font-weight: 500;
  font-size: 14px;
  height: 30px;
  justify-content: space-between;
  input {
    margin: 0px;
    margin-left: 8px;
  }
`;

const ButtonRow = styled(Centered)`
  gap: 8px;
  margin: 20px 0px 0px 0px;
  justify-content: flex-end;
`;

const Button = styled.button<{ variant: "primary" | "secondary" }>`
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin: 0px 5px;
  width: 100px;
  ${text13500}
  font-weight: 600;
  ${({ variant }) => {
    if (variant === "primary") {
      return `
        background-color: #007bff;
        color: white;
        font-weight: 500px;
        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      `;
    } else {
      return `
        background-color: #f0f0f0;
        border: 1px solid #ccc;
      `;
    }
  }}
`;
// Toggle Styles
const ToggleRow = styled(Row)`
  /* margin-bottom: 15px; */
`;

const ToggleSwitch = styled.label`
  position: relative;
  width: 40px;
  height: 20px;
  display: inline-block;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + span {
    background-color: green;
  }

  input:checked + span::before {
    transform: translateX(20px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.3s;
  border-radius: 20px;

  ::before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.3s;
    border-radius: 50%;
  }
`;

export default ReminderSettings;
