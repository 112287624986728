import * as Components from ".";

const Maggio = ({ onClick }) => {
  const link = "/meal/02-14-2025/express-dinner/maggiano-s-little-italy";
  const image =
    "https://olo-images-live.imgix.net/20/20724234c1b041a190f751441505719f.jpg?auto=format%2Ccompress&q=60&cs=tinysrgb&w=600&h=500&fit=crop&fm=png32&s=65a539d7cd60113b73690ae5cf07bb13";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Maggiano's Little Italy
        </Components.RestaurantName>
        <Components.MenuName>Bring The Romance Home</Components.MenuName>
        <Components.Notes>
          <Components.Warning>
            {/* <FontAwesomeIcon icon={faExclamationTriangle} /> */}
            <Components.Bullet></Components.Bullet>
            Pre-Order&nbsp;<b>by noon Friday, 2/14</b>
          </Components.Warning>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>5-6P Friday, Valentine's Day</b>&nbsp;
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                That's Amore Carryout Bundle for 2
              </Components.DishName>
              <Components.AlaCartePrice>$110</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              <b>NEW Wagyu Meatballs</b>
              <br></br>
              <b>Choice of Salad</b>
              <br></br>• Chef's Signature Caesar • Maggiano's Salad<br></br>
              <b>Two Entrees of Your Choice</b>
              <br></br>• NEW Wagyu Stuffed Shells • Fettuccine Bolognese •
              Rigatoni alla Vodka • Beef Tenderloin Medallions • Our Famous
              Rigatoni “D” • Chicken Piccata • Chicken Marsala • Chicken
              Parmesan • Blackened Salmon with Crispy Calabrian Shrimp • Shrimp
              Scampi
              <br></br>
              <b>Desserts</b>
              <br></br>• Tiramisu • New York Style Cheesecake Cookies<br></br>
              <b>Fresh Baked Bread</b>
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
          >
            pre-order Valentine's Day Dinner
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Maggio;
