import React, { ReactElement, useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";
import "react-toggle/style.css"; // for ES6 modules
import { faRedo as faReload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { RootState } from "src/store";
import styled, { keyframes } from "styled-components";
import { TrackingMap } from "./TrackingMap";
import { LOGGING, receiptHourFormatShort } from "../..";
import { trackOrders } from "../../../../../store/actions";
import {
  Beige,
  Centered,
  centeredByColumn,
  text12300,
  BorderGrey,
  CenteredByColumn,
  CenteredByColumnLeft,
} from "../../Shared";

type ContentContainerProps = {};
const ContentContainer = styled(Centered)<ContentContainerProps>`
  width: 100%;
  /* padding: 0px 0px 3px 20px; */
  justify-content: flex-start;
  overflow-x: scroll;
  margin-bottom: -15px;
  @media only screen and (min-width: 800px) {
    padding: 0px calc(1vw * var(--desktop-left-padding) + 90px);
    padding-bottom: 20px;
  }
  @media only screen and (min-width: 1200px) {
    background-color: ${Beige};
  }
`;
type OrderProps = {
  showMap: Boolean;
};
const OrderContainer = styled(Centered)`
  position: relative;
  /* border: 2px solid red; */
  margin: 20px 20px 5px 20px;
  padding-bottom: 20px;
`;
const MapBottomPadding = styled.div`
  position: absolute;
  width: 100%;
  height: 25px;
  background-color: white;
  z-index: 2;
  bottom: -10px;
  @media only screen and (min-width: 1200px) {
    background-color: ${Beige};
  }
`;
const Order = styled.div<OrderProps>`
  /* border: 1px solid ${BorderGrey}; */
  box-sizing: border-box;
  height: 220px;
  height: ${(props) => (props.showMap ? "220px" : "100px")};
  padding: 0;
  position: relative;
  width: 400px;
  max-width: calc(100vw - 40px);
  ${CenteredByColumn}
  svg {
    margin-right: 5px;
  }
`;
const OrderBorder = styled.div`
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100%;
  height: 125px;
  /* background-color: yellow; */
  border: 1px solid ${BorderGrey};
  border-top: none;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
  z-index: 3;
  /* box-sizing: content-box; */
  width: 400px;
  max-width: calc(100vw - 40px);
`;

const Restaurant = styled(Centered)`
  span {
    white-space: nowrap;
  }
`;
const Status = styled(Centered)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  align-self: stretch;
  width: 100%;
  justify-content: flex-start;
  b {
    font-weight: 300;
    margin-left: 10px;
    text-transform: lowercase;
    // font-size: 12px;
    // border-left: 1px solid black;
  }
`;
const ETA = styled(Centered)`
  white-space: nowrap;
  b {
    font-weight: 500;
  }
`;
const From = styled.span`
  margin: 0px 3px;
`;
const To = styled.span`
  margin-left: 10px;
  // font-weight: 300;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const ReloadButton = styled.button`
  position: absolute;
  top: 12px;
  right: 10px;
  margin: 0px;
  padding: 0px;
  svg {
    font-size: 14px;
  }
`;
const Spinning = styled(ReloadButton)`
  animation: ${spin} 0.5s linear infinite;
`;

const TextContainer = styled.div`
  ${centeredByColumn}
  align-items: flex-start;
  padding: 10px 40px 10px 20px;
  ${text12300}
  height: 100px;
  width: 100%;
  background-color: ${Beige};
  border: 1px solid ${BorderGrey};
  border-bottom: none;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  @media only screen and (min-width: 1200px) {
    background-color: white;
  }
`;
export interface TrackingProps {}
export const Tracking: React.FC<TrackingProps> =
  ({}: TrackingProps): ReactElement => {
    const tracking = useSelector((state: RootState) => state.tracking);
    const [loading, setLoading] = useState(false);
    const [mapKey, setMapKey] = useState(0);
    const dispatch = useDispatch();
    const store = useStore();

    useEffect(() => {
      trackOrders()(dispatch, store.getState).then(() => {});
    }, []);
    const reload = useCallback(async () => {
      // @ts-ignore
      setLoading(true);
      await trackOrders()(dispatch, store.getState);
      setLoading(false);
      setMapKey((prevKey) => prevKey + 1);
    }, []);
    if (tracking.length < 1) {
      return null;
    }
    return (
      <ContentContainer>
        {tracking.map((t) => {
          const latest = t.status.length ? t.status[t.status.length - 1] : null;
          // LOGGING && console.log("looping got latest:", latest);
          const { destination, departure, current, stopNum } = t;
          const showMap =
            (current?.lng && current?.lat) ||
            (destination?.lng && destination?.lat) ||
            (departure?.lng && departure?.lat);

          return (
            <OrderContainer>
              {showMap ? <OrderBorder></OrderBorder> : null}
              {showMap ? <MapBottomPadding></MapBottomPadding> : null}
              <Order showMap={showMap}>
                <TextContainer>
                  {loading ? (
                    <Spinning>
                      <FontAwesomeIcon icon={faReload} />
                    </Spinning>
                  ) : (
                    <ReloadButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        reload();
                      }}
                    >
                      <FontAwesomeIcon icon={faReload} />
                    </ReloadButton>
                  )}
                  <Restaurant>
                    {/* <FontAwesomeIcon icon={faHouse}/> */}
                    <span>{t.restaurant}</span>
                  </Restaurant>
                  <Status>
                    <span>{latest?.type}</span>
                    {latest.type === "picked up" ||
                    latest.type === "en route" ? (
                      <b>
                        {stopNum} {`${stopNum > 1 ? "stops" : "stop"}`} before
                        you
                      </b>
                    ) : null}
                  </Status>
                  {latest ? (
                    latest.type === "delivered" ? (
                      <ETA>
                        <b>
                          {moment(latest.etaRange.start).format(
                            receiptHourFormatShort
                          )}
                        </b>
                      </ETA>
                    ) : moment(latest.etaRange.start).valueOf() -
                        moment().valueOf() <
                      1000 * 60 * 30 ? (
                      <ETA>
                        Arriving in
                        <b>
                          <From>
                            {(
                              Math.round(
                                Math.max(
                                  0,
                                  moment(latest.etaRange.start).valueOf() -
                                    moment().valueOf()
                                ) / 60000
                              ) +
                              latest.etaRange.range / 2
                            ).toFixed(0)}
                          </From>
                        </b>
                        minutes
                      </ETA>
                    ) : (
                      <ETA>
                        Arriving
                        <b>
                          <From>
                            {moment(latest.etaRange.start).format(
                              receiptHourFormatShort
                            )}
                          </From>
                          -
                          <To>
                            {moment(latest.etaRange.start)
                              .add(latest.etaRange.range, "m")
                              .format(receiptHourFormatShort)}
                          </To>
                        </b>
                      </ETA>
                    )
                  ) : null}
                </TextContainer>
                {showMap ? (
                  <TrackingMap
                    key={mapKey}
                    current={current}
                    destination={destination}
                    departure={departure}
                  />
                ) : null}
              </Order>
            </OrderContainer>
          );
        })}
      </ContentContainer>
    );
  };
