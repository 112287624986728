import { faGift } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import styled from "styled-components";
import { Container, Field, Title } from "./Contact";
import { Blue, Gold, Pink, PinkPeach, BackgroundDarkGreen } from "../../Shared";
const CouponField = styled(Field)`
  label {
    font-weight: 600;
  }
  span {
    // background-color: lightgreen;
    background-color: #f1d627;
    // background-color: ${BackgroundDarkGreen};
    // background-color: #86dbd4;
    padding: 0px 7px;
    // color: white;
    border-radius: 5px;
    position: relative;
    right: -5px;
    font-weight: 600;
    svg {
      color: #e23680;
      margin-right: 7px;
      font-size: 16px;
    }
  }
`;
export const Total = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const { credit, total, due, discount } = cart;
  return (
    <>
      <Container>
        {credit > 0 || discount > 0 ? (
          <Field>
            <label>total</label>
            <span>${total?.toFixed(2)}</span>
          </Field>
        ) : null}
        {credit > 0 && (
          <CouponField>
            <label>credit</label>
            <span>
              {/* 💝 */}
              <FontAwesomeIcon icon={faGift} />
              -${credit?.toFixed(2)}
            </span>
          </CouponField>
        )}
        {discount > 0 && (
          <Field>
            <label>credit</label>
            <span>-${discount?.toFixed(2)}</span>
          </Field>
        )}
        <Field>
          <label>due</label>
          <span>${due?.toFixed(2)}</span>
        </Field>
      </Container>
    </>
  );
};
