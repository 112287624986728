import { LOGGING } from "../../constants";
import { LOAD_PIXEL } from "../actionTypes";
import { apiCall } from "../api";

export const loadPixel = (pixel) => ({
  type: LOAD_PIXEL,
  pixel,
});

export const loadClickID = (
  source,
  fbclid,
  restaurantId = null,
  cuisineName = null,
  multiCuisine = null,
  lan = null
) => {
  return (dispatch, getState) => {
    dispatch(
      loadPixel({
        source,
        fbclid,
        restaurantId,
        cuisineName,
        multiCuisine,
        lan,
      })
    );
    return;
  };
};
