import React, { useEffect, useState } from "react";
import // faCheck as faHasNotTried,
// faBookmark as faNotWantToTry,
"@fortawesome/pro-light-svg-icons";
import {
  faCheck as faHasTried,
  faCheck as faHasNotTried,
  faBookmark as faNotWantToTry,
} from "@fortawesome/pro-regular-svg-icons";
import { faBookmark as faWantsToTry } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useStore } from "react-redux";
import { Loading } from "src/screens/shared";
import LoadingCircle from "src/screens/shared/LoadingCircle";
import styled from "styled-components";
import { AccountingCalendar } from "../..";
import { LOGGING } from "../../../../../src/constants";
import {
  setRestaurantWantsToTry,
  setRestaurantHasTried,
} from "../../../../../src/store/actions";
import {
  BorderGrey,
  centered,
  TextGrey,
  text12300,
  Centered,
  centeredByColumn,
} from "../Shared";

export const LightGreen = "#d4f0d4";
// const Green = "#4CAF50";

const buttonHeight = 30;

export const UserAction = styled.button<{
  isChecked?: boolean;
  isTried?: boolean;
  lightMode?: boolean;
}>`
  ${centered}
  height: ${buttonHeight}px;
  border-radius: ${buttonHeight / 2}px;
  position: relative;
  margin: 0px;
  /* width: 130px; */
  background-color: ${(props) =>
    props.isChecked ? LightGreen : props.lightMode ? "white" : "transparent"};
  border: ${(props) => (props.lightMode ? "0px" : "1px")} solid ${LightGreen};
  padding: 0px ${(props) => (props.lightMode ? "0px" : "10px")} 0px
    ${(props) => (props.lightMode ? "0px" : "30px")};
  /* width: ${(props) => (props.lightMode ? `${buttonHeight}px` : "auto")}; */
  /* padding: 0px 20px; */
  svg {
    font-size: 15px;
    position: ${(props) => (props.lightMode ? "relative" : "absolute")};
    left: ${(props) => (props.lightMode ? "0px" : "10px")};
    color: green;
    z-index: 0;
    margin-right: ${(props) => (props.lightMode ? "0px" : "5px")};
  }
  //disabled
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:first-child {
    margin-right: 10px;
  }
  span {
    ${text12300}
    font-weight: 400;
    line-height: 11px;
    /* display: ${(props) => (props.lightMode ? "none" : "inline")}; */
  }
`;
// set lightMode default to false
export const Tried = ({ restaurant, lightMode = false }) => {
  // LOGGING && console.log("Tried called with restaurant: ", restaurant);
  const [hasTried, setHasTried] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    setHasTried(Boolean(restaurant?.hasTried));
  }, [restaurant]);

  return (
    <UserAction
      lightMode={lightMode}
      isChecked={hasTried}
      isTried={hasTried}
      disabled={loading}
      onClick={(e) => {
        e.preventDefault();
        // setLoading(true);
        setHasTried(!hasTried);
        setRestaurantHasTried(
          restaurant.restaurantId || restaurant._id,
          !hasTried,
          restaurant?.name
        )(dispatch, store.getState).then((res) => {
          // setLoading(false);
          LOGGING && console.log("setRestaurantWantsToTry response: ", res);
        });
      }}
    >
      {/* {loading && <LoadingCircle size={30} margin="0px" isAbsolute={true}/>} */}

      <FontAwesomeIcon
        color={hasTried ? "green" : "#aaa"}
        icon={hasTried ? faHasTried : faHasNotTried}
      />
      <span>
        {loading ? "saving..." : hasTried ? "Tried" : "Mark as tried"}
      </span>
    </UserAction>
  );
};

export const WantToTry = ({ restaurant, lightMode = false }) => {
  // LOGGING && console.log("WantToTry called with restaurant: ", restaurant);
  const [wantsToTry, setWantsToTry] = useState(false);
  const dispatch = useDispatch();
  const store = useStore();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setWantsToTry(restaurant?.wantsToTry);
  }, [restaurant]);
  return (
    <UserAction
      lightMode={lightMode}
      disabled={loading}
      isChecked={wantsToTry}
      onClick={(e) => {
        e.preventDefault();
        // setLoading(true);
        setWantsToTry(!wantsToTry);
        setRestaurantWantsToTry(
          restaurant.restaurantId || restaurant._id,
          !wantsToTry,
          restaurant?.name
        )(dispatch, store.getState).then((res) => {
          // setLoading(false);
          LOGGING && console.log("setRestaurantWantsToTry response: ", res);
        });
      }}
    >
      {/* {loading && <LoadingCircle size={30} margin="0px" />} */}
      <FontAwesomeIcon
        color={wantsToTry ? "green" : "#aaa"}
        icon={wantsToTry ? faWantsToTry : faNotWantToTry}
      />
      <span>{loading ? "saving..." : "Want to try"}</span>
    </UserAction>
  );
};
