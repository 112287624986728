import React, { Component } from "react";
import moment from "moment-timezone";
import posthog from "posthog-js";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LOGGING, Loading, Navbar, Routes } from ".";
import { RoutesOverview } from "./components/admin/RoutesOverview";
import { SubNav } from "../../components/SubNav";
import {
  readAllRoutes,
  payDriverForRoute,
  skipTaskForDriver,
  deliverTaskForDriver,
  updateUserInfo,
} from "../../store/actions";

class PageRoutes extends Component {
  constructor(props) {
    LOGGING && console.log("PageRoutes constructor called with props:", props);
    super();
    this.state = {
      loading: true,
      routes: {},
    };
    this.handleSendPayment = this.handleSendPayment.bind(this);
    this.handleChangePaymentAmount = this.handleChangePaymentAmount.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleSkipTask = this.handleSkipTask.bind(this);
    this.handleDeliverTask = this.handleDeliverTask.bind(this);
    this.handleSaveCredit = this.handleSaveCredit.bind(this);
    this.handleSaveAddressMap = this.handleSaveAddressMap.bind(this);
    this.handleLoadRoutes = this.handleLoadRoutes.bind(this);
  }

  handleSendPayment(route_id, e) {
    e.preventDefault();
    LOGGING && console.log("handleSendPayment", route_id);

    let routes = this.state.routes;
    let route;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i]._id === route_id) {
        routes[i].driverPaymentInfo.paying = true;
        route = routes[i];
        break;
      }
    }
    this.setState({
      routes: routes,
    });
    this.props
      .payDriverForRoute({
        routeId: route_id,
        amount: route.driverPaymentInfo.amount,
        message: route.driverPaymentInfo.message,
      })
      .then((updated_route) => {
        let routes = this.state.routes;
        for (let i = 0; i < routes.length; i++) {
          if (routes[i]._id === route_id) {
            routes[i] = updated_route;
            break;
          }
        }
        this.setState({
          route,
        });
      })
      .catch((err) => {
        LOGGING && console.log("payDriverForRoute got err", err);
        let routes = this.state.routes;

        for (let i = 0; i < routes.length; i++) {
          if (routes[i]._id === route_id) {
            routes[i].driverPaymentInfo.paymentErrormessage = err.message;
            routes[i].driverPaymentInfo.paying = false;
            break;
          }
        }
        this.setState({
          route,
        });
      });
  }

  handleChangePaymentAmount(route_id, e) {
    LOGGING &&
      console.log(
        "handleChangePaymentAmount rendering with e.target:",
        route_id,
        e.target.value
      );
    let routes = this.state.routes;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i]._id === route_id) {
        routes[i].driverPaymentInfo.amount = e.target.value;
        break;
      }
    }
    this.setState({
      routes: routes,
    });
  }

  handleChangeMessage(route_id, e) {
    console.log("handleChangeMessage", route_id, e);
    let routes = this.state.routes;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i]._id === route_id) {
        routes[i].driverPaymentInfo.message = e.target.value;
        break;
      }
    }
    this.setState({
      routes: routes,
    });
  }

  handleSkipTask({ driverId, taskId }) {
    this.props.skipTaskForDriver({ driverId, taskId }).then(() => {
      this.handleLoadRoutes(true);
    });
  }

  handleDeliverTask({ driverId, taskId }) {
    this.props.deliverTaskForDriver({ driverId, taskId }).then(() => {
      this.handleLoadRoutes(true);
    });
  }

  handleSaveCredit(userId, credit) {
    this.props.updateUserInfo(userId, { credit }).then(() => {
      this.handleLoadRoutes(true);
    });
  }

  handleSaveAddressMap(userId, addressMap) {
    this.props.updateUserInfo(userId, { addressMap }).then(() => {
      this.handleLoadRoutes(true);
    });
  }

  //foo

  handleLoadRoutes(today) {
    this.setState({
      loading: true,
    });
    this.props.readAllRoutes(today).then((routes) => {
      const filledRoutes = routes.map((route) => {
        return {
          driverPaymentInfo: route.driverPaymentInfo || {
            amount: 40,
            message: `${
              moment(route.endTime).hour() > 15 ? "Dinner" : "Lunch"
            } delivery ${moment(route.endTime).format("MMM DD YYYY")}`,
          },
          ...route,
        };
      });
      this.setState(
        {
          routes: filledRoutes,
          loading: false,
        },
        () => {
          window.scrollTo(0, 0);
        }
      );
    });
  }

  componentDidMount() {
    LOGGING && console.log("PageRoutes componentdidmount");
    this.handleLoadRoutes(true);

    // Make sure opts out PostHog
    posthog.opt_out_capturing();
  }

  render() {
    const { currentUser } = this.props;
    const { routes } = this.state;
    LOGGING &&
      console.log("PageRoutes rendering with", {
        props: this.props,
        state: this.state,
      });
    if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
      return <Redirect to="/" />;
    }
    return (
      <div className="page">
        <Navbar noShadow={true} />
        <SubNav
          items={["today"]} // ,'all']}
          onSelected={(item) => this.handleLoadRoutes(item === "today")}
        />
        {this.state.loading ? (
          <Loading />
        ) : (
          <>
            <RoutesOverview initialRoutes={routes} />
            <Routes
              routes={routes}
              onSkipTask={this.handleSkipTask}
              onDeliverTask={this.handleDeliverTask}
              onSaveCredit={this.handleSaveCredit}
              onSaveAddressMap={this.handleSaveAddressMap}
              onChangePaymentAmount={this.handleChangePaymentAmount}
              onSendPaymant={this.handleSendPayment}
              onChangeMessage={this.handleChangeMessage}
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageRoutes got redux state:", state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readAllRoutes,
  payDriverForRoute,
  skipTaskForDriver,
  deliverTaskForDriver,
  updateUserInfo,
})(PageRoutes);
