import React, { Component } from "react";
import moment from "moment-timezone";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { FoodieEvent, FoodieSource, TrackEvent } from "src/api/tracker";
import weeklyMenu from "src/store/reducers/weeklyMenu";
import {
  LOGGING,
  Footer,
  NavbarSingleMeal,
  getViewableDays,
  getWeeklyMenuWindows,
  WeeklyMenuLink,
  DateNavbar,
  MenuDateFormatLong,
  DefaultTimeZone,
  EmptyWeeklyMenu,
} from ".";
import AppStoreLink from "./components/customers/banners/AppStoreLink";
import GoogleOneTapSignupWithProvider from "./components/customers/banners/GoogleOneTapSignupWidget";
import GuestAuth from "./components/customers/banners/GuestAuth";
import GuestWelcomeMessageByCuisine from "./components/customers/banners/GuestWelcomeMessageByCuisine";
import { MealHighlights } from "./components/customers/banners/MealHighlights";
import NavGuest from "./components/customers/banners/NavGuest";
import { UpcomingKeyRestaurants } from "./components/customers/banners/UpcomingKeyRestaurants";
import { NewGuestCredit } from "./components/referrals/NewGuestCredit";
import NewYearMenus from "./components/referrals/NewYearMenus";
import { Beige, BorderGrey } from "./components/Shared";
import {
  fetchWeeklyMeals,
  fetchWeeklyMealDetails,
  fetchDeliveryZoneByCity,
  setDeliveryCity,
  resetDeliveryCity,
  updateDeliveryCity,
  getReferralCode,
  emptyCart,
  saveNomination,
  submitNomination,
  getRestaurantsByAlphabet,
  readPastOrdersByCustomer,
  readFutureOrdersByCustomer,
  readFutureOrdersFromRedux,
  updateInteraction,
  readHighlight,
  fetchWeeklyMealDetailsLight,
  getFavorites,
  getTastes,
  loadClickID,
} from "../../store/actions";
import { LoadingTop } from "../shared";
class PageGuest extends Component {
  constructor(props) {
    super(props);
    const { currentUser, history, location, pixel } = props;
    // Preserve the query string in the URL
    if (history.action === "POP") {
      history.replace({
        pathname: location.pathname,
        search: location.search,
        state: location.state,
      });
    }
    const urlParams = new URLSearchParams(location.search);
    const source = urlParams.get("source") || pixel?.source;
    const fbclid = urlParams.get("fbclid") || pixel?.fbclid;
    const restaurantId = urlParams.get("restaurantId") || pixel?.restaurantId;
    const cuisineName = urlParams.get("cuisineName") || pixel?.cuisineName;
    const multiCuisine = urlParams.get("multiCuisine") || pixel?.multiCuisine;
    const lan = urlParams.get("lan") || pixel?.lan;
    LOGGING &&
      console.log("PageGuest got:", {
        source,
        fbclid,
        restaurantId,
        cuisineName,
        multiCuisine,
        lan,
      });
    if (
      fbclid ||
      source ||
      restaurantId ||
      cuisineName ||
      multiCuisine ||
      lan
    ) {
      this.props.loadClickID(
        source,
        fbclid,
        restaurantId,
        cuisineName,
        multiCuisine,
        lan
      );
      LOGGING && console.log("PageGuest got pixel:", this.props?.pixel);
    }
    const hour = moment().hour();
    if (history.action === "POP") {
      history.replace();
    }
    const homeScrollY = this.props?.location?.state?.homeScrollY || 0;
    const hasGiftCard = currentUser?.features?.includes("GIFT_CARD");

    let nomination = [];
    if (
      props.nomination &&
      props.nomination != "undefined" &&
      props.nomination.length > 0
    ) {
      nomination = props.nomination;
    }
    this.state = {
      loading: weeklyMenu?.length > 0 ? false : "loading menu...",
      freeze: -1,
      readingCounter: 0,
      hasDrafts: currentUser?.user?.membership?.isActive,
      showDeliveryCityOptions: false,
      viewableOptions: {},
      showConfirmation: false,
      showOrderConfirmation: false,
      showPreferences: false,
      // currentUser.isAuthenticated &&
      // !currentUser?.user?.preferences &&
      // (moment().hour() > 17 || moment().hour() < 7),
      dishesLoadedByDayIndex: null,
      selectedDeliveryZone: null,
      referralCode: null,
      showReferralCodeAnnouncement: false,
      showUserMenu: false,
      mealRefs: [],
      howItWorksRef: React.createRef(),
      showShare: false,
      showSocialActions: false,
      selectedDayIndex: 0,
      today: moment().tz(DefaultTimeZone).format(MenuDateFormatLong),
      tomorrow: moment()
        .tz(DefaultTimeZone)
        .add(1, "d")
        .format(MenuDateFormatLong),
      weekOfYear: moment().tz(DefaultTimeZone).format("w"),
      homeScrollY,
      showHowItWorks: true,
      viewMode: 0,
      quickAccess: "home",
      nomination: { ...nomination },
      showNominationform: false,
      showSurvey: true,
      showGuestAuth: false,
      showTasteSurvey:
        (!currentUser?.user?.tastes || currentUser?.user?.tastes?.length < 3) &&
        ![9, 10, 15, 16].includes(hour),
      // create 7 refs for each day
      dayRefs: Array(7)
        .fill(0)
        .map((i) => React.createRef()),
    };

    this.handleHasDrafts = this.handleHasDrafts.bind(this);
    this.handleSelectViewMode = this.handleSelectViewMode.bind(this);
    this.handleGoToHowItWorks = this.handleGoToHowItWorks.bind(this);
    this.handleToggleShowSocialActions =
      this.handleToggleShowSocialActions.bind(this);
    this.handleChangeDeliveryCity = this.handleChangeDeliveryCity.bind(this);
    this.handleSelectDeliveryCity = this.handleSelectDeliveryCity.bind(this);
    this.handleToggleShowDeliveryCityOptions =
      this.handleToggleShowDeliveryCityOptions.bind(this);
    this.handleToggleUserMenu = this.handleToggleUserMenu.bind(this);
    this.handleToggleShowShare = this.handleToggleShowShare.bind(this);
    this.handleGoToDate = this.handleGoToDate.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleBackToTop = this.handleBackToTop.bind(this);
    this.handleToggleShowHowItWorks =
      this.handleToggleShowHowItWorks.bind(this);
    this.handleFreezeBackground = this.handleFreezeBackground.bind(this);
    this.handleClosePage = this.handleClosePage.bind(this);
    this.handleSetReadingCounter = this.handleSetReadingCounter.bind(this);
    this.handleCloseGuestAuth = this.handleCloseGuestAuth.bind(this);
  }
  handleCloseGuestAuth() {
    this.setState({ showGuestAuth: false });
  }
  handleHasDrafts(hasDrafts) {
    this.setState({ hasDrafts });
  }
  handleClosePage() {
    this.setState({
      showPreferences: false,
    });
  }
  handleSetReadingCounter(readingCounter) {
    this.setState({ readingCounter });
    const { currentUser } = this.props;
    if (readingCounter > 3) {
      const selectedDeliveryZone =
        this.props.deliveryZoneByCity[this.props.deliveryCity];
      const viewableDays = getViewableDays();
      const { windowsForWeeklyMenu } = getWeeklyMenuWindows(viewableDays);

      this.setState({ loading: "loading menu..." }, () => {
        // blocking loading
        this.props
          .fetchWeeklyMeals(
            windowsForWeeklyMenu,
            selectedDeliveryZone,
            currentUser?.user?._id
          )
          .then(() => {
            LOGGING && console.log("fetchWeeklyMeals done");
            this.setState({ loading: false }, () => {
              LOGGING && console.log("fetchWeeklyMeals done2");
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            });
          });
      });
    }
  }

  handleFreezeBackground() {
    const { freeze, homeScrollY } = this.state;
    LOGGING && console.log("handleFreezeBackground called with: ", freeze);
    if (freeze >= 0) {
      const resumeY = freeze;
      this.setState({ freeze: -1 }, () => {
        window.scrollTo(0, resumeY);
      });
    } else {
      this.setState({ freeze: homeScrollY, innerHeight: window.innerHeight });
    }
  }

  handleToggleShowHowItWorks(e) {
    e.preventDefault();
    const { showHowItWorks } = this.state;
    this.setState({ showHowItWorks: !showHowItWorks });
  }

  handleGoToHowItWorks(e) {
    e.preventDefault();
    const { howItWorksRef } = this.state;
    window.scrollTo({
      top: howItWorksRef?.current?.offsetTop + window.innerHeight() / 2,
      left: 0,
      behavior: "auto",
    });
  }
  handleSelectViewMode(viewMode, e) {
    e.preventDefault();
    this.setState({ viewMode });
  }
  handleBackToTop(e) {
    e.preventDefault();
    this.setState(
      {
        selectedDayIndex: 0,
      },
      () => {
        window.scrollTo(0, 0);
      }
    );
  }

  handleScroll() {
    const { selectedDayIndex, dayRefs } = this.state;
    const { weeklyMenu } = this.props;
    if (!weeklyMenu) {
      return;
    }
    const weeklyMenuLength = weeklyMenu.filter(
      (m) => m.lunch.length > 0 && m.dinner.length > 0
    ).length;
    const currentScrollTop = window.pageYOffset,
      scrollY = window.scrollY;
    const currentDateTop = dayRefs?.[selectedDayIndex]?.current?.offsetTop;
    const currentDateBottom =
      selectedDayIndex < weeklyMenuLength - 1
        ? dayRefs?.[selectedDayIndex + 1]?.current?.offsetTop
        : currentDateTop + dayRefs?.[selectedDayIndex]?.current?.offsetHeight;

    this.setState({ homeScrollY: window.scrollY });
    if (
      selectedDayIndex < weeklyMenuLength - 1 &&
      currentScrollTop + 100 >= currentDateBottom
    ) {
      // LOGGING && console.log('handleScroll increasing selectedDayIndex by 1.');
      this.setState({
        selectedDayIndex: selectedDayIndex + 1,
      });
    } else if (
      selectedDayIndex > 0 &&
      currentScrollTop + 200 < currentDateTop
    ) {
      // LOGGING && console.log('handleScroll decreasing selectedDayIndex by 1.');
      this.setState({
        selectedDayIndex: selectedDayIndex - 1,
      });
    }
  }
  handleGoToDate(dayIndex, e) {
    // ? categories[c].refMobile : categories[c].refDesktop

    const { selectedDayIndex, dayRefs } = this.state;
    LOGGING &&
      console.log("handleGoToDate called with:", {
        selectedDayIndex,
        dayIndex,
        dayRefs,
        scrollingTo: dayRefs[dayIndex].current.offsetTop,
      });
    e.preventDefault();

    // scroll to the selected day ref to top of screen, and scroll 50vh more

    window.scrollTo({
      top:
        dayRefs[dayIndex].current.offsetTop +
        window.visualViewport.height * 0.6,
      left: 0,
      behavior: "auto",
    });
    this.setState({
      selectedDayIndex: dayIndex,
    });
  }
  handleToggleShowSocialActions(e) {
    e.preventDefault();
    LOGGING && console.log("Pagehome handleToggleShowSocialActions called");
    const { showSocialActions } = this.state;
    this.setState({ showSocialActions: !showSocialActions }, () => {
      window.scrollTo(0, 0);
    });
  }
  handleToggleShowShare(e) {
    e.preventDefault();
    LOGGING && console.log("Pagehome handleToggleShowShare called");
    const { showShare } = this.state;
    this.setState({ showShare: !showShare }, () => {
      window.scrollTo(0, 0);
    });
  }
  handleToggleUserMenu(e) {
    e.preventDefault();
    LOGGING && console.log("Pagehome handleToggleUserMenu called");
    const { showUserMenu } = this.state;
    this.setState({ showUserMenu: !showUserMenu });
  }
  handleToggleShowDeliveryCityOptions(e) {
    const { showDeliveryCityOptions } = this.state;
    LOGGING &&
      console.log(
        "handleToggleShowDeliveryCityOptions called with: ",
        showDeliveryCityOptions
      );
    e.preventDefault();

    this.setState({ showDeliveryCityOptions: !showDeliveryCityOptions });
  }

  handleSelectDeliveryCity(selected, e) {
    e.preventDefault();

    const { deliveryZoneByCity } = this.props;
    const deliveryCity = Object.keys(deliveryZoneByCity).sort((a, b) =>
      a.localeCompare(b)
    )[selected];
    const selectedDeliveryZone = deliveryZoneByCity[deliveryCity];
    LOGGING &&
      console.log("handleSelectDeliveryCity called with:", {
        deliveryCity,
        selected,
        deliveryZoneByCity,
        selectedDeliveryZone,
      });

    this.props.updateDeliveryCity(selectedDeliveryZone);
    this.setState({
      selectedDeliveryZone,
    });
    window.scrollTo(0, 0);
  }

  handleChangeDeliveryCity(selectedCityIndex) {
    const { deliveryZoneByCity, currentUser } = this.props;
    const viewableDays = getViewableDays();
    const { windowsForWeeklyMenu } = getWeeklyMenuWindows(viewableDays);

    LOGGING &&
      console.log("handleChangeDeliveryCity called with:", {
        selectedCityIndex,
        deliveryZoneByCity,
      });

    const deliveryCity = Object.keys(deliveryZoneByCity)[selectedCityIndex];
    const selectedDeliveryZone = deliveryZoneByCity[deliveryCity];

    this.props.updateDeliveryCity(selectedDeliveryZone);
    // this.props.emptyCart();
    this.setState(
      {
        selectedDeliveryZone,
        showDeliveryCityOptions: false,
        loading: "loading menu...",
      },
      () => {
        this.props
          .fetchWeeklyMeals(
            windowsForWeeklyMenu,
            selectedDeliveryZone,
            currentUser?.user?._id
          )
          .then(() => {
            this.setState({ loading: false }, () => {
              window.scrollTo(0, 0);
            });
          });
      }
    );
  }

  loadDeliveryZone() {
    // TODO: load default zone
    if (!this.state.selectedDeliveryZone) {
    }
  }

  trackPageHomeEvent(endAt, startAt) {
    const userId = this.props.currentUser?.user?._id;
    const uuid = `${FoodieEvent.PAGE_HOME}${startAt}`;
    if (this.props.location?.search?.includes("source=cards")) {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.BUSINESS_CARDS,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    } else if (this.props.location?.search?.includes("source=instagram")) {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.INSTAGRAM,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    } else if (this.props.location?.search?.includes("source=story")) {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.STORY,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    } else if (this.props.location?.search?.includes("source=promo")) {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.PROMO,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    } else {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.UNKNOWN,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    }
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source");
    LOGGING &&
      console.log("PageGuest componentDidMount2:", window.location.search);
    const startAt = moment().valueOf();
    this.trackPageHomeEvent(-1, startAt);
    this.props.readHighlight();
    const homeScrollY = this.props?.location?.state?.homeScrollY || 0;
    window.addEventListener("scroll", this.handleScroll);

    const { currentUser, weeklyMenu, meals, pixel } = this.props;

    // showCompetitorComparison: used to decide if the user sees the 30 trial blocker
    const showCompetitorComparison =
      currentUser.isAuthenticated &&
      (!currentUser?.user?.membership?.status ||
        currentUser?.user?.membership?.status === "canceled") &&
      !currentUser?.user?.interaction?.seen_competitor_comparison;

    // selectedDeliveryZone: used to retrieve the correct weekly menu summary for home rendering
    let selectedDeliveryZone = "Palo Alto";
    const deliveryCity =
      this.props.deliveryCity ||
      this.props.currentUser?.user?.deliveryZone?.name;
    if (this.props.deliveryZoneByCity && deliveryCity) {
      selectedDeliveryZone = this.props.deliveryZoneByCity[deliveryCity];
    } else {
      await this.props.fetchDeliveryZoneByCity();
      selectedDeliveryZone =
        this.props.deliveryZoneByCity[this.props.deliveryCity];
    }

    // showCompetitorComparison
    // selectedDeliveryZone
    this.setState({
      showCompetitorComparison,
      selectedDeliveryZone,
    });

    const viewableDays = getViewableDays();
    const { windowsForWeeklyMenu, nextMeal } =
      getWeeklyMenuWindows(viewableDays);

    // update weekly summary which is used for home rendering
    let summaryOutdated = true;
    if (weeklyMenu && weeklyMenu.length > 0) {
      const menuFirstDay =
        moment().hour() > 16 ||
        (moment().hour() === 16 && moment().minute() > 30)
          ? moment().add(1, "d").startOf("day").valueOf()
          : moment().startOf("day").valueOf();
      summaryOutdated = weeklyMenu?.[0]?.lunchTime < menuFirstDay;
      // LOGGING &&  console.log("summary outdated check: ", { menuFirstDay, existingLunchTime: weeklyMenu[0].lunchTime });
    }

    if (summaryOutdated) {
      this.setState(
        { loading: "loading menu...", windowsForWeeklyMenu },
        () => {
          // blocking loading
          const loadingStartAt = moment().valueOf();
          this.props
            .fetchWeeklyMeals(
              windowsForWeeklyMenu.slice(0, 1),
              selectedDeliveryZone,
              currentUser?.user?._id
            )
            .then(() => {
              this.setState({ loading: false }, () => {
                const loadingEndAt = moment().valueOf();
                this.trackPageHomeEvent(moment().valueOf(), startAt);
                MealVisited(
                  `page guest ${loadingEndAt - loadingStartAt}ms`,
                  "guest",
                  null,
                  null,
                  0,
                  pixel?.fbclid,
                  pixel?.source
                );
                // setTimeout(() => {
                //   window.scrollTo(0, 0);
                // }, 1);
              });

              // unblocking loading
              this.props
                .fetchWeeklyMeals(
                  windowsForWeeklyMenu,
                  selectedDeliveryZone,
                  currentUser?.user?._id
                )
                .then(() => {
                  LOGGING && console.log("fetchWeeklyMeals done");
                  this.props.readFutureOrdersByCustomer();
                  if (this.props?.cart) {
                    LOGGING && console.log("got a cart from anonymous user");
                    this.props.readFutureOrdersFromRedux(this.props.cart);
                  }
                });
            });
        }
      );
    } else {
      this.trackPageHomeEvent(moment().valueOf(), startAt);
      setTimeout(() => {
        window.scrollTo(0, homeScrollY);
      }, 1);

      if (this.props?.cart) {
        LOGGING && console.log("got a cart from anonymous user");
        this.props.readFutureOrdersFromRedux(this.props.cart);
      }

      // unblocking loading
      this.setState({ loading: false, windowsForWeeklyMenu });
      this.props
        .fetchWeeklyMeals(
          windowsForWeeklyMenu,
          selectedDeliveryZone,
          currentUser?.user?._id
        )
        .then(() => {
          this.props.readFutureOrdersByCustomer();
          if (this.props.cart) {
            LOGGING && console.log("got a cart from anonymous user");
            this.props.readFutureOrdersFromRedux(this.props.cart);
          }
        });
    }

    // update weekly details which is not used for home rendering,
    // but buffered for single meal rendering
    let mealsOutdated = true;
    if (meals && meals.length > 0) {
      meals.sort((a, b) => a.windowStart - b.windowStart);
      mealsOutdated = meals[0].windowStart < moment().startOf("day").valueOf();
    }
    LOGGING &&
      console.log("pageguest componentDidMount with meals", {
        meals,
        mealsOutdated,
      });
    if (mealsOutdated) {
      // get next meal
      this.props.fetchWeeklyMealDetailsLight(
        [nextMeal],
        currentUser?.user?._id
      );
      // get next day that's not next meal
      this.props.fetchWeeklyMealDetailsLight(
        Object.values(windowsForWeeklyMenu[0]).filter((m) => m !== nextMeal),
        currentUser?.user?._id
      );
      // get next week that's not next day
      this.props.fetchWeeklyMealDetails(
        windowsForWeeklyMenu.slice(1),
        currentUser?.user?._id
      );
      window.scrollTo(0, homeScrollY || 0);
    }

    window.scrollTo(0, homeScrollY || 0);
    setTimeout(() => {
      this.setState({ showGuestAuth: true });
    }, 3000);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    // LOGGING &&
    //   console.log("PageGuest rendering with:", {
    //     state: this.state,
    //     props: this.props,
    //   });

    const {
      loading,
      hasAnnouncement,
      // showShare,
      hasGiftCard,
      showDeliveryCityOptions,
      referralCode,
      showReferralCodeAnnouncement,
      selectedDeliveryZone,
      mealRefs,
      showSocialActions,
      selectedDayIndex,
      dayRefs,
      today,
      tomorrow,
      weekOfYear,
      howItWorksRef,
      homeScrollY,
      showHowItWorks,
      nominationErrorMessage,
      showNominationConfirmation,
      freeze,
      innerHeight,
      showUserMenu,
      showNominationform,
      nomination,
      showMembershipOnboarding,
      showPreferences,
      showCompetitorComparison,
      readingCounter,
      showTasteSurvey,
      windowsForWeeklyMenu,
      hasDrafts,
      showGuestAuth,
    } = this.state;

    const {
      currentUser,
      deliveryZoneByCity,
      weeklyMenu,
      orderHistoryByRestaurant,
      orders,
      carts,
      highlight,
      pixel,
    } = this.props;

    const showEmptyMenu = loading === "loading menu...";

    if (currentUser.isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <div
        className="page no-nav with-footer"
        style={freeze >= 0 ? { position: "fixed", top: `-${freeze}px` } : {}}
      >
        <Helmet>
          {/* <title>Eat w/ neighbors, carpool delivery.</title> */}
          <title>Explore restaurants beyond your neighborhood.</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="description" key="description" content={"free"} />
          <meta name="title" key="title" content={"foodie"} />
          <meta property="og:title" key="og:title" content={"foodie"} />
          <meta property="og:locale" key="og:locale" content="en_US" />
          <meta charSet="utf-8" />
          <meta property="og:type" key="og:type" content="website" />
          <meta
            property="og:description"
            key="og:description"
            content={"free"}
          />
          {/* <meta
            property="og:image"
            key="og:image"
            content={`https://images.pexels.com/photos/984888/pexels-photo-984888.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2`}
          /> */}
        </Helmet>
        <>
          <NavGuest source={pixel?.source} fbclid={pixel?.fbclid} />
          {/* {highlight?.windowStart ? null : <GiftCardAnnouncement />} */}
          {/*highlight?.windowStart ? (
            <MealHighlights
              highlight={highlight}
              source={pixel?.source}
              fbclid={pixel?.fbclid}
            />
          ) : null*/}
          {/* <AppStoreLink
            customStyle={{
              backgroundColor: "white",
              width: "100%",
              borderBottom: `1px solid ${BorderGrey}`,
            }}
            source={pixel?.source}
            fbclid={pixel?.fbclid}
          /> */}
          <GuestWelcomeMessageByCuisine
            cuisine={pixel?.cuisineName}
            multiCuisine={pixel?.multiCuisine}
            lan={pixel?.lan}
          />
          {loading ? null : (
            <DateNavbar
              dates={(weeklyMenu ?? [])
                .filter(
                  (m) =>
                    (m.lunch ?? []).length > 0 && (m.dinner ?? []).length > 0
                )
                .map((m) => m?.lunchTime)}
              selectedDayIndex={selectedDayIndex}
              onGoToDate={this.handleGoToDate}
              ordersByDate={[]}
              showUserMenu={showUserMenu}
              isOnGuestPage={true}
            />
          )}
          <NewGuestCredit
            key={Math.random()}
            source={pixel?.source}
            fbclid={pixel?.fbclid}
          />
          {/* <NewYearMenus /> */}
          <UpcomingKeyRestaurants
            source={pixel?.source}
            fbclid={pixel?.fbclid}
            restaurantId={pixel?.restaurantId}
            cuisineName={pixel?.cuisineName}
            multiCuisine={pixel?.multiCuisine}
          />
          {loading ? (
            showEmptyMenu ? (
              <EmptyWeeklyMenu />
            ) : (
              <LoadingTop text={loading} />
            )
          ) : (
            <WeeklyMenuLink
              orderHistoryByRestaurant={orderHistoryByRestaurant}
              dayRefs={dayRefs}
              menu={weeklyMenu}
              onSetReadingCounter={this.handleSetReadingCounter}
              readingCounter={readingCounter}
              selectedDeliveryZone={selectedDeliveryZone}
              hasAnnouncement={hasAnnouncement}
              referralCode={referralCode}
              showReferralCodeAnnouncement={showReferralCodeAnnouncement}
              dishesLoaded={true}
              mealRefs={mealRefs}
              isAuthenticated={currentUser.isAuthenticated}
              today={today}
              tomorrow={tomorrow}
              weekOfYear={weekOfYear}
              onBackToTop={this.handleBackToTop}
              howItWorksRef={howItWorksRef}
              homeScrollY={homeScrollY}
              showHowItWorks={showHowItWorks}
              onToggleShowHowItWorks={this.handleToggleShowHowItWorks}
              showNominationform={showNominationform}
              onToggleShowNominationForm={this.handleToggleShowNominationForm}
              freeze={freeze}
              onFreeze={this.handleFreezeBackground}
              innerHeight={innerHeight}
              isMember={false}
              userLocation={currentUser?.user?.deliveryInfo?.map}
              nomination={nomination}
              nominationErrorMessage={nominationErrorMessage}
              onEditNomination={this.handleEditNomination}
              onSubmitNomination={this.handleSubmitNomination}
              onChangeRestaurantName={this.handleChangeRestaurantName}
              onSelectRestaurantAddress={this.handleSelectRestaurantAddress}
              isMobile={true}
              userName={`${currentUser?.user?.firstName} ${currentUser?.user?.lastName} ${currentUser?.user?._id}`}
              source={pixel?.source}
              fbclid={pixel?.fbclid}
              cuisineName={pixel?.cuisineName}
              multiCuisine={pixel?.multiCuisine}
            />
          )}
        </>

        {showGuestAuth && (
          <GoogleOneTapSignupWithProvider
            onClose={() => {
              this.setState({ showGuestAuth: false });
            }}
            pixel={pixel}
          />
        )}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    week: state.week,
    deliveryZoneByCity: state.deliveryZoneByCity,
    nomination: state.nomination,
    weeklyMenu: state.weeklyMenu.payLoad,
    meals: state.meals,
    orderHistoryByRestaurant: state.orderHistoryByRestaurant,
    // referralCode: state.referralCode.payLoad,
    restaurants: state.restaurants,
    cuisines: state.cuisines,
    orders: state.orders,
    carts: state.carts,
    highlight: state.highlight,
    pixel: state.pixel,
    carts: state.carts,
    cart: state.cart.payLoad,
  };
}

export default connect(mapStateToProps, {
  loadClickID,
  fetchWeeklyMeals,
  fetchDeliveryZoneByCity,
  fetchWeeklyMealDetails,
  fetchWeeklyMealDetailsLight,
  setDeliveryCity,
  resetDeliveryCity,
  updateDeliveryCity,
  getReferralCode,
  emptyCart,
  saveNomination,
  submitNomination,
  getRestaurantsByAlphabet,
  readPastOrdersByCustomer,
  readFutureOrdersByCustomer,
  readFutureOrdersFromRedux,
  updateInteraction,
  readHighlight,
  getFavorites,
  getTastes,
  // })(ReactRouterWrappedPageHome);
})(PageGuest);
