import { useEffect, useState } from "react";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import posthog from "posthog-js";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button } from "src/components/Button";
import { Cell, Flex, Grid } from "src/components/Layout";
import { WeeklySchedule } from "src/components/WeeklySchedule";
import { useForm } from "src/hooks";
import { LOGGING, Navbar } from "src/screens/tasting_menu";
import { getDrivers } from "src/store/actions";
import styled from "styled-components";
import { DriverModal } from "./DriverModal";

export const Drivers = () => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const drivers = useSelector((state: any) => state.drivers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDrivers());
  }, [dispatch]);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] = useState<any>();
  const { formData, onInputChange } = useForm({ message: "", picture: "" });

  if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
    return <Redirect to="/" />;
  }
  // LOGGING && console.log('Drivers rendering with drivers:', drivers);

  // Make sure opts out Post Hog
  posthog.opt_out_capturing();

  return (
    <div className="page">
      <Navbar />
      <DriversContainer>
        <Grid columns={1} gap={24} md={{ columns: 2 }} xxl={{ columns: 4 }}>
          <>
            <CreateDriverButton
              onClick={() => {
                setSelectedDriver(undefined);
                setModalIsOpen(true);
              }}
            >
              Create Driver
            </CreateDriverButton>
            {drivers.map((driver: any) => (
              <Driver key={driver._id}>
                <h3>
                  {driver.firstName}{" "}
                  {driver?.driverInfo?.totalDeliveries
                    ? " | " +
                      (
                        100 -
                        (driver.driverInfo?.totalLateDeliveries /
                          driver.driverInfo?.totalDeliveries) *
                          100
                      ).toFixed(2) +
                      "% on-time "
                    : ""}
                </h3>
                <h4>
                  {"Total: " +
                    (driver?.driverInfo?.totalDeliveries
                      ? driver.driverInfo.totalDeliveries
                      : 0)}{" "}
                  |
                  {" Late: " +
                    (driver?.driverInfo?.totalLateDeliveries
                      ? driver.driverInfo.totalLateDeliveries
                      : 0)}{" "}
                  |
                  {" On-Time: " +
                    (driver?.driverInfo?.totalDeliveries
                      ? driver?.driverInfo?.totalLateDeliveries
                        ? driver?.driverInfo?.totalDeliveries -
                          driver?.driverInfo?.totalLateDeliveries
                        : driver?.driverInfo?.totalDeliveries
                      : "0")}
                </h4>
                <h4>
                  Penalties: {driver.driverPenalties}
                  {(
                    (1 -
                      driver.driverPenalties /
                        (driver.driverInfo?.totalDeliveries || 1)) *
                    100
                  ).toFixed(2)}
                  %
                </h4>
                <br />
                <Grid columns="80px 1fr" align="start" widthFull gap={4}>
                  <Cell>ObjectID:</Cell>
                  <Cell justify="end">{driver._id}</Cell>
                  <Cell>Phone:</Cell>
                  <Cell justify="end">{driver.phone}</Cell>
                  <Cell>Schedule:</Cell>
                  <Cell justify="end">
                    <WeeklySchedule disabled value={driver.weeklySchedule} />
                  </Cell>
                  <Cell>Active:</Cell>
                  <Cell justify="end">{driver.active ? "Yes" : "No"}</Cell>
                  <Cell>Color:</Cell>
                  <Cell justify="end">
                    <div
                      style={{
                        width: 80,
                        height: 15,
                        background: driver.profileColor,
                      }}
                    ></div>
                  </Cell>
                  <Cell>Paypal:</Cell>
                  <Cell justify="end">{driver.driverPaypalAccount}</Cell>
                  <Cell>Notes:</Cell>
                  <Cell justify="end">{driver.notes}</Cell>
                </Grid>
                <EditButton
                  variant="secondary"
                  onClick={() => {
                    setSelectedDriver(driver);
                    setModalIsOpen(true);
                  }}
                >
                  <Flex align="center" gap={6}>
                    <FontAwesomeIcon icon={faEdit} />
                    <div>Edit</div>
                  </Flex>
                </EditButton>
                <TextMessage>
                  <textarea
                    name="message"
                    placeholder="Type your message here"
                    onChange={onInputChange}
                  />
                  <textarea
                    name="picture"
                    placeholder="Type the link to the picture here"
                    onChange={onInputChange}
                  />
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      fetch(
                        "https://foodie-watch-api.herokuapp.com/api/messages/",
                        {
                          method: "put",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            To: driver.email,
                            Body: formData.message,
                            mediaUrl: formData.picture,
                          }),
                        }
                      );
                    }}
                  >
                    Send
                  </Button>
                </TextMessage>
              </Driver>
            ))}
          </>
        </Grid>
        <DriverModal
          isOpen={modalIsOpen}
          driver={selectedDriver}
          onClose={() => setModalIsOpen(false)}
        />
      </DriversContainer>
    </div>
  );
};

const DriversContainer = styled.div`
  width: 100%;
  padding: 32px;

  h3 {
    margin: 6px 0 12px 0;
    padding: 0;
  }
`;

const CreateDriverButton = styled.button`
  color: #ffffff;
  background-color: #000000;
  opacity: 0.8;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.6px;
  box-shadow: 0 4px 32px 0 rgb(28 29 33 / 12%);
  min-height: 150px;
`;

const Driver = styled.div`
  font-size: 13px;
  box-shadow: 0 4px 32px 0 rgb(28 29 33 / 12%);
  padding: 12px 24px;
  background-color: #ffffff;
  border-radius: 5px;
`;

const EditButton = styled(Button)`
  margin-top: 16px;
`;

const TextMessage = styled.div`
  font-size: 13px;
  margin-top: 16px;
  // padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  // box-shadow: 0 4px 32px 0 rgb(28 29 33 / 12%);
  textarea {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    padding: 10px;
  }
`;
