import React, { Component } from "react";
import posthog from "posthog-js";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  LOGGING,
  Loading,
  RestaurantDirectory,
  Navbar,
  RestaurantsMap2,
  ModeSelector,
} from ".";
import {
  readRestaurants,
  saveRestaurant,
  saveMarkups,
} from "../../store/actions";

class PageRestaurantDirectory extends Component {
  constructor() {
    super();
    LOGGING && console.log("PageRestaurantDirectory constructor");
    this.state = {
      loading: true,
      mode: 0, // 0: directory, 1:map
      zoom: 15,
      center: {
        lat: 37.3858165,
        lng: -122.0945878,
      },
      showRestaurant: -1,
      showCommission: {
        needsWork: true,
        done: true,
      },
      showOperation: {
        needsWork: true,
        done: true,
      },
      showCommissionRange: {
        hasCommission: true,
        noCommission: true,
      },
      allowedCommissions: [true, false],
      allowedOperations: [true, false],
      allowedCommissionRanges: [true, false],
      showFilters: false,
      showMarkUp: null,
    };

    this.handleAddRestaurant = this.handleAddRestaurant.bind(this);
    this.handleSelectMode = this.handleSelectMode.bind(this);
    this.handleShowRestaurant = this.handleShowRestaurant.bind(this);
    this.handleHideRestaurant = this.handleHideRestaurant.bind(this);
    this.handleToggleNeedsWork = this.handleToggleNeedsWork.bind(this);
    this.handleToggleFilterByWork = this.handleToggleFilterByWork.bind(this);
    this.handleToggleShowFilters = this.handleToggleShowFilters.bind(this);
    this.handleToggleShowMarkUp = this.handleToggleShowMarkUp.bind(this);
    this.handleSaveMarkUp = this.handleSaveMarkUp.bind(this);
  }

  handleSaveMarkUp(restaurant, e) {
    LOGGING &&
      console.log("handleSaveMarkUp called with: ", {
        restaurant,
        "e.target:": e.target,
      });
    const names = e.target.name.split("-"),
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value,
      platform = names[0],
      field = names[1];
    LOGGING &&
      console.log("handleSaveMarkUp got: ", {
        restaurant,
        platform,
        field,
        value,
      });
    const original = restaurant.markups[platform];
    this.props.saveMarkups({
      _id: restaurant._id,
      markups: {
        ...restaurant.markups,
        [platform]: {
          ...original,
          [field]: value,
        },
      },
    });
  }
  handleToggleShowMarkUp(restaurantId, e) {
    e.preventDefault();
    const { showMarkUp } = this.state;
    this.setState({
      showMarkUp: showMarkUp === restaurantId ? null : restaurantId,
    });
  }
  handleToggleShowFilters(e) {
    e.preventDefault();
    const { showFilters } = this.state;
    this.setState({
      showFilters: !showFilters,
    });
  }
  handleToggleFilterByWork(workType, allowedValue, e) {
    e.preventDefault();
    const workTypeName = `show${workType}`;
    const workTypeValue = this.state[workTypeName];
    LOGGING &&
      console.log("handleToggleFilterByWork called with: ", {
        workTypeName,
        workType,
        allowedValue,
      });
    this.setState(
      {
        [workTypeName]: {
          ...workTypeValue,
          [allowedValue]: !workTypeValue[allowedValue],
        },
      },
      () => {
        const { showCommission, showOperation, showCommissionRange } =
          this.state;
        LOGGING &&
          console.log("handleToggleFilterByWork got: ", {
            showCommission,
            showOperation,
            showCommissionRange,
          });
        let allowedCommissions = [],
          allowedOperations = [],
          allowedCommissionRanges = [];
        if (showCommission.needsWork) {
          allowedCommissions.push(true);
        }
        if (showCommission.done) {
          allowedCommissions.push(false);
        }
        if (showOperation.needsWork) {
          allowedOperations.push(true);
        }
        if (showOperation.done) {
          allowedOperations.push(false);
        }
        if (showCommissionRange.hasCommission) {
          allowedCommissionRanges.push(true);
        }
        LOGGING &&
          console.log("handleToggleFilterByWork allowedCommissionRanges: ", {
            allowedCommissionRanges,
          });
        if (showCommissionRange.noCommission) {
          allowedCommissionRanges.push(false);
        }
        LOGGING &&
          console.log("handleToggleFilterByWork allowedCommissionRanges: ", {
            allowedCommissionRanges,
          });
        this.setState({
          allowedOperations,
          allowedCommissions,
          allowedCommissionRanges,
          showFilters: false,
        });
      }
    );
  }
  handleToggleNeedsWork(restaurantId, field, e) {
    e.preventDefault();
    const fieldName = `needs${field}Work`;
    const { restaurants } = this.props;
    const restaurant = restaurants.find((r) => r._id === restaurantId);
    LOGGING &&
      console.log(
        "handleToggleNeedsWork called with restaurant.map:",
        restaurant.map
      );
    const fieldValue = !restaurant[fieldName];
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props
          .saveRestaurant({ _id: restaurant._id, [fieldName]: fieldValue })
          .then(() => {
            this.setState({
              loading: false,
              // showRestaurant: -1,
              center: restaurant.map,
            });
          });
      }
    );
  }
  handleSelectMode(mode, e) {
    e.preventDefault();
    this.setState({ mode });
  }
  handleAddRestaurant(e) {
    e.preventDefault();
    this.setState({
      restaurant: {
        name: "",
        address: "",
      },
    });
  }
  handleShowRestaurant(showRestaurant, e) {
    e.preventDefault();
    this.setState({ showRestaurant });
  }
  handleHideRestaurant(e) {
    e.preventDefault();
    this.setState({ showRestaurant: -1 });
  }

  componentDidMount() {
    LOGGING && console.log("PageRestaurantDirectory componentdidmount");
    this.props.readRestaurants().then(() => {
      const { restaurants } = this.props;
      let markups = {};
      restaurants.forEach((r) => {
        markups[r._id] = r.markups || {
          doordash: 0,
          ubereats: 0,
        };
      });
      this.setState(
        {
          loading: false,
          markups,
        },
        () => {
          window.scrollTo(0, 0);
        }
      );
    });

    // Make sure opts out PostHog
    posthog.opt_out_capturing();
  }

  render() {
    const { currentUser, restaurants } = this.props;
    const {
      loading,
      mode,
      zoom,
      center,
      showRestaurant,
      showMarkUp,
      allowedCommissions,
      allowedOperations,
      allowedCommissionRanges,
      showFilters,
      showCommission,
      showOperation,
      showCommissionRange,
      markups,
    } = this.state;

    const restaurantsToDisplay = restaurants.filter(
      (r) =>
        r.map &&
        r.level === 2 &&
        allowedOperations.includes(r.needsOperationWork) &&
        allowedCommissions.includes(r.needsCommissionWork) &&
        allowedCommissionRanges.includes(r.commission > 0)
    );

    LOGGING &&
      console.log("PageRestaurantDirectory rendering with:", {
        props: this.props,
        state: this.state,
      });

    if (
      !currentUser.isAuthenticated ||
      (!currentUser.user.isAdmin && !currentUser.user.isOps)
    ) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        <Navbar />{" "}
        {loading ? (
          <Loading />
        ) : (
          <>
            <ModeSelector
              mode={mode}
              onSelectMode={this.handleSelectMode}
              onToggleFilter={this.handleToggleFilterByWork}
              onToggleShowFilters={this.handleToggleShowFilters}
              showFilters={showFilters}
              showCommission={showCommission}
              showOperation={showOperation}
              showCommissionRange={showCommissionRange}
              numberOfShown={restaurantsToDisplay.length}
            />{" "}
            {mode === 0 ? (
              <RestaurantDirectory
                restaurants={restaurants}
                // markups={markups}
                showMarkUp={showMarkUp}
                onAddRestaurant={this.handleAddRestaurant}
                onSaveMarkUp={this.handleSaveMarkUp}
                onToggleShowMarkUp={this.handleToggleShowMarkUp}
              />
            ) : (
              <RestaurantsMap2
                restaurants={restaurantsToDisplay}
                center={center}
                zoom={zoom}
                showRestaurant={showRestaurant}
                onShowRestaurant={this.handleShowRestaurant}
                onHideRestaurant={this.handleHideRestaurant}
                markups={markups}
                onToggleRestaurantWork={this.handleToggleNeedsWork}
              />
            )}{" "}
          </>
        )}{" "}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageRestaurantDirectory got redux state:", state);
  return { currentUser: state.currentUser, restaurants: state.restaurants };
}

export default connect(mapStateToProps, {
  readRestaurants,
  saveRestaurant,
  saveMarkups,
})(PageRestaurantDirectory);
