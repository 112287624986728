import React from "react";
import styled from "styled-components";
import { awardName } from "./RestaurantThumbnail";
import { capitalizeWords } from "../../../../constants";
import {
  Centered,
  CenteredByColumnLeft,
  Beige,
  BorderGrey,
  text12300,
  CenteredByColumn,
} from "../Shared";
const RestaurantCount = styled(Centered)`
  ${text12300}
  width: 100%;
  max-width: 500px;
  text-align: left;
  justify-content: flex-start;
  padding: 0px 20px;
  @media screen and (min-width: 500px) {
    justify-content: center;
    text-align: center;
  }
`;
const Select = styled.select`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  background-color: white;
  color: black; /* Ensure text color is black */

  /* Override iOS and mobile browser styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Add a custom background for the dropdown arrow */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;

  /* Handle focus state */
  &:focus {
    outline: none;
    border-color: #666;
    box-shadow: 0 0 3px #999;
  }

  /* Style for the options (this might not apply directly but helps future compatibility) */
  option {
    background-color: white;
    color: black; /* Ensure the selected option's color is black */
  }
`;
const SelectContainer = styled(Centered)`
  flex-grow: 1;
  align-self: stretch;
  justify-content: space-between;
  label {
    ${text12300}
    width: 90px;
    text-align: left;
  }
  select {
    width: 100%;
  }
`;
const borderRadius = 15;
const FilterContainer = styled(CenteredByColumnLeft)<{ isSticky?: boolean }>`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  background-color: ${Beige};
  padding: 20px;
  height: 40vh;
  max-height: 400px;
  min-height: 200px;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  @media screen and (min-width: 500px) {
    /* width: 100vw; */
    padding: 10px 40px;
    max-width: 800px;
    /* select {
      width: 200px;
    } */
    > button {
      display: none;
    }
  }
`;

const BackDrop = styled.button`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 101;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4) !important;
`;

const GuideFilters = ({
  //   topRestaurants,
  //   loading,
  cities,
  categories,
  levels,
  city,
  setCity,
  category,
  setCategory,
  level,
  setLevel,
  onHide,
  //   filterRef,
  //   isSticky,
}) => {
  return (
    <BackDrop onClick={onHide}>
      <FilterContainer onClick={(e) => e.stopPropagation()}>
        <SelectContainer>
          <label htmlFor="city-filter">City</label>
          <Select
            id="city-filter"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          >
            <option value="all">All</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {capitalizeWords(city)}
              </option>
            ))}
          </Select>
        </SelectContainer>
        <SelectContainer>
          <label htmlFor="category-filter">Cuisine</label>
          <Select
            id="category-filter"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="all">All</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {capitalizeWords(category)}
              </option>
            ))}
          </Select>
        </SelectContainer>
        <SelectContainer>
          <label htmlFor="category-filter">Award</label>
          <Select
            id="level-filter"
            value={level}
            onChange={(e) => setLevel(e.target.value)}
          >
            <option value="all">All</option>
            {levels.map((level) => (
              <option key={level} value={level}>
                {awardName[level - 1]}
              </option>
            ))}
          </Select>
        </SelectContainer>
      </FilterContainer>
    </BackDrop>
  );
};

export default GuideFilters;
