import { Component } from "react";
import { connect } from "react-redux";
import {
  getDishesByCategoryName,
  getSortedCategoryNamesFromDishes,
} from "src/util/categories";
import styled from "styled-components";
import { LOGGING, Footer } from ".";
// import { BestSellers } from "./components/customers/mealMenu/MealMenuScreen";
import { CategoryDishList } from "./components/customers/restaurantMenu/CategoryDishList";
import { RestaurantHero } from "./components/customers/restaurantMenu/RestaurantHero";
import { MealsForRestaurant } from "./components/restaurants/MealsForRestaurant";
import {
  ALLCAPTEXT,
  Card,
  textOverflow,
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  text12300,
  text13500,
  Text12300,
} from "./components/Shared";
import { readRestaurantByName, changeFavorites } from "../../store/actions";
import { Loading } from "../shared";

const DishContainer = styled(Card)`
  height: 200px;
  /* width: calc(100% - 40px); */
  align-self: stretch;
  position: relative;
  padding-left: 50%;
  margin-bottom: 20px;
`;
const DishPrice = styled(CenteredByColumnLeft)``;
const Doordash = styled(Text12300)``;
const Foodie = styled(Text12300)``;
const DishImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  object-fit: cover;
`;
const DishText = styled(CenteredByColumn)`
  padding: 10px;
  height: 100%;
  align-self: stretch;
  overflow: hidden;
  justify-content: flex-start;
`;
const DishName = styled.div`
  ${text13500}/* text-align: center; */
`;
const DishDescription = styled.div`
  ${text12300}
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
`;

const Dish = ({ dish }) => (
  <DishContainer>
    <DishImage src={dish.imageURL} />
    <DishText>
      <DishName>{dish?.name}</DishName>
      {/* <DishDescription>{dish.description}</DishDescription> */}
      <DishPrice>
        <Doordash>
          <label>Doordash Price</label>${dish.priceDoordashListed}
        </Doordash>
        <Foodie>
          <label>Foodie Price</label>${dish.priceFoodieListed}
        </Foodie>
      </DishPrice>
    </DishText>
  </DishContainer>
);
const BestSellerContainer = styled(CenteredByColumnLeft)`
  padding: 10px 20px;
  width: 100%;
  > label {
    ${ALLCAPTEXT}
    font-size: 20px;
    margin-bottom: 10px;
  }
`;
const BestSellers = ({ dishes }) => (
  <BestSellerContainer>
    <label>Best Sellers</label>
    {dishes.map((dish, index) => (
      <Dish dish={dish} />
    ))}
  </BestSellerContainer>
);
class PageRestaurantMenu extends Component {
  constructor(props) {
    LOGGING &&
      console.log("PageRestaurantMenu constructor called with props:", props);
    super();
    const { currentUser, deliveryCity } = props;
    const address = currentUser?.user?.deliveryInfo?.address;
    LOGGING &&
      console.log("PageRestaurantMenu constructor got:", {
        deliveryCity,
        address,
      });

    window.scrollTo(0, 0);
    this.state = {
      loading: true,
    };

    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleToggleFavorite = this.handleToggleFavorite.bind(this);
  }
  handleToggleFavorite() {
    // e.preventDefault();
    const { restaurant, isFavorite } = this.state;
    const { _id: restaurantId } = restaurant;
    const { currentUser } = this.props;
    const { favorites, _id: userId } = currentUser.user;
    LOGGING &&
      console.log("PageRestaurantMenu handleToggleFavorite called with:", {
        isFavorite,
        restaurant,
        favorites,
      });
    const updatedFavorites = isFavorite
      ? favorites.filter((f) => f !== restaurantId)
      : [...favorites, restaurantId];
    this.props.changeFavorites(updatedFavorites).then(() => {
      this.setState({ isFavorite: !isFavorite });
    });
  }
  handleGoBack(e) {
    e.preventDefault();
    this.props.history.push({
      pathname: "/favorites",
      state: {
        PageFavoritesPos: this.props?.location?.state?.PageFavoritesPos,
      },
    });
  }

  async componentDidMount() {
    const { restaurantName } = this.props.match.params;

    // window.scrollTo(0, 0);
    this.setState(
      {
        loading: true,
      },
      async () => {
        this.props
          .readRestaurantByName(restaurantName)
          .then(({ restaurant, meals }) => {
            LOGGING &&
              console.log(
                "readRestaurantByName got restaurant from server:",
                restaurant,
                meals
              );
            // const orderedCategoryNames =
            //   getSortedCategoryNamesFromDishes(dishes);

            // const dishesByCategoryName = getDishesByCategoryName(dishes);

            // const categories = orderedCategoryNames.map((cName) => {
            //   return {
            //     categoryName: cName,
            //     dishes: dishesByCategoryName[cName],
            //   };
            // });
            const { currentUser } = this.props;
            const isFavorite = currentUser?.user?.favorites?.includes(
              String(restaurant._id)
            );
            this.setState({
              restaurant,
              isFavorite,
              // categories,
              // dishes,
              meals,
              loading: false,
            });
          })
          .catch((e) => {
            LOGGING && console.log("no such meal!");
            this.props.history.push("/");
          });
      }
    );
  }

  render() {
    const { loading, restaurant, categories, isFavorite, meals } = this.state;
    const { currentUser } = this.props;

    LOGGING &&
      console.log("PageRestaurantMenu rendering with", {
        props: this.props,
        state: this.state,
      });

    return (
      <div className="page no-nav with-footer">
        {loading ? (
          <Loading />
        ) : (
          <>
            <RestaurantHero
              name={restaurant?.name}
              restaurantId={restaurant._id}
              heroImage={restaurant.heroImage}
              address={restaurant.address}
              onBack={this.handleGoBack}
              onToggleFavorite={this.handleToggleFavorite}
              isLoggedIn={currentUser.isAuthenticated}
              isFavorite={isFavorite}
            />
            <MealsForRestaurant meals={meals} />
            {/* <MealHeader restaurant={restaurant} /> */}
            {restaurant.bestSellers && restaurant.bestSellers.length > 0 ? (
              <BestSellers dishes={restaurant.bestSellers} />
            ) : null}
            {/* {categories.map((category, index) => {
              return (
                <CategoryDishList
                  key={index}
                  category={category}
                  isMobile={true}
                />
              );
            })} */}
          </>
        )}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageRestaurantMenu got redux state:", state);
  return { currentUser: state.currentUser };
}

export default connect(mapStateToProps, {
  readRestaurantByName,
  changeFavorites,
})(PageRestaurantMenu);
