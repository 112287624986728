import React, { Component } from "react";
import moment from "moment-timezone";
import posthog from "posthog-js";
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { apiCall } from "src/store/api";
import { LOGGING, Loading, CurrentList, Navbar } from ".";
import { CATimeZone, getCurrentDayDeliveryWindows } from ".";
import {
  readOrders,
  readCheckouts,
  payCheckout,
  finishCheckout,
  readCarts,
  readOrdersAndCarts,
  finishCart,
  markOrderAsOrdered,
  markAllOrderAsOrdered,
  updateOrder,
  updateOrderOpsNote,
  emailRestaurant,
  markPaymentAsPaid,
  orderCancelItem,
  cancelOrderByOps,
  saveRestaurant,
  savePreOrderedDishQuantity,
  mealCancelDish,
  resumeOrder,
  resumeOrderNoPayment,
} from "../../store/actions";

class PageCurrentOrderBatch extends Component {
  constructor() {
    super();
    LOGGING && console.log("PageCurrentOrderBatch constructor");
    this.state = {
      showMealType: "lunch",
      loading: true,
      showDetails: 0, // 0: details, 1: summary, 2: drivers
      selectedRestaurant: [],
      restaurantList: [],
      showRestaurantOptions: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSelectMeal = this.handleSelectMeal.bind(this);
    this.ref = React.createRef();
    this.handleShowDetails = this.handleShowDetails.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleChangeOpsNote = this.handleChangeOpsNote.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handleCancelItem = this.handleCancelItem.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.handleSaveRestaurant = this.handleSaveRestaurant.bind(this);
    this.handleChangeRestaurant = this.handleChangeRestaurant.bind(this);
    this.handleToggleShowRestaurantOptions =
      this.handleToggleShowRestaurantOptions.bind(this);
    this.handleSavePreOrderedDishQuantity =
      this.handleSavePreOrderedDishQuantity.bind(this);
    this.handleCancelDish = this.handleCancelDish.bind(this);
    this.handleFinishCart = this.handleFinishCart.bind(this);
    this.handleFinishCheckout = this.handleFinishCheckout.bind(this);
    this.handleFinishCheckoutNoPayment =
      this.handleFinishCheckoutNoPayment.bind(this);
    this.handleResumeOrder = this.handleResumeOrder.bind(this);
    this.handleResumeOrderNoPayment =
      this.handleResumeOrderNoPayment.bind(this);
  }
  handleToggleShowRestaurantOptions(e) {
    LOGGING && console.log("handleToggleShowRestaurantOptions called");
    e.preventDefault();
    const { showRestaurantOptions } = this.state;
    this.setState({ showRestaurantOptions: !showRestaurantOptions });
  }
  handleChangeRestaurant(restaurantIndex) {
    const { restaurantList } = this.state;
    LOGGING &&
      console.log("handleChangeRestaurant called with:", {
        restaurantList,
        restaurantIndex,
      });
    this.setState({
      selectedRestaurant: [restaurantList[restaurantIndex]],
      showRestaurantOptions: false,
    });
  }

  handleEmail(orders, e) {
    LOGGING && console.log("inside handleEmail");
    e.preventDefault();
    const ordersLeft = orders.filter(
      (order) => !order.hasOwnProperty("orderOps")
    );
    const unpaidGroupOrders = orders.filter((order) => order.payTime < 0);
    LOGGING && console.log("unpaidGroupOrders: ", unpaidGroupOrders);
    Promise.resolve(
      unpaidGroupOrders.forEach((order) => {
        this.props.markPaymentAsPaid(order._id);
      })
    )
      .then(() =>
        ordersLeft.forEach((order) => {
          if (order._id === ordersLeft[0]._id)
            this.props.emailRestaurant(orders);
        })
      )
      .then((r) => setTimeout(r, 4000))
      .then(() =>
        ordersLeft.forEach((order) => {
          if (order._id === ordersLeft[0]._id)
            this.props.markAllOrderAsOrdered(ordersLeft);
        })
      );
  }

  handlePayment(orderId, e) {
    LOGGING && console.log("handling group payment:", orderId);
    e.preventDefault();
    this.props.markPaymentAsPaid(orderId);
  }

  handleCancelItem(orderId, index, dishId, cancelQuantity) {
    const { lunchTime, dinnerTime } = this.state;
    this.props
      .orderCancelItem(orderId, index, dishId, cancelQuantity)
      .then(() => {
        toast.success("cancel item success");
        this.setState({ loading: true });
        this.props.readOrders(true, lunchTime, dinnerTime).then(() => {
          this.setState({ loading: false });
        });
      });
  }

  handleCancelOrder(orderId) {
    const { lunchTime, dinnerTime } = this.state;
    this.props.cancelOrderByOps(orderId, {
      success: () => {
        toast.success("cancel order success");
        this.setState({ loading: true });
        this.props.readOrders(true, lunchTime, dinnerTime).then(() => {
          this.setState({ loading: false });
        });
      },
    });
  }

  handleOrder(orderId, e) {
    e.preventDefault();
    this.props.markOrderAsOrdered(orderId);
  }

  handleChangeOpsNote(orderId, e) {
    e.preventDefault();
    console.log(e.target.value);
    this.props.updateOrderOpsNote(orderId, e.target.value);
  }

  handleShowDetails(showDetails, e) {
    e.preventDefault();
    this.setState({ showDetails });
  }

  handleSelectMeal(mealType, e) {
    e.preventDefault();
    LOGGING && console.log("handleSelectMeal called with mealType:", mealType);
    const { orders } = this.props;
    const restaurantList = Array.from(
      new Set(
        orders
          .filter((o) => o.mealType === mealType)
          .map((order) => order?.goods?.[0]?.dish?.restaurant?.name)
      )
    );
    this.setState({
      showMealType: mealType,
      restaurantList,
      selectedRestaurant: [restaurantList[0]],
    });
  }

  handleClose() {
    LOGGING && console.log("PageCurrentOrderBatch handleClose");
    this.props.history.goBack();
  }

  handleSaveRestaurant(restaurant) {
    LOGGING && console.log("PageCurrentOrderBatch handleSaveRestaurant");
    const { lunchTime, dinnerTime } = this.state;
    this.props.saveRestaurant(restaurant, {
      success: () => {
        toast.success("Restaurant save success!");
        this.props.readOrders(true, lunchTime, dinnerTime).then(() => {
          this.setState({ loading: false });
        });
      },
    });
  }

  handleSavePreOrderedDishQuantity(mealId, preOrderedDishQuantity) {
    LOGGING &&
      console.log(
        "PageCurrentOrderBatch handleSavePreOrderedDishQuantity called with",
        {
          mealId,
          preOrderedDishQuantity,
        }
      );
    const { lunchTime, dinnerTime } = this.state;
    this.props.savePreOrderedDishQuantity(mealId, preOrderedDishQuantity, {
      success: () => {
        if (Object.keys(preOrderedDishQuantity).length > 1) {
          toast.success("Mark all as sold out success!");
        } else {
          toast.success("Dish quantity save success!");
        }
        this.props.readOrders(true, lunchTime, dinnerTime).then(() => {
          this.setState({ loading: false });
        });
      },
    });
  }

  handleCancelDish(mealId, dishId) {
    LOGGING &&
      console.log("PageCurrentOrderBatch handleCancelDish called with", {
        mealId,
        dishId,
      });
    const { lunchTime, dinnerTime } = this.state;
    this.props.mealCancelDish(mealId, dishId, {
      success: () => {
        toast.success("Cancel dish success!");
        this.props.readOrders(true, lunchTime, dinnerTime).then(() => {
          this.setState({ loading: false });
        });
      },
    });
  }

  handleFinishCart(cart) {
    LOGGING &&
      console.log("PageCurrentOrderBatch handleFinishCart called with", {
        cart,
      });
    this.setState({ loading: true });
    this.props.finishCart(cart).then(() => {
      this.setState({ loading: false });
    });
  }

  handleFinishCheckout(checkout) {
    LOGGING &&
      console.log("PageCurrentOrderBatch handleFinishCheckout called with", {
        checkout,
      });
    this.setState({ loading: true });
    this.props.payCheckout(checkout).then(() => {
      this.setState({ loading: false });
    });
  }

  handleFinishCheckoutNoPayment(checkout) {
    LOGGING &&
      console.log(
        "PageCurrentOrderBatch handleFinishCheckoutNoPayment called with",
        {
          checkout,
        }
      );

    this.setState({ loading: true });
    this.props.finishCheckout(checkout).then(() => {
      this.setState({ loading: false });
    });
  }

  handleResumeOrder(canceledOrder) {
    LOGGING &&
      console.log("PageCurrentOrderBatch handleResumeOrder called with", {
        canceledOrder,
      });
    this.setState({ loading: true });
    this.props.resumeOrder(canceledOrder).then(() => {
      this.setState({ loading: false });
    });
  }

  handleResumeOrderNoPayment(canceledOrder) {
    LOGGING &&
      console.log(
        "PageCurrentOrderBatch handleResumeOrderNoPayment called with",
        {
          canceledOrder,
        }
      );
    this.setState({ loading: true });
    this.props.resumeOrderNoPayment(canceledOrder).then(() => {
      this.setState({ loading: false });
    });
  }

  componentDidMount() {
    const now = moment().tz(CATimeZone);
    const opsOrderTime =
      now.hour() === 11 ||
      now.hour() === 12 ||
      now.hour() === 16 ||
      now.hour() === 17
        ? now.add(-2, "h")
        : now.hour() === 18 || now.hour() === 19
        ? now.add(-3, "h")
        : now;

    const { windows } = getCurrentDayDeliveryWindows(
      opsOrderTime.valueOf(),
      false
    );
    LOGGING &&
      console.log("PageCurrentOrderBatch componentDidMount with:", { windows });
    const lunchTime = windows.lunch.default.start;
    const earlyDinnerTime = windows.earlyDinner.default.start;
    const dinnerTime = windows.dinner.default.start;
    LOGGING &&
      console.log("PageCurrentOrderBatch componentdidmount got:", {
        windows,
        lunchTime,
        dinnerTime,
        earlyDinnerTime,
        opsOrderTime: opsOrderTime.format("LLLL"),
      });
    this.setState({ loading: true }, () => {
      this.props
        .readOrdersAndCarts(true, lunchTime, earlyDinnerTime, dinnerTime)
        .then(() => {
          const { orders, checkouts, carts, canceledOrders } = this.props;
          const showMealType = "lunch";

          const restaurantList = Array.from(
            new Set(
              orders
                .filter((o) => o.mealType === showMealType)
                .map((order) => order?.goods?.[0]?.dish?.restaurant?.name)
            )
          );
          LOGGING &&
            console.log(
              "PageCurrentOrderBatch componentDidmount got from backend:",
              {
                orders,
                checkouts,
                carts,
                canceledOrders,
                restaurantList,
              }
            );
          this.setState(
            {
              loading: false,
              showMealType,
              lunchTime,
              dinnerTime,
              earlyDinnerTime,
              selectedRestaurant: [restaurantList[0]],
              restaurantList,
            },
            () => {
              window.scrollTo(0, 0);
            }
          );
        });
    });

    // Make sure opts out PostHog
    posthog.opt_out_capturing();
  }

  render() {
    const { orders, checkouts, carts, canceledOrders, currentUser } =
      this.props;
    const {
      showMealType,
      loading,
      showDetails,
      lunchTime,
      earlyDinnerTime,
      dinnerTime,
      selectedRestaurant,
      restaurantList,
      showRestaurantOptions,
    } = this.state;
    LOGGING &&
      console.log("PageCurrentOrderBatch rendering with", {
        props: this.props,
        state: this.state,
      });

    if (
      !currentUser.isAuthenticated ||
      (!currentUser.user.isAdmin && !currentUser.user.isOps)
    ) {
      return <Redirect to="/" />;
    }

    const filteredOrders = orders
      .filter(
        (o) =>
          selectedRestaurant.includes(o?.goods?.[0]?.dish?.restaurant?.name) &&
          o.mealType === showMealType
      )
      .sort((a, b) => (a.payTime > b.payTime ? 1 : -1));

    let drivers = {};
    filteredOrders.forEach((o) => {
      const { driver, name, stop } = o;

      if (driver) {
        const { firstName } = driver;
        if (firstName in drivers) {
          drivers[driver.firstName].push({ name, stop });
        } else {
          drivers[driver.firstName] = [{ name, stop }];
        }
      }
    });

    Object.keys(drivers).forEach((driver) => {
      const route = drivers[driver];
      const sortedRoute = route.sort((a, b) => (a.stop > b.stop ? 1 : -1));
      drivers[driver] = [...route];
    });

    return (
      <div className="page">
        <Navbar noShadow={true} />
        {!loading ? (
          <CurrentList
            pdfRef={this.ref}
            orders={filteredOrders}
            checkouts={checkouts}
            carts={carts}
            canceledOrders={canceledOrders}
            drivers={drivers}
            lunchOrders={
              orders.filter((o) => o.window.start === lunchTime).length
            }
            earlyDinnerOrders={
              orders.filter((o) => o.window.start === earlyDinnerTime).length
            }
            dinnerOrders={
              orders.filter((o) => o.window.start === dinnerTime).length
            }
            onBack={this.handleClose}
            showMealType={showMealType}
            showDetails={showDetails}
            onSelectMeal={this.handleSelectMeal}
            onShowDetails={this.handleShowDetails}
            onOrder={this.handleOrder}
            onChangeOpsNote={this.handleChangeOpsNote}
            onEmail={this.handleEmail}
            onPayment={this.handlePayment}
            onCancelItem={this.handleCancelItem}
            onCancelOrder={this.handleCancelOrder}
            onSaveRestaurant={this.handleSaveRestaurant}
            restaurantList={restaurantList}
            selectedRestaurant={selectedRestaurant}
            onChangeRestaurant={this.handleChangeRestaurant}
            showRestaurantOptions={showRestaurantOptions}
            onToggleShowRestaurantOptions={
              this.handleToggleShowRestaurantOptions
            }
            onSavePreOrderedDishQuantity={this.handleSavePreOrderedDishQuantity}
            onCancelDish={this.handleCancelDish}
            onFinishCart={this.handleFinishCart}
            onFinishCheckout={this.handleFinishCheckout}
            onFinishCheckoutNoPayment={this.handleFinishCheckoutNoPayment}
            onResumeOrder={this.handleResumeOrder}
            onResumeOrderNoPayment={this.handleResumeOrderNoPayment}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageCurrentOrderBatch got redux state:", state);
  return {
    orders: state.orders,
    checkouts: state.checkouts,
    carts: state.carts,
    canceledOrders: state.canceledOrders,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readOrders,
  readCheckouts,
  payCheckout,
  finishCheckout,
  resumeOrder,
  resumeOrderNoPayment,
  readCarts,
  readOrdersAndCarts,
  finishCart,
  markOrderAsOrdered,
  markAllOrderAsOrdered,
  updateOrder,
  updateOrderOpsNote,
  emailRestaurant,
  markPaymentAsPaid,
  orderCancelItem,
  cancelOrderByOps,
  saveRestaurant,
  savePreOrderedDishQuantity,
  mealCancelDish,
})(PageCurrentOrderBatch);
