import { faCrown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import styled from "styled-components";
import { Container, Field } from "./Contact";
import { Brown, Centered, TextGrey } from "../../Shared";
const Logo = styled(Centered)`
  background-color: ${Brown};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center !important;
  svg {
    font-size: 12px;
    margin: 0px;
    margin-bottom: 2px;
  }
`;
export const FoodieMember = () => (
  <Logo>
    <FontAwesomeIcon icon={faCrown} />
  </Logo>
);
const Fee = styled(Centered)`
  span {
    line-height: 1.5;
  }
`;
const FoodiePrice = styled.span`
  line-height: 1.5;
  margin-left: 10px;
`;
const DoordashPrice = styled.span`
  line-height: 1.5;
  margin-right: 10px;
  text-decoration: line-through;
  color: ${TextGrey};
  font-weight: 300;
`;
const MemberSaving = styled.span`
  line-height: 1.5;
  margin-left: 5px;
  margin-right: 10px;
  color: ${TextGrey};
  font-weight: 300;
`;
export const Fees = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const isGuest = !currentUser?.isAuthenticated;
  const isMember = currentUser?.user?.membership?.status === "active";
  const {
    serviceFee,
    deliveryFee,
    tax,
    sum,
    doordashComparables,
    discount,
    credit,
  } = cart;
  const { distance, memberSaving, isAvailable, isOutOfRange } =
    doordashComparables || {};
  const {
    foodSaving,
    taxSaving,
    expandedRangeFeeSaving,
    totalSaving,
    serviceFeeSaving,
    deliveryFeeSaving,
  } = memberSaving || {};
  const showDoordashPrice = isAvailable && isMember && !isOutOfRange;
  const showMemberSaving = isGuest || !isMember;
  return (
    <Container>
      {/* <Title>Summary</Title> */}
      <Field>
        <label>subtotal</label>
        <Fee>
          {showDoordashPrice && (
            <DoordashPrice>
              Doordash ${(sum + foodSaving || 0)?.toFixed(2)}
            </DoordashPrice>
          )}
          {showDoordashPrice && <FoodieMember />}
          {showMemberSaving && <FoodieMember />}
          {showMemberSaving && (
            <MemberSaving>
              Member ${(sum - foodSaving || 0)?.toFixed(2)}
            </MemberSaving>
          )}
          <FoodiePrice>${sum?.toFixed(2)}</FoodiePrice>
        </Fee>
      </Field>
      <Field>
        <label>service fee</label>
        <Fee>
          {showDoordashPrice && (
            <DoordashPrice>
              Doordash ${(serviceFee + serviceFeeSaving || 0)?.toFixed(2)}
            </DoordashPrice>
          )}
          {showDoordashPrice && <FoodieMember />}
          {showMemberSaving && <FoodieMember />}
          {showMemberSaving && (
            <MemberSaving>
              Member ${(serviceFee - serviceFeeSaving || 0)?.toFixed(2)}
            </MemberSaving>
          )}
          <FoodiePrice>${serviceFee?.toFixed(2)}</FoodiePrice>
        </Fee>
      </Field>
      <Field>
        <label>
          delivery fee{`${distance > 0 ? `(${distance?.toFixed(0)} mi)` : ""}`}
        </label>
        <Fee>
          {showDoordashPrice && (
            <DoordashPrice>
              Doordash ${(deliveryFee + deliveryFeeSaving || 0)?.toFixed(2)}
            </DoordashPrice>
          )}
          {showDoordashPrice && <FoodieMember />}
          {showMemberSaving && <FoodieMember />}
          {showMemberSaving && <MemberSaving>Member $0.00</MemberSaving>}
          <FoodiePrice>${deliveryFee?.toFixed(2)}</FoodiePrice>
        </Fee>
      </Field>

      {showDoordashPrice && expandedRangeFeeSaving > 0 && (
        <Field>
          <label>expanded range fee</label>
          <Fee>
            {showDoordashPrice && (
              <DoordashPrice>
                Doordash ${expandedRangeFeeSaving?.toFixed(2)}
              </DoordashPrice>
            )}
            {showDoordashPrice && <FoodieMember />}
            <FoodiePrice>$0.00</FoodiePrice>
          </Fee>
        </Field>
      )}

      <Field>
        <label>tax</label>
        <Fee>
          {showDoordashPrice && (
            <DoordashPrice>
              Doordash ${(tax + taxSaving || 0)?.toFixed(2)}
            </DoordashPrice>
          )}
          {showDoordashPrice && <FoodieMember />}
          {showMemberSaving && <FoodieMember />}
          {showMemberSaving && (
            <MemberSaving>
              Member ${(tax - taxSaving || 0)?.toFixed(2)}
            </MemberSaving>
          )}
          <FoodiePrice>${tax?.toFixed(2)}</FoodiePrice>
        </Fee>
      </Field>
      {/* {discount > 0 && (
        <Field>
          <label>discount</label>
          <Fee>
            <span></span>
            <FoodiePrice>-${discount?.toFixed(2)}</FoodiePrice>
          </Fee>
        </Field>
      )}
      {credit > 0 && (
        <Field>
          <label>credit</label>
          <Fee>
            <span></span>
            <FoodiePrice>-${credit?.toFixed(2)}</FoodiePrice>
          </Fee>
        </Field>
      )} */}
    </Container>
  );
};
