import { useState } from "react";
import { faArrowToTop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {
  BackgroundGrey,
  BorderGrey,
  card,
  centered,
  LightShadow,
} from "../../Shared";
const BackToTopContainer = styled.button<{ bottom: number }>`
  position: fixed;
  ${centered};
  bottom: ${(props) => props.bottom || 20}px;
  left: 10px;
  background-color: white;
  border-radius: 50%;
  padding: 0px;
  margin: 0px;
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
  transition: 0.3s;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  box-shadow: ${LightShadow};
  border: 1px solid ${BorderGrey};
  svg {
    font-size: 20px;
  }
  @media screen and (min-width: 500px) {
    left: auto;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
`;
export const BackToTop = ({ bottom = 20 }) => {
  // only show when the user scrolls down, and down more than 100px
  const [show, setShow] = useState(false);
  window.onscroll = function () {
    if (document.documentElement.scrollTop > 150) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const onClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  return show ? (
    <BackToTopContainer onClick={onClick} bottom={bottom}>
      <FontAwesomeIcon icon={faArrowToTop} />
    </BackToTopContainer>
  ) : null;
};
