import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Centered,
  PinkPeach,
  NavHeight,
  textTitle,
  textUpper,
  CenteredByColumn,
  actionTransparent,
  TextUPPER,
} from "../Shared";

const Container = styled(Centered)`
  /* background-image: url("https://myhumblefood.wordpress.com/wp-content/uploads/2013/12/img_6754.jpg"); */
  background-image: url("https://myhumblefood.wordpress.com/wp-content/uploads/2013/12/img_6737.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
`;

const Overlay = styled(CenteredByColumn)`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  padding-top: calc(15vh + ${NavHeight}px);
  padding-bottom: 15vh;
  padding-left: 20px;
  padding-right: 20px;
`;

const Title = styled(TextUPPER)`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const LinkToGuide = styled(Link)`
  ${actionTransparent}
  color: white;
  border-color: white;
`;
const GuideEntry = () => {
  return (
    <Container>
      <Overlay>
        <Title>
          2025 Foodie Guide<br></br>Must-Tries
        </Title>
        <LinkToGuide to="/guide2025" style={{ textDecoration: "none" }}>
          {/* <span>Check your score</span> */}
          <span>How Many Have You Tried?</span>
        </LinkToGuide>
      </Overlay>
    </Container>
  );
};

export default GuideEntry;
