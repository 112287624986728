import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import {} from "@fortawesome/free-solid-svg-icons";
import {
  faTags,
  faTruck,
  faCheck,
  faUtensils,
  faMinusCircle as faInactive,
  faCheckCircle as faActive,
  faTimesCircle as faCancelled,
  faClock as faTrialing,
  faCreditCard,
  faPiggyBank,
  faConciergeBell,
  faCrown,
  faMoneyBill,
  faCalendarAlt,
  faTimesCircle,
  faCoins,
} from "@fortawesome/pro-light-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faArrowToRight as faCompare,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { Toaster } from "react-hot-toast";
import { Link, Redirect } from "react-router-dom";
import stripeBadge from "src/assets/images/stripe-badge-transparent-1.png";
import { Flex } from "src/components/Layout";
import { PayWithPaypal } from "src/components/Pay";
import { Close, LOGGING } from "src/screens/shared";
import { NewCardForm, Back } from "src/screens/tasting_menu/components";
import {
  CATimeZone,
  FreeTrialDays,
  MembershipCost,
} from "src/screens/tasting_menu/components";
import { CreditCardIcons } from "src/screens/tasting_menu/components/";
import { LoadingSpinner } from "src/screens/tasting_menu/components/customers/survey/Taste";
import { MemberOnlyRestaurants } from "src/screens/tasting_menu/components/home/MemberOnlyRestaurants";
import { MemberOnlyRestaurantsCollage } from "src/screens/tasting_menu/components/home/MemberOnlyRestaurantsCollage";
import {
  upper14500,
  text12300,
  Grey,
  CenteredByColumnLeft,
  ActionBlack,
  Text12300,
  TextTitle,
  Centered,
  CenteredByColumn,
  Beige,
  card,
  textTitle,
  centered,
  Brown,
  Purple,
  Gold,
  Pink,
  text13500,
  NavHeight,
  BorderGrey,
  actionBlack,
  actionGrey,
  BackgroundGrey,
} from "src/screens/tasting_menu/components/Shared";
import styled from "styled-components";

require("../../screens/tasting_menu/components/Checkout.scss");
const breakPoint = 800;
const Container = styled(CenteredByColumn)`
  width: 100vw;
`;
const Section = styled(CenteredByColumn)`
  /* width: calc(100% - 40px); */
  width: 100%;
  /* padding: 15px; */
  margin-top: 10px;
  /* ${card} */
  /* border-bottom: 1px solid ${BorderGrey}; */
  padding-bottom: ${(props) => (props.theme.lastOne ? "0px" : "10px")};
  /* box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.2); */
  /* div:last-child {
    padding-bottom: 0px;
  } */
  border-bottom: 1px solid
    ${(props) => (props.theme.lastOne ? "transparent" : "lightgrey")};
  @media only screen and (min-width: ${breakPoint}px) {
    border: 1px solid ${BorderGrey};
    padding: 15px 15px 0px 15px;
    /* box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.2); */
    width: 100%;
    flex-grow: 1;
    justify-content: flex-start;
    position: relative;
  }
`;

const SectionTitle = styled(Centered)`
  /* ${upper14500} */
  ${textTitle}
  margin-bottom: ${(props) => (props.theme.noMargin ? "0px" : "10px")};
  white-space: nowrap;
  width: 100%;
  /* text-align: center; */
  position: relative;
  justify-content: space-between;
  button {
    /* ${text12300}
    border-bottom: 1px solid black;
    margin: 10px 0px 0px 0px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    padding: 0px; */
    ${actionBlack}
  }
`;
const ManageMembership = styled.button`
  ${centered}
  padding: 18px;
  height: 80px;
  /* margin: 5px; */
  border-left: 1px solid ${BorderGrey};
  align-self: stretch;
  flex-grow: 1;
  background-color: black;
  /* justify-content: space-between; */
  text-align: center;
  position: relative;
  font-weight: 500;
  span {
    width: 100%;
    /* padding-right: 30px; */
    letter-spacing: 0.8px;
    font-size: 16px;
    color: white;
  }
  svg {
    /* margin-left: 20px; */
    /* display: none; */
    position: absolute;
    right: 30px;
    color: white;
    font-size: 20px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    height: 50px;
    margin-bottom: 15px;
  }
`;
const MembershipStatus = styled(Centered)`
  text-align: center;
  padding: 0px 25px 0px 18px;
  align-self: stretch;
  flex-grow: 1;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  svg {
    font-size: 16px;
    margin-right: 8px;
  }
  /* font-weight: 600; */
`;
const MembershipStatusDesktop = styled(Centered)`
  display: none;
  width: 100%;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px;
  justify-content: flex-start;
  background-color: ${Beige};
  padding: 50px 20px 20px 20px;
  position: relative;
  label {
    ${upper14500}
    /* margin-bottom: 20px; */
    position: absolute;
    top: 20px;
    left: 20px;
  }
  svg {
    margin-right: 15px;
  }
  span {
    ${textTitle}
    font-size: 15px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
  }
`;
export interface SubSectionProps {
  center?: boolean;
}

export const SubSection = styled(CenteredByColumnLeft)<SubSectionProps>`
  ${text12300}
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  /* margin-bottom: 15px; */
  /* border-bottom: 1px solid lightgrey; */
  /* padding-bottom: 15px; */
  width: 100%;
  /* background-color: ${Beige}; */
  /* padding: 15px 20px; */
  /* span {
    line-height: 24px;
  } */
  svg {
    font-size: 16px;
    margin-right: 5px;
    line-height: 24px;
    height: 24px;
  }
  > button {
    /* ${text12300}
  border-bottom: 1px solid black;
  margin: 10px 0px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500; */
    ${actionBlack}
    /* margin: 10px 0 20px 0%; */
    svg {
      margin-left: 10px;
      /* font-size: 20px; */
      display: none;
    }
    /* padding: 20px 20px; */
  }
`;
export const PayMethodWrapper = styled(SubSection)`
  margin: 0px;
  width: 100%;
  flex-direction: column;
  /* justify-content: flex-start; */
  div:first-child {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
  }
`;
export const SelectWrapper = styled(Centered)`
  width: 100%;
  /* justify-content: space-around; */
`;
export const SubSectionTitle = styled(Centered)`
  justify-content: flex-start;
  ${text12300}
  /* ${textTitle} */
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
  /* border-bottom: 1px solid black; */
  > span {
    position: absolute;
    left: 37px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-transform: capitalize;
  }
  /* width: 100px; */
  flex-shrink: 0;
  color: ${(props) => (props.theme.isTransparent ? "transparent" : "black")};
  svg {
    margin-right: 15px;
    font-size: 18px;
    width: 30px;
  }
`;
const StatusRow = styled(SubSectionTitle)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
  span {
    position: relative;
    left: 0px;
  }
`;
export enum PayMethod {
  Stripe,
  Paypal,
}

type MembershipProps = {
  membership: any;
  stripeInfo: any;
  loading: Boolean;
  saveCard: Boolean;
  onStart: (paypalSubscribeId?: string) => void;
  onResume: () => void;
  onCancel: () => void;
  onChangeNumber: () => void;
  onSaveCard: () => void;
  onShowAuth: () => void;
  onBack: () => void;
  onToggleSaveCard: () => void;
  onToggleShowComparison: () => void;
  onSetShowPayment?: (showPayment?: Boolean) => void;
  showComparison: Boolean;
  showPayment?: Boolean;
  newCard: any;
  error: any;
  cardType: any;
};
const MobileTop = styled(CenteredByColumn)`
  margin: 0px 20px;
  /* height: ${NavHeight}px; */
  /* position: absolute;
  top: 0px;
  left: 0px; */
  padding: 0px;
  justify-content: stretch;
  flex-grow: 1;
  align-self: stretch;
  ${text12300}
  font-weight: 400;
  font-size: 13px;
  @media only screen and (min-width: ${breakPoint}px) {
    display: none;
  }
`;
const DesktopLeft = styled(CenteredByColumn)`
  display: none;
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
    width: 60%;
    margin-right: 20px;
    > div:first-child {
      margin-top: 0px;
      /* box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12); */
    }
  }
`;
const DesktopRight = styled(CenteredByColumn)`
  display: none;
  width: 40%;
  justify-content: flex-start;
  align-self: stretch;
  div:first-child {
    margin-top: 0px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
  }
`;
const DesktopCostBanner = styled(MobileTop)`
  display: none;
  ${textTitle}
  font-size: 14px;
  span {
    text-align: center;
    width: 100%;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
    border: 1px solid ${BorderGrey};
    background: white;
  }
`;
const SeeMore = styled(Link)`
  ${text12300}
  border-bottom: 1px solid black;
  margin: 10px 0px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
`;

const Cell = styled(Centered)`
  ${text12300}
  flex-wrap: wrap;
  /* font-size: 11px; */
  /* justify-content: flex-start;   */
  text-transform: capitalize;
  line-height: 1.6;
  height: 55px;
  /* border-bottom: 1px solid black; */
  /* background-color: green; */
  width: 100%;
  /* padding: 0px 5px; */
  white-space: pre-wrap;
  position: relative;
  svg {
    /* position: absolute; */
    font-size: 7px;
    margin-left: 2px;
    margin-bottom: 1px;
    /* right: 30px;
    bottom: 18px; */
  }
`;
const CellTransparent = styled(Cell)`
  color: transparent;
`;
const CellBold = styled(Cell)`
  font-weight: 500;
  /* text-transform: uppercase; */
  font-size: 11px;
`;

export const ExistingCard = styled.div`
  /* ${card} */
  ${text12300}
  padding: 20px;
  /* flex-direction: column; */
  margin-bottom: 20px;
  flex-grow: 1;
  align-self: stretch;
  border: 1px solid black; //${BorderGrey};
  /* width: 100%; */
  .form-section {
    margin: 0px;

    .form-subsection {
      .form-row {
        .form-field-wrapper {
          input {
            /* background-color: red; */
            font-size: 12px;
          }
        }
      }
    }
  }
  button {
    margin-top: 20px;
    ${centered}
    svg {
      border: 1px solid black;
      border-radius: 3px;
      margin-right: 10px;
      padding: 3px;
    }
    ${text12300}
  }
  button.unchecked {
    svg {
      color: transparent;
    }
  }
  @media only screen and (min-width: ${breakPoint}px) {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    flex-grow: 0;
  }
`;
const NewCardSection = styled(CenteredByColumn)`
  align-items: flex-start;
  margin-bottom: 30px;
  background-color: ${BackgroundGrey};
  & button {
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
`;
export const NewCard = styled(ExistingCard)`
  /* border: 1px solid red; */
  border-radius: 10px;
  border: none;
  background-color: ${BackgroundGrey};
  padding: 0px 0px 20px 0px;
`;
const Note = styled.span`
  margin: 10px 0px;
  ${text12300}
  font-size: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: pre-wrap;
  svg {
    font-size: 6px;
    margin-right: 5px;
    margin-bottom: 3px;
    /* margin-top: 3px; */
  }
  line-height: 1.7;
  /* font-size: 11px; */
`;

const MobileSection = styled(Section)`
  border-bottom: none;
  /* padding-top: 20px; */
  @media only screen and (min-width: ${breakPoint}px) {
    /* display: none; */
    border: 1px solid ${BorderGrey};
  }
`;

const DesktopSection = styled(Section)`
  display: none;
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
  }
`;

const SubSectionMembershipPrice = () => (
  <SubSection>
    <SubSectionTitle>
      {/* <Icon>🏷️</Icon> */}
      <FontAwesomeIcon icon={faMoneyBill} />
      <span>$10 per month</span>
    </SubSectionTitle>
  </SubSection>
);

const SubSectionMembershipCancel = () => (
  <SubSection>
    <SubSectionTitle>
      <FontAwesomeIcon icon={faTimesCircle} />
      {/* <Icon>⏹️</Icon> */}
      {/* ⚙️🚫 */}
      <span>cancel any time</span>
    </SubSectionTitle>
  </SubSection>
);

const SubSectionMembershipTrial = () => (
  <SubSection>
    <SubSectionTitle>
      <FontAwesomeIcon icon={faCalendarAlt} />
      {/* <Icon>🗓️</Icon> */}
      <span>30-day free trial</span>
    </SubSectionTitle>
    {/* <Note>500+ restaurants delivered with dine-in price.</Note> */}
  </SubSection>
);

const SectionCostMobile = () => (
  <MobileSection>
    <SectionTitle>Cost</SectionTitle>
    {/* <SubSection>{`$${MembershipCost} per month, ${FreeTrialDays} days free trial, cancel any time.`}</SubSection> */}
    <SubSectionMembershipPrice />
    <SubSectionMembershipTrial />
    <SubSectionMembershipCancel />
  </MobileSection>
);
const SectionCostDesktop = () => (
  <DesktopSection theme={{ lastOne: true }}>
    {/* <SectionTitle>Cost</SectionTitle> */}
    <FontAwesomeIcon icon={faTimesCircle} />
    <SubSection>{`$${MembershipCost} per month, ${FreeTrialDays} days free trial, cancel any time.`}</SubSection>
  </DesktopSection>
);
const Icon = styled.span`
  font-size: 30px;
  margin-right: 10px;
  line-height: 40px;
`;
const SubSectionFoodPrice = () => (
  <SubSection>
    <SubSectionTitle>
      <FontAwesomeIcon icon={faPiggyBank} />
      {/* <Icon>💰</Icon> */}
      <span>cheaper food price</span>
    </SubSectionTitle>
    {/* <Note>500+ restaurants delivered with dine-in price.</Note> */}
  </SubSection>
);
const ShowComparison = styled.button`
  ${text12300}
  border-bottom: 1px solid black;
  margin: 10px 0px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  padding: 0px;
`;
const SubSectionComparison = ({ onClick }) => (
  <SubSection center={true}>
    <ShowComparison onClick={onClick}>
      compare with dashpass
      <FontAwesomeIcon icon={faCompare} />
    </ShowComparison>
  </SubSection>
);
const SubSectionExclusiveRestaurants = () => (
  <SubSection>
    <SubSectionTitle>
      <FontAwesomeIcon icon={faCrown} />
      {/* <Icon>🍔</Icon> */}
      <span>100+ member-only restaurants</span>
    </SubSectionTitle>
    {/* <Note>Our 25-mile delivery range reaches local favorites.</Note> */}
    {/* <SeeMore to="/restaurants">see all restaurants</SeeMore> */}
    {/* <MemberOnlyRestaurantsCollage /> */}
  </SubSection>
);
const AverageSaving = () => (
  <SubSection>
    <SubSectionTitle>
      {/* <Icon>💸</Icon> */}
      <FontAwesomeIcon icon={faCoins} />
      <span>$50~$60 per month</span>
    </SubSectionTitle>
    {/* <span>20% cheaper food price</span> */}
    {/* <Note>Many members save over $100 per month.</Note> */}
  </SubSection>
);
const SubSectionServiceFee = () => (
  <SubSection>
    <SubSectionTitle>
      <FontAwesomeIcon icon={faConciergeBell} />
      {/* <Icon>👩🏻‍💼</Icon> */}
      <span>flat service fee</span>
    </SubSectionTitle>
    {/* <Note>flat fee for any distance and any order size.</Note> */}
  </SubSection>
);
const SubSectionDeliveryFee = () => (
  <SubSection>
    <SubSectionTitle>
      <FontAwesomeIcon icon={faTruck} />
      {/* <Icon>🚚</Icon> */}
      <span>25-mile free delivery</span>
    </SubSectionTitle>
    {/* <Note>flat fee for any distance and any order size.</Note> */}
  </SubSection>
);
const Content = styled(CenteredByColumn)`
  padding-bottom: ${NavHeight}px;
  width: 100%;
  overflow-y: scroll;
  @media only screen and (min-width: ${breakPoint}px) {
    flex-direction: row;
    align-items: flex-start;
    max-width: ${breakPoint}px;
    padding: 20px;
  }
`;
export const CardInfo = styled(Centered)`
  svg {
    font-size: 20px;
    margin: 0 10px 0 0;
    /* line-height: 30px; */
  }
`;
const MobileBottom = styled(CenteredByColumn)`
  ${card}
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 20px;
  border-top: 1px solid ${BorderGrey};
  @media only screen and (min-width: ${breakPoint}px) {
    /* flex-direction: row;
    width: 60vw;
    padding-top: 20px; */
    display: none;
  }
`;

const Header = styled(Centered)`
  height: ${NavHeight}px;
  position: fixed;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid ${BorderGrey};
  ${textTitle}
  button {
    left: auto !important;
    right: 0px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
    padding: 0px 8vw;
    button {
      left: auto !important;
      right: calc(50% - ${breakPoint / 2}px) !important;
    }
  }
`;
const ClosePaymentButton = styled.button`
  position: absolute;
  right: 20px;
  top: 30px;
  svg {
    font-size: 20px;
  }
`;
const PaymentSection = ({
  isActive,
  isTrial,
  isCanceled,
  isPaypal,
  card,
  subscriptionId,
  canceled_at,
  paused_at,
  start_date,
  next_billing_date,
  payMethod,
  setPayMethod,
  onChangeNumber,
  newCard,
  cardType,
  error,
  saveCard,
  onToggleSaveCard,
  onStart,
  onCancel,
  onResume,
  loading,
  onClose,
}) => (
  <Section theme={{ lastOne: true }}>
    <SectionTitle>Secure Payment</SectionTitle>
    {onClose ? (
      <ClosePaymentButton onClick={onClose}>
        <FontAwesomeIcon icon={faChevronDown} />
      </ClosePaymentButton>
    ) : null}
    {(isActive || isTrial || isCanceled) && subscriptionId != null ? (
      <SubSection>
        <SubSectionTitle>Paid By</SubSectionTitle>
        {isPaypal ? (
          <span>PayPal</span>
        ) : card?.brand && card?.last4 ? (
          <CardInfo>
            <FontAwesomeIcon
              className="brand-icon"
              icon={CreditCardIcons[card.brand] || faCreditCard}
            />
            {`...${card.last4} 
      ${
        card.exp_month && card.exp_year
          ? ` Exp. ${card.exp_month}/${card.exp_year}`
          : ""
      }`}
          </CardInfo>
        ) : null}
      </SubSection>
    ) : null}
    {isActive || isTrial || isCanceled ? (
      <SubSection>
        <SubSectionTitle>Started</SubSectionTitle>
        <span>
          {`${moment(start_date * 1000).format("MMM DD, yyyy HH:mmA")}`}
        </span>
      </SubSection>
    ) : null}
    {isActive && !isTrial && !canceled_at && !paused_at && next_billing_date ? (
      <SubSection>
        <SubSectionTitle>next billing</SubSectionTitle>
        {`${moment(next_billing_date * 1000).format("MMM DD, yyyy HH:mmA")}`}
      </SubSection>
    ) : null}

    {isTrial && !canceled_at && !paused_at ? (
      <SubSection>
        <SubSectionTitle>billing starts</SubSectionTitle>
        <span>{`${moment(next_billing_date * 1000)
          .tz(CATimeZone)
          .format("MMM DD, yyyy HH:mmA")}`}</span>
      </SubSection>
    ) : null}

    {canceled_at ? (
      <>
        <SubSection>
          <SubSectionTitle>canceled</SubSectionTitle>
          <span>{`${moment(canceled_at * 1000).format(
            "MMM DD, yyyy HH:mmA"
          )}`}</span>
        </SubSection>
        <SubSection>
          <SubSectionTitle>{`${
            isActive ? "will become" : "became"
          } inactive`}</SubSectionTitle>
          <span>{`${moment(next_billing_date * 1000).format(
            "MMM DD, yyyy HH:mmA"
          )}`}</span>
        </SubSection>
      </>
    ) : null}
    {paused_at ? (
      <>
        <SubSection>
          <SubSectionTitle>paused</SubSectionTitle>
          <span>{`${moment(paused_at * 1000).format(
            "MMM DD, yyyy HH:mmA"
          )}`}</span>
        </SubSection>
        <SubSection>
          <SubSectionTitle>{`${
            isActive ? "will become" : "became"
          } inactive`}</SubSectionTitle>
          <span>{`${moment(next_billing_date * 1000).format(
            "MMM DD, yyyy HH:mmA"
          )}`}</span>
        </SubSection>
      </>
    ) : null}
    {isActive || isTrial || isCanceled ? null : (
      <>
        <PayMethodWrapper>
          {/* <SubSectionTitle>Payment Method</SubSectionTitle> */}
          <SelectWrapper>
            <PayMethodSelect
              theme={{ selected: payMethod === PayMethod.Stripe }}
              onClick={() => {
                setPayMethod(PayMethod.Stripe);
              }}
            >
              {payMethod === PayMethod.Stripe && (
                <FontAwesomeIcon icon={faCheck} />
              )}
              <span>pay by card</span>
            </PayMethodSelect>
            <PayMethodSelect
              theme={{ selected: payMethod === PayMethod.Paypal }}
              onClick={() => {
                setPayMethod(PayMethod.Paypal);
              }}
            >
              <span>pay by paypal</span>
              {payMethod === PayMethod.Paypal && (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </PayMethodSelect>
          </SelectWrapper>
        </PayMethodWrapper>

        {payMethod === PayMethod.Stripe ? (
          card?.brand && card?.last4 ? (
            <ExistingCard>
              <FontAwesomeIcon
                className="brand-icon"
                icon={CreditCardIcons[card.brand] || faCreditCard}
              />
              <span>{`...${card.last4} 
      ${
        card.exp_month && card.exp_year
          ? ` Exp. ${card.exp_month}/${card.exp_year}`
          : ""
      }`}</span>
            </ExistingCard>
          ) : (
            <NewCardSection>
              <NewCard>
                <div className="stripe-badge">
                  <img src={stripeBadge} alt="Stripe Badge" />
                </div>
                <div className="form-section">
                  <NewCardForm
                    onChangeNumber={onChangeNumber}
                    newCard={newCard}
                    error={error}
                    cardType={cardType}
                  />
                </div>
              </NewCard>
              <button
                className={`save-card ${saveCard ? "checked" : "unchecked"}`}
                onClick={onToggleSaveCard}
              >
                <FontAwesomeIcon icon={faCheck} />
                <span>save card</span>
              </button>
            </NewCardSection>
          )
        ) : null}

        {payMethod === PayMethod.Paypal &&
          !isActive &&
          !isTrial &&
          !canceled_at &&
          !paused_at && (
            <SubSection theme={{ isCentered: true }}>
              <PayWithPaypal
                isSubscribe
                noTrialPeriod={false}
                onPaypalPaid={(paypalSubscribeId) => onStart(paypalSubscribeId)}
              />
            </SubSection>
          )}
      </>
    )}

    {payMethod === PayMethod.Stripe ? (
      !subscriptionId && isTrial ? null : (
        <ManageMembership
          disabled={loading}
          onClick={() =>
            !isActive && !isTrial && !canceled_at && !paused_at
              ? onStart()
              : !canceled_at && !paused_at
              ? onCancel()
              : onResume()
          }
        >
          <span>{`${
            !isActive && !isTrial && !canceled_at && !paused_at
              ? "start free trial"
              : !canceled_at && !paused_at
              ? "cancel or pause"
              : "resume"
          }`}</span>
        </ManageMembership>
      )
    ) : null}

    {payMethod === PayMethod.Paypal ? (
      <>
        {!loading &&
        (isActive ||
          ((canceled_at || paused_at) &&
            next_billing_date > moment().unix())) ? (
          <Button
            onClick={() => (canceled_at || paused_at ? onResume() : onCancel())}
          >{`${
            canceled_at || paused_at ? "resume" : "cancel or pause"
          }`}</Button>
        ) : (canceled_at || paused_at) &&
          next_billing_date < moment().unix() ? (
          <SubSection theme={{ isCentered: true }}>
            <SubSectionTitle>Reactivate Membership</SubSectionTitle>
            <PayWithPaypal
              isSubscribe
              noTrialPeriod={true}
              onPaypalPaid={(paypalSubscribeId) => onStart(paypalSubscribeId)}
            />
          </SubSection>
        ) : null}
      </>
    ) : null}
  </Section>
);

export const Overlay = styled(Centered)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  ${textTitle}
  font-size: 20px;
  font-weight: 600;
  /* color: black; */
`;
export const Membership = (props: MembershipProps) => {
  const {
    membership,
    stripeInfo,
    loading,
    error,
    newCard,
    cardType,
    saveCard,
    onStart,
    onCancel,
    onResume,
    onChangeNumber,
    onToggleSaveCard,
    onBack,
    onShowAuth,
    onToggleShowComparison,
    showComparison,
    showPayment,
    onSetShowPayment,
  } = props;

  const { card } = stripeInfo || {};
  const {
    isPaypal,
    status,
    canceled_at,
    paused_at,
    start_date,
    next_billing_date,
    isActive,
    subscriptionId,
  } = membership || {};
  const isTrial = status === "trialing",
    isCanceled = status === "canceled" || status === "suspended";
  const [payMethod, setPayMethod] = useState<PayMethod>(
    isPaypal ? PayMethod.Paypal : PayMethod.Stripe
  );
  LOGGING &&
    console.log("Membership got data:", {
      payMethod,
      membership,
      stripeInfo,
      loading,
      status,
      subscriptionId,
      start_date,
      next_billing_date,
      canceled_at,
      paused_at,
      card,
      cardType,
      newCard,
      error,
      isPaypal,
      isActive,
      isTrial,
      isCanceled,
    });

  return (
    <Container>
      {loading ? (
        <Overlay>
          <LoadingSpinner />
        </Overlay>
      ) : null}
      <Toaster />
      <Content>
        <Header>
          <Close onClick={onBack} />
          <span>Membership</span>
        </Header>
        <MobileTop>
          <Section>
            {error?.message ? "error" : null}
            <SubSection>
              <StatusRow>
                <SectionTitle theme={{ noMargin: true }}>status</SectionTitle>
                {status === "active" ? (
                  <Icon>🟢</Icon>
                ) : status === "cancelled" ? (
                  <Icon>🔴</Icon>
                ) : status === "trialing" ? (
                  <Icon>🟢</Icon>
                ) : (
                  <Icon>🔴</Icon>
                )}
                <span>{status || "inactive"}</span>
              </StatusRow>
            </SubSection>
          </Section>
          <Section>
            <SectionTitle>benefits</SectionTitle>
            <SubSectionFoodPrice />
            <SubSectionServiceFee />
            <SubSectionDeliveryFee />
            <SubSectionExclusiveRestaurants />
            {/* <SubSectionComparison onClick={onToggleShowComparison} />             */}
          </Section>
          {/* <Section>
            <SectionTitle>real average saving</SectionTitle>
            <AverageSaving />
          </Section> */}
          <SectionCostMobile />
        </MobileTop>
        <MobileBottom>
          {showPayment ? (
            <PaymentSection
              isActive={isActive}
              isTrial={isTrial}
              isCanceled={isCanceled}
              isPaypal={isPaypal}
              card={card}
              subscriptionId={subscriptionId}
              canceled_at={canceled_at}
              paused_at={paused_at}
              start_date={start_date}
              next_billing_date={next_billing_date}
              payMethod={payMethod}
              setPayMethod={setPayMethod}
              onChangeNumber={onChangeNumber}
              newCard={newCard}
              cardType={cardType}
              error={error}
              saveCard={saveCard}
              onToggleSaveCard={onToggleSaveCard}
              onStart={onStart}
              onCancel={onCancel}
              onResume={onResume}
              loading={loading}
              onClose={() => onSetShowPayment(false)}
            />
          ) : (
            <ManageMembership
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSetShowPayment(!showPayment);
              }}
            >
              <span>{`${
                status !== "active" &&
                status !== "cancelled" &&
                status !== "trialing"
                  ? "start free trial"
                  : "manage membership"
              }`}</span>
              {showPayment ? null : <FontAwesomeIcon icon={faChevronUp} />}
            </ManageMembership>
          )}
        </MobileBottom>
        <DesktopLeft>
          <Section>
            <SectionTitle>benefits</SectionTitle>
            <SubSectionFoodPrice />
            <SubSectionServiceFee />
            <SubSectionDeliveryFee />
            <SubSectionExclusiveRestaurants />

            {/* <SubSectionComparison onClick={onToggleShowComparison} /> */}
          </Section>
          {/* <Section>
            <SectionTitle>average saving</SectionTitle>
            <AverageSaving />
          </Section> */}
          <SectionCostMobile />
        </DesktopLeft>
        <DesktopRight>
          <MembershipStatusDesktop>
            <label>Your Status</label>
            <FontAwesomeIcon
              icon={
                status === "active"
                  ? faActive
                  : status === "cancelled"
                  ? faCancelled
                  : status === "trialing"
                  ? faTrialing
                  : faInactive
              }
            />
            <span>
              {loading
                ? "Loading..."
                : error?.message
                ? "error"
                : status || "Inactive"}
            </span>
          </MembershipStatusDesktop>
          <PaymentSection
            isActive={isActive}
            isTrial={isTrial}
            isCanceled={isCanceled}
            isPaypal={isPaypal}
            card={card}
            subscriptionId={subscriptionId}
            canceled_at={canceled_at}
            paused_at={paused_at}
            start_date={start_date}
            next_billing_date={next_billing_date}
            payMethod={payMethod}
            setPayMethod={setPayMethod}
            onChangeNumber={onChangeNumber}
            newCard={newCard}
            cardType={cardType}
            error={error}
            saveCard={saveCard}
            onToggleSaveCard={onToggleSaveCard}
            onStart={onStart}
            onCancel={onCancel}
            onResume={onResume}
            loading={loading}
            onClose={null}
          />
        </DesktopRight>
      </Content>
    </Container>
  );
};

export const PayMethodSelect = styled.button`
  /* width: 100%;
  height: var(--subnav-height);*/
  margin: 20px 10px 10px 10px;
  ${text12300}
  ${centered}  
  svg {
    margin-right: 5px;
  }
  font-weight: ${(props) => (props.theme.selected ? 600 : 300)};
  border-bottom: 1px solid
    ${(props) => (props.theme.selected ? "black" : "transparent")};
`;

const PayMethodItem = styled.button<{ $active: boolean }>`
  height: 40px;
  box-sizing: border-box;
  width: auto;
  position: relative;
  box-sizing: border-box;
  padding: 0;
  color: var(--border-grey);
  border-bottom: 2px solid transparent;

  ${({ $active }) =>
    $active
      ? `
          color: black;
          border-bottom: 2px solid black;
        `
      : ""}
`;

const Payment = styled(CenteredByColumnLeft)`
  width: calc(100vw-40px);
  margin: 20px;
`;

const Status = styled.div`
  text-transform: capitalize;
  label {
    font-weight: 600;
    background-color: ${Grey};
    padding: 5px;
    margin-right: 10px;
  }
  ${text12300}
  span {
  }
  margin-bottom: 10px;
`;

const Billing = styled(Status)``;

const Card = styled(Status)`
  margin-bottom: 10px;
`;

const Button = styled(ActionBlack)`
  margin: 0px auto 30px auto;
  @media only screen and (min-width: ${breakPoint}px) {
    /* position: absolute;
    bottom: 80px;
    margin: 0px; */
    /* margin-top: 0px; */
    width: auto;
    box-shadow: none;
    /* left: calc(50% - 50px); */
  }
`;
