import { useState } from "react";
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { TrackAdsEvent } from "src/api/tracker";
import { getItemNumberInCart } from "src/constants";
import { LOGGING } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import { CartDetails } from "./CartDetails";
import { Counter } from "./Counter";
import { JustAdded } from "./JustAdded";
import { NextIcon } from "./NextIcon";
import {
  CenteredByColumn,
  Centered,
  text12300,
  centered,
  NavHeight,
  mobileNextAction,
  BorderGrey,
} from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";

export const ButtonTop = 20;
export const ButtonRight = "70px";
export const ButtonHeight = 40;
export const ButtonWidth = 150;

const AuthButtonContainer = styled(Centered)`
  position: relative;
`;
const ButtonContainer = styled(CenteredByColumn)`
  position: fixed;
  z-index: 102;
  top: ${ButtonTop}px;
  right: ${ButtonRight};
  align-items: flex-end;
  @media screen and (max-width: ${BreakPoint}px) {
    top: auto;
    bottom: 0px;
    background-color: transparent;
    width: 100vw;
    right: 0px;
    padding: 0px 10px 15px 10px;
  }
`;
export const NextActionContainer = styled(Centered)<{ opacity?: number }>`
  /* border: 1px solid orange; */
  max-width: 300px;
  @media screen and (max-width: ${BreakPoint}px) {
    position: fixed;
    width: 100vw;
    max-width: 100vw;
    padding: 10px;
    min-height: 80px;
    left: 0px;
    bottom: 0px;
    background: ${({ opacity }) => `rgba(255, 255, 255, ${opacity || 0})`};
    border-top: 1px solid
      ${({ opacity }) => (opacity ? BorderGrey : "transparent")};
    box-shadow: 0px -2px 4px ${({ opacity }) => `rgba(0, 0, 0, ${opacity ? 0.1 : 0})`};
    flex-direction: column;
    z-index: 100;
  }
`;
export const ToggleCartDetails = styled.button<{
  rotated?: boolean;
  hasGoods?: boolean;
  style?: {};
}>`
  ${centered}
  display: ${({ hasGoods }) => (hasGoods ? "flex" : "none")}!important;
  span {
    ${text12300}
    font-weight: 400;
    margin-right: 10px;
  }
  height: 100%;
  margin: 10px 0 0 20px;
  padding: 0px;
  svg:first-child {
    margin-right: 10px;
    font-size: 14px;
    margin-bottom: 2px;
  }
  /* svg:last-child {
    transition: transform 0.3s ease;
    transform: ${({ rotated }) => (rotated ? "rotate(90deg)" : "rotate(0deg)")};
  } */
  ${({ style }) => ({ ...style })}
  @media screen and (max-width: ${BreakPoint}px) {
    ${mobileNextAction}
    justify-content: space-between;
    svg {
      font-size: 20px !important;
    }
  }
`;
const Container = styled(Centered)``;

const GoToCheckout = styled(Link)<{ disabled?: boolean }>`
  ${centered}
  margin-left: 10px;
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 0px 20px;
  height: ${ButtonHeight}px;
  width: ${ButtonWidth}px;
  justify-content: flex-end;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  > span {
    display: inline-block;
    ${text12300}
    font-weight: 500;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    left: 0px;
    text-align: center;
  }
  svg {
    font-size: 20px;
  }
  @media screen and (max-width: ${BreakPoint}px) {
    display: none;
  }
`;
export const CheckoutThumbnail = ({
  showPeek,
  onHidePeek,
  goodsToPeek,
  cartCount,
  isMember,
  onEditGoodInCart,
  showCartOnMeal,
  menuLink,
  source,
  fbclid,
  isAuthenticated,
}) => {
  const [showDetails, setShowDetails] = useState(showCartOnMeal); // true for debug
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  // @ts-ignore
  const hasItem = getItemNumberInCart(cart) > 0 || false;
  const location = useLocation();
  const onToggleDetails = (e) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  return (
    <Container>
      <ButtonContainer>
        <AuthButtonContainer>
          {!isAuthenticated && (
            <GoToCheckout
              to={{
                pathname: "/auth",
                state: {
                  title: `To Order from \n${
                    hasItem ? cart?.meal?.restaurant?.name : menuLink
                  }`,
                  next: hasItem ? "/checkout" : location.pathname,
                  orderId: cart?._id || null,
                  restaurantId: cart?.meal?.restaurant?._id || null,
                  email: cart?.email || null,
                  phone: cart?.phone || null,
                },
              }}
              onClick={(e) => {
                // Insert Facebook Pixel tracking here
                // @ts-ignore
                if (window.fbq && fbclid) {
                  // @ts-ignore
                  window.fbq("track", "ViewContent", {
                    content_name: `${menuLink} to auth`,
                    content_category: "pageSingleMeal",
                    value: 0,
                    currency: "USD",
                  });
                  // Track Ads Event
                  TrackAdsEvent(fbclid, {
                    source: source,
                    eventCode: "ViewContent",
                    page: "pageSingleMeal",
                    contentName: `${menuLink} to auth`,
                    orderId: cart?._id || null,
                  });
                }
              }}
            >
              {/* <GoToCheckout to="/checkout" disabled={getItemNumberInCart(cart)===0}> */}
              <span>sign in & checkout</span>
              {/* <FontAwesomeIcon icon={faChevronRight} /> */}
              {/* <NextIcon /> */}
            </GoToCheckout>
          )}
          <GoToCheckout
            to="/checkout"
            disabled={!hasItem}
            onClick={(e) => {
              // Insert Facebook Pixel tracking here
              // @ts-ignore
              if (window.fbq && fbclid) {
                // @ts-ignore
                window.fbq("track", "InitiateCheckout", {
                  content_name: `${menuLink} to checkout`,
                  content_category: "pageSingleMeal",
                  value: 0,
                  currency: "USD",
                });
                // Track Ads Event
                TrackAdsEvent(fbclid, {
                  source: source,
                  eventCode: "InitiateCheckout",
                  page: "pageSingleMeal",
                  contentName: `${menuLink} to checkout`,
                  orderId: cart?._id,
                });
              }
            }}
          >
            {/* <GoToCheckout to="/checkout" disabled={getItemNumberInCart(cart)===0}> */}
            <span>{isAuthenticated ? "checkout" : "guest checkout"}</span>
            {/* <FontAwesomeIcon icon={faChevronRight} /> */}
            {/* <NextIcon /> */}
          </GoToCheckout>
        </AuthButtonContainer>
        <NextActionContainer>
          <ToggleCartDetails
            onClick={onToggleDetails}
            rotated={showDetails}
            hasGoods={cartCount > 0}
          >
            <FontAwesomeIcon icon={faShoppingCart} />
            <span>view cart</span>
            <Counter number={cartCount} />
            {/* <FontAwesomeIcon icon={faChevronUp} /> */}
          </ToggleCartDetails>
        </NextActionContainer>
      </ButtonContainer>
      <CartDetails
        show={showDetails}
        isMember={isMember}
        onClose={(e) => {
          e.preventDefault();
          setShowDetails(false);
        }}
        onEditGoodInCart={onEditGoodInCart}
        isAuthenticated={isAuthenticated}
      />
      <JustAdded
        show={showPeek && !showDetails}
        onHide={onHidePeek}
        goodsToPeek={goodsToPeek}
      />
    </Container>
  );
};
