import React, { ReactElement, useCallback, useState } from "react";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faHouse,
  faTruck,
  faChevronRight,
  faChevronLeft,
  faInfoCircle,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useAsync } from "react-async";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { GetReferrerFromCode } from "src/api/referrals";
import { CheckoutBanner } from "src/screens/tasting_menu/components";
import { RootState } from "src/store";
import { addGoodsToCart } from "src/store/actions";
import styled, { keyframes } from "styled-components";
import { breakPoint } from "./MyReferralCodeEventMember";
import { LOGGING, PopUp, IconImages, SupportedSanJoseZipCodes } from "..";
import templateImage from "../../../../assets/images/gift_expire_template.png";
import { DishChooserContainer } from "../dish/DishChooserContainer";
import {
  CenteredByColumn,
  Text12300,
  CenteredByColumnLeft,
  BorderGrey,
  Centered,
  Yellow,
  Beige,
  Grey,
  Pink,
  text12300,
  card,
  centeredByColumn,
  NavHeight,
  textTitle,
  SpringYellow,
} from "../Shared";
import { RibbonYellow } from "../Shared";

export interface ReferralAwardProps {
  promo: any;
  socialInviteCode: string;
  dishes: any[];
  promoMeals: any[];
  restaurant: string;
}
const Container = styled(CenteredByColumn)`
  @media only screen and (min-width: 1200px) {
    justify-content: flex-start;
  }
`;
const topHeight = 90;
const Amount = styled.span`
  /* background-color: ${Beige}; */
  /* padding: 30px 40px; */
  /* align-self: stretch;
 ${text12300}
 line-height: 24px;
 text-align: center; */
  /* margin-bottom: 10px; */
  label {
    /* font-size: 40px; */
    font-weight: 600;
    font-size: 45px;
    letter-spacing: 2px;
    /* line-height: 35px; */
    /* margin: 0 6px; */
    /* line-height: 1; */
  }
`;
const SubFooter = styled.span`
  /* margin: 5px 0px; */
  color: #f9f6e2;
  ${text12300}
  font-weight: 600;
  font-size: 14px;
  line-height: 1.8;
  z-index: 1;
  text-transform: none;
  text-transform: capitalize;
  position: absolute;
  bottom: 20px;
  right: 30px;
  z-index: 1;
  @media screen and (max-width: 370px) {
    font-size: 11px;
    right: 28px;
  }
`;
const Footer = styled(CenteredByColumn)`
  margin-top: 10px;
  svg {
    font-size: 5px;
    margin: 0px 5px;
  }
  /* ${text12300}
    font-size: 14px;    
    line-height: 1.1;    
    text-transform: uppercase;     */
`;
const CreditContentContainer = styled.div`
  width: calc(100vw - 30px);
  max-width: 400px;
  height: calc(63.333vw - 19px);
  max-height: 253.32px;
  margin: 15px 0px;
  padding-bottom: 10px;
  background-image: url(${templateImage});
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 15px; /* Ensures rounding */
  overflow: hidden; /* Clips background image */
  background-clip: padding-box; /* Ensures background respects rounding */
  @media only screen and (min-width: 500px) {
    margin-bottom: 20px;
    /* width: 360px; */
  }
  @media only screen and (min-width: 1200px) {
    width: 360px;
  }
`;
const Icon = styled(Centered)`
  position: absolute;
  font-size: 60px;
  left: -5px;
  bottom: -20px;
  transform: rotate(-15deg);
`;
const Border = styled(CenteredByColumn)`
  border: 2px solid ${RibbonYellow};
  /* height: ${NavHeight}px;   */

  /* background-color: ${Beige}; */

  /* padding-bottom: ; */
  padding: 20px;
  align-self: stretch;
  flex-grow: 1;
  h1 {
    width: 100%;
    text-align: center;
    ${textTitle}
    /* ${text12300} */
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    /* border-bottom: 1px solid black; */
    /* line-height: 28px; */
    /* font-weight: 500; */
    /* margin-top: 5px; */
    z-index: 1;
    /* text-transform: uppercase; */
    /* text-transform: none; */
    /* background-color: ${Beige}; */
    margin: 0px;
    margin-bottom: 12px;
  }
  /* h1 {
    ${text12300}
    font-size: 14px;    
    line-height: 1.1;    
    text-transform: uppercase;    
  } */
`;

export interface NewUserCreditProps {}
export const NewUserCredit: React.FC<NewUserCreditProps> =
  ({}: NewUserCreditProps): ReactElement => {
    const currentUser = useSelector((state: RootState) => state.currentUser);
    const { tokens, firstName, credit, creditSpent, membership } =
      currentUser?.user;
    if (
      membership?.status === "trialing" &&
      !membership?.subscriptionId &&
      membership?.canceled_at > moment().unix()
    ) {
      return (
        <Container>
          <CreditContentContainer>
            <Border>
              <h1>welcome benefit</h1>
              <Amount>
                <label>membership</label>
              </Amount>
              <Footer>
                Expires{" "}
                <b>
                  {moment(membership.canceled_at * 1000).format(
                    " dddd, MM.DD.YYYY"
                  )}
                </b>
              </Footer>
            </Border>
          </CreditContentContainer>
        </Container>
      );
    }

    if (!tokens || tokens.length === 0) {
      return null;
    }
    const availableTokens = tokens
      .filter((t) => t.expiredAt > moment().valueOf())
      .sort((a, b) => a.expiredAt < b.expiredAt);

    if (availableTokens.length === 0) {
      return null;
    }

    const { dollarAmount, tokenSpent, expiredAt, name } = availableTokens[0];

    if (dollarAmount === 0 || dollarAmount <= (tokenSpent || 0)) {
      return null;
    }

    return (
      <Container>
        <CreditContentContainer>
          <SubFooter>
            Expires <b>{moment(expiredAt).format("MMM D, YYYY")}</b>
          </SubFooter>
        </CreditContentContainer>
      </Container>
    );
  };
