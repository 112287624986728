import React, { Component } from "react";
// import { map } from 'lodash';
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CancelOrderSelectionItem } from "src/api/orders";
import { LOGGING, Loading, MealOrderList } from ".";
import {
  markOrdersAsRestaurantConfirmed,
  orderCancelItem,
  readCurrentMealOrders,
} from "../../store/actions";

class PageRestaurantMealNoSignIn extends Component {
  constructor() {
    super();
    LOGGING && console.log("PageRestaurantMealNoSignIn constructor");
    this.state = {
      loading: true,
      showDetails: 0, //0 - unprocessed, 1 - processed, 2 - summary
      meal: null,
    };
    this.handleShowDetails = this.handleShowDetails.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.handleCancelItem = this.handleCancelItem.bind(this);
    this.handleCancelSelectionItem = this.handleCancelSelectionItem.bind(this);
    this.orderRefs = {};
  }

  handleCancelItem(orderId, index, dishId, cancelQuantity) {
    const { currentUser } = this.props;
    const isAdmin = currentUser?.user?.isAdmin || currentUser?.user?.isOps;
    if (!isAdmin) {
      toast.error("Please contact Foodie Admin at 408-520-0627");
      return;
    }
    this.setState({ loading: true }, () => {
      this.props
        .orderCancelItem(orderId, index, dishId, cancelQuantity)
        .then(() => {
          toast.success("cancel item success");
          this.readMealOrders();
          this.setState({ loading: false });
        });
    });
  }

  handleCancelSelectionItem(orderId, goodId, goodSelectionId, selectedItemId) {
    const { currentUser } = this.props;
    const isAdmin = currentUser?.user?.isAdmin || currentUser?.user?.isOps;
    if (!isAdmin) {
      toast.error("Please contact Foodie Admin at 408-520-0627");
      return;
    }
    this.setState({ loading: true }, () => {
      CancelOrderSelectionItem(
        orderId,
        goodId,
        goodSelectionId,
        selectedItemId,
        1,
        this.props.currentUser
      ).then(() => {
        toast.success("cancel selection success");
        this.readMealOrders();
        this.setState({ loading: false });
      });
    });
  }

  handleOrder() {
    const { orders } = this.props;
    const orderIds = orders
      .filter((o) => !o.restaurantConfirmTime)
      .map((o) => o._id);
    LOGGING && console.log("handleorder called with orderIds", orderIds);
    this.props.markOrdersAsRestaurantConfirmed(orderIds);
  }

  handleShowDetails(showDetails, e) {
    e.preventDefault();
    this.setState({ showDetails });
  }

  componentDidMount() {
    LOGGING && console.log("PageRestaurantMealNoSignIn componentdidmount");
    const { restaurantId } = this.props.match.params;
    this.props.currentUser.isAuthenticated = true;
    this.props.currentUser.user["isRestaurant"] = true;
    this.props.currentUser.user["restaurant"] = { _id: restaurantId };
    this.readMealOrders();
  }

  readMealOrders() {
    this.setState({ loading: true }, () => {
      this.props.readCurrentMealOrders().then((meal) => {
        const { orders } = this.props;
        LOGGING &&
          console.log("PageRestaurantMealNoSignIn componentDidmount got:", {
            meal,
            orders,
          });
        orders.forEach((o) => {
          if (o.restaurantConfirmTime) {
            this.orderRefs[o._id] = React.createRef();
          }
        });
        this.setState({ meal, loading: false });
      });
    });
  }

  render() {
    const { orders, currentUser } = this.props;
    const { loading, showDetails, meal } = this.state;
    LOGGING &&
      console.log("PageRestaurantMealNoSignIn rendering with", {
        props: this.props,
        state: this.state,
      });

    return (
      <div className="page">
        {loading ? (
          <Loading />
        ) : (
          <MealOrderList
            meal={meal}
            orders={orders.map((o, index) => ({ ...o, index }))}
            showDetails={showDetails}
            onShowDetails={this.handleShowDetails}
            onOrder={this.handleOrder}
            onCancelItem={this.handleCancelItem}
            onCancelSelectionItem={this.handleCancelSelectionItem}
            onPrintOrder={this.handlePrintOrder}
            isAdmin={currentUser?.user?.isAdmin || currentUser?.user?.isOps}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageRestaurantMealNoSignIn got redux state:", state);
  return {
    orders: state.orders,
    currentUser: state.currentUser,
    restaurantId: state.restaurantId,
  };
}

export default connect(mapStateToProps, {
  readCurrentMealOrders,
  markOrdersAsRestaurantConfirmed,
  orderCancelItem,
})(PageRestaurantMealNoSignIn);
