import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  CenteredByColumn,
  Text12300,
  TextTitle,
  TextUPPER,
} from "../tasting_menu/components/Shared";

interface RestaurantsSlideShowProps {
  restaurants: { name: string; city: string; image: string }[];
  width: string;
  height: string;
}

const Container = styled.div<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
  position: relative;
`;

const Slide = styled.div<{ src: string; visible: boolean }>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent dark overlay */
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled(CenteredByColumn)<{ visible: boolean }>`
  position: absolute;
  color: white;
  text-align: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  z-index: 2;
`;

const RestaurantName = styled(TextUPPER)`
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 32px;
`;
const RestaurantCity = styled(TextTitle)`
  font-size: 20px;
  font-weight: 500;
  position: relative;
  padding-left: 15px;
  label {
    position: absolute;
    left: -6px;
    text-align: right;
    font-size: 16px;
    top: 3px;
    /* border-bottom: 1px solid white; */
  }
  /* border-bottom: 1px solid white; */
`;

const RestaurantsSlideShow = ({
  restaurants,
  width,
  height,
}: RestaurantsSlideShowProps) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % restaurants.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [restaurants.length]);

  return (
    <Container width={width} height={height}>
      {restaurants.map((restaurant, i) => (
        <Slide key={i} src={restaurant.image} visible={i === index} />
      ))}
      <Overlay>
        {restaurants.map((restaurant, i) => (
          <TextContainer key={i} visible={i === index}>
            <RestaurantName>{restaurant.name}</RestaurantName>
            <RestaurantCity>
              <label>📍</label>
              {restaurant.city}
            </RestaurantCity>
          </TextContainer>
        ))}
      </Overlay>
    </Container>
  );
};

export default RestaurantsSlideShow;
