import React from "react";
import "./Card.scss";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronDown,
  faChevronUp,
  faCreditCard,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";
import { CreditCardIcons } from ".";

// NewCardForm is a form-subsection
const NewCardForm = ({ error, onChangeNumber, cardType, newCard }) => (
  <div className="form-subsection card-form">
    {error && !error.param && error.message ? (
      <div className="error-overall">{error.message}</div>
    ) : null}
    <div className="form-row">
      <div
        className={`form-field-wrapper ${
          error && error.param === "number" ? "wrong" : ""
        }`}
      >
        <NumberFormat
          format={cardType ? cardType.format : "####-####-####-####"}
          mask="X"
          type="text"
          placeholder="1234-1234-1234-1234"
          onValueChange={onChangeNumber.bind(this, "newCard", "number")}
          value={newCard?.number}
          name="number"
        />
        {cardType && cardType.type && CreditCardIcons[cardType.type] ? (
          <FontAwesomeIcon
            className="card-icon"
            icon={CreditCardIcons[cardType.type]}
          />
        ) : null}
        {error.param === "number" ? (
          <div className="error-message">
            {error && error.message ? error.message : ""}
          </div>
        ) : null}
      </div>
    </div>
    <div className="form-row">
      <div
        className={`form-field-wrapper ${
          error && error.param === "cvc" ? "wrong" : ""
        }`}
      >
        <NumberFormat
          format={
            cardType && cardType.code && cardType.code.format
              ? cardType.code.format
              : ""
          }
          mask="X"
          type="text"
          placeholder={`${
            cardType && cardType.code ? cardType.code.name : "CVV"
          }`}
          onValueChange={onChangeNumber.bind(this, "newCard", "cvc")}
          value={newCard?.cvc}
          name="cvc"
        />
        {error.param === "cvc" ? (
          <div className="error-message">
            {error && error.message ? error.message : ""}
          </div>
        ) : null}
      </div>
    </div>
    <div className="form-row">
      <div
        className={`form-field-wrapper half ${
          error && error.param === "exp_month" ? "wrong" : ""
        }`}
      >
        <NumberFormat
          format="##"
          mask="X"
          type="text"
          placeholder="MM"
          onValueChange={onChangeNumber.bind(this, "newCard", "exp_month")}
          value={newCard.exp_month}
          name="exp_month"
          className={`card-info ${
            error && error.param === "exp_month" ? "wrong" : ""
          }`}
        />
        {error.param === "exp_month" ? (
          <div className="error-message">
            {error && error.message ? error.message : ""}
          </div>
        ) : null}
      </div>
      <div
        className={`form-field-wrapper half ${
          error && error.param === "exp_year" ? "wrong" : ""
        }`}
      >
        <NumberFormat
          format="####"
          mask="X"
          type="text"
          placeholder="YYYY"
          onValueChange={onChangeNumber.bind(this, "newCard", "exp_year")}
          value={newCard.exp_year}
          name="exp_year"
        />
        {error.param === "exp_year" ? (
          <div className="error-message">
            {error && error.message ? error.message : ""}
          </div>
        ) : null}
      </div>
    </div>
  </div>
);
// ExistingCard is a form-row
const ExistingCard = ({
  onToggleShowCardAction,
  showCardAction,
  onDeleteCard,
  existingCard,
}) => (
  <div className="form-row existing-card">
    <button className="card-wrapper" onClick={onToggleShowCardAction}>
      <FontAwesomeIcon
        className="brand-icon"
        icon={CreditCardIcons[existingCard.brand] || faCreditCard}
      />
      <span className="brand-name">{existingCard.brand}</span>
      <span className="last4">{` ending in ${existingCard.last4}`}</span>
      <FontAwesomeIcon
        className="show-card-action"
        icon={showCardAction ? faChevronUp : faChevronDown}
      />
    </button>
    {showCardAction ? (
      <button className="delete-card" onClick={onDeleteCard}>
        <FontAwesomeIcon icon={faTrashAlt} />
        <span>delete</span>
      </button>
    ) : null}
  </div>
);

export { NewCardForm, ExistingCard };
