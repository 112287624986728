import { faCertificate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import foodieLogo from "../../../../../assets/images/logo_light.png";
import michelin from "../../../../../assets/images/michelin.png";
import yelpLogo from "../../../../../assets/images/yelplogo.png";
import {
  Blue,
  Centered,
  CenteredByColumn,
  text12300,
  awardLevelColor,
  awardLevelText,
  centeredByColumn,
} from "../../Shared";
const width = 50;
const Container = styled(Centered)`
  position: relative;
  /* transform: rotate(-10deg); */
  /* border: 1px solid black; */
  width: ${width}px;
  height: ${width}px;
  z-index: 1;
  svg {
    font-size: ${width}px;
    position: absolute;
    /* opacity: 0.95; */
  }
  span {
    ${text12300};
    color: black;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    z-index: 1;
    text-align: center;
    line-height: 1.2;
  }
`;

const MichelinImage = styled.img`
  width: ${width}px;
  height: ${width}px;
  object-fit: contain;
  position: relative;
  /* margin-right: 2px; */
  z-index: 1;
`;

const MichelinText = styled.span`
  font-family: "Montserrat", sans-serif;
  font-size: 6px;
  text-transform: uppercase;
  font-weight: 500;
  color: white !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 1.2;
`;
const NewRestaurantLogo = () => {
  return (
    <Container>
      <FontAwesomeIcon icon={faCertificate} color={Blue} />
      <span>new!</span>
    </Container>
  );
};

export const MichelinLogo = () => {
  return (
    <MichelinImage
      src={michelin}
      alt="michelin"
      style={{ width: width, height: width }}
    />
  );
};

const YelpImage = styled.img`
  /* position: absolute; */
  width: ${width - 5}px;
  height: auto;
  object-fit: contain;
`;

const YelpContainer = styled(CenteredByColumn)`
  background-color: #d12324;
  width: ${width}px;
  height: ${width}px;
  border-radius: 10px;
  span {
    color: white;
    font-family: "Oswald", sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
  }
`;
export const Yelp100Logo = () => {
  return (
    <YelpContainer>
      {/* <FontAwesomeIcon icon={faCertificate} color={"#d12324"} /> */}
      <YelpImage src={yelpLogo} alt="yelp" />
      <span>100</span>
    </YelpContainer>
  );
};

const FoodieAwardContainer = styled(Link)<{ level: number }>`
  ${centeredByColumn}
  width: ${width}px;
  height: ${width}px;
  background-color: ${(props) => awardLevelColor[props.level]};
  border-radius: 50%;
  /* color: ${(props) =>
    props.level === 1 ? "white" : props.level === 2 ? "black" : "black"}; */
  span {
    ${text12300}
    font-weight: 600;
    font-size: ${(props) => (props.level === 3 ? "11px" : "9px")};
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    line-height: 1;
  }
  label {
    ${text12300}
    color: white;
    font-size: 9px;
    //italic
    /* font-style: italic; */
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: 3px;
    /* position: absolute;
    top: 5px; */
  }
  padding-bottom: 2px;
`;

export const FoodeiAward = ({ level }) => {
  return (
    <FoodieAwardContainer level={level} to="/guide2025">
      <label>foodie's</label>
      <span>{awardLevelText[level]}</span>
    </FoodieAwardContainer>
  );
};
export default NewRestaurantLogo;
