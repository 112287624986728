import React from "react";
import styled from "styled-components";
import { Centered, NavHeight, text12300 } from "../Shared";
import { BorderGrey } from "../Shared";

interface RestaurantCountWithProgressProps {
  numberHasTried: number;
  total: number; // Adjust the type based on your actual data structure
  firstName: string;
}

const Container = styled(Centered)`
  /* padding: 16px; */
`;

const Text = styled.span`
  ${text12300}
  font-weight: 400;
  margin-right: 8px;
  label {
    font-weight: 500;
  }
`;

const ProgressBarContainer = styled.div`
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 50px;
  overflow: hidden;
  width: 60px;
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  background-color: green;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease-in-out;
  /* border-radius: 4px; */
`;

const RestaurantCountWithProgress: React.FC<RestaurantCountWithProgressProps> =
  ({ numberHasTried, total, firstName }) => {
    const progress = total > 0 ? (numberHasTried / total) * 100 : 0;

    return (
      <Container>
        <Text>
          Tried{" "}
          <label>
            {numberHasTried}/{total}
          </label>
        </Text>
        <ProgressBarContainer>
          <ProgressBar
            progress={progress}
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </ProgressBarContainer>
      </Container>
    );
  };

export default RestaurantCountWithProgress;
