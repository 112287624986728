import React from "react";
import { MealVisited } from "src/api/meals";
import { TrackAdsEvent } from "src/api/tracker";
import { LOGGING } from "src/constants";
import styled from "styled-components";
import { BackgroundBlue, Beige, BorderGrey, centered } from "../../Shared";

const AppStoreLink = ({
  customStyle,
  source,
  fbclid,
}: {
  customStyle?: any;
  source?: any;
  fbclid?: any;
}) => {
  const appStoreUrl = "https://apps.apple.com/app/FoodieEarth/6738896239"; // Replace with your actual App Store URL

  return (
    <AppleLink
      href={appStoreUrl}
      target="_blank"
      rel="noopener noreferrer"
      customPadding={customStyle?.padding}
      onClick={() => {
        // Insert Facebook Pixel tracking here
        // @ts-ignore
        if (window.fbq && fbclid) {
          LOGGING && console.log("app store link click is tracked by fbq");
          // @ts-ignore
          window.fbq("track", "ViewContent", {
            content_name: `appStoreLink`,
            content_category: "pageGuest",
            value: 0,
            currency: "USD",
          });

          // Track Ads Event
          TrackAdsEvent(fbclid, {
            source,
            eventCode: "ViewContent",
            page: "pageGuest",
            contentName: "appStoreLink",
          });

          MealVisited("app store link", "guest", null, null, 0, fbclid, source);
        }
      }}
    >
      <img
        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
        alt="Download on the App Store"

        // style={{ ...styles.image, ...customStyle }}
      />
    </AppleLink>
  );
};

const AppleLink = styled.a<{ customPadding?: string }>`
  ${centered}
  text-decoration: none;
  padding: ${(props) => props.customPadding || "10px 0px"};
  box-sizing: "border-box";
  image: {
    height: 60px;
  }

  @media screen and (max-width: 350px) {
    image {
      height: 40px;
    }
  }
`;

export default AppStoreLink;
