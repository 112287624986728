import bobaStamp from "../../../../../assets/images/boba.png";
import generalStamp from "../../../../../assets/images/foodie.png";
import cantoneseStamp from "../../../../../assets/images/hongkong.png";
import japaneseStamp from "../../../../../assets/images/japanese.png";
import koreanStamp from "../../../../../assets/images/korean.png";
import ramenStamp from "../../../../../assets/images/ramen.png";
import taiwaneseStamp from "../../../../../assets/images/taiwanese.png";
import vietnameseStamp from "../../../../../assets/images/viet.png";

export const hironori = {
  name: "HiroNori Craft Ramen",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/T1uCzsi7p0VJhTo0EPcm_A/o.jpg",
};
export const sumiya = {
  name: "Sumiya Yakitori",
  city: "Santa Clara",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/fR1WrUAob_pmXH8wp3MziA/o.jpg",
};
export const gochi = {
  name: "Gochi Japanese Tapas",
  city: "Mountain View",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/VNGHuTk2IW3dwrWYJuipjQ/o.jpg",
};
export const leichi = {
  name: "Leichi",
  city: "Santa Clara",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/R1lkuO13BsTpuxyedrtInA/o.jpg",
};
export const tomi = {
  name: "Tomi Sushi",
  city: "San Jose",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/lgvSqd7lhY2s34Gak1zaOg/o.jpg",
};
export const demiya = {
  name: "Demiya",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/0LOewPKzyOu6artdIaMBMA/o.jpg",
};
export const muzigo = {
  name: "Udon Muzigo",
  city: "Mountain View",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/otu-JEfeOITD5465-xflvQ/o.jpg",
};
export const rantei = {
  name: "Rantei Japanese Cuisine",
  city: "Santa Clara",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/BFHvTgkpu8MwZKvqBTo_uw/o.jpg",
};

export const dishdash = {
  name: "DishDash",
  city: "Sunnyvale",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/cgb9P5S4OpRpg8siZo-IVQ/o.jpg",
  yelpRating: 4.4,
  YelpReviews: 4724,
};

export const dtf = {
  name: "Din Tai Fung",
  city: "Santa Clara",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/mFzF52RuiqUQThGTtIWYyA/o.jpg",
  yelpRating: 3.7,
  YelpReviews: 5392,
};
export const zareen = {
  name: "Zareen's",
  city: "Palo Alto",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/6nByLxaJs02bPbJJyT0DFA/o.jpg",
  yelpRating: 4.4,
  YelpReviews: 2535,
};
export const terun = {
  name: "Terun Pizza",
  city: "Palo Alto",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/JYHldFvMOIv46G0kOv5qRg/o.jpg",
  yelpRating: 4.1,
  YelpReviews: 2191,
};

export const cajun = {
  name: "Cajun Crack'n",
  city: "Milpitas",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/3tSQJobgnBkwtbcS0Qo9mA/o.jpg",
  yelpRating: 4.5,
  YelpReviews: 1166,
};

export const mustBeThai = {
  name: "Must Be Thai",
  city: "San Jose",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/VI1IFEsggWxanXMk48XC9w/o.jpg",
  yelpRating: 4.0,
  YelpReviews: 2234,
};

export const good = {
  name: "The Good Salad",
  city: "San Jose",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/UXdJDDVxPcRdn0cRz7ihVQ/o.jpg",
  yelpRating: 4.9,
  YelpReviews: 507,
};

export const taiwanCafe = {
  name: "Taiwan Cafe",
  city: "Milpitas",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/65xiAQTGRnldyu17QDaNHw/o.jpg",
  yelpRating: 3.8,
  YelpReviews: 446,
};

export const porridge = {
  name: "Taiwan Porridge",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/uI3I3yC-i8owO1eIp-x5uQ/o.jpg",
  yelpRating: 4.0,
  YelpReviews: 498,
};

export const o2 = {
  name: "O2 Valley",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/uYpk6NuTlcNpNXzumi7ZXQ/o.jpg",
  yelpRating: 4.4,
  YelpReviews: 562,
};

export const shang = {
  name: "Shang Yu Lin",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/noksvOhAV1-L_cdrQ78seg/o.jpg",
  yelpRating: 4.2,
  YelpReviews: 104,
};
const heyTea = {
  name: "HeyTea",
  city: "Sunnyvale",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/UJm3haBDqtflLAUa2jHpVA/o.jpg",
  yelpRating: 4.4,
  YelpReviews: 69,
};

export const tasty = {
  name: "Tasty Pot",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/QlS8f0mMxontNtoXrsOyXg/o.jpg",
  yelpRating: 3.9,
  YelpReviews: 538,
};

export const hongkong = {
  name: "Hong Kong Restaurant",
  city: "Palo Alto",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/I9d2MDNtpI2lTc-Ss4baog/o.jpg",
  yelpRating: 3.6,
  YelpReviews: 415,
};

const koi = {
  name: "Koi Palace",
  city: "Milpitas",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/KLrtV5aNbLiap8G_kOGP-w/o.jpg",
  yelpRating: 3.2,
  YelpReviews: 1690,
};

const keewah = {
  name: "Kee Wah Bakery",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/hiaGA6VaPSEoTFiC8EmYRA/o.jpg",
  yelpRating: 4.1,
  YelpReviews: 370,
};

const hl = {
  name: "HL Peninsula Restaurant",
  city: "Milpitas",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/GKX69bD9X-IyV4l2ZlimPQ/o.jpg",
  yelpRating: 3.2,
  YelpReviews: 527,
};

const sifu = {
  name: "Sifu Wong Kitchen",
  city: "Sunnyvale",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/Gt9lVRBMvQSoRb0QFMiH3w/o.jpg",
  yelpRating: 3.9,
  YelpReviews: 995,
};

const huilaushan = {
  name: "Hui Lau Shan",
  city: "Cupertino",
  image:
    "https://i.pinimg.com/1200x/06/62/1b/06621b15b296028addba223406c689dd.jpg",
  yelpRating: 4.3,
  YelpReviews: 782,
};

const sunRight = {
  name: "Sunright Tea Studio",
  city: "Sunnyvale",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/tZV2sN3pxj1FsdUedSaE3w/o.jpg",
  yelpRating: 4.3,
  YelpReviews: 765,
};

const tp = {
  name: "TP Tea",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/p7kGqNWwemAc0Xb1W5_neg/o.jpg",
  yelpRating: 4.1,
  YelpReviews: 1106,
};

const ume = {
  name: "Ume Tea",
  city: "Palo Alto",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/WULgfPswUiUbCNTGmhl5yw/o.jpg",
  yelpRating: 4.2,
  YelpReviews: 863,
};

const ippudo = {
  name: "Ippudo",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/wztra17lRqUlCypAOmpBBg/o.jpg",
  yelpRating: 3.8,
  YelpReviews: 797,
};

const moon = {
  name: "Moon Tea",
  city: "Sunnyvale",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/Ul7PguwrVCP9dQph8bjdMQ/o.jpg",
  yelpRating: 4.1,
  YelpReviews: 183,
};
const hajime = {
  name: "Ramen Hajime",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/ua3BCJGXKJfFKEoAlzyaLw/o.jpg",
  yelpRating: 4.6,
  YelpReviews: 545,
};

const kowa = {
  name: "Ramen Kowa",
  city: "Palo Alto",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/L06ByR5afZt1dpdciST6TQ/o.jpg",
  yelpRating: 4.2,
  YelpReviews: 253,
};

const kunjip = {
  name: "Kunjip",
  city: "Santa Clara",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/M-lLXIOep8sn_8_ojBUqFw/o.jpg",
  yelpRating: 4.0,
  YelpReviews: 2239,
};

const paik = {
  name: "Paik's Noodle",
  city: "Santa Clara",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/ZqRkbE5sdsOOUlYb0Ua4eg/o.jpg",
  yelpRating: 4.3,
  YelpReviews: 1658,
};

const daeho = {
  name: "Daeho Kalbijjim & Beef Soup",
  city: "Milpitas",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/cWRSqtqyQMumN9TDA2Z1Fg/o.jpg",
  yelpRating: 4.4,
  YelpReviews: 1377,
};
const bbq = {
  name: "Bb.q Chicken",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/MvCc8JJGLwbORft45YkHAw/o.jpg",
  yelpRating: 4.2,
  YelpReviews: 238,
};

const bimbimbowl = {
  name: "Bibimbowl",
  city: "Sunnyvale",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/fZTrwBHb1dABCNFDeozTng/o.jpg",
  yelpRating: 4.3,
  YelpReviews: 108,
};

const rollHouse = {
  name: "Roll House",
  city: "Santa Clara",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/7uP2tJ5blEVAOF3L7czAqw/o.jpg",
};
const tenButchers = {
  name: "10 Butchers Korean BBQ",
  city: "Sunnyvale",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/MpVCAG-0aCz8gmX0m0c47Q/o.jpg",
  yelpRating: 4.3,
  YelpReviews: 1865,
};

const sgd = {
  name: "SGD Tofu House",
  city: "Santa Clara",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/KNtNieXVL23nbbYBRL783Q/o.jpg",
  yelpRating: 4.0,
  YelpReviews: 1466,
};

const phoHanoi = {
  name: "Pho Ha Noi",
  city: "Cupertino",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/Z1graYLONUKU-21f0tGbuQ/o.jpg",
};

const phoLovers = {
  name: "Pho Lovers",
  city: "Sunnyvale",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/cLfa34OYSVlvCtUo23nQDA/o.jpg",
};

const tayho = {
  name: "Banh Cuon Tay Ho",
  city: "Santa Clara",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/Z2H5FHaxnoseirh2eSyJaQ/o.jpg",
};

const phoToChau = {
  name: "Pho To Chau",
  city: "Mountain View",
  image: "https://s3-media0.fl.yelpcdn.com/bphoto/krO9ekRqdZ0rG_plPMaCFw/o.jpg",
};

export const cuisineInLanguage = {
  chinese: {
    chinese: "中餐",
    japanese: "中華料理",
    korean: "중식",
    vietnamese: "Trung Quốc",
  },
  taiwanese: {
    chinese: "臺灣料理店",
    japanese: "台湾料理",
    korean: "대만 요리",
    vietnamese: "Đài Loan",
  },
  japanese: {
    chinese: "壽司店",
    japanese: "日本料理",
    korean: "일식",
    vietnamese: "Nhật Bản",
  },
  cantonese: {
    chinese: "港式茶餐廳",
    japanese: "広東料理",
    korean: "광동 요리",
    vietnamese: "Quảng Đông",
  },
  korean: {
    chinese: "韓國烤肉店",
    japanese: "韓国料理",
    korean: "한식",
    vietnamese: "Hàn Quốc",
  },
  vietnamese: {
    chinese: "越南河粉店",
    japanese: "ベトナム料理",
    korean: "베트남 요리",
    vietnamese: "Việt Nam",
  },
};

export const taiwaneseEssay = (cuisines: string[]) => {
  let name = "";
  for (let i = 0; i < cuisines?.length - 1; i++) {
    name += cuisineInLanguage?.[cuisines[i]]?.chinese;
    name += "、";
  }
  name += cuisineInLanguage?.[cuisines[cuisines.length - 1]]?.chinese;
  return {
    welcome: "矽谷必備",
    p1: "美食不該遙不可及。",
    p2: `我們將最受追捧的${name}與各大高評價名店送到你家—新鮮直達，最遠可達20英里。`,
    p3: "探索我們精選的口袋清單與隱藏美食地圖，提前預訂，享受定時配送。",
  };
};
export const japaneseEssay = (cuisines: string[]) => {
  let name = "";
  for (let i = 0; i < cuisines?.length - 1; i++) {
    name += cuisineInLanguage?.[cuisines[i]]?.japanese;
    name += "、";
  }
  name += cuisineInLanguage?.[cuisines[cuisines.length - 1]]?.japanese;
  return {
    welcome: "シリコンバレーの必需品",
    p1: "美食は手の届かないものではありません。",
    p2: `Foodieは、最も人気のある${name}レストランや高評価の名店をあなたの元へ—できたての料理を最長20マイル先までお届けします。`,
    p3: "厳選された名店や隠れた名店を探索し、事前注文でスケジュール配送をお楽しみください。",
  };
};
export const cantoneseEssay = (cuisines: string[]) => {
  let name = "";
  for (let i = 0; i < cuisines?.length - 1; i++) {
    name += cuisineInLanguage?.[cuisines[i]]?.cantonese;
    name += "、";
  }
  name += cuisineInLanguage?.[cuisines[cuisines.length - 1]]?.cantonese;
  return {
    welcome: "矽谷必備",
    p1: "好餐廳唔應該遙不可及。",
    p2: `Foodie將最受歡迎嘅${name}餐廳同高評價名店送到你屋企—新鮮直送, 最遠可達20英里。`,
    p3: "發掘我哋精選嘅人氣食肆同隱世美食，提前預訂，享受準時送餐服務。",
  };
};
// 좋은 레스토랑은 멀리 있지 않아야 합니다. great restaurants should not be out of reach.
export const koreanEssay = (cuisines: string[]) => {
  let name = "";
  for (let i = 0; i < cuisines?.length - 1; i++) {
    name += cuisineInLanguage?.[cuisines[i]]?.korean;
    name += "、";
  }
  name += cuisineInLanguage?.[cuisines[cuisines.length - 1]]?.korean;
  return {
    welcome: "실리콘 밸리의 필수 서비스", ///silicon valley essential
    p1: "좋은 레스토랑은 멀리 있지 않아야 합니다.", // great restaurants should not be out of reach.
    // p1: "최고의 한식당을 찾아 추천해 드려요.", ////we recommend the best korean restaurants
    p2: `Foodie가 가장 사랑받는 ${name} 맛집과 미식 명소를 신선하게 준비해 최대 20마일까지 배달해 드립니다.`,
    p3: "대표 맛집 라인업을 만나보세요—미리 주문하고 예약된 시간에 받아보세요.",
  };
};

export const vietnameseEssay = (cuisines: string[]) => {
  let name = "";
  for (let i = 0; i < cuisines?.length - 1; i++) {
    name += cuisineInLanguage?.[cuisines[i]]?.vietnamese;
    name += "、";
  }
  name += cuisineInLanguage?.[cuisines[cuisines.length - 1]]?.vietnamese;
  return {
    welcome: "Phải Có Ở Silicon Valley",
    p1: "Những nhà hàng tuyệt vời không nên nằm ngoài tầm với.",
    p2: `Foodie mang đến cho bạn những nhà hàng ${name} được săn đón nhất cùng với các món ăn yêu thích khác—tươi ngon, sẵn sàng phục vụ, và trong phạm vi lên đến 20 dặm.`,
    // p3: "Khám phá danh sách các quán ăn biểu tượng và những viên ngọc ẩn giấu—đặt trước để giao hàng theo lịch trình.",
    p3: "Khám phá danh sách các nhà hàng mang tính biểu tượng và những viên ngọc ẩn của chúng tôi—đặt hàng trước để được giao hàng theo lịch trình.",
  };
};
const getCuisineNames = (cuisines: string[]) => {
  // first capitalize the first letter of each cuisine, then join them with a comma, the last one with "and"
  const capitalized = cuisines?.map(
    (cuisine) => cuisine.charAt(0).toUpperCase() + cuisine.slice(1)
  );
  const joined =
    capitalized.slice(0, -1).join(", ") + " and " + capitalized.slice(-1);
  return joined;
};
export const englishEssay = (cuisines: string[]) => {
  const joined = getCuisineNames(cuisines);
  return {
    welcome: "A Silicon Valley Essential",
    p1: "Great restaurants shouldn’t be out of reach.",
    p2: `Foodie brings the most sought-after ${
      cuisines?.length > 0
        ? `${joined} restaurants and other culinary favorites`
        : "restaurants"
    } to you—fresh, ready, and up to 20 miles away.`,
    p3: "Explore our lineup of iconic eateries and hidden gems—order ahead for scheduled delivery.",
  };
};

export const getEssay = (cuisines: string[], lan = "english") => {
  switch (lan) {
    case "chinese":
      return taiwaneseEssay(cuisines);
    case "taiwanese":
      return taiwaneseEssay(cuisines);
    case "cantonese":
      return cantoneseEssay(cuisines);
    case "japanese":
      return japaneseEssay(cuisines);
    case "korean":
      return koreanEssay(cuisines);
    case "vietnamese":
      return vietnameseEssay(cuisines);
    default:
      return englishEssay(cuisines);
  }
};

export const cuisineRestaurants = {
  japanese: [
    hironori,
    demiya,
    dtf,
    rantei,
    gochi,
    muzigo,

    zareen,
    good,
    mustBeThai,
    dishdash,
  ],
  taiwanese: [
    dtf,
    taiwanCafe,
    o2,
    rantei,
    porridge,
    zareen,
    tasty,
    mustBeThai,
    shang,
  ],
  cantonese: [
    hongkong,
    dtf,
    rantei,
    koi,
    zareen,
    keewah,
    hl,
    mustBeThai,
    sifu,
    huilaushan,
  ],
  boba: [sunRight, ume, tp, moon, huilaushan, shang, heyTea, keewah, o2],
  ramen: [
    hironori,
    muzigo,
    ippudo,
    dtf,
    kowa,
    gochi,
    hajime,
    zareen,
    tomi,
    rantei,
  ],
  korean: [
    daeho,
    kunjip,
    paik,
    dtf,
    bbq,
    sgd,
    rantei,
    bimbimbowl,
    tenButchers,
    zareen,
    rollHouse,
  ],
  vietnamese: [
    phoHanoi,
    rantei,
    phoLovers,
    dtf,
    tayho,
    daeho,
    phoToChau,
    sifu,
    mustBeThai,
    heyTea,
  ],
  general: [dtf, zareen, dishdash, hironori, cajun, mustBeThai, good, dishdash],
};

export const stamps = {
  japanese: {
    image: japaneseStamp,
    width: 160,
    height: 220,
    bottom: -80,
    left: -40,
  },
  taiwanese: {
    image: taiwaneseStamp,
    width: 90,
    height: 140,
    bottom: -30,
    left: -10,
  },
  korean: {
    image: koreanStamp,
    width: 80,
    height: 120,
    bottom: -20,
    left: 0,
  },
  cantonese: {
    image: cantoneseStamp,
    width: 90,
    height: 130,
    bottom: -20,
    left: -5,
  },
  boba: {
    image: bobaStamp,
    width: 80,
    height: 130,
    bottom: -30,
    left: 0,
  },
  ramen: {
    image: ramenStamp,
    width: 80,
    height: 130,
    bottom: -30,
    left: 0,
  },
  vietnamese: {
    image: vietnameseStamp,
    width: 80,
    height: 130,
    bottom: -30,
    left: 0,
  },
  general: {
    image: generalStamp,
    width: 80,
    height: 130,
    bottom: -30,
    left: 0,
  },
};
