import styled from "styled-components";
import { HeroImage } from "..";
import { Kai, LunarNewYearSlogan } from "../../PageSMLinkPreview";
import {
  Overlay,
  Content,
  RestaurantName,
  Title,
  MealLink,
} from "../customers/banners/MealHighlights";
import {
  actionTransparent,
  ALLCAPTEXT,
  Centered,
  centeredByColumn,
  CenteredByColumn,
} from "../Shared";
const HeroHeight = 30;
const heroImages = [
  "https://43andcompany.myshopify.com/cdn/shop/products/Valentine_s2023MiniDessertSampler1_580x@2x.jpg?v=1675099464",
  "https://olo-images-live.imgix.net/20/20724234c1b041a190f751441505719f.jpg?auto=format%2Ccompress&q=60&cs=tinysrgb&w=600&h=500&fit=crop&fm=png32&s=65a539d7cd60113b73690ae5cf07bb13",
  "https://sprinkles-dyo-api-production.s3.amazonaws.com/106ebb24-0101-45af-a5e1-7318ca0caa5d.png",
];
const SubTitle = styled(RestaurantName)`
  margin-bottom: 10px;
`;
const ContentContainer = styled.a`
  ${centeredByColumn}
  width: 100vw;
  height: ${HeroHeight}vh;
  position: relative;
  .container {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
  }
  @media only screen and (max-height: 600px) {
    height: 40vh;
  }
  @media only screen and (min-width: 1200px) {
    height: ${HeroHeight}vh;
    .container {
      .pic {
        height: 100%;
      }
    }
  }
`;
const TextContainer = styled(Content)`
  padding: 20px 0px;
`;
const TitleText = styled(CenteredByColumn)`
  margin-bottom: 20px;
`;

const Slogan = styled.span`
  ${ALLCAPTEXT}
  font-size: 32px;
  line-height: 1.1;
`;

const Action = styled(Centered)`
  ${actionTransparent}
  border-color: white;
  text-transform: uppercase;
`;
const NewYearMenus = () => {
  return (
    <ContentContainer href={`/holiday`}>
      <Overlay></Overlay>
      {heroImages ? <HeroImage images={heroImages} /> : null}
      <TextContainer>
        <SubTitle>Valentine's Day Special Menus</SubTitle>
        <TitleText>
          <Slogan>happy valentine's day</Slogan>
          {/* <LunarNewYearSlogan /> */}
        </TitleText>

        <Action>Pre-Order Now</Action>
      </TextContainer>
    </ContentContainer>
  );
};
export default NewYearMenus;
