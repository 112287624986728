import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore, connect } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { TrackAdsEvent } from "src/api/tracker";
import { RootState } from "src/store";
import { loadClickID, fetchNextMealLink } from "src/store/actions";
import { Loading } from "../shared";
import { LOGGING } from "../shared";

interface RouteParams {
  restaurantId: string;
}

const PageNextMeal: React.FC = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const restaurantId = urlParams.get("restaurantId");
  const cuisineName = urlParams.get("cuisineName");

  // State to manage loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const currentUser = useSelector((state: RootState) => state.currentUser);
  const name = currentUser?.user?.firstName
    ? currentUser?.user?.firstName + " " + currentUser?.user?.lastName
    : "guest";
  const pixel = useSelector((state: RootState) => state.pixel);
  let source;
  let fbclid;
  if (!pixel?.fbclid) {
    source = urlParams.get("source");
    fbclid = urlParams.get("fbclid");
    if (fbclid) {
      loadClickID(
        source,
        fbclid,
        restaurantId,
        cuisineName
      )(dispatch, store.getState);
    }
    LOGGING &&
      console.log("PageNextMeal got source & fbclid from URL: ", {
        source,
        fbclid,
      });
  } else {
    source = pixel.source;
    fbclid = pixel.fbclid;
    LOGGING && console.log("PageNextMeal got pixel from redux: ", pixel);
  }

  useEffect(() => {
    if (restaurantId) {
      setLoading(true);
      LOGGING &&
        console.log("PageNextMeal fetching mealLink from server: ", {
          restaurantId,
        });
      MealVisited(
        `next meal for ${restaurantId}`,
        name,
        null,
        currentUser?.user?._id,
        0,
        fbclid,
        source
      );

      // Insert Facebook Pixel tracking here
      // @ts-ignore
      if (window.fbq && fbclid) {
        LOGGING && console.log("next meal visit is tracked by fbq");
        // @ts-ignore
        window.fbq("track", "ViewContent", {
          content_name: `next meal ${restaurantId}`,
          content_category: "pageNextMeal",
          value: 0,
          currency: "USD",
        });

        // Track Ads Event
        TrackAdsEvent(fbclid, {
          source: source,
          eventCode: "ViewContent",
          page: "PageNextMeal",
          contentName: `next meal ${restaurantId}`,
        });
      }

      fetchNextMealLink({ restaurantId })(dispatch, store.getState)
        .then((mealLink) => {
          setLoading(false);
          LOGGING &&
            console.log("PageNextMeal got mealLink from server: ", mealLink);
          if (mealLink) {
            // Redirect to the next meal
            if (fbclid && source) {
              history.push(
                `/${mealLink}?source=${source}&fbclid=${fbclid}&restaurantId=${restaurantId}`
              );
            } else if (fbclid) {
              history.push(
                `/${mealLink}?fbclid=${fbclid}&restaurantId=${restaurantId}`
              );
            } else if (source) {
              history.push(
                `/${mealLink}?source=${source}&restaurantId=${restaurantId}`
              );
            } else {
              history.push(`/${mealLink}?restaurantId=${restaurantId}`);
            }
          } else {
            if (currentUser?.isAuthenticated) {
              // Redirect to the home page
              history.push("/");
            } else {
              // Redirect to the login page
              if (fbclid && source) {
                history.push(
                  `/guest?source=${source}&fbclid=${fbclid}&restaurantId=${restaurantId}`
                );
              } else if (fbclid) {
                history.push(
                  `/guest?fbclid=${fbclid}&restaurantId=${restaurantId}`
                );
              } else if (source) {
                history.push(
                  `/guest?source=${source}&restaurantId=${restaurantId}`
                );
              } else {
                history.push(`/guest?restaurantId=${restaurantId}`);
              }
            }
          }
        })
        .catch((err) => {
          setError(err?.message);
          setLoading(false); // Ensure loading is stopped on error
        });
    }
  }, [restaurantId]);

  LOGGING &&
    console.log("PageNextMeal render with: ", {
      loading,
      restaurantId,
    });
  return <Loading />;
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  fetchNextMealLink,
})(PageNextMeal);
