import React, { useState } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GoogleLogin,
  useGoogleOneTapLogin,
  GoogleOAuthProvider,
} from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { TrackAdsEvent } from "src/api/tracker";
import { LOGGING } from "src/constants";
import { checkUserEmail, signIn, signUp2 } from "src/store/actions";
import styled from "styled-components";
import { GoogleLogo } from "../../auth/Shared";
import {
  UpwardShadow,
  BorderGrey,
  centeredByColumn,
  textTitle,
  Centered,
  centered,
  TextGrey,
} from "../../Shared";

const Container = styled(UpwardShadow)`
  ${centered}
  /* justify-content: space-between; */
  background-color: white;
  position: fixed;
  height: 100px;
  bottom: 0px;
  width: 100vw;
  z-index: 104;
  left: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 1px solid ${BorderGrey};
  border-left: 1px solid ${BorderGrey};
  border-right: 1px solid ${BorderGrey};
`;

const Button = styled.button`
  ${centered}
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: white;
  position: fixed;
  right: 5px;
  bottom: 45px;
  svg {
    font-size: 20px;
    color: ${TextGrey};
  }
`;

export const GoogleLoginFallback = ({ onSuccess, onError, onClose }) => (
  <Container>
    <GoogleLogin
      onSuccess={onSuccess}
      onError={onError}
      text="continue_with"
      theme="filled_blue"
      size="large"
    />
    <Button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose();
      }}
    >
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  </Container>
);

const GoogleOneTapSignup = ({ onClose, pixel }) => {
  const [oneTapDismissed, setOneTapDismissed] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();

  const onSuccess = (fromOneTap: boolean, response: { credential: string }) => {
    console.log("Google Login Success fromOneTap:", fromOneTap, response);

    const decoded = jwtDecode(response.credential) as {
      email: string;
      given_name: string;
      family_name: string;
      picture: string;
      sub: string;
    };

    const {
      email,
      given_name: firstName,
      family_name: lastName,
      picture: imageUrl,
      sub: googleId,
    } = decoded;
    LOGGING &&
      console.log("handleGoogleSuccess got user:", {
        firstName,
        lastName,
        email,
        googleId,
        imageUrl,
      });

    setUser({
      email,
      firstName,
      lastName,
      googleId,
      imageUrl,
    });

    checkUserEmail(email)(dispatch, store.getState).then((response) => {
      if (response) {
        setLoading(true);
        signIn({ email, googleId, profilePictureURL: imageUrl })(
          dispatch,
          store.getState
        ).then(() => {
          setLoading(false);
          history.push("/");
        });
      } else {
        setLoading(true);
        signUp2({
          email,
          firstName,
          lastName,
          googleId,
          profilePictureURL: imageUrl,
        })(dispatch, store.getState).then(() => {
          // @ts-ignore
          if (window.fbq && pixel?.fbclid) {
            // @ts-ignore
            window.fbq("track", "CompleteRegistration", {
              content_name: `sign up by google one tap`,
              content_category: "pageAuth",
              value: 0,
              currency: "USD",
            });

            // Track Ads Event
            TrackAdsEvent(pixel?.fbclid, {
              source: pixel?.source,
              eventCode: "CompleteRegistration",
              page: "pageAuth",
              contentName: "signUpByGoogleOneTap",
            });
          }
          setLoading(false);
          history.push("/");
        });
      }
    });
  };

  const onError = () => {
    console.log("Google Login Failed");
    setOneTapDismissed(true); // Fallback to button if One Tap fails
  };

  // return <GoogleLoginFallback onSuccess={onSuccess.bind(this, false)} onError={onError} onClose={onClose}/>;

  useGoogleOneTapLogin({
    onSuccess: onSuccess.bind(this, true),
    onError: () => {
      console.log("One Tap Failed");
      setOneTapDismissed(true);
    },
  });

  if (loading) {
    return (
      <LoadingContainer>
        <GoogleLogo /> <span>Signing in with Google...</span>
      </LoadingContainer>
    );
  }
  if (oneTapDismissed) {
    return (
      <GoogleLoginFallback
        onSuccess={onSuccess}
        onError={onError}
        onClose={onClose}
      />
    );
  }

  return null; // One Tap is auto-triggered so nothing to show.
};

const GoogleOneTapSignupWithProvider = ({ onClose, pixel }) => (
  <GoogleOAuthProvider
    clientId={
      "79589333326-egqfjrgf8kd5f8ievksp41u08r8hjf0h.apps.googleusercontent.com"
    }
  >
    <GoogleOneTapSignup onClose={onClose} pixel={pixel} />
  </GoogleOAuthProvider>
);

export default GoogleOneTapSignupWithProvider;

const LoadingContainer = styled(UpwardShadow)`
  ${centered}
  span {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    letter-spacing: 0.15px;
    margin-left: 5px;
  }
  height: 100px;
  position: fixed;
  bottom: 0px;
  width: 100vw;
  z-index: 104;
  left: 0;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 1px solid ${BorderGrey};
  border-left: 1px solid ${BorderGrey};
  border-right: 1px solid ${BorderGrey};
  border-bottom: 1px solid white;
  box-sizing: border-box;
`;
