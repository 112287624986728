import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TrackAdsEvent } from "src/api/tracker";
import styled from "styled-components";
import AppStoreLink from "./AppStoreLink";
import logoBlack from "../../../../../foodie_black.png";
import logoWhite from "../../../../../logo_transparent.png";
import {
  BorderGrey,
  Centered,
  Gold,
  NavHeight,
  centered,
  text12300,
} from "../../Shared";

const Container = styled(Centered)<{ scrolled: boolean }>`
  width: 100vw;
  height: ${NavHeight}px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  justify-content: space-between;
  padding: 0 20px;
  background-color: ${({ scrolled }) =>
    scrolled ? "rgba(255, 255, 255, 0.95)" : "rgba(255, 255, 255, 0.25)"};

  border-bottom: ${({ scrolled }) =>
    scrolled ? `1px solid ${BorderGrey}` : "none"};

  /* box-shadow: ${({ scrolled }) =>
    scrolled ? "0px 0px 2px rgba(0, 0, 0, 0.1)" : "none"}; */
  z-index: 103;

  @media screen and (max-width: 350px) {
    padding: 0 10px;
  }
  @media screen and (min-width: 500px) {
    padding: 0 10vw;
    justify-content: flex-start;
  }
  @media screen and (max-height: 700px) {
    height: var(--nav-height-short);
  }
`;

const Button = styled(Link)<{ scrolled: boolean }>`
  ${centered}
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  padding: 0 15px;
  height: 38px;
  background-color: ${Gold};
  border-radius: 6px;
  margin: 0px 10px;
  flex-grow: 1;
  max-width: 80px;
  box-sizing: content-box;
  svg {
    color: ${({ scrolled }) => (scrolled ? "black" : "white")};
    font-size: 20px;
  }
  span {
    ${text12300}
    font-weight: 600;
    text-transform: uppercase;
  }
  @media screen and (max-width: 350px) {
    margin: 0px 7px;
    padding: 0 10px;
    span {
      font-size: 11px;
      line-height: 14px;
      text-align: center;
    }
  }
`;

const LogoImage = styled.img`
  height: ${NavHeight - 30}px;
  width: auto;
  @media screen and (max-height: 700px) {
    height: calc(var(--nav-height-short) - 20px);
  }
`;

const ActionContainer = styled(Centered)`
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (min-width: 500px) {
    /* justify-content: flex-start; */
  }
`;
const NavGuest = ({ fbclid, source }: { fbclid: string; source: string }) => {
  const [scrolled, setScrolled] = useState(false);
  const colorChangeThreshold = window.innerHeight * 0.2;

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > colorChangeThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Container scrolled={scrolled}>
      <LogoImage src={scrolled ? logoBlack : logoWhite} alt="logo" />
      <ActionContainer>
        <Button
          scrolled={scrolled}
          to="/auth"
          onClick={() => {
            // Insert Facebook Pixel tracking here
            // @ts-ignore
            if (window.fbq && fbclid) {
              // @ts-ignore
              window.fbq("track", "ViewContent", {
                content_name: `page guest to sign up`,
                content_category: "pageGuest",
                value: 0,
                currency: "USD",
              });

              // Track Ads Event
              TrackAdsEvent(fbclid, {
                source: source,
                eventCode: "ViewContent",
                page: "pageGuest",
                contentName: "page guest to sign up",
              });
            }
          }}
        >
          <span>sign up</span>
        </Button>
        <AppStoreLink
          customStyle={{ height: "40px" }}
          source={source}
          fbclid={fbclid}
        />
      </ActionContainer>
    </Container>
  );
};

export default NavGuest;
