import { type } from "os";
import React, { ReactElement, useState } from "react";
import { faThumbsUp as faTrendy } from "@fortawesome/pro-light-svg-icons";
import { faMinus, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { DishType } from "src/api/dish";
import { TrackAdsEvent } from "src/api/tracker";
import { BREAK_POINTS } from "src/constants";
import { useDishCountInCart } from "src/hooks/useDishCountInCart";
import { Dish } from "src/models";
import LoadingCircle from "src/screens/shared/LoadingCircle";
import { getDishCountInCart } from "src/util/cart";
import styled from "styled-components";
import DishDescription from "./DishDescription";
import { Prices } from "./Prices";
import { LOGGING, DedupClickMiliSeconds } from "../../";
import {
  TextUPPER,
  Text12300,
  text12300,
  textTitle,
  Centered,
  Card,
  card,
  textOverflow,
  Beige,
  Yellow,
  Pink,
  ActionTransparent,
  ActionBlack,
  ActionGrey,
  CenteredByColumn,
  actionBlack,
  BorderGrey,
} from "../../Shared";
import { CenteredByColumnLeft } from "../../Shared";
interface DishProps {
  cart?: any; // See MealMenuScreen for comments
  dish: Dish;
  isMobile: boolean;
  isMember: boolean;
  isAuthenticated?: boolean;
  restaurantIsMemberOnly?: boolean;
  onOpenDishDetails: (e: React.MouseEvent<HTMLInputElement>) => void;
  onAddDishToCart: () => void;
  onRemoveDishFromCart: () => void;
  onStartMembership?: () => void;
  onShowAuth?: () => void;
  onShowMembershipDetails?: () => void;
  isSaving?: boolean;
  menuLink?: string;
  source?: string;
  fbclid?: string;
}

const IMAGE_SIZE = 140;
const IMAGE_WIDTH = 140;
const TEXT_CONTAINER_MIN_WIDTH = 120;

const MOBILE_DISH_CONTAINER_MARGIN = 6;

const DESKTOP_DISH_CONTAINER_MARGIN = 8;
const DESKTOP_DISH_CONTAINER_MIN_WIDTH =
  TEXT_CONTAINER_MIN_WIDTH + IMAGE_SIZE + DESKTOP_DISH_CONTAINER_MARGIN * 2;
const DESKTOP_DISH_CONTAINER_MAX_WIDTH =
  TEXT_CONTAINER_MIN_WIDTH * 2 + IMAGE_SIZE + DESKTOP_DISH_CONTAINER_MARGIN * 2;

const WHITE_HOVER_COLOR = "#dbdbdb";

type ContainerProps = {
  isMobile: boolean;
  hasOrdered: boolean;
};
const Container = styled.div<ContainerProps>`
  // Positioning
  position: relative;
  display: flex;
  flex-direction: row;
  margin: ${(props) =>
    props.isMobile
      ? MOBILE_DISH_CONTAINER_MARGIN
      : DESKTOP_DISH_CONTAINER_MARGIN}px;
  padding: 0px;

  // Width
  width: ${(props) =>
    props.isMobile
      ? "100%"
      : `calc(50% - ${DESKTOP_DISH_CONTAINER_MARGIN * 2}px)`};
  min-width: ${(props) =>
    props.isMobile ? undefined : `${DESKTOP_DISH_CONTAINER_MIN_WIDTH}px`};
  max-width: ${(props) =>
    props.isMobile ? undefined : `${DESKTOP_DISH_CONTAINER_MAX_WIDTH}px`};
  /* height: ${(props) =>
    props.hasOrdered ? `${IMAGE_SIZE + 40}px` : `${IMAGE_SIZE}px`}; */
  // Border
  /* border: 1px solid #e7e7e7; */
  /* border-radius: 8px; */
  /* overflow: hidden; */

  // For text
  color: black;
  word-spacing: -1px;
  letter-spacing: -0.5px;
  ${card}
  background-color: ${Beige};
  box-shadow: 0 0px 6px 4px rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  align-self: stretch;
`;

const ButtonContainer = styled.button<{ isBig?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
  width: 100%;
  min-height: ${IMAGE_SIZE}px;
  align-content: stretch;
  position: relative;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  padding-bottom: ${(props) => (props.isBig ? `${35}px` : `${0}px`)};
`;
type ItemDescriptionContainerProps = {
  hasOrdered: boolean;
};
const ItemDescriptionContainer = styled(
  CenteredByColumnLeft
)<ItemDescriptionContainerProps>`
  // Positioning

  /* justify-content: flex-start; */
  /* margin-left: 16px; */
  /* margin-bottom: 16px; */

  // Size
  /* min-width: ${TEXT_CONTAINER_MIN_WIDTH}px; */
  width: calc(100% - ${IMAGE_WIDTH}px);
  height: 100%;
  text-align: left;
  /*position: absolute;
  align-self: stretch;
  flex-grow: 1;
  top: 0px;
  right: 0px;
  padding: 10px; */
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  // padding-bottom: ${(props) => (props.hasOrdered ? `${50}px` : `${10}px`)};
`;

const Title = styled(TextUPPER)`
  font-size: 13px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: 1px;
  /* height: 36px; */
  margin-bottom: 10px;
  /* margin: 10px 12px; */
  /* margin-bottom: 10px; */
  ${textOverflow}
  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  @media only screen and (min-width: 500px) {
    font-size: 16px;
  }
`;
const Price = styled.div`
  font-size: 13px;
  font-weight: 500;
  /* line-height: 20px; */
  letter-spacing: 1px;

  position: absolute;
  left: 0px;
  margin-left: 12px;
  font-weight: ${(props) => (props.theme === "selected" ? 500 : 300)};
`;

export const GetMemberShip = styled(Link)`
  ${actionBlack}
  position: absolute;
  right: 10px;
  bottom: 10px;
  /* width: 60px; */
  width: auto;
  min-width: 50px;
  padding: 0px 10px;
  font-size: 10px;
  height: 30px;
`;

const MemberPrice = styled(Price)`
  bottom: 12px;
`;
const RegularPrice = styled(Price)`
  bottom: 30px;
`;
const Description = styled(Text12300)`
  font-size: 10px;
  /* margin: 10px 12px; */
  ${textOverflow}
  line-height: 1.3;
  /* max-height: 12px; */
  text-transform: none;
  /* color: #767676; */
  /* padding-left: 10px; */
  @supports (-webkit-line-clamp: 3) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

const Sold = styled(Text12300)`
  /* color: grey; */
  background-color: gold;
  font-weight: 400;
  /* width: 50px;
  height: 50px;
  border-radius: 50%; */
  position: absolute;
  top: 8px;
  left: 8px;
  /* box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2); */
  svg {
    margin-right: 5px;
  }
  padding: 0px 8px;
  border-radius: 5px;
`;

const CartButtonContainer = styled.div`
  // Positioning
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  margin: 0px;
  font-weight: 700;
  font-size: 12px;
`;

const ModifyItemCountContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

type FloatingButtonProps = {
  height: number;
  backgroundColor: string;
  hoverBgColor: string;
  color: string;
};
const FloatingButton = styled.button<FloatingButtonProps>`
  /* box-shadow: transparent 0px 0px 0px 1px inset, rgb(0 0 0 / 20%) 0px 2px 8px; */
  border-radius: 16px;
  background-color: ${(props) => props.backgroundColor};
  height: ${(props) => props.height}px;
  width: ${(props) => props.height}px;
  color: ${(props) => props.color};
  border: 1px solid rgba(217, 217, 217, 1);
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${(props) => props.hoverBgColor};
  }
  &:disabled {
    color: ${BorderGrey};
  }
`;

const AddToCartButton = styled(ActionBlack)`
  /* border-radius: 16px; */
  padding: 0px 12px;
  transition: background-color 0.1s ease;
  margin: 0px;
  height: 25px;
  font-size: 10px;
  letter-spacing: 0.6px;
  /* border-bottom: 1px solid black;
  color: black;
  background-color: transparent; */
  &:hover {
    background-color: #dbdbdb;
  }
`;

const DishCountInCartButton = styled(FloatingButton)`
  pointer-events: none;
  margin: 0 10px;
`;

type ImageProps = { backgroundImageUrl: string };
const Image = styled.div<ImageProps>`
  background-image: url(${(props) => props.backgroundImageUrl});
  background-size: cover;
  min-height: ${IMAGE_SIZE}px;
  height: 100%;
  min-width: ${IMAGE_WIDTH}px;
  background-position: center;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const CountInCart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 128, 0, 0.8);
  color: white;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0 0 8px 2px rgba(28, 29, 33, 0.3);
  span {
    display: inline-block;
    text-align: center;
  }
  span.count {
    font-family: "Theano Didot", serif;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.6px;
  }
  span.in-cart,
  span.already {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0.6px;
  }
  span.already {
    margin-top: 2px;
  }

  @media (min-width: ${BREAK_POINTS.ml}px) {
    bottom: 20px;
  }
`;

export const Auth = styled(GetMemberShip)``;

export const DishV2: React.FC<DishProps> = ({
  cart,
  dish,
  isMobile,
  onOpenDishDetails,
  onAddDishToCart,
  onRemoveDishFromCart,
  isMember,
  isAuthenticated,
  onStartMembership,
  onShowAuth,
  restaurantIsMemberOnly,
  onShowMembershipDetails,
  isSaving,
  menuLink,
  source,
  fbclid,
}: DishProps): ReactElement => {
  const hookDishCountInCart = useDishCountInCart(dish._id);
  const dishCountInCart =
    cart != null ? getDishCountInCart(dish._id, cart) : hookDishCountInCart;
  // LOGGING &&
  //   console.log("DishV2 rendering with:", { isAuthenticated, isMember });
  const { priceFoodieListed, priceFoodieNonMember, priceDoordashListed } = dish;
  const memberOnly = priceFoodieListed && !priceDoordashListed;
  // restaurantIsMemberOnly || (priceFoodieListed && !priceDoordashListed);
  const debouncedRemove = debounce(onRemoveDishFromCart, DedupClickMiliSeconds);
  const debouncedAdd = debounce(onAddDishToCart, DedupClickMiliSeconds);

  return (
    <Container isMobile={isMobile} hasOrdered={dishCountInCart > 0}>
      <ButtonContainer
        isBig={!isMember && memberOnly}
        // @ts-ignore
        onClick={
          memberOnly && !isMember
            ? isAuthenticated
              ? onShowMembershipDetails
              : onShowAuth
            : onOpenDishDetails
        }
      >
        <Image backgroundImageUrl={dish.imageURL} />
        {dish?.mustTry ? (
          <Sold>
            <FontAwesomeIcon icon={faTrendy} />
            {/* {`sold ${dish?.orderedRange}`} */}
            must try
          </Sold>
        ) : null}
        {dishCountInCart > 0 ? (
          <CountInCart>
            <span className="count">{dishCountInCart}</span>
            <span className="already">already</span>
            <span className="in-cart">in cart</span>
          </CountInCart>
        ) : null}
        <ItemDescriptionContainer hasOrdered={dishCountInCart > 0}>
          <Title>{dish.name}</Title>
          {dish?.description && dish.description.length > 0 ? (
            <DishDescription description={dish.description} />
          ) : null}
          {/* <Description>{dish.description}
          <button onClick={e=>{
            e.preventDefault();
            e.stopPropagation();
            console.log('more');
          }}>more</button>
          </Description> */}
          <Prices
            priceFoodieListed={priceFoodieListed}
            priceFoodieNonMember={priceFoodieNonMember}
            priceDoordashListed={priceDoordashListed}
            isMember={isMember}
          />
        </ItemDescriptionContainer>
      </ButtonContainer>
      {memberOnly && !isMember ? (
        <GetMemberShip
          to={{
            pathname: "/membership",
            state: {
              treatPath: `/meal/${menuLink}`,
              next: `/meal/${menuLink}`,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            // Insert Facebook Pixel tracking here
            // @ts-ignore
            if (window.fbq && fbclid) {
              LOGGING && console.log("membership click is tracked by fbq");
              // @ts-ignore
              window.fbq("track", "ViewContent", {
                content_name: `${menuLink} to membership`,
                content_category: "pageSingleMeal",
                value: 0,
                currency: "USD",
              });

              // Track Ads Event
              TrackAdsEvent(fbclid, {
                source: source,
                eventCode: "ViewContent",
                page: "pageSingleMeal",
                contentName: `${menuLink} to membership`,
                orderId: cart?._id,
              });
            }
          }}
        >
          start 30D free trial
        </GetMemberShip>
      ) : (
        <CartButtonContainer>
          {dishCountInCart > 0 ? (
            (dish.selections ?? []).length === 0 &&
            dish.dishType !== DishType.GROUP ? (
              <ModifyItemCountContainer>
                <FloatingButton
                  height={32}
                  backgroundColor="white"
                  hoverBgColor="white"
                  color="black"
                  // disabled={isSaving}
                  onClick={(e) => {
                    e.stopPropagation();
                    debouncedRemove();
                    // onRemoveDishFromCart();
                  }}
                >
                  <FontAwesomeIcon
                    icon={dishCountInCart === 1 ? faTrash : faMinus}
                  />
                </FloatingButton>
                {/* {isSaving ? (
                  <LoadingCircle size={32} margin={"0px 10px"} />
                ) : ( */}
                <DishCountInCartButton
                  height={32}
                  backgroundColor="green"
                  hoverBgColor="green"
                  color="white"
                >
                  {dishCountInCart}
                </DishCountInCartButton>
                {/* )} */}
                <FloatingButton
                  height={32}
                  // disabled={isSaving}
                  backgroundColor="white"
                  hoverBgColor="white"
                  color="black"
                  onClick={(e) => {
                    e.stopPropagation();
                    // onAddDishToCart();
                    debouncedAdd();
                    LOGGING && console.log("debouncedAdd called");
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </FloatingButton>
              </ModifyItemCountContainer>
            ) : (
              <AddToCartButton
                onClick={(e) => {
                  e.stopPropagation();
                  // onAddDishToCart();
                  onOpenDishDetails(e as React.MouseEvent<HTMLInputElement>);
                }}
              >
                add
              </AddToCartButton>
            )
          ) : (dish.selections ?? []).length === 0 &&
            dish.dishType !== DishType.GROUP ? (
            <FloatingButton
              height={32}
              backgroundColor="white"
              hoverBgColor={WHITE_HOVER_COLOR}
              color="black"
              onClick={(e) => {
                e.stopPropagation();
                // onAddDishToCart();
                debouncedAdd();
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </FloatingButton>
          ) : (
            <AddToCartButton
              onClick={(e) => {
                e.stopPropagation();
                // onAddDishToCart();
                onOpenDishDetails(e as React.MouseEvent<HTMLInputElement>);
              }}
            >
              {/* <FontAwesomeIcon icon={faPlus} /> */}
              add
            </AddToCartButton>
          )}
        </CartButtonContainer>
      )}
    </Container>
  );
};
