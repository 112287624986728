import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { Close } from "src/screens/shared";
import { ContinueWithGoogle } from "src/screens/shared/Auth";
import styled from "styled-components";
import { GoogleLogo } from "../../auth/Shared";
import {
  BorderGrey,
  card,
  centeredByColumn,
  CenteredByColumn,
  Centered,
  UpwardShadow,
  text12300,
  TextGrey,
  text13500,
  CenteredByColumnLeft,
} from "../../Shared";

const Content = styled(CenteredByColumnLeft)`
  width: 100%;
  padding: 20px;
`;

const Headline = styled.span`
  ${text12300}
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
`;
const Nav = styled(Centered)`
  border-bottom: 1px solid ${BorderGrey};
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
  border-bottom: 1px solid ${BorderGrey};
  span {
    ${text12300}
    font-size: 13px;
    font-weight: 500;
    color: ${TextGrey};
  }
  button {
    position: absolute;
    right: 20px;
    font-size: 20px;
  }
`;
const Container = styled(UpwardShadow)`
  ${centeredByColumn}
  justify-content: space-between;
  background-color: white;
  /* padding-bottom: 20px; */
  position: fixed;
  bottom: 0px;
  width: 100vw;
  z-index: 104;
  left: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 1px solid ${BorderGrey};
  border-left: 1px solid ${BorderGrey};
  border-right: 1px solid ${BorderGrey};
`;
const GuestAuth = ({ onClose }) => {
  const onGoogleSuccess = () => {
    onClose();
  };
  const onGoogleReady = () => {
    console.log("onGoogleReady called");
  };
  const onGoogleFailure = () => {
    console.log("onGoogleFailure called");
  };
  return (
    <Container>
      <Nav>
        <GoogleLogo />
        <span>Sign in with Google</span>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Nav>
      <Content>
        <Headline>Use your Google Account ot sign in to Foodie</Headline>
        <ContinueWithGoogle
          onSuccess={onGoogleSuccess}
          onFailure={onGoogleFailure}
          onReady={onGoogleReady}
          textButton="Continue"
          showGoogleLogo={false}
        />
      </Content>
    </Container>
  );
};

export default GuestAuth;
