import React, { ReactElement, useCallback, useState, useEffect } from "react";
// import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faTimes,
  faPlusCircle,
  faShoppingCart,
} from "@fortawesome/pro-light-svg-icons";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { set } from "lodash";
import moment from "moment-timezone";
import { useAsync } from "react-async";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { GetReferralCodeDetails } from "src/api/referrals";
import { FoodieEvent, FoodieSource } from "src/api/tracker";
import { useTrackEventCallback } from "src/hooks/useTrackEventCallback";
import { RootState } from "src/store";
import styled, { keyframes } from "styled-components";
import { BreakPoint } from "./MealHeader";
import { LOGGING, getItemNumberInCart } from "../..";
import {
  addGoodsToCart,
  updateOrderOnMeal,
} from "../../../../../store/actions";
import { OrderList } from "../../mealMenu/OrderList";
import {
  LightBlue,
  BadgeHeight,
  textTitle,
  centeredByColumn,
  Centered,
  CenteredByColumn,
  Card,
  card,
  Text12300,
  CenteredByColumnLeft,
  TextTitle,
  text12300,
  Beige,
  BorderGrey,
  centered,
  textOverflow,
} from "../../Shared";
export interface OrderHistoryInMealProps {
  orders: any;
  windowStart: number;
  mealId: string;
  onFlashCartPeek: (good: any) => void;
}
export interface OrderHistoryContainerProps {
  freeze: boolean;
}
const OrderHistoryContainer = styled(
  CenteredByColumn
)<OrderHistoryContainerProps>`
  position: absolute;
  width: ${BadgeHeight}px;
  height: ${BadgeHeight}px;
  border-radius: 50%;
  left: calc(0px - ${BadgeHeight}px / 2);
  z-index: ${(props) => (props.freeze ? 101 : 1)};
  /* align-items: flex-start; */
`;

const ToggleShowDetails = styled.button`
  ${textTitle}
  ${centeredByColumn}
  padding: 10px 0px 13px 0px;
  justify-content: space-between;
  z-index: 100;
  width: ${BadgeHeight}px;
  height: ${BadgeHeight}px;
  background-color: ${LightBlue};
  border-radius: 50%;
  label {
    width: 80%;
    font-size: 10px;
    text-align: center;
    line-height: 1.15;
  }
  span {
    font-size: 16px;
    line-height: 1.1;
    letter-spacing: 0.5px;
    border-bottom: 1px solid black;
  }
`;
export const ContentContainer = styled(CenteredByColumnLeft)`
  /* height: 50px; */
  justify-content: flex-start;
  /* height: 270px; */
  /* box-shadow: none; */
  /* position: fixed; */
  /* background-color: rgba(0, 0, 0, 0.7); */
  /* z-index: 0; */
  /* padding: 20px 0 10px calc(${BadgeHeight}px + 10px); */
  /* left: 0px;
  top: 0px; */
  background-color: ${LightBlue};
  overflow-x: scroll;
  /* margin-bottom: -5px; */
  padding-top: 15px;
  /* justify-content: center; */
  h1 {
    ${centered};
    svg {
      font-size: 14px;
      margin: 0 5px 2px 0;
    }
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    padding-left: 55px;
  }
`;

const OrderHistoryList = styled(Centered)`
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
  height: auto;
  > button:first-child {
    margin-left: 10px;
  }
`;
const Order = styled.button`
  ${card}
  margin: 0 20px 10px 0;
  ${centeredByColumn}
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 125px;
  max-height: 190px;
  align-self: stretch;
  box-shadow: 0 0px 4px 2px rgba(52, 41, 41, 0.2);
  background-color: ${Beige};
  padding: 0 0 40px 0;
  box-sizing: border-box;
`;

const TotalItem = styled(Centered)`
  /* position: absolute;
  bottom: 42px;
  left: 10px;   */
  ${text12300}
  text-align: left;
  font-size: 9px;
  justify-content: flex-start;
  padding-left: 10px;
  /* background-color: grey;
  color: white;
  padding: 0px 7px;
  border-radius: 3px; */
  text-transform: capitalize;
  font-weight: 400;
`;

const ViewDetails = styled(Text12300)`
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  /* background-color: black; */
  bottom: 10px;
  left: 10px;
  /* color: white; */
  border-bottom: 1px solid black;
  padding: 0px;
`;

const OrderPlaceHolder = styled.div`
  height: 50px;
  width: 100px;
  background: transparent;
  color: transparent;
  display: block;
  position: relative;
`;
const Pictures = styled(Centered)`
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 5px;
`;
export interface DishImageContainerProps {
  index: number;
}
export interface DishImageProps {
  imageUrl: string;
}
const DishImageContainer = styled(Centered)<DishImageContainerProps>`
  justify-content: flex-start;
  width: 20px;
  z-index: ${(props) => props.index};
`;
const DishImage = styled.div<DishImageProps>`
  display: block;
  /* position: absolute; */
  background-image: url(${(props) => props.imageUrl});
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  left: 0px;
  flex-shrink: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
`;
const ImageOverlay = styled.div`
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  left: 0px;
  z-index: 1;
`;
const AddOrder = styled.button`
  position: absolute;
  right: 10px;
  bottom: 8px;
  padding: 0px;
  font-size: 18px;
`;
const OrderDate = styled(TextTitle)`
  display: block;
  /* margin-bottom: 8px; */
  /* font-weight: 500; */
  font-size: 13px;
  /* line-height: 30px;
  height: 30px; */
  padding: 10px 0 0 10px;
  text-align: left;
  /* padding: 10px 10px 0px 10px; */
  /* background-color: white; */
  width: 100%;
  box-sizing: border-box;
  /* max-width: calc(100% - 70px); */
`;
const OrderGoods = styled(Centered)`
  text-align: left;
  /* width: 100%; */
  /* background-color: white;; */
  background-color: ${Beige};
  flex-wrap: wrap;
  padding: 5px 10px 5px 10px;
  /* line-height: 1.2; */
  /* position: absolute;
  top: 0px;
  left: 0px; */

  /* padding-top: 15px; */
  /* padding-right: 5px; */
  /* > div:last-child {
    margin-bottom: 0px;
    border: none;
  } */
`;
const Good = styled(Text12300)`
  /* line-height: 1.2; */
  /* margin-bottom: 15px; */
  /* width: calc(100% - 20px); */
  /* padding: 15px 0px; */
  /* margin: 0 10px; */
  /* background-color: pink; */
  display: inline;
  & svg {
    margin: 0px 3px 1px 3px;
    font-size: 8px;
  }
  /* min-height: 70px; */
  /* border-bottom: 1px solid ${BorderGrey};   */
  font-size: 9px;
  line-height: 1.5;
  ${textOverflow}
  text-transform: none;
  @supports (-webkit-line-clamp: 3) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

const Quantity = styled(Text12300)``;
const Dish = styled(Text12300)`
  line-height: 1.4;
  font-size: 11px;
  /* margin-bottom: 5px; */
`;
const AddDish = styled.button`
  position: absolute;
  padding: 0px;
  margin: 0px;
  top: 20px;
  right: 0px;
  svg {
    font-size: 14px;
  }
`;
const DishName = styled(Text12300)`
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 10px;
`;
const Selections = styled(CenteredByColumnLeft)``;
const Selection = styled(CenteredByColumnLeft)``;
const SelectionName = styled(Text12300)``;
const SelectionItems = styled(CenteredByColumnLeft)`
  /* padding-left: 10px;   */
`;
const SelectionItem = styled(Centered)`
  align-items: flex-start;
  line-height: 1.7;
  svg {
    font-size: 4px;
    margin-top: 6px;
    margin-right: 5px;
  }
`;
const CartThumbnail = styled(Link)`
  width: ${BadgeHeight}px;
  height: ${BadgeHeight}px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  z-index: 190;
  top: calc(-${BadgeHeight}px - 10px);
  /* padding-bottom: 5px; */
  ${centeredByColumn}
  color: white;
  margin: 0px;
  span {
    display: inline-block;
    line-height: 20px;
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    ${text12300}
    font-weight: 500;
    margin-bottom: 5px;
  }
  svg {
    font-size: 18px;
  }
`;

const OrderListContainer = styled(CenteredByColumn)`
  width: 100vw;
  height: 100vh;
  box-shadow: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 200;
  /* padding: 20px 0px; */
  box-sizing: border-box;
  left: 0px;
  top: 0px;
  overflow: scroll;
  padding-left: 50px;
  /* justify-content: center; */
`;
const OrderSummary = styled(CenteredByColumnLeft)`
  /* color: grey; */
  ${text12300}

  justify-content: flex-start;
  svg {
    font-size: 2px;
    margin: 0px 2px;
  }
  /* margin-bottom: 10px; */
  padding-left: 10px;
  margin: 3px 0px;
`;
const OrderElapsed = styled(Text12300)`
  /* font-weight: 400; */
  line-height: 1;
  font-size: 9px;
  text-transform: lowercase;
  margin-bottom: 3px;
`;
const OrderCount = styled(Text12300)`
  /* font-weight: 400; */
  line-height: 1;
  font-size: 9px;
`;

export const OrderHistoryInMeal: React.FC<OrderHistoryInMealProps> = ({
  orders,
  mealId,
  windowStart,
  onFlashCartPeek,
}: OrderHistoryInMealProps): ReactElement => {
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch();
  const store = useStore();
  const [showDetails, setShowDetails] = useState(false);
  const [gotoOrderId, setGotoOrderId] = useState(null);
  const addDish = useCallback(
    async (good) => {
      console.log("addDish called with: ", good);
      const selections = good.selections.map((s) => ({
        dishSelection: s.dishSelection._id,
        selectedItems: s.selectedItems.map((i) => ({
          item: i.item._id,
          count: i.count,
        })),
      }));
      const updatedCart = addGoodsToCart({
        goodsToAdd: [{ ...good, selections }],
        window: {
          start: windowStart,
          end: windowStart + 60 * 60 * 1000,
          cost: 0,
        },
        referralCode: null,
        mealId,
        isMember: false,
        promo: null,
      })(dispatch, store.getState);
      LOGGING && console.log("addDish got with: ", { updatedCart });

      updateOrderOnMeal()(dispatch, store.getState)
        .then(() => {
          console.log("OrderHistoryInMeal addDish updateOrderOnMeal done");
          onFlashCartPeek([good]);
        })
        .catch((e) => {
          console.error(
            "OrderHistoryInMeal addDish updateOrderOnMeal error:",
            e
          );
        });
    },
    [cart]
  );

  const addOrder = useCallback(
    async (order) => {
      // console.log("addOrder called with:", order);
      const goods = order.goods.filter(
        (g) => g.quantity > (g?.cancelQuantity || 0)
      );
      const goodsToAdd = goods.map((good) => {
        const selections = good.selections.map((s) => ({
          dishSelection: s.dishSelection._id,
          selectedItems: s.selectedItems.map((i) => ({
            item: i.item._id,
            count: i.count,
          })),
        }));
        return { ...good, selections };
      });

      addGoodsToCart({
        goodsToAdd,
        window: {
          start: windowStart,
          end: windowStart + 60 * 60 * 1000,
          cost: 0,
        },
        referralCode: null,
        mealId,
        isMember: false,
        promo: null,
      })(dispatch, store.getState);

      updateOrderOnMeal()(dispatch, store.getState)
        .then(() => {
          console.log("OrderHistoryInMeal addOrder updateOrderOnMeal done");
          onFlashCartPeek(goodsToAdd);
        })
        .catch((e) => {
          console.error(
            "OrderHistoryInMeal addOrder updateOrderOnMeal error:",
            e
          );
        });
    },
    [cart]
  );
  LOGGING &&
    console.log("OrderHistoryInMeal rendering with:", {
      showDetails,
      orders,
      mealId,
    });
  return (
    <ContentContainer>
      <h1>
        <FontAwesomeIcon icon={faHistory} /> previous orders
      </h1>
      {showDetails ? (
        <OrderListContainer
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // console.log("ToggleShowDetails clicked on overlay");
            setShowDetails(!showDetails);
            // onFreeze();
          }}
        >
          <OrderList
            height={window.innerHeight}
            width={window.innerWidth}
            orders={orders}
            addOrder={addOrder}
            addDish={addDish}
            gotoOrderId={gotoOrderId}
          />
        </OrderListContainer>
      ) : null}
      <OrderHistoryList>
        {orders.map((o, orderIndex) => {
          // const g = o.goods[0];
          const goods = o.goods.filter(
            (g) =>
              g?.quantity > (g?.cancelQuantity || 0) &&
              g?.dish?.name?.length > 0
          );

          const totalItems = goods.reduce(
            (a, b) => a + (b?.quantity - (b?.cancelQuantity || 0)),
            0
          );
          // console.log("goods:", {goods, totalItems});
          return (
            <Order
              key={orderIndex}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowDetails(true);
                setGotoOrderId(o._id);
              }}
            >
              <OrderDate>
                {moment(o.window.start).format("ddd MM/DD/YY")}
              </OrderDate>
              <OrderSummary>
                {/* <OrderElapsed>{`${getItemNumberInCart(o)} items, ${moment(
              o.window.start
            ).fromNow()}.`}</OrderElapsed> */}

                <OrderElapsed>{moment(o.window.start).fromNow()}</OrderElapsed>
                {/* <FontAwesomeIcon icon={faCircle} />  */}
                <OrderCount>{getItemNumberInCart(o)} items</OrderCount>
              </OrderSummary>

              <OrderGoods>
                <Pictures>
                  {goods.slice(0, 4).map((g, i) => (
                    <DishImageContainer index={o?.goods?.length - i} key={i}>
                      <DishImage imageUrl={g?.dish?.imageURL}></DishImage>
                    </DishImageContainer>
                  ))}
                </Pictures>
                <Good>
                  {goods
                    .map(
                      (g) =>
                        `${g?.dish?.name} x ${
                          g?.quantity - (g?.cancelQuantity || 0)
                        }`
                    )
                    .join(", ")}
                </Good>
              </OrderGoods>
              <ViewDetails>Details</ViewDetails>
              <AddOrder
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addOrder(o);
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} />
              </AddOrder>
            </Order>
          );
        })}
        <OrderPlaceHolder>hi</OrderPlaceHolder>
      </OrderHistoryList>
    </ContentContainer>
  );
};
