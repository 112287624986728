import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LOGGING } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import {
  BorderGrey,
  card,
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  text12300,
  TextTitle,
} from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";
export const Container = styled(CenteredByColumnLeft)`
  align-self: stretch;
  border: 1px solid ${BorderGrey};
  padding: 15px 30px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: ${BreakPoint}px) {
    border: none;
    margin: 0px 10px;
    padding: 10px 0px;
    border-bottom: 1px solid ${BorderGrey};
    &:last-child {
      border-bottom: none;
    }
  }
`;
export const Title = styled(TextTitle)`
  margin-bottom: 10px;
  @media screen and (max-width: ${BreakPoint}px) {
    margin-bottom: 5px;
    font-size: 24px;
  }
`;
export const Field = styled(Centered)`
  align-self: stretch;
  ${text12300}
  font-size: 14px;
  font-weight: 400;
  /* background-color: white; */
  flex-grow: 1;
  justify-content: space-between;
  label {
    text-transform: capitalize;
    /* font-weight: 600; */
  }
  > svg {
    margin-right: 10px;
  }
  @media screen and (max-width: ${BreakPoint}px) {
    font-size: 14.5px;
    font-weight: 500;
  }
`;
export const ContactContainer = styled(Container)`
  border: none;
  padding: 0px;
  margin-bottom: 40px;
  @media screen and (max-width: ${BreakPoint}px) {
    border: none;
    margin-bottom: 20px;
  }
`;

export const Contact = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  LOGGING && console.log("Cart1", cart);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  useEffect(() => {
    LOGGING && console.log("Cart2", cart);
    setName(
      cart?.name ||
        currentUser?.user?.firstName + " " + currentUser?.user?.lastName
    );
    setEmail(cart?.email || currentUser?.user?.email);
    setPhone(cart?.phone || currentUser?.user?.phone);
  }, [cart, currentUser]);
  LOGGING && console.log("Contact", { name, email, phone });

  return (
    <ContactContainer>
      <Title>contact info</Title>
      <Field>{name}</Field>
      <Field>{email}</Field>
      <Field>{phone}</Field>
    </ContactContainer>
  );
};
