import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faHeart as faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faEmpty } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { RootState } from "src/store";
import styled from "styled-components";
import { LOGGING } from "..";
import { toggleFavorite } from "../../../../store/actions";
import {
  MechanicGreen,
  MealHeight,
  Beige,
  NavHeight,
  BorderGrey,
  BackgroundGrey,
  text12300,
  Centered,
  centeredByColumn,
  TextGrey,
  centered,
  textTitle,
} from "../Shared";
const BreakPoint = 800;
interface ToggleProps {
  isOn?: boolean;
  isNav?: boolean;
}
const toggleHeight = 20;
const toggleHeightOnNav = 40;
const ToggleSwitch = styled(Centered)<ToggleProps>`
  width: ${(props) =>
    (props.isNav ? toggleHeightOnNav : toggleHeight) * 2 + 4}px;
  height: ${(props) => (props.isNav ? toggleHeightOnNav : toggleHeight)}px;
  background-color: ${(props) => (props.isOn ? "green" : "grey")};
  border-radius: ${(props) =>
    (props.isNav ? toggleHeightOnNav : toggleHeight) / 2}px;
  position: relative;
  justify-content: ${(props) => (props.isOn ? "flex-start" : "flex-end")};
  padding: 3px ${(props) => (props.isNav ? "8px" : "4px")} 2px
    ${(props) => (props.isNav ? "11px" : "7px")};
  span {
    ${text12300}
    text-transform: uppercase;
    font-size: ${(props) => (props.isNav ? "12px" : "8px")};
    font-weight: 600;
    color: white;
    text-align: ${(props) => (props.isOn ? "left" : "right")};
  }
`;

const ToggleSwitchButton = styled.div<ToggleProps>`
  width: ${(props) => (props.isNav ? toggleHeightOnNav : toggleHeight) - 4}px;
  height: ${(props) => (props.isNav ? toggleHeightOnNav : toggleHeight) - 4}px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: ${(props) => (props.isOn ? "auto" : "2px")};
  right: ${(props) => (props.isOn ? "2px" : "auto")};
  transition: left 0.3s ease;
`;

export const ToggleOn = ({ isNav }) => (
  <ToggleSwitch isOn isNav={isNav}>
    <span>on</span>
    <ToggleSwitchButton isOn isNav={isNav} />
  </ToggleSwitch>
);

export const ToggleOff = ({ isNav }) => (
  <ToggleSwitch isNav={isNav}>
    <ToggleSwitchButton isNav={isNav} />
    <span>off</span>
  </ToggleSwitch>
);
interface ButtonContainerProps {
  isFavorite: boolean;
  position?: any;
  isNav?: boolean;
  isMobile?: boolean;
}
const ButtonContainer = styled.button<ButtonContainerProps>`
  ${centered}
  align-items: ${(props) => (props.isNav ? "centered" : "flex-end")};
  /* flex-direction: ${(props) => (props.isNav ? "row" : "column")}; */
  background-color: transparent;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  /* background-color: pink; */
  /* position: absolute;
  top: ${(props) => (props.isNav ? "auto" : "8px")};
  right: ${(props) =>
    props.position?.right ? `${props.position?.right}px` : "auto"};
  left: ${(props) =>
    props.position?.left ? `${props.position?.left}px` : "auto"};
  z-index: 1; */
  /* width: ${(props) => (props.isNav ? "auto" : "70px")}; */
  svg {
    font-size: 20px;
    color: ${(props) => (props.isFavorite ? "#ea4c89" : "grey")};
  }
  label {
    ${text12300}
    font-family: ${(props) => (props.isNav ? "Theano Didot" : "Montserrat")};
    font-size: ${(props) => (props.isNav ? "12px" : "8px")};
    font-weight: 400;
    margin-left: ${(props) => (props.isNav ? "10px" : "0px")};
    text-transform: ${(props) => (props.isNav ? "none" : "capitalize")};
  }
  @media only screen and (min-width: 1200px) {
    position: ${(props) => (props.isNav ? "relative" : "absolute")};
    top: ${(props) => (props.isNav ? `0px` : `${MealHeight + 6}px`)};
    right: 10px;
    flex-direction: column;
    /* align-items: flex-end; */
    align-items: ${(props) => (props.isNav ? "flex-start" : "flex-end")};
    label {
      margin-left: 0px;
      margin-top: ${(props) => (props.isNav ? "10px" : "0px")};
      line-height: 1.6;
      font-weight: 300;
    }
  }
`;

export interface AlertButtonProps {
  restaurantId: String;
  restaurantName: String;
  position?: any;
  isNav?: boolean;
  isMobile?: boolean;
}
export const AlertButton: React.FC<AlertButtonProps> = ({
  restaurantId,
  position,
  isNav,
  isMobile,
}: AlertButtonProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [isFavorite, setIsFavorite] = useState(
    currentUser?.user?.favorites?.includes(String(restaurantId))
  );
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    // hook called first render
    setIsFavorite(currentUser?.user?.favorites?.includes(String(restaurantId))); // state updated with same value
  }, [restaurantId, currentUser]); //

  const toggle = useCallback(
    async (restaurantId) => {
      setIsFavorite(!isFavorite);
      await toggleFavorite(restaurantId)(dispatch, store.getState);
    },
    [currentUser]
  );

  return (
    <ButtonContainer
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        LOGGING && console.log("alert toggle!");
        toggle(restaurantId);
      }}
      position={position}
      isFavorite={isFavorite}
      isNav={isNav}
    >
      <label>reminder</label>
      {isFavorite ? <ToggleOn isNav={isNav} /> : <ToggleOff isNav={isNav} />}
      {/* <label>{`Remind me${isNav ? " to order." : ""}`}</label> */}
    </ButtonContainer>
  );
};
