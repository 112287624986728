import * as crypto from "crypto";
import { add } from "lodash";
import { apiCall } from "src/store/api";
import { LOGGING } from "../constants";
// import moment from "moment";

export const getGoodsKey = (goods, dish) => {
  const dishCount = Object.keys(goods).filter((dishId) =>
    dishId.startsWith(dish._id)
  ).length;
  return `${dish._id}_${dishCount + 1}`;
};

// @param selections. Example
// [
//  {
//   selectedItems: [{ item: "62b3cf96701959009dbae209" }]
//  }
// ]
export const getMyGoods = (goods, userId) => {
  let myGoods = {};
  if (goods) {
    Object.keys(goods).forEach((goodKey) => {
      const { addedBy } = goods[goodKey];
      // LOGGING &&
      //   console.log("getMyGoods looping through: ", {
      //     goods,
      //     goodKey,
      //     userId,
      //     addedBy,
      //   });
      if (!addedBy || String(addedBy?._id || addedBy) === String(userId)) {
        myGoods[goodKey] = goods[goodKey];
      }
    });
  }
  return myGoods;
};
export const getDishKey = (
  dish,
  selections /* w/ selectedItems */,
  addedBy
) => {
  const dishId = String(dish._id);
  const selectedItemIds = selections
    .map((selection) =>
      selection.selectedItems.map((item) =>
        String(item?.item?._id || item?.item)
      )
    )
    .flat()
    .sort();
  LOGGING && console.log("getDishKey selections: ", selections);

  LOGGING && console.log("getDishKey selectedItemIds: ", selectedItemIds);

  const selectedItemIdsToHash = selectedItemIds.join("");

  return `${dishId}-${crypto
    .createHash("md5")
    .update(String(selectedItemIdsToHash))
    .digest("hex")}-${crypto
    .createHash("md5")
    .update(String(addedBy))
    .digest("hex")}`;
};

export const calcDueServer = async (currentUser, cart, originalCredit) => {
  LOGGING &&
    console.log("calcDue called with: ", { currentUser, cart, originalCredit });

  let couponCode = cart.couponCode;

  const cartItems = Object.values(cart.goods).map((g) => {
    return {
      quantity: g.quantity,
      dish: g.dish,
      price: g.price,
      selectedSelections: g.selections
        .map((selection) => {
          return selection.selectedItems;
        })
        .flat(),
    };
  });

  let tip = cart?.tips;
  let tipRate = cart?.tipRate;
  let tipCustom = cart?.tipCustom;
  // LOGGING && console.log("calcDue 2: ", {tip, tipRate, tipCustom});
  // initial tip rate = 0.15
  if (!tip && !tipRate && !(tipCustom !== null && tipCustom >= 0)) {
    tipRate = 0.15;
  }
  // const timestamp = moment().valueOf();
  // LOGGING && console.log("calcDue 3: ", {tip, tipRate, tipCustom, timestamp});
  const cartTotalDetails = await apiCall(
    "post",
    "/pricing/GetCartTotalWithCartItems",
    {
      userId: cart?.user?._id || currentUser?.user?._id,
      mealId: cart.meal?._id || cart.meal,
      cartItems: cartItems,
      couponCode: couponCode?._id,
      tip,
      tipCustom,
      tipRate,
      orderId: cart._id,
      isMember: cart.user?.membership?.isActive,
      doordashComparables: cart.doordashComparables,
      // timestamp,
    }
  );

  LOGGING && console.log("calcDue cartTotalDetails: ", cartTotalDetails);

  const minTotalForCoupon =
    cartTotalDetails.couponError != null
      ? cartTotalDetails.couponError.minimumTotal
      : null;

  const {
    memberSaving,
    subtotalMember,
    subtotalNonMember,
    subtotalRestaurant,
    totalTaxMember,
    totalTaxNonMember,
    totalTaxRestaurant,
    serviceFeeMember,
    serviceFeeNonMember,
    deliveryFeeMember,
    deliveryFeeNonMember,
    isExpress,
    doordashComparables,
  } = cartTotalDetails;
  return {
    ...cart,
    user: cart?.user || currentUser?.user,
    userGroup: currentUser?.user?.userGroup,
    sum: cartTotalDetails.subtotal,
    serviceFee: cartTotalDetails.serviceFee,
    deliveryFee: cartTotalDetails.deliveryFee,
    total: cartTotalDetails.total,
    tax: cartTotalDetails.tax,
    discount: cartTotalDetails.couponDiscount,
    due: cartTotalDetails.due,
    credit: cartTotalDetails.creditDiscount,
    tips: cartTotalDetails.tip,
    tipCustom: cartTotalDetails.tipCustom,
    tipRate: cartTotalDetails.tipRate,
    couponCode,
    totalNotEnoughForCoupon: minTotalForCoupon,
    companyBudget: 0,
    companyPay: 0,
    memberSaving,
    subtotalMember,
    subtotalNonMember,
    subtotalRestaurant,
    totalTaxMember,
    totalTaxNonMember,
    totalTaxRestaurant,
    serviceFeeMember,
    serviceFeeNonMember,
    deliveryFeeMember,
    deliveryFeeNonMember,
    isExpress,
    doordashComparables,
  };
};

export const orderGoodsToCartGoods = (orderGoods) => {
  LOGGING && console.log("orderGoodsToCartGoods got:", orderGoods);
  return orderGoods.reduce((cartGoods, g) => {
    const { dish, selections, price, quantity, comment, addedBy } = g;
    const goodsKey = getGoodsKey(cartGoods, dish);
    cartGoods[goodsKey] = {
      dish,
      selections,
      price,
      quantity,
      comment,
      addedBy,
    };
    return cartGoods;
  }, {});
};
