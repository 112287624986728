import * as Components from ".";

const Company43 = ({ onClick }) => {
  const linkLunch = "/meal/02-14-2025/lunch/43-company";
  const image =
    "https://cdn.shopify.com/s/files/1/1585/6295/products/Valentine_s2023MiniDessertSampler1_1024x1024.jpg?v=1675099464";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>43 & Co.</Components.RestaurantName>
        <Components.MenuName>Valentine's Day Special</Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Pre-Order by&nbsp;<b>Wednesday, Feb 12</b>.
          </Components.DeliveryTime>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>12-1P Friday, Valentine's Day</b>&nbsp;
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                V DAY MINI DESSERT SAMPLER (12)
              </Components.DishName>
              <Components.AlaCartePrice>$64</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Celebrate Valentine's Day with our Special Edition Mini Dessert
              Sampler. Each box comes with 2ea of 6 different assortments: 2ea
              Mini Fruit Tart 2ea Mini Lemon Tart 2ea Mini Vanilla Cream Puff
              2ea Mini Heart-Shaped Lychee-Champagne Tart 2ea Mini
              Hazelnut-Espresso Cake 2ea Mini Chocolate Cake
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
            disabled={true}
          >
            sold out
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Company43;
