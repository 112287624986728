import { capitalizeWords } from "src/constants";
import RestaurantsSlideShow from "src/screens/shared/RestaurantsSlideShow";
import styled from "styled-components";
import { cuisineRestaurants, stamps, getEssay } from "./AdsRestaurants";
import {
  BackgroundPink,
  BorderGrey,
  card,
  CenteredByColumn,
  textTitle,
  TextUPPER,
  thinShadow,
} from "../../Shared";

const Container = styled(CenteredByColumn)`
  background-color: ${BackgroundPink};
  width: 100vw;
  border-bottom: 1px solid ${BorderGrey};
  margin-bottom: 1px;
  background-color: white;
  z-index: 1;
`;
const Content = styled(CenteredByColumn)`
  background-color: white;
  ${thinShadow}
  padding: 15px;
  width: calc(100vw - 40px);
  /* border: 1px solid ${BorderGrey}; */
  position: relative;
  top: -10vh;
  margin-bottom: calc(-15vh + 80px);
  z-index: 1;
  p {
    ${textTitle}
    /* font-family: "DFKai-SB", "BiauKai", "Kai", "Kaiti", "Noto Serif Hong Kong", "serif"; */
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    text-transform: none;
    text-align: center;
    margin-block-end: 30px;
    margin-block-start: 0px;
  }
  @media screen and (min-width: 500px) {
    width: 70vw;
    max-width: 700px;
    margin-bottom: 0px;
  }
`;

const Border = styled(CenteredByColumn)`
  border: 2px solid #c43d2a;
  width: 100%;
  padding: 40px 30px 25px 30px;
  @media screen and (min-width: 500px) {
    padding: 40px 50px 25px 50px;
  }
`;
const Welcome = styled(TextUPPER)`
  /* font-family: "DFKai-SB", "BiauKai", "Kai", "Kaiti", "Noto Serif Hong Kong", "serif"; */
  text-align: center;

  margin-bottom: 30px;
`;

const ChineseWelcome = styled(TextUPPER)`
  font-family: "DFKai-SB", "BiauKai", "Kai", "Kaiti", "Noto Serif Hong Kong",
    "serif";
  font-size: 34px;
  text-align: center;
  margin-bottom: 30px;
`;

const ChineseParagraph = styled.p`
  font-family: "DFKai-SB", "BiauKai", "Kai", "Kaiti", "Noto Serif Hong Kong",
    "serif" !important;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  text-transform: none;
  text-align: center;
  margin-block-end: 30px;
  margin-block-start: 0px;
`;
const StampImage = styled.img<{
  width: number;
  height: number;
  bottom: number;
  left: number;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: absolute;
  z-index: 100;
  bottom: ${(props) => props.bottom}px;
  left: ${(props) => props.left}px;
  object-fit: fill;
  transform: rotate(-18deg);
`;
const GuestWelcomeMessageByCuisine = ({
  cuisine,
  multiCuisine,
  lan,
}: {
  cuisine: string;
  multiCuisine: string;
  lan: string;
}) => {
  const multiCuisineNames = multiCuisine?.split("-") || [];
  const cuisines = cuisine
    ? Array.from(new Set([cuisine, ...multiCuisineNames]))
    : multiCuisineNames;
  const essay = getEssay(cuisines, lan);
  console.log("GuestWelcomeMessageByCuisine got lan:", lan);
  return (
    <Container>
      {/* <HeroImage src={hero} alt="hero" /> */}
      {/* <ImageOverlay /> */}
      <RestaurantsSlideShow
        restaurants={
          cuisineRestaurants?.[cuisine] || cuisineRestaurants.general
        }
        width={"100vw"}
        height={"60vh"}
      />
      <Content>
        {cuisine && stamps?.[cuisine] ? (
          <StampImage
            width={stamps?.[cuisine]?.width}
            height={stamps?.[cuisine]?.height}
            bottom={stamps?.[cuisine]?.bottom}
            left={stamps?.[cuisine]?.left}
            src={stamps?.[cuisine]?.image}
            alt="stamp"
          />
        ) : (
          <StampImage
            width={stamps?.general?.width}
            height={stamps?.general?.height}
            bottom={stamps?.general?.bottom}
            left={stamps?.general?.left}
            src={stamps?.general?.image}
            alt="stamp"
          />
        )}
        <Border>
          {lan && lan !== "english" ? (
            <>
              <ChineseWelcome>{essay.welcome}</ChineseWelcome>
              <ChineseParagraph>{essay.p1}</ChineseParagraph>
              <ChineseParagraph>{essay.p2}</ChineseParagraph>
              <ChineseParagraph>{essay.p3}</ChineseParagraph>
            </>
          ) : (
            <>
              <Welcome>{essay.welcome}</Welcome>
              <p>{essay.p1}</p>
              <p>{essay.p2}</p>
              <p>{essay.p3}</p>
            </>
          )}
        </Border>
      </Content>
    </Container>
  );
};

export default GuestWelcomeMessageByCuisine;
