import "./Close.scss";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Close = ({ onClick, customeStyle = {} }) => (
  <button
    className="close-button"
    type="button"
    onClick={onClick}
    style={{ ...customeStyle }}
  >
    <FontAwesomeIcon icon={faTimes} />
  </button>
);
export default Close;
