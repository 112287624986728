import { faChild, faLeaf, faGlass } from "@fortawesome/pro-solid-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Blue, centered, Centered, Gold, text12300 } from "../../Shared";

const Container = styled(Centered)`
  border: 1px solid white;
  padding: 0px 3px;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 5px;
  svg {
    color: white;
    margin-right: 3px;
    font-size: 11px;
  }
  span {
    ${text12300};
    line-height: 18px;
    color: white;
    font-size: 11px;
    font-weight: 500;
    text-transform: capitalize;
  }
`;

export const KidsMenuLogo = () => {
  return (
    <Container>
      <FontAwesomeIcon icon={faChild} />
      <span>kids</span>
    </Container>
  );
};

export const DrinksMenuLogo = () => {
  return (
    <Container>
      <FontAwesomeIcon icon={faGlass} />
      <span>drinks</span>
    </Container>
  );
};

export const VegetarianMenuLogo = () => {
  return (
    <Container>
      <FontAwesomeIcon icon={faLeaf} />
      <span>VG</span>
    </Container>
  );
};
const numberHeight = 18;
const TriedContainer = styled(Container)`
  ${centered}
  margin: 5px 0px 0px 0px;
  //green with a little bit of transparency
  background-color: rgb(255, 191, 0, 0.95);
  /* opacity: 0.2; */
  border: none;
  height: 26px;
  padding: 0px 8px 2px 8px;
  border-radius: 8px;
  label,
  span {
    /* color: white; */
    font-size: 12px;
    line-height: 12px;
    text-transform: none;
    font-weight: 500;
    color: black;
  }
  label {
    width: ${numberHeight}px;
    height: ${numberHeight}px;
    line-height: ${numberHeight}px;
    background-color: black;
    /* border : 1px solid black; */
    color: white;
    border-radius: 50%;
    text-align: center;
    margin-left: 5px;
    font-weight: 500;
    font-size: 10px;
  }
  svg {
    /* color: white; */
    font-size: 11px;
    margin-right: 5px;
    color: black;
  }
  @media screen and (min-width: 1200px) {
    margin: 0px;
    label,
    span {
      font-weight: 500;
    }
  }
`;

export const TriedLogo = ({ number }) => {
  return (
    <TriedContainer>
      <FontAwesomeIcon icon={faCheck} />
      {/* <span>Tried {number>2?`${number} times`:number>1?"twice":"once"}</span> */}
      <span>Tried</span>
      <label>{number > 5 ? "5+" : number}</label>
    </TriedContainer>
  );
};
