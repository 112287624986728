import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useSelector, useDispatch, useStore } from "react-redux";
import { RootState } from "src/store";
import { getPaymentIntentDetails } from "src/store/actions";
import styled from "styled-components";
import { CenteredByColumn } from "./components/Shared";

const stripePromise = loadStripe("pk_test_9sHP773itP5XmkeoKv6ops4q00ehslfaIP");

const Container = styled(CenteredByColumn)`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (error) {
      setErrorMessage(error.message);
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} id="payment-form">
      <PaymentElement />
      <button disabled={isLoading || !stripe || !elements}>
        {isLoading ? "Processing..." : "Pay now"}
      </button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const PageCheckoutV2 = () => {
  const cart = useSelector((state: RootState) => state.carts?.[0]);
  const currentUser = useSelector((state: RootState) => state.currentUser.user);
  const rootState = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const store = useStore();
  const [paymentIntentDetails, setPaymentIntentDetails] = useState(null);
  const [client_secret, setClient_secret] = useState(null);

  useEffect(() => {
    if (cart) {
      console.log("PageCheckoutV2 cart", cart);
      const paymentIntentId = cart.paymentIntentId;
      getPaymentIntentDetails(paymentIntentId)(dispatch, store.getState).then(
        (res) => {
          console.log("PageCheckoutV2 res", res);
          setPaymentIntentDetails(res);
          setClient_secret(res.client_secret);
        }
      );
    }
    if (currentUser) {
      console.log("PageCheckoutV2 currentUser", currentUser);
    }
    console.log("PageCheckoutV2 rootState", rootState);
  }, [cart, currentUser, rootState]);
  const appearance = {
    theme: "stripe" as const,
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "system-ui, sans-serif",
      spacingUnit: "4px",
      borderRadius: "4px",
    },
  };

  const options: StripeElementsOptions = {
    // clientSecret: client_secret as string,
    appearance,
    // amount: paymentIntentDetails?.amount,
    // mode: "payment" as const,
    // currency: paymentIntentDetails?.currency
    mode: "payment",
    amount: 1099,
    currency: "usd",
    paymentMethodCreation: "manual",
  };

  return (
    <Container>
      <h1>Checkout</h1>
      {/* {client_secret ? ( */}
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
      {/* ) : (
            <p>Loading payment info...</p>
    ) */}
    </Container>
  );
};

export default PageCheckoutV2;
