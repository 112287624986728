import React from "react";
import "./Nav.scss";
import {
  faUserCircle,
  faMapMarkedAlt,
  faReceipt,
  faCalendarEdit,
  faUtensilsAlt,
  faHome,
  faTrophyAlt,
  faRoute,
  faMailBulk,
  faUsers,
  faChartBar,
  faCog,
  faMoneyCheckAlt,
  faCars,
  faCalculatorAlt,
  faTrophy,
} from "@fortawesome/pro-light-svg-icons";
import {
  faBars,
  faChevronDown,
  faChevronUp,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useUserType } from "src/hooks/useUserType";
import { CartThumbnail, LOGGING, PopUp, Change } from "./";
import { NavbarPopUp } from "./NavbarPopUp";

const ByDay = ({
  onToggleShowViewSelections,
  showViewSelections,
  selectedOption,
  viewableOptions,
  onSelectViewByDay,
  onSelectViewMode,
  viewByWeek,
  onViewByWeekEmptyCart,
  cartIsFull,
  cartTime,
  disabled,
}) => {
  const dates = Object.keys(viewableOptions);

  return (
    <div className="by-day">
      <button
        className="view-mode selected"
        onClick={
          cartIsFull && !showViewSelections
            ? onViewByWeekEmptyCart.bind(this, cartTime)
            : onToggleShowViewSelections
        }
        disabled={disabled}
      >
        <span>
          {viewByWeek ? /*dateRangeSelected*/ "upcoming" : selectedOption}
        </span>
        <FontAwesomeIcon
          icon={showViewSelections ? faChevronUp : faChevronDown}
        />
      </button>

      <PopUp
        isPoppedUp={showViewSelections}
        componentToDisplay={
          <ul className="view-day-selections">
            <li className={viewByWeek ? "selected" : ""}>
              <button
                onClick={
                  cartIsFull && !viewByWeek
                    ? onViewByWeekEmptyCart?.bind(this, cartTime)
                    : onSelectViewMode?.bind(this, true)
                }
                // disabled={!dishesLoaded}
              >
                <span>
                  {/*dateRangeRow*/} {`upcoming`}
                </span>
                {viewByWeek ? <FontAwesomeIcon icon={faCheck} /> : null}
              </button>
            </li>
            {Object.keys(viewableOptions || {}).map((day, index) => (
              <li
                key={index}
                className={
                  !viewByWeek && day === selectedOption ? "selected" : ""
                }
              >
                <button
                  onClick={
                    cartIsFull && !viewByWeek
                      ? onViewByWeekEmptyCart.bind(this, cartTime)
                      : onSelectViewByDay.bind(this, day)
                  }
                >
                  <span>{day}</span>
                  {!viewByWeek && day === selectedOption ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : null}
                </button>
              </li>
            ))}
          </ul>
        }
        hidePopUp={onToggleShowViewSelections}
        backgroundColor="transparent"
      />
    </div>
  );
};

const ViewMode = ({
  onSelectViewMode,
  viewByWeek,
  showViewSelections,
  selectedOption,
  viewableOptions,
  onToggleShowViewSelections,
  onSelectViewableOption,
  cartIsFull,
  onViewByWeekEmptyCart,
  cartTime,
  deliveryZoneByCity,
  onChangeDeliveryCity,
  selectedDeliveryCity,
  onToggleShowDeliveryCityOptions,
  showDeliveryCityOptions,
  dishesLoaded,
}) => {
  LOGGING &&
    console.log("ViewMode rendering with :", {
      showDeliveryCityOptions,
      selectedDeliveryCity,
      onToggleShowDeliveryCityOptions,
      showViewSelections,
      deliveryZoneByCity,
    });
  return (
    <div className="select-view-mode">
      {selectedDeliveryCity ? (
        <Change
          options={deliveryZoneByCity.map((d) => d.city)}
          onChange={onChangeDeliveryCity}
          selected={selectedDeliveryCity}
          showOptions={showDeliveryCityOptions}
          onToggleShowOptions={onToggleShowDeliveryCityOptions}
          className="change-city"
          disabled={!dishesLoaded}
        />
      ) : null}
      <ByDay
        showViewSelections={showViewSelections}
        onToggleShowViewSelections={onToggleShowViewSelections}
        selectedOption={selectedOption}
        viewableOptions={viewableOptions}
        onSelectViewByDay={onSelectViewableOption}
        onSelectViewMode={onSelectViewMode}
        viewByWeek={viewByWeek}
        cartIsFull={cartIsFull}
        onViewByWeekEmptyCart={onViewByWeekEmptyCart}
        cartTime={cartTime}
        disabled={!dishesLoaded}
      />
    </div>
  );
};

const Nav = ({
  cart,
  onToggleUserMenu,
  showUserMenu,
  onSignOut,
  currentUser,
  numberOfItems,
  noShadow,
  driverView,
  marketingEmailTemplate,
  onToggleShowViewSelections,
  showViewSelections,
  selectedOption,
  viewableOptions,
  onSelectViewableOption,
  onSelectViewMode,
  viewByWeek,
  onViewByWeekEmptyCart,
  cartIsFull,
  dishesLoaded,
  deliveryZoneByCity,
  onChangeDeliveryCity,
  selectedDeliveryCity,
  onToggleShowDeliveryCityOptions,
  showDeliveryCityOptions,
  showAdminQuickLinks,
  onToggleShowAdminQuickLinks,
}) => {
  const userType = useUserType();
  LOGGING &&
    console.log("Nav rendering with: ", {
      userType,
      showViewSelections,
      selectedDeliveryCity,
    });
  const isHome =
    !window.location.pathname.includes("calendar") &&
    !window.location.pathname.includes("accounting") &&
    !window.location.pathname.includes("drivers") &&
    !window.location.pathname.includes("current") &&
    !window.location.pathname.includes("users") &&
    !window.location.pathname.includes("nominations") &&
    !window.location.pathname.includes("stats") &&
    !window.location.pathname.includes("restaurant-directory") &&
    // !window.location.pathname.includes('restaurant-map') &&
    !window.location.pathname.includes("routes") &&
    !window.location.pathname.includes("couponCodes") &&
    !window.location.pathname.includes("config") &&
    !window.location.pathname.includes("delivery");
  LOGGING && console.log("nav rendering: ", { isHome, currentUser });
  return (
    <nav
      className={`${noShadow ? "no-shadow" : ""} ${
        currentUser.isAuthenticated && currentUser.user.isAdmin
          ? "admin-view"
          : ""
      } ${
        currentUser.isAuthenticated && currentUser.user.isOps ? "ops-view" : ""
      } ${
        currentUser.isAuthenticated && currentUser.user.isMarketer
          ? "marketer-view"
          : currentUser.isAuthenticated && currentUser.user.isRestaurant
          ? "restaurant-view"
          : ""
      }`}
    >
      <div className="nav-content">
        <button className="user" type="button" onClick={onToggleUserMenu}>
          <FontAwesomeIcon
            icon={currentUser.isAuthenticated ? faUserCircle : faBars}
          />
        </button>

        {driverView ? (
          <div className="nav-title">
            <div className="driver-title">
              <span>Thanks, </span>
              <span className="driver-name">{currentUser.user.firstName}!</span>
            </div>
          </div>
        ) : (
          <React.Fragment>
            {currentUser.isAuthenticated &&
            (currentUser.user.isDriver ||
              currentUser.user.isOps ||
              currentUser.user.isRestaurant ||
              currentUser.user.isMarketer ||
              (currentUser.user.isAdmin && !isHome)) ? null : (
              <ViewMode
                viewByWeek={viewByWeek}
                onToggleShowViewSelections={onToggleShowViewSelections}
                showViewSelections={showViewSelections}
                selectedOption={selectedOption}
                viewableOptions={viewableOptions}
                onSelectViewableOption={onSelectViewableOption}
                cartIsFull={cartIsFull}
                onViewByWeekEmptyCart={onViewByWeekEmptyCart}
                cartTime={cart && cart.meal ? cart.meal.windowStart : 0}
                dishesLoaded={dishesLoaded}
                deliveryZoneByCity={deliveryZoneByCity}
                onChangeDeliveryCity={onChangeDeliveryCity}
                selectedDeliveryCity={selectedDeliveryCity}
                onToggleShowDeliveryCityOptions={
                  onToggleShowDeliveryCityOptions
                }
                showDeliveryCityOptions={showDeliveryCityOptions}
                onSelectViewMode={onSelectViewMode}
              />
            )}

            {currentUser.user.isAdmin ? (
              <div
                className={`admin-quick-access
                 ${showAdminQuickLinks ? "show" : "hide"}`}
              >
                <button
                  className="toggle-quick-access"
                  onClick={onToggleShowAdminQuickLinks}
                >
                  {`${showAdminQuickLinks ? "hide" : "show"} nav`}
                </button>
                {showAdminQuickLinks && (
                  <div className="quick-access-container">
                    <Link
                      to="/"
                      className={`${
                        !window.location.pathname.includes("calendar") &&
                        !window.location.pathname.includes("drivers") &&
                        !window.location.pathname.includes("current") &&
                        !window.location.pathname.includes("users") &&
                        !window.location.pathname.includes("leaders") &&
                        !window.location.pathname.includes("nominations") &&
                        !window.location.pathname.includes("stats") &&
                        !window.location.pathname.includes(
                          "restaurant-directory"
                        ) &&
                        !window.location.pathname.includes("routes") &&
                        !window.location.pathname.includes("couponCodes") &&
                        !window.location.pathname.includes("config") &&
                        !window.location.pathname.includes("delivery")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faHome} />
                      <span>home</span>
                    </Link>
                    <Link
                      to="/calendar"
                      className={`${
                        window.location.pathname.includes("calendar")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faCalendarEdit} />
                      <span>calendar</span>
                    </Link>
                    {/* <Link
                      to="/accounting"
                      className={`${
                        window.location.pathname.includes("accounting")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faCalculatorAlt} />
                      <span>accounting</span>
                    </Link> */}
                    <Link
                      to="/delivery_v2"
                      className={`${
                        window.location.pathname.includes("delivery")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faMapMarkedAlt} />
                      <span>delivery</span>
                    </Link>
                    <Link
                      to="/current"
                      className={`${
                        window.location.pathname.includes("current")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faReceipt} />
                      <span>orders</span>
                    </Link>
                    <Link
                      to="/restaurant-directory"
                      className={`${
                        window.location.pathname.includes(
                          "restaurant-directory"
                        )
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faUtensilsAlt} />
                      <span>restaurants</span>
                    </Link>
                    {/* <Link
                      to="/nominations"
                      className={`${
                        window.location.pathname.includes("nominations")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faTrophyAlt} />
                      <span>nominations</span>
                    </Link> */}
                    {/* <Link
                      to="/stats"
                      className={`${
                        window.location.pathname.includes("stats")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faChartBar} />
                      <span>stats</span>
                    </Link> */}
                    <Link
                      to="/routes"
                      className={`${
                        window.location.pathname.includes("routes")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faRoute} />
                      <span>Routes</span>
                    </Link>
                    <Link
                      to="/users"
                      className={`${
                        window.location.pathname.includes("users")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faUsers} />
                      <span>Users</span>
                    </Link>
                    <Link
                      to="/leaders"
                      className={`${
                        window.location.pathname.includes("leaders")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faTrophy} />
                      <span>Leaders</span>
                    </Link>
                    <Link
                      to="/drivers"
                      className={`${
                        window.location.pathname.includes("drivers")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faCars} />
                      <span>drivers</span>
                    </Link>
                    <Link
                      to="/couponCodes"
                      className={`${
                        window.location.pathname.includes("couponCodes")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faMoneyCheckAlt} />
                      <span>Coupons</span>
                    </Link>
                    {/* <a href={`${marketingEmailTemplate}`}>
                    <FontAwesomeIcon icon={faMailBulk} />
                    <span>News</span>
                  </a> */}
                    <Link
                      to="/config"
                      className={`${
                        window.location.pathname.includes("config")
                          ? "selected"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faCog} />
                      <span>Config</span>
                    </Link>
                  </div>
                )}
              </div>
            ) : currentUser.user.isOps ? (
              <div className="admin-quick-access">
                <div className="quick-access-container">
                  <Link
                    to="/"
                    className={`${
                      !window.location.pathname.includes("current") &&
                      !window.location.pathname.includes("restaurant-directory")
                        ? "selected"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faHome} />
                    <span>home</span>
                  </Link>
                  <Link
                    to="/current"
                    className={`${
                      window.location.pathname.includes("current")
                        ? "selected"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faReceipt} />
                    <span>orders</span>
                  </Link>
                  <Link
                    to="/restaurant-directory"
                    className={`${
                      window.location.pathname.includes("restaurant-directory")
                        ? "selected"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faUtensilsAlt} />
                    <span>restaurants</span>
                  </Link>
                </div>
              </div>
            ) : currentUser.user.isRestaurant ? (
              <div className="nav-title">
                <div className="restaurant-title">
                  <span>{currentUser?.user?.restaurant?.name}</span>
                </div>
              </div>
            ) : (
              <div className="admin-quick-access">
                <div className="quick-access-container">
                  <Link
                    to="/orders"
                    // className={`${window.location.pathname.includes('current') ? 'selected' : ''}`}
                  >
                    <FontAwesomeIcon icon={faReceipt} />
                    <span>orders</span>
                  </Link>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        <NavbarPopUp
          showUserMenu={showUserMenu}
          onSignOut={onSignOut}
          onToggleUserMenu={onToggleUserMenu}
          userType={userType}
        />
      </div>
    </nav>
  );
};

export default Nav;
