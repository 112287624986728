import { useEffect, useState } from "react";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { TrackAdsEvent } from "src/api/tracker";
import { LOGGING } from "src/constants";
import { BreakPoint } from "src/screens/tasting_menu/components/customers/mealMenu/MealHeader";
import { RootState } from "src/store";
import { resumeMembership } from "src/store/actions";
import styled from "styled-components";
import { Field } from "./Contact";
import { centered, Gold, BorderGrey, text12300, NavHeight } from "../../Shared";
const ResumeMembershipButton = styled.button`
  ${centered}
  align-self: stretch;
  justify-content: flex-start;
  padding: 20px;
  background-color: ${Gold};
  text-transform: none;
  margin: 0px 0px 20px 0px;
  ${text12300}
  font-size: 14px;
  font-weight: 400;
  padding: 15px 30px;
  b {
    font-weight: 700;
  }
  svg {
    color: black;
    margin: 0px;
  }
  &:disabled {
    background-color: ${BorderGrey};
  }
`;

const StartMembershipLink = styled(Link)`
  ${centered}
  align-self: stretch;
  justify-content: flex-start;
  padding: 20px;
  background-color: ${Gold};
  /* background-color: pink; */
  text-transform: none;
  margin: 0px 0px 20px 0px;
  ${text12300}
  font-size: 14px;
  font-weight: 400;
  padding: 15px 30px;
  /* position: absolute; */
  /* top: -${NavHeight}px; */
  height: ${NavHeight}px;
  /* flex-grow: 1; */
  width: 100%;
  box-sizing: border-box;
  b {
    font-weight: 700;
  }
  svg {
    color: black;
    margin: 0px;
    font-size: 20px;
  }
  @media screen and (max-width: ${BreakPoint}px) {
    position: fixed;
    top: ${NavHeight}px;
    /* bottom: 80px; */
    left: 0px;
    margin: 0px;
    width: 100vw;
    z-index: 100;
    padding: 15px 20px;
    box-sizing: border-box;
    /* box-shadow: 0 2px 3px 0px rgba(28, 29, 33, 0.15); */
    svg {
      /* display: block !important;; */
      font-size: 20px;
    }
    span {
      font-size: 16px !important;
    }
  }
`;
export const ToSaveByMembershipOnCheckout = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const pixel = useSelector((state: RootState) => state.pixel);
  const [resuming, setResuming] = useState(false);
  const [wasMember, setWasMember] = useState(false);
  const dispatch = useDispatch();
  const store = useStore();
  LOGGING &&
    console.log(
      "ToSaveBy rendering with doordash comparables:",
      cart?.doordashComparables?.nonMemberSaving
    );
  useEffect(() => {
    if (currentUser?.user?.membership?.canceled_at) {
      setWasMember(true);
    }
  }, [currentUser]);
  const onResume = (e) => {
    e.preventDefault();
    setResuming(true);
    resumeMembership()(dispatch, store.getState).then(() => {
      setResuming(false);
    });
  };
  const memberSavingLessTip =
    cart?.memberSaving - cart?.doordashComparables?.memberSaving?.tipSaving ||
    0 ||
    0;
  if (wasMember) {
    return (
      <ResumeMembershipButton onClick={onResume} disabled={resuming}>
        <Field>
          {resuming ? (
            <span>Resuming your membership...</span>
          ) : (
            <span>
              Resume membership to save <b>${memberSavingLessTip.toFixed(2)}</b>
              {`${
                cart?.doordashComparables?.nonMemberSaving?.totalSaving > 0
                  ? " more"
                  : ""
              }`}
              .
            </span>
          )}
          {resuming ? null : <FontAwesomeIcon icon={faChevronRight} />}
        </Field>
      </ResumeMembershipButton>
    );
  }
  return (
    <StartMembershipLink
      to={{
        pathname: "/membership",
        state: { treatPath: "/checkout" },
      }}
      replace={true}
      onClick={() => {
        // Insert Facebook Pixel tracking here
        // @ts-ignore
        if (window.fbq && pixel?.fbclid) {
          LOGGING &&
            console.log("page checkout to membership click is tracked by fbq");
          // @ts-ignore
          window.fbq("track", "ViewContent", {
            content_name: `${cart.meal?.restaurant?.name} checkout to membership`,
            content_category: "pageCheckout",
            value: cart?.total,
            currency: "USD",
          });

          // Track Ads Event
          TrackAdsEvent(pixel?.fbclid, {
            source: pixel?.source,
            eventCode: "ViewContent",
            page: "pageCheckout",
            contentName: `${cart.meal?.restaurant?.name} checkout to membership`,
            orderId: cart?._id,
          });
        }
      }}
    >
      <Field>
        <span>
          {wasMember ? "Resume" : "Start"} membership to save{" "}
          <b>${memberSavingLessTip.toFixed(2)}</b>
          {`${
            cart?.doordashComparables?.nonMemberSaving?.totalSaving > 0
              ? " more"
              : " now"
          }`}
          .
        </span>
        <FontAwesomeIcon icon={faChevronRight} />
      </Field>
    </StartMembershipLink>
  );
};
