import React, { useState } from "react";
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getItemNumberInCart, LOGGING } from "src/constants";
import { GetMealLink } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import { Container, Field, Title } from "./Contact";
import {
  ActionGrey,
  ActionUnderline,
  centered,
  Centered,
  CenteredByColumn,
  text12300,
  TextTitle,
} from "../../Shared";
import { CartDetailsViewOnly } from "../carts/CartDetails";
import { ToggleCartDetails } from "../carts/CheckoutThumbnail";
import { BreakPoint } from "../mealMenu/MealHeader";
const ToggleCartSummary = styled.button<{ rotated?: boolean }>`
  ${centered}
  svg {
    font-size: 20px;
    margin: 0px;
    transition: transform 0.3s ease;
    transform: ${({ rotated }) => (rotated ? "rotate(90deg)" : "rotate(0deg)")};
  }
  @media screen and (max-width: ${BreakPoint}px) {
    margin: 0px;
    background-color: white;
    color: black;
    /* border: 1px solid black; */
    padding: 0px 10px;
    border-radius: 0px;
    width: 50px;
    ${centered}
    justify-content: flex-end;
    svg {
      font-size: 20px;
    }
  }
`;
const DetailsContainer = styled(CenteredByColumn)`
  align-self: stretch;
  position: relative;
  padding-top: 50px;
`;
const EditCart = styled(ActionGrey)`
  /* padding: 0px; */
  ${text12300}
  text-transform: capitalize;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  svg {
    margin-right: 7px;
  }
`;
export const CartSummary = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [showDetails, setShowDetails] = useState(false);
  const history = useHistory();
  const onToggleDetails = (e) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };
  const onEditCart = (e) => {
    e.preventDefault();
    const mealLink = GetMealLink(cart.meal);
    LOGGING && console.log("onEditCart mealLink:", mealLink);
    history.push({
      pathname: `/${mealLink}`,
    });

    // history.push({
    //   pathname: `/${mealLink}`,
    //   state: { showCartOnMeal: true }
    // });
  };
  LOGGING &&
    console.log("CartSummary rendering with showDetails:", showDetails);
  return (
    <Container>
      <TextTitle style={{ marginBottom: "40px", fontSize: "23px" }}>
        Summary
      </TextTitle>
      <Field>
        <label>cart ({getItemNumberInCart(cart)} items)</label>

        <ToggleCartSummary
          onClick={onToggleDetails}
          rotated={showDetails}
          // style={{ margin: "0px !important" }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </ToggleCartSummary>
      </Field>
      {showDetails && (
        <DetailsContainer>
          <EditCart onClick={onEditCart}>
            <FontAwesomeIcon icon={faPlus} />
            <span>Add More Items</span>
          </EditCart>
          <CartDetailsViewOnly
            show={showDetails}
            isMember={currentUser?.user?.membership?.isActive}
          />
        </DetailsContainer>
      )}
    </Container>
  );
};
