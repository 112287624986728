import { useRef, useEffect, useState } from "react";
import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { set, upperCase } from "lodash";
import posthog from "posthog-js";
import { useStore, useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { TrackAdsEvent } from "src/api/tracker";
import { LOGGING } from "src/constants";
import { RootState } from "src/store";
import {
  loadClickID,
  readTopRestaurants,
  readTopRestaurantUserStats,
  setRestaurantStarRating,
  setRestaurantReview,
} from "src/store/actions";
import styled from "styled-components";
import { BackToTop } from "./components/customers/mealMenu/BackToTop";
import SharePage from "./components/referrals/SharePage";
import GuideFilters from "./components/restaurants/GuideFilters";
import RestaurantCountWithProgress from "./components/restaurants/RestaurantCountWithProgress";
import RestaurantThumbnail, {
  AwardContainer,
  RestaurantThumbnailEmpty,
} from "./components/restaurants/RestaurantThumbnail";
import {
  Centered,
  CenteredByColumn,
  textTitle,
  ALLCAPTEXT,
  CenteredByColumnLeft,
  Beige,
  text12300,
  BorderGrey,
  Text12300,
  NavHeight,
  thinShadow,
  centered,
  BackgroundGrey,
  centeredByColumn,
  card,
  TextTitle,
  TextGrey,
  upper14500,
} from "./components/Shared";
import { Back, Loading } from "../shared";
import BackOrHome from "../shared/BackOrHome";
interface LocationState {
  homeScrollY?: number;
  fromRestaurantId?: string | null;
  // Add other state properties as needed
}
const breakPoint = 800;
const ContentContainer = styled(CenteredByColumn)`
  background-color: ${Beige};
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: ${NavHeight}px;
  > button {
    z-index: 100;
  }
  @media screen and (min-width: ${breakPoint}px) {
    padding-left: 7vw;
    padding-right: 7vw;
  }
`;

const List = styled(CenteredByColumn)<{ scrollY?: number }>`
  /* flex-wrap: wrap; */
  padding: 10px 20px;
  align-items: center;
  width: 100%;
  /* transform: ${(props) =>
    props.scrollY ? `translateY(-${props.scrollY}px)` : "none"}; */
  @media screen and (min-width: ${breakPoint}px) {
    max-width: 500px;
    align-items: center;
  }
`;
const LoadingText = styled(Text12300)``;
const Header = styled(Centered)`
  ${textTitle}
  /* padding-top: 30px;
  padding-bottom: 10px; */
  background-color: transparent;
  @media screen and (min-width: ${breakPoint}px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;
const SubHeader = styled(Centered)`
  ${ALLCAPTEXT}
  font-size: 13px;
  margin-bottom: 20px;
`;

const Essay = styled(CenteredByColumnLeft)`
  padding: 0px 20px;
  max-width: 500px;
  p {
    ${text12300}
    max-width: 800px;
    line-height: 1.8;
    /* text-align: center; */
    /* margin: 10px 0px; */
  }
`;

const Nav = styled(CenteredByColumn)`
  width: 100%;
  /* max-width: 700px; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: white;
  height: ${NavHeight}px;
  @media screen and (min-width: ${breakPoint}px) {
    padding: 0px calc(50vw - 350px + ${NavHeight}px);
    border-bottom: 1px solid ${BorderGrey};
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
  }
`;
const Nav2 = styled(CenteredByColumn)`
  width: 100%;
  /* align-items: flex-start; */
  /* padding-right: 10px; */
  padding: 10px 40px;
  position: sticky;
  /* top: ${NavHeight}px; */
  top: 0px;
  z-index: 4;
  background-color: white;
  border-bottom: 1px solid ${BorderGrey};
  border-top: 1px solid ${BorderGrey};
  background-color: white;
  /* align-items: stretch; */
  /* max-width: 500px; */
  @media screen and (min-width: ${breakPoint}px) {
    /* ${card} */
    border-top: none;
    border-bottom: none;
    height: ${NavHeight}px;
    position: fixed;
    left: calc(50vw + 230px);
    /* width: auto; */
    flex-grow: 0;
    /* flex-direction: row; */
    align-items: center;
    /* padding: 20px 20px 20px 0px; */
    background-color: transparent;
    top: ${NavHeight + 30}px;
    /* left: calc(50vw + 350px); */
    /* background-color: white; */
    width: calc(50vw - 250px);
    max-width: 250px;
    height: 120px;
    /* align-items: flex-start; */
    padding: 0px;
    /* ${thinShadow}
    border-radius: 10px;
    padding: 20px; */
  }
`;

const Filters = styled(Centered)`
  /* justify-content: flex-end; */
  flex-grow: 1;
  margin: 0px 10px;
  margin-top: 10px;
  /* align-self: stretch;   */
  max-width: 500px;
  /* align-self: auto; */
  button:first-child {
    margin-right: 10px;
  }
  @media screen and (min-width: ${breakPoint}px) {
    /* margin-top: 0px; */
    flex-direction: column;

    button:first-child {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
`;

const Filter = styled.button<{ isOn?: boolean }>`
  ${centered}
  justify-content: space-between;
  border: 1px solid ${(props) => (props.isOn ? "black" : BorderGrey)};
  background-color: ${(props) => (props.isOn ? "black" : "transparent")};
  color: ${(props) => (props.isOn ? "white" : "black")};
  border-radius: 5px;
  padding: 8px;
  align-self: stretch;
  flex-grow: 1;
  position: relative;
  padding-left: 22px;
  width: 140px;
  svg {
    position: absolute;
    left: 5px;
    font-size: 10px;
  }
  span {
    ${text12300}
    font-weight: 400;
    font-size: 11px;
    line-height: 1;
    margin-right: 8px;
    /* margin-top: 8px; */
  }
  label {
    ${text12300}
    font-weight: 500;
    font-size: 11px;
    line-height: 1;
    /* font-size: 12px;
    font-weight: bold;
    color: black; */
  }
  @media screen and (min-width: ${breakPoint}px) {
    /* flex-grow: 0; */
    /* align-self: auto; */
    /* background-color: white; */
    /* padding: 0px 10px; */

    padding-left: 28px;
    padding-right: 10px;
    svg {
      left: 10px;
    }
  }
`;

const Section = styled(CenteredByColumn)`
  margin: 30px 0px;
  flex-grow: 1;
  align-self: stretch;
`;
const SectionTitle = styled(Centered)`
  width: 100%;
  margin-bottom: 20px;
  /* justify-content: flex-start; */
`;
const filterButtonWidth = 35;

const Count = styled(Centered)<{ level?: number }>`
  align-items: flex-end;
  margin: 0px 10px;
  ${textTitle}
  color: ${(props) =>
    props.level === 3
      ? "rgb(157, 131, 78)"
      : props.level === 2
      ? "rgb(140, 140, 140)"
      : "rgb(134, 106, 86)"};
  opacity: 0.5;
  label {
    font-size: 26px;
    line-height: 1;
    letter-spacing: 1px;
  }
  span {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 3px;
    letter-spacing: 1px;
  }
`;
const SectionHeader = styled(CenteredByColumn)`
  width: 100%;
  margin-bottom: 20px;
`;
const SectionDescription = styled(Text12300)`
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
  /* color: ${TextGrey}; */
`;
const FilterToggle = styled.button`
  ${centered}
  margin: 0px;
  position: absolute;
  right: 20px;
  width: ${filterButtonWidth}px;
  height: ${filterButtonWidth}px;
  border-radius: 50%;
  background-color: ${BackgroundGrey};
`;
const PageTopRestaurants = () => {
  const currentUser = useSelector((state: RootState) => state?.currentUser);
  const name = currentUser?.user?.firstName
    ? currentUser?.user?.firstName + " " + currentUser?.user?.lastName
    : "guest";
  const [loading, setLoading] = useState(false);
  const [filterReady, setFilterReady] = useState(false);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [goldFilteredRestaurants, setGoldFilteredRestaurants] = useState([]);
  const [silverFilteredRestaurants, setSilverFilteredRestaurants] = useState(
    []
  );
  const [bronzeFilteredRestaurants, setBronzeFilteredRestaurants] = useState(
    []
  );
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation<LocationState>();
  const [homeScrollY, setHomeScrollY] = useState<number>(
    location.state?.homeScrollY || 0
  );
  const [fromRestaurantId, setFromRestaurantId] = useState<string | null>(
    location.state?.fromRestaurantId || null
  );
  const [showFilter, setShowFilter] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  // const location = useLocation();
  const filterRef = useRef<HTMLDivElement | null>(null);
  const scrollYRef = useRef<HTMLDivElement | null>(null);
  const [city, setCity] = useState("all"); // City filter state, default to "all"
  const [category, setCategory] = useState("all");
  const [level, setLevel] = useState("all");
  const [savedPosition, setSavedPosition] = useState(0);
  const [numberHasTried, setNumberHasTried] = useState(0);
  const [numberWantsToTry, setNumberWantsToTry] = useState(0);
  const [seeTried, setSeeTried] = useState(false);
  const [tempScrollY, setTempScrollY] = useState(0);
  const [seeWantsToTry, setSeeWantsToTry] = useState(false);
  const firstName = currentUser?.user?.firstName;
  const isAuthenticated = currentUser?.isAuthenticated;
  const TitleAwardContainer = styled(CenteredByColumn)<{ level: number }>`
    ${upper14500}
    font-weight: 600;
    /* ${textTitle} */
    font-size: 18px;
    /* text-transform: uppercase; */
    color: ${(props) =>
      props.level === 3
        ? "rgb(157, 131, 78)"
        : props.level === 2
        ? "rgb(140, 140, 140)"
        : "rgb(134, 106, 86)"};
  `;
  const topRestaurants = useSelector(
    (state: RootState) => state.topRestaurants
  );
  // LOGGING && console.log("PageTopRestaurants got from state:", topRestaurants);

  const pixel = useSelector((state: RootState) => state.pixel);
  let source;
  let fbclid;
  if (!pixel?.fbclid) {
    const urlParams = new URLSearchParams(location.search);
    source = urlParams.get("source");
    fbclid = urlParams.get("fbclid");
    if (fbclid) {
      loadClickID(source, fbclid)(dispatch, store.getState);
    }
    LOGGING &&
      console.log("PageRestaurantGuide got source & fbclid from URL: ", {
        source,
        fbclid,
      });
  } else {
    source = pixel.source;
    fbclid = pixel.fbclid;
    LOGGING && console.log("PageRestaurantGuide got pixel from redux: ", pixel);
  }

  if (
    currentUser?.user?.isAdmin ||
    currentUser?.user?.isUserGroupAdmin ||
    currentUser?.user?.isDriver ||
    currentUser?.user?.isLeadDriver ||
    currentUser?.user?.isOps ||
    currentUser?.user?.isRestaurant ||
    currentUser?.user?.isMarketer ||
    currentUser?.user?.isPendingActivation ||
    currentUser?.user?.isBlocked
  ) {
    posthog.opt_out_capturing();
  }

  useEffect(() => {
    let result = [];
    if (seeTried) {
      result = topRestaurants.filter((r) => r.hasTried);
    }
    if (seeWantsToTry) {
      result = [...result, ...topRestaurants.filter((r) => r.wantsToTry)];
    }

    result = Array.from(new Set(result));
    if (!seeTried && !seeWantsToTry) {
      result = topRestaurants;
    }
    setFilteredRestaurants(result);
  }, [seeTried, seeWantsToTry]);

  const handleTurnOnSeeTried = (e) => {
    e.preventDefault();
    setSeeTried(true);
  };

  const handleTurnOffSeeTried = (e) => {
    e.preventDefault();
    setSeeTried(false);
  };

  const handleTurnOnSeeWantsToTry = (e) => {
    e.preventDefault();
    setSeeWantsToTry(true);
  };
  const handleTurnOffSeeWantsToTry = (e) => {
    e.preventDefault();
    setSeeWantsToTry(false);
  };

  useEffect(() => {
    MealVisited(
      "Restaurant Guide 2025",
      name,
      null,
      currentUser?.user?._id,
      0,
      fbclid,
      source
    );
    if (topRestaurants.length === 0) {
      setLoading(true);
    }
    readTopRestaurants()(dispatch, store.getState).then((res) => {
      const restaurantIds = res.map((r) => r.restaurantId);
      setLoading(false);
      if (isAuthenticated) {
        setFilterReady(false);
        readTopRestaurantUserStats(restaurantIds)(
          dispatch,
          store.getState
        ).then((res) => {
          LOGGING && console.log("readTopRestaurantUserStats response: ", res);
          setFilterReady(true);
        });
        // const cities = Array.from(new Set(res.map((r) => r.city))).sort();
        // const categories = Array.from(
        //   new Set(topRestaurants.map((r) => r.awardCategory.toLowerCase()))
        // ).sort();
        // const levels = Array.from(
        //   new Set(topRestaurants.map((r) => r.awardLevel || 1))
        // ).sort();
        // setCities(cities);
        // setCategories(categories);
        // setLevels(levels);
      }
    });
  }, []);

  useEffect(() => {
    // if (city !== "all") {
    //   filtered = filtered.filter((restaurant) => restaurant.city === city);
    // }
    // if (category !== "all") {
    //   filtered = filtered.filter(
    //     (restaurant) => restaurant.awardCategory === category
    //   );
    // }
    // if (level !== "all") {
    //   filtered = filtered.filter(
    //     (restaurant) => (restaurant.awardLevel || 1).toString() === level
    //   );
    // }

    const numberHasTried = topRestaurants.filter((r) => r.hasTried).length;
    const numberWantsToTry = topRestaurants.filter((r) => r.wantsToTry).length;
    setNumberHasTried(numberHasTried);
    setNumberWantsToTry(numberWantsToTry);
    let filtered = [];

    console.log("something has changed:", {
      seeTried,
      seeWantsToTry,
      filtered,
    });

    if (!seeTried && !seeWantsToTry) {
      filtered = topRestaurants;
      console.log("filtered", filtered.length);
    } else {
      if (seeTried) {
        filtered = topRestaurants.filter((r) => r.hasTried);
        console.log("filtered2", filtered.length);
      }
      if (seeWantsToTry) {
        filtered = [...filtered, ...topRestaurants.filter((r) => r.wantsToTry)];
        console.log("filtered3", filtered.length);
      }
    }
    console.log("filtered4", filtered.length);
    filtered = Array.from(new Set(filtered));
    console.log("filtered4", filtered.length);
    setFilteredRestaurants(filtered);
    setGoldFilteredRestaurants(filtered.filter((r) => r.awardLevel === 3));
    setSilverFilteredRestaurants(filtered.filter((r) => r.awardLevel === 2));
    setBronzeFilteredRestaurants(filtered.filter((r) => r.awardLevel === 1));
  }, [topRestaurants, seeTried, seeWantsToTry]);

  const handleStarRating = async (restaurantId: string, starRating: number) => {
    // Insert Facebook Pixel tracking here
    // @ts-ignore
    if (window.fbq && fbclid) {
      LOGGING &&
        console.log("top restaurants starRating click is tracked by fbq");
      // @ts-ignore
      window.fbq("track", "CustomizeProduct", {
        content_name: `rates ${restaurantId} ${starRating} stars`,
        content_category: "pageTopRestaurants",
        value: 0,
        currency: "USD",
      });

      // Track Ads Event
      TrackAdsEvent(fbclid, {
        source: source,
        eventCode: "CustomizeProduct",
        page: "pageTopRestaurants",
        contentName: `rates ${restaurantId} ${starRating} stars`,
      });
    }

    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantStarRating(restaurantId, starRating)(
      dispatch,
      store.getState
    ).then((res) => {
      LOGGING &&
        console.log("setRestaurantStarRating response: ", {
          restaurantId,
          starRating: res,
        });
    });
  };

  const handleReview = async (restaurantId: string, review: string) => {
    // Insert Facebook Pixel tracking here
    // @ts-ignore
    if (window.fbq && fbclid) {
      LOGGING && console.log("top restaurants review click is tracked by fbq");
      // @ts-ignore
      window.fbq("track", "CustomizeProduct", {
        content_name: `reviews ${restaurantId}`,
        content_category: "pageTopRestaurants",
        value: 0,
        currency: "USD",
      });

      // Track Ads Event
      TrackAdsEvent(fbclid, {
        source: source,
        eventCode: "CustomizeProduct",
        page: "pageTopRestaurants",
        contentName: `reviews ${restaurantId}`,
      });
    }

    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantReview(restaurantId, review)(dispatch, store.getState).then(
      (res) => {
        LOGGING &&
          console.log("setRestaurantReview response: ", {
            restaurantId,
            review: res,
          });
      }
    );
  };

  const handleShowFilter = (e) => {
    e.preventDefault();
    setShowFilter(true);
    setScrollY(window.scrollY);
  };

  const handleHideFilter = (e) => {
    e.preventDefault();
    setShowFilter(false);
    setSavedPosition(scrollY);
  };

  useEffect(() => {
    if (savedPosition > 0) {
      window.scrollTo(0, savedPosition);
      setSavedPosition(0);
      setScrollY(0);
    }
  }, [savedPosition]);

  useEffect(() => {
    const handleScroll = () => {
      if (filterRef.current) {
        const rect = filterRef.current.getBoundingClientRect();
        const sticky = rect.top <= 0;
        if (sticky !== isSticky) {
          setIsSticky(sticky);
        }
      }

      if (scrollYRef.current) {
        const scrollTop = scrollYRef.current.scrollTop;
        console.log("scrollTop:", scrollTop);
        setScrollY(scrollTop);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  LOGGING &&
    console.log("PageTopRestaurants rendering with:", {
      topRestaurants,
      filteredRestaurants,
    });

  return (
    <ContentContainer
    // isScrollable={!showFilter}
    >
      {/* <BackOrHome /> */}
      <Back
        onClick={() => {
          history.push("/");
        }}
        isAuthenticated={currentUser?.isAuthenticated}
      />
      <Nav>
        <Header>Foodie Guide</Header>
        {/* <SubHeader>2025 Edition</SubHeader> */}
        <SharePage
          url={"https://foodie.earth/guide2025"}
          source={source}
          fbclid={fbclid}
        />
      </Nav>
      {/* {!loading && (
        <Essay>      
          <p>
            {" "}
            This guide introduces {topRestaurants.length} top restaurants from
            various cuisines in the South Bay (out of 100,000+ restaurants!)
            based on Yelp reviews and feedback from our foodie community. You
            can try them all within two months using our service.{" "}
          </p>{" "}
          <p>
            {" "}
            It’s perfect for newcomers or those stuck visiting the same few
            spots near home. Step out of your comfort zone—you’ll be pleasantly
            surprised! :){" "}
          </p>
        </Essay>
      )} */}
      {loading && <LoadingText>Loading...</LoadingText>}{" "}
      {/* Show loading indicator while fetching */}
      {topRestaurants.length > 0 && firstName && !loading && (
        <Nav2>
          <RestaurantCountWithProgress
            numberHasTried={numberHasTried}
            total={topRestaurants.length}
            firstName={firstName}
          />
          {/* <FilterToggle onClick={handleShowFilter}>
            <FontAwesomeIcon icon={faFilter} />
          </FilterToggle> */}
          <Filters>
            <Filter
              disabled={!filterReady || numberHasTried === 0}
              isOn={seeTried}
              onClick={seeTried ? handleTurnOffSeeTried : handleTurnOnSeeTried}
            >
              <FontAwesomeIcon icon={faFilter} />
              <span>tried</span>
              <label>{numberHasTried}</label>
            </Filter>
            <Filter
              disabled={!filterReady || numberWantsToTry === 0}
              isOn={seeWantsToTry}
              onClick={
                seeWantsToTry
                  ? handleTurnOffSeeWantsToTry
                  : handleTurnOnSeeWantsToTry
              }
            >
              <FontAwesomeIcon icon={faFilter} />
              <span>want to try</span>
              <label>{numberWantsToTry}</label>
            </Filter>
          </Filters>
        </Nav2>
      )}
      {loading || filteredRestaurants.length === 0 ? (
        <List>
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
        </List>
      ) : (
        <List scrollY={scrollY}>
          {goldFilteredRestaurants.length > 0 && (
            <Section>
              <SectionHeader>
                <SectionTitle>
                  <AwardContainer level={3}>
                    <span>Gold</span>
                  </AwardContainer>
                  <Count level={3}>
                    <label>{goldFilteredRestaurants.length}</label>
                    <span>/{filteredRestaurants.length}</span>
                  </Count>
                </SectionTitle>
                <SectionDescription>
                  Restaurants whose signature dishes wow everyone — no matter
                  your taste.
                </SectionDescription>
              </SectionHeader>
              {goldFilteredRestaurants.map((restaurant, index) => (
                <RestaurantThumbnail
                  key={index}
                  restaurant={restaurant}
                  onStarRating={handleStarRating}
                  onReview={handleReview}
                  source={source}
                  fbclid={fbclid}
                />
              ))}
            </Section>
          )}
          {silverFilteredRestaurants.length > 0 && (
            <Section>
              <SectionHeader>
                <SectionTitle>
                  <AwardContainer level={2}>
                    <span>Silver</span>
                  </AwardContainer>
                  <Count level={2}>
                    <label>{silverFilteredRestaurants.length}</label>
                    <span>/{filteredRestaurants.length}</span>
                  </Count>
                </SectionTitle>
                <SectionDescription>
                  Top of their cuisine in the Bay Area — backed by years of
                  loyal fans.
                </SectionDescription>
              </SectionHeader>
              {silverFilteredRestaurants.map((restaurant, index) => (
                <RestaurantThumbnail
                  key={index}
                  restaurant={restaurant}
                  onStarRating={handleStarRating}
                  onReview={handleReview}
                  source={source}
                  fbclid={fbclid}
                />
              ))}
            </Section>
          )}
          {bronzeFilteredRestaurants.length > 0 && (
            <Section>
              <SectionHeader>
                <SectionTitle>
                  <AwardContainer level={1}>
                    <span>Bronze</span>
                  </AwardContainer>
                  <Count level={1}>
                    <label>{bronzeFilteredRestaurants.length}</label>
                    <span>/{filteredRestaurants.length}</span>
                  </Count>
                </SectionTitle>
                <SectionDescription>
                  Restaurants known for unique dishes, consistent quality, and a
                  loyal following.
                </SectionDescription>
              </SectionHeader>
              {bronzeFilteredRestaurants.map((restaurant, index) => (
                <RestaurantThumbnail
                  key={index}
                  restaurant={restaurant}
                  onStarRating={handleStarRating}
                  onReview={handleReview}
                  source={source}
                  fbclid={fbclid}
                />
              ))}
            </Section>
          )}
        </List>
      )}
      {showFilter && (
        <GuideFilters
          cities={cities}
          categories={categories}
          levels={levels}
          city={city}
          setCity={setCity}
          category={category}
          setCategory={setCategory}
          level={level}
          setLevel={setLevel}
          onHide={handleHideFilter}
        />
      )}
      {!showFilter && <BackToTop />}
    </ContentContainer>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    topRestaurants: state.topRestaurants,
  };
}

export default connect(mapStateToProps, {
  readTopRestaurants,
})(PageTopRestaurants);
