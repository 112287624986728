import * as Components from ".";

const CPK = ({ onClick }) => {
  const linkLunch =
    "/meal/02-14-2025/express-dinner/california-pizza-kitchen-santa-clara";
  const image =
    "https://i.pinimg.com/736x/47/49/7c/47497c9939de93fd8623f10acb3f557e.jpg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          California Pizza Kitchen
        </Components.RestaurantName>
        <Components.MenuName>Sweet Meal for Two</Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Pre-Order by&nbsp;<b>noon Friday, Feb 14</b>
          </Components.DeliveryTime>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>5-6P Friday, Valentine's Day</b>&nbsp;
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Valentine's Day Pack</Components.DishName>
              <Components.AlaCartePrice>$60</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              One Appetizer, Two Entrées, and One Dessert.
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
          >
            pre-order Valentine's Day Dinner
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default CPK;
