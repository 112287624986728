import React, { Component } from "react";
import posthog from "posthog-js";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LOGGING, Loading, UserList, Navbar } from ".";
import { SearchUsers } from "./components/admin/SearchUsers";
import { UserAlphabet } from "./components/admin/UserAlphabet";
import {
  readUsers,
  createUserGroup,
  readUserGroups,
  changeUserGroupInfoForUser,
  updateUserInfo,
  readUsersMarketing,
  delegate,
  readUser,
} from "../../store/actions";

class PageUserList extends Component {
  constructor(props) {
    super(props);
    LOGGING && console.log("PageUserList constructor");
    this.state = {
      users: [],
      userGroups: [],
      loading: false,
      newUserGroupName: undefined,
      initial: props?.match?.params?.initial,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleCreateNewUserGroup = this.handleCreateNewUserGroup.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChangeGroupForUser = this.handleChangeGroupForUser.bind(this);
    this.handleChangeisGroupAdminForUser =
      this.handleChangeisGroupAdminForUser.bind(this);
    this.handleStopFavorites = this.handleStopFavorites.bind(this);
    this.handleStopMarketingEmail = this.handleStopMarketingEmail.bind(this);
    this.handleStopMarketingText = this.handleStopMarketingText.bind(this);
    this.handleStopFavoriteAlertText =
      this.handleStopFavoriteAlertText.bind(this);
    this.handleStopFavoriteAlertEmail =
      this.handleStopFavoriteAlertEmail.bind(this);
    this.handleBlockUser = this.handleBlockUser.bind(this);
    this.handleSaveUser = this.handleSaveUser.bind(this);
    this.handleDelegate = this.handleDelegate.bind(this);
    this.handleSelectUser = this.handleSelectUser.bind(this);
  }

  handleSelectUser(userId) {
    LOGGING && console.log("handleSelectUser called with userId:", userId);
    this.props.readUser(userId).then((user) => {
      this.setState({ users: [user] });
    });
  }

  handleDelegate(userId, e) {
    e.preventDefault();
    LOGGING && console.log("handleDelegate called with userId:", userId);
    this.props.delegate(userId).then(() => {
      this.props.history.push("/");
    });
  }

  handleChangeisGroupAdminForUser(user) {
    LOGGING && console.log("handleChangeisGroupAdminForUser", user);
    const { initial } = this.props.match?.params;
    this.setState({ loading: true }, () => {
      this.props
        .changeUserGroupInfoForUser({
          userId: user._id,
          userGroupId: user.userGroup._id,
          isUserGroupAdmin: user.isUserGroupAdmin ? false : true,
        })
        .then(() => {
          this.props.readUsers(initial).then((users) => {
            this.setState({ users, loading: false });
          });
        });
    });
  }

  handleChangeGroupForUser(user, selectedGroup) {
    LOGGING && console.log("handleChangeGroupForUser", user, selectedGroup);
    const { initial } = this.props.match?.params;
    this.setState({ loading: true }, () => {
      this.props
        .changeUserGroupInfoForUser({
          userId: user._id,
          userGroupId: selectedGroup[0]._id,
          isUserGroupAdmin: false,
        })
        .then(() => {
          this.props.readUsers(initial).then((users) => {
            this.setState({ users, loading: false });
          });
        });
    });
  }

  handleCreateNewUserGroup(e) {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      this.props
        .createUserGroup(this.state.newUserGroupName)
        .then((userGroup) => {
          this.setState({ newUserGroupName: undefined });
          this.props.readUserGroups().then((userGroups) => {
            this.setState({ userGroups, loading: false });
          });
        });
    });
  }

  handleStopFavorites(userId, stopFavoriteAlerts) {
    LOGGING &&
      console.log("PageUserList handleStopFavorites", {
        userId,
        stopFavoriteAlerts,
      });
    const { initial } = this.props.match?.params;
    this.props.updateUserInfo(
      userId,
      { "interaction.stop_favorites": stopFavoriteAlerts },
      {
        success: () => {
          toast.success("set favorite alerts success!");
          this.setState({ loading: true }, () => {
            this.props.readUsers(initial).then((users) => {
              this.setState({ users });
              this.props.readUserGroups().then((userGroups) => {
                this.setState({ userGroups, loading: false });
              });
            });
          });
        },
      }
    );
  }

  handleStopMarketingEmail(userId, receiveMarketingEmail) {
    LOGGING &&
      console.log("PageUserList stopMarketingEmail", {
        userId,
        receiveMarketingEmail,
      });
    this.props.updateUserInfo(
      userId,
      { receiveMarketingEmail },
      {
        success: () => {
          toast.success("receiveMarketingEmail success!");
          const updatedUsers = this.state.users.map((u) => {
            if (u._id === userId) {
              return { ...u, receiveMarketingEmail };
            } else {
              return u;
            }
          });
          this.setState({ users: [...updatedUsers] });
        },
      }
    );
  }

  handleStopMarketingText(userId, receiveMarketingText) {
    LOGGING &&
      console.log("PageUserList stopMarketingText", {
        userId,
        receiveMarketingText,
      });
    this.props.updateUserInfo(
      userId,
      { receiveMarketingText },
      {
        success: () => {
          toast.success("receiveMarketingText success!");
          const updatedUsers = this.state.users.map((u) => {
            if (u._id === userId) {
              return { ...u, receiveMarketingText };
            } else {
              return u;
            }
          });
          this.setState({ users: [...updatedUsers] });
        },
      }
    );
  }

  handleStopFavoriteAlertText(userId, receiveFavoriteAlertText) {
    LOGGING &&
      console.log("PageUserList receiveFavoriteAlertText", {
        userId,
        receiveFavoriteAlertText,
      });
    this.props.updateUserInfo(
      userId,
      { receiveFavoriteAlertText },
      {
        success: () => {
          toast.success("receiveFavoriteAlertText success!");
          const updatedUsers = this.state.users.map((u) => {
            if (u._id === userId) {
              return { ...u, receiveFavoriteAlertText };
            } else {
              return u;
            }
          });
          this.setState({ users: [...updatedUsers] });
        },
      }
    );
  }

  handleStopFavoriteAlertEmail(userId, receiveFavoriteAlertEmail) {
    LOGGING &&
      console.log("PageUserList receiveFavoriteAlertEmail", {
        userId,
        receiveFavoriteAlertEmail,
      });
    this.props.updateUserInfo(
      userId,
      { receiveFavoriteAlertEmail },
      {
        success: () => {
          toast.success("receiveFavoriteAlertEmail success!");
          const updatedUsers = this.state.users.map((u) => {
            if (u._id === userId) {
              return { ...u, receiveFavoriteAlertEmail };
            } else {
              return u;
            }
          });
          this.setState({ users: [...updatedUsers] });
        },
      }
    );
  }

  handleBlockUser(userId, isBlocked) {
    LOGGING &&
      console.log("PageUserList blockUser", {
        userId,
        isBlocked,
      });
    const { initial } = this.props.match?.params;
    this.props.updateUserInfo(
      userId,
      { isBlocked },
      {
        success: () => {
          toast.success("set isBlocked success!");
          this.setState({ loading: true }, () => {
            this.props.readUsers(initial).then((users) => {
              this.setState({ users });
              this.props.readUserGroups().then((userGroups) => {
                this.setState({ userGroups, loading: false });
              });
            });
          });
        },
      }
    );
  }

  handleSaveUser(userId, data) {
    LOGGING && console.log("PageUserList handleSaveUser", { userId, data });
    const { initial } = this.props.match?.params;

    this.props.updateUserInfo(userId, data, {
      success: () => {
        toast.success("save credit success!");
        this.setState({ loading: true }, () => {
          this.props.readUsers(initial).then((users) => {
            this.setState({ users });
            this.props.readUserGroups().then((userGroups) => {
              this.setState({ userGroups, loading: false });
            });
          });
        });
      },
    });
  }

  handleEdit(e) {
    e.preventDefault();
    LOGGING && console.log("PageUserList handleEdit", e.target);
    this.setState({ [e.target.name]: e.target.value });
  }
  handleClose() {
    LOGGING && console.log("PageUserList handleClose");
    this.props.history.goBack();
  }

  componentDidMount() {
    LOGGING && console.log("PageUserList componentdidmount");
    const { initial } = this.state;
    if (initial !== undefined) {
      this.setState({ loading: true }, () => {
        this.props
          .readUsers(initial === "all" ? null : initial)
          .then((users) => {
            this.setState({ users });
            this.props.readUserGroups().then((userGroups) => {
              this.setState({ userGroups });
              this.props.readUsersMarketing().then((usersMarketing) => {
                this.setState({ usersMarketing, loading: false });
              });
            });
          });
      });
    }

    // Make sure opts out PostHog
    posthog.opt_out_capturing();
  }

  render() {
    const { currentUser } = this.props;
    const {
      users,
      loading,
      newUserGroupName,
      userGroups,
      usersMarketing,
      initial,
    } = this.state;
    LOGGING &&
      console.log("PageUserList rendering with", {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        <Navbar />
        <SearchUsers onSelect={this.handleSelectUser} />
        <UserAlphabet initial={initial} />
        {loading ? (
          <Loading />
        ) : (
          <UserList
            users={users}
            usersMarketing={usersMarketing}
            onBack={this.handleClose}
            newUserGroupName={newUserGroupName}
            onCreateNewUserGroup={this.handleCreateNewUserGroup}
            onEdit={this.handleEdit}
            userGroups={userGroups}
            onChangeGroupForUser={this.handleChangeGroupForUser}
            onChangeisGroupAdminForUser={this.handleChangeisGroupAdminForUser}
            onStopFavorites={this.handleStopFavorites}
            onStopMarketingEmail={this.handleStopMarketingEmail}
            onStopMarketingText={this.handleStopMarketingText}
            onStopFavoriteAlertText={this.handleStopFavoriteAlertText}
            onStopFavoriteAlertEmail={this.handleStopFavoriteAlertEmail}
            onBlockUser={this.handleBlockUser}
            onSaveUser={this.handleSaveUser}
            onDelegate={this.handleDelegate}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageUserList got redux state:", state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readUsers,
  createUserGroup,
  readUserGroups,
  changeUserGroupInfoForUser,
  updateUserInfo,
  readUsersMarketing,
  delegate,
  readUser,
})(PageUserList);
