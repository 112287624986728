import { LOGGING } from "src/constants";
import { ObjectId } from "src/constants/types";
import { Dish } from "src/models";
import { Good } from "src/screens/tasting_menu/components/dish/PureDishChooser";
import { getDishKey } from "./order";

export const getDishCountInCart = (dishId: ObjectId, cart: any): number => {
  return cart?.goods
    ? Object.keys(cart.goods).reduce(
        (a, b) =>
          (String(b).includes(String(dishId)) ? cart.goods[b].quantity : 0) + a,
        0
      )
    : 0;
};

export const getGoodKeyFromCart = (
  existingGoods: { [key: string]: Good },
  dishId: ObjectId
): string | null => {
  if (existingGoods == null) return null;

  const relevantKeys = Object.keys(existingGoods).filter((existingGoodKey) =>
    existingGoodKey.includes(String(dishId))
  );
  return relevantKeys[0];
};

type AddDishQuantityFromCart = Good & { goods: { [key: string]: Good } };
export const addDishQuantityToCart = ({
  goods,
  dish,
  selections,
  price,
  quantity,
  comment,
  addedBy,
}: AddDishQuantityFromCart) => {
  LOGGING &&
    console.log("AddDishQuantityFromCart called with:", {
      quantity,
      goods,
      addedBy,
    });

  const step1 = Object.entries(goods);
  console.log("AddDishQuantityFromCart got step1:", step1);
  const step2 = step1.map(([_, good]: [string, Good]) => {
    return [
      getDishKey(good.dish, good.selections, good.addedBy?._id || good.addedBy),
      good,
    ];
  });
  console.log("AddDishQuantityFromCart got step2:", step2);
  const step3 = Object.fromEntries(step2);
  console.log("AddDishQuantityFromCart got step3:", step3);
  const goodsWithCorrectKey = Object.fromEntries(
    Object.entries(goods).map(([_, good]: [string, Good]) => {
      return [
        getDishKey(
          good.dish,
          good.selections,
          good.addedBy?._id || good.addedBy
        ),
        good,
      ];
    })
  );
  console.log(
    "AddDishQuantityFromCart got from existing cart goodsWithCorrectKey:",
    goodsWithCorrectKey
  );
  const goodsKey = getDishKey(dish, selections, addedBy);
  console.log("AddDishQuantityFromCart got for new dishgoodsKey:", goodsKey);
  const existingGoodInCart = goodsWithCorrectKey[goodsKey];
  console.log(
    "AddDishQuantityFromCart got existingGoodInCart:",
    existingGoodInCart
  );
  const updateGoods = {
    ...goodsWithCorrectKey,
    [goodsKey]: {
      dish,
      selections,
      price,
      quantity: quantity + (existingGoodInCart?.quantity ?? 0),
      comment,
      addedBy,
    },
  };
  console.log("AddDishQuantityFromCart got updateGoods:", updateGoods);
  return updateGoods;
};

type RemoveDishQuantityFromCart = {
  goods: { [key: string]: Good } | null;
  dish: Dish;
  quantity: number;
};
export const removeDishQuantityFromCart = ({
  goods,
  dish,
  quantity,
}: RemoveDishQuantityFromCart) => {
  const nonNullGoods: { [key: string]: Good } = goods ?? {};

  // Could be multiple with different selections
  const goodsKey = getGoodKeyFromCart(nonNullGoods, dish._id);
  const existingGoodInCart = nonNullGoods[goodsKey];
  const remainingQuantity = (existingGoodInCart?.quantity ?? 0) - quantity;
  const updateGoods = {
    ...nonNullGoods,
  };
  if (remainingQuantity > 0) {
    updateGoods[goodsKey] = {
      ...existingGoodInCart,
      quantity: remainingQuantity,
    };
  } else {
    delete updateGoods[goodsKey];
  }
  return updateGoods;
};

export const orderGoodsToCartMapGoods = (
  orderGoods: Good[] | null
): { [key: string]: Good } => {
  if (orderGoods == null) return {};

  return Object.fromEntries(
    orderGoods.map((good) => {
      return [getDishKey(good.dish, good.selections), good];
    })
  );
};
