import React, { useEffect } from "react";
import "./Auth.scss";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faBaby, faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { Loading, Back, PopUp } from "./";
import { LOGGING, PhoneVerificationStatus } from "../../constants";
import {
  NewUserFormPhone,
  PhoneCodeVerificationForm,
} from "../tasting_menu/components/auth/GetPhone";
import {
  GoogleLogo,
  FacebookLogo,
} from "../tasting_menu/components/auth/Shared";
// import { reactCodeInput } from 'CodeInputField.scss'
const props = {
  // className: reactCodeInput,
  inputStyle: {
    fontFamily: "monospace",
    margin: "4px",
    MozAppearance: "textfield",
    width: "15px",
    borderRadius: "3px",
    fontSize: "14px",
    height: "26px",
    paddingLeft: "7px",
    backgroundColor: "black",
    color: "lightskyblue",
    border: "1px solid lightskyblue",
  },
  inputStyleInvalid: {
    fontFamily: "monospace",
    margin: "4px",
    MozAppearance: "textfield",
    width: "15px",
    borderRadius: "3px",
    fontSize: "14px",
    height: "26px",
    paddingLeft: "7px",
    backgroundColor: "black",
    color: "red",
    border: "1px solid red",
  },
};

// export const ContinueWithFacebook = ({
//   onCallback,
//   onInitialClick,
//   autoLoad,
//   textButton,
// }) => (
//   <div className="auth-option continue-with-facebook">
//     <FacebookLogo />
//     <FacebookLogin
//       appId={
//         process.env.NODE_ENV === "development"
//           ? process.env.REACT_APP_DEV_FACEBOOK_APP_ID
//           : process.env.REACT_APP_PROD_FACEBOOK_APP_ID
//       }
//       autoLoad={autoLoad}
//       fields="first_name,last_name,email,picture"
//       scope="public_profile"
//       textButton={textButton}
//       callback={onCallback}
//       onClick={onInitialClick}
//       cssClass="custom-facebook-button-class"
//     />
//   </div>
// );

export const ContinueWithGoogle = ({
  onSuccess,
  onFailure,
  textButton,
  onReady,
  showGoogleLogo = true,
}) => {
  LOGGING &&
    console.log("ContinueWithGoogle called with:", {
      onSuccess,
      onFailure,
      textButton,
      onReady,
    });
  return (
    <div className="auth-option continue-with-google custom-continue-with-google-button-wrapper">
      {showGoogleLogo && (
        <div className="social-icon-wrapper">
          <GoogleLogo />
        </div>
      )}
      <GoogleLogin
        clientId={
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEV_GOOGLE_CLIENT_ID
            : process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID
        }
        render={(renderProps) => (
          <button
            className="custom-google-button"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <span>{textButton}</span>
          </button>
        )}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        onAutoLoadFinished={onReady}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
};

export const ContinueWithFacebook = ({ onSuccess, onFailure, textButton }) => {
  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1141895330627799", // Your Facebook App ID
        cookie: true,
        xfbml: true,
        version: "v21.0", // Correct Graph API version
      });

      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleFacebookLogin = () => {
    console.log("Facebook login clicked");
    window.FB.login(
      (response) => {
        console.log("Facebook login response:", response);
        if (response.status === "connected") {
          // Fetch user details
          window.FB.api(
            "/me",
            {
              fields:
                "id,name,email,first_name,last_name,picture.width(200).height(200),gender,birthday,location",
            },
            (user) => {
              console.log("User details:", user);
              onSuccess(user);
            }
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
          onFailure(response);
        }
      },
      { scope: "public_profile,email" } // Permissions
    );
  };

  return (
    <div className="auth-option continue-with-facebook custom-facebook-button-wrapper">
      <div className="social-icon-wrapper">
        <FontAwesomeIcon icon={faFacebookF} color={"#3b5998"} />
      </div>
      <button
        onClick={handleFacebookLogin}
        className="facebook-login-button custom-facebook-button"
      >
        <span>Continue with Facebook</span>
      </button>
    </div>
  );
};

const SignUpByEmail = () => (
  <div className="auth-option by-email">
    {/* <FontAwesomeIcon icon={faUser} /> */}
    <Link to="/signup">
      <span>create an account</span>
    </Link>
  </div>
);

const SignInByEmail = ({ next }) => (
  <div className="auth-option by-email">
    {/* <FontAwesomeIcon icon={faUser} /> */}
    <Link to="/signin" state={{ next }}>
      <span>log in</span>
    </Link>
  </div>
);

const GetEmail = ({ email, onChange, onContinue }) => (
  <div className="get-email">
    <input
      name="email"
      onChange={onChange}
      defaultValue={email}
      placeholder="email"
    />
    <div className="auth-option continue">
      <button type="button" onClick={onContinue}>
        continue
      </button>
    </div>
  </div>
);

export const NewUserWithEmail = ({
  onEdit,
  firstName,
  lastName,
  phone,
  email,
  password,
  error,
  onRegister,
  next,
}) => (
  <form className="new-user" onSubmit={onRegister}>
    {error ? <div className="form-error">{error.message}</div> : null}
    <div className="form-row">
      <input
        name="given-name"
        autocomplete="given-name"
        placeholder="First Name"
        defaultValue={firstName}
        onChange={onEdit}
      />
    </div>
    <div className="form-row">
      <input
        name="family-name"
        autocomplete="family-name"
        placeholder="Last Name"
        defaultValue={lastName}
        onChange={onEdit}
      />
    </div>
    <div className="form-row">
      <input
        name="phone"
        defaultValue={phone}
        onChange={onEdit}
        placeholder="Phone"
        className="form-field"
      />
    </div>
    <div className="form-row">
      <input
        name="email"
        defaultValue={email}
        onChange={onEdit}
        placeholder="Email"
        className="form-field"
        autocomplete="email"
      />
    </div>
    <div className="form-row">
      <input
        name="password"
        placeholder="Password"
        defaultValue={password}
        onChange={onEdit}
        className="form-field"
        type="password"
      />
    </div>
    <button type="submit">creat an account</button>
    <div className="alread-signed-up">
      <span className="form-notes">Already signed up?</span>
      <Link className="alternative-action sign-in" to="/signin">
        sign in
      </Link>
    </div>
    {/* {next ? (
      <React.Fragment>
        <Dividor />
        <Link to={next} className="continue-as-guest">
          continue as guest
        </Link>
      </React.Fragment>
    ) : null} */}
  </form>
);
export const PhoneInputForm = ({
  phone,
  onEditPhone,
  onSendPhoneVerificationCode,
}) => (
  <React.Fragment>
    {/* <div className="form-title">Phone number</div>
    <div className="form-notes send-verification">{message}</div> */}

    <div className="form-row auth phone">
      <PhoneInput
        placeholder="Enter phone number"
        value={phone}
        onChange={onEditPhone.bind(this, "phone")}
        name="phone"
        className="form-field"
        defaultCountry="US"
        country="US"
      />
      <button className="send-code" onClick={onSendPhoneVerificationCode}>
        continue
      </button>
    </div>
  </React.Fragment>
);

// export const PhoneCodeVerificationForm = ({
//   phoneCode,
//   onEditPhone,
//   message,
//   onCheckPhoneVerificationCode,
//   onSendPhoneVerificationCode,
// }) => (
//   <React.Fragment>
//     <div className="form-notes phone-code-verification">{message}</div>
//     <div className="form-row verification-code">
//       <ReactCodeInput
//         fields={4}
//         type="number"
//         values={Array.from(phoneCode)}
//         onChange={onEditPhone.bind(this, "phoneCode")}
//         className="verification-input"
//       />
//       <button className="main-action" onClick={onCheckPhoneVerificationCode}>
//         Verify
//       </button>
//     </div>

//     <div className="form-notes">
//       <button
//         className="alternative-action send-code"
//         onClick={onSendPhoneVerificationCode}
//       >
//         send code again
//       </button>
//     </div>
//   </React.Fragment>
// );

const Auth = ({
  emailSubmitted, //false: getEmail, true: newUserEmail,
  emailForm,
  signUpByEmail,
  signInByEmail,
  email,
  firstName,
  lastName,
  password,
  next,
  facebookAutoload,
  onChange,
  onRegister,
  onSubmitEmail,
  onContinueWithFacebook,
  onClickContinueWithFacebook,
  onGoogleReady,
  onClickContinueWithGoogle,
  onGoogleSuccess,
  onGoogleFailure,
}) => (
  // <div className={`checkout-auth ${loading ? "loading-container" : ""}`}>
  <div className="checkout-auth">
    {/* {loading ? <Loading /> : null} */}
    <div className="checkout-auth-body loading-content">
      {emailForm ? (
        emailSubmitted ? (
          <NewUserWithEmail
            email={email}
            firstName={firstName}
            lastName={lastName}
            password={password}
            onEdit={onChange}
            onRegister={onRegister}
          />
        ) : (
          <GetEmail
            email={email}
            onChange={onChange}
            onContinue={onSubmitEmail}
          />
        )
      ) : null}
      {signUpByEmail ? <SignUpByEmail /> : null}
      {signInByEmail ? <SignInByEmail /> : null}
      {/* <ContinueWithFacebook
        onCallback={onContinueWithFacebook}
        onInitialClick={onClickContinueWithFacebook}
        autoLoad={facebookAutoload}
        textButton="Continue with facebook"
      />
      <ContinueWithGoogle
        onSuccess={onGoogleSuccess}
        onFailure={onGoogleFailure}
        onReady={onGoogleReady}
        onClick={onClickContinueWithGoogle}
        textButton="Continue with google"
      /> */}
    </div>
  </div>
);

export const ResetPassword = ({
  submitted,
  isChanged,
  password,
  confirmPassword,
  showPassword,
  showConfirmPassword,
  onToggleShownPassword,
  onToggleShownConfirmPassword,
  onEdit,
  onResetPassword,
}) => (
  <div className="reset-password">
    {submitted && isChanged && (
      <div className="password-change-status">
        Your password changed successfully.
      </div>
    )}
    {submitted && !isChanged && (
      <div className="password-change-status">
        Your password was not changed. Please check your link.
      </div>
    )}
    {!submitted && (
      <form className="new-user" onSubmit={onResetPassword}>
        <div className="form-row password-row">
          <input
            name="password"
            placeholder="Your New Password"
            defaultValue={password}
            onChange={onEdit}
            className="form-field"
            type={showPassword ? "text" : "password"}
          />
          <button onClick={onToggleShownPassword}>
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </button>
        </div>
        <div className="form-row password-row">
          <input
            name="confirmPassword"
            placeholder="Confirm Password"
            defaultValue={confirmPassword}
            onChange={onEdit}
            className="form-field"
            type={showConfirmPassword ? "text" : "password"}
          />
          <button onClick={onToggleShownConfirmPassword}>
            <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
          </button>
        </div>
        <button type="submit" className="main-action">
          Reset Password
        </button>
      </form>
    )}
  </div>
);

export const ForgetPassword = ({ emailSent, email, onEdit, onSubmit }) => {
  return emailSent ? (
    <form className="new-user">
      <h2>check your email</h2>
      <span className="form-notes sent-email-if-exists">
        {`We have sent you instructions to reset your password, if an account associated with ${email} exists.`}
      </span>
      <Link to="/" className="main-action">
        back to home
      </Link>
    </form>
  ) : (
    <form className="new-user" onSubmit={onSubmit}>
      <h2>Forgot password?</h2>
      <span className="form-notes send-instructions">
        Enter the email address associated with your account, and we'll email
        you a link to reset your password.
      </span>
      <div className="form-row">
        <input
          name="email"
          placeholder="enter email address"
          value={email}
          onChange={onEdit}
          className="form-field"
        />
      </div>
      <button type="submit" className="main-action">
        send reset link
      </button>
    </form>
  );
};

export default Auth;
