import React, { Component } from "react";
import moment from "moment-timezone";
import posthog from "posthog-js";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { driverHourlyWage, maxRouteTime, minRouteTime } from "src/constants";
import { getRouteForDriver } from "src/store/actions/routeV2";
import {
  LOGGING,
  Loading,
  ConfirmAlert,
  DriverTasks,
  DriverSummary,
  DriverStartingRestaurant,
  Navbar,
  DeliveryStatusBanner,
  DriverNotification,
} from ".";
import { RouteInProgressScreen } from "./components/RouteInProgressScreen";
import { TransientMessage } from "./components/TransientMessage";
import {
  markOrderAsDelivered,
  markOrderAsDispatched,
  readDriverOrders,
  createRoute,
  startRoute,
  endRoute,
  fetchRoute,
  startFromRestaurant,
  updateRoute,
  getDriverInfo,
  confirmDriverAvailability,
  uploadOrderDeliveryPicture,
  payDriverForRoute,
} from "../../store/actions";

const prevEndTime = () => {
  const now = moment().tz("America/Los_Angeles");
  const hr = now.hour(),
    minute = now.minute();
  // After 7:30 PM => return 7:30 PM
  if (hr > 19 || (hr === 19 && minute >= 30)) {
    return now.startOf("day").set({ hour: 19.5 });
  }
  // After 1:00 PM => return 1:00 PM
  if (hr >= 13) {
    return now.startOf("day").set({ hour: 13 });
  }
  // Before 1:00 PM => return 7:30 PM yesterday
  return now.subtract(1, "day").startOf("day").set({ hour: 19.5 });
};

const getAvailabilityInfo = (user) => {
  let needConfirmAvailability = false;
  let availabilityResponse = undefined;

  LOGGING && console.log("getAvailabilityInfo called with user:", user);

  if (user === undefined) {
    LOGGING && console.log("getAvailabilityInfo user == undefined.");
    return { needConfirmAvailability, availabilityResponse };
  }
  const { driverInfo } = user;
  if (driverInfo === undefined) {
    LOGGING && console.log("getAvailabilityInfo driverInfo == undefined.");
    return { needConfirmAvailability, availabilityResponse };
  }
  if (driverInfo.lastAvailabilityAskTime === undefined) {
    LOGGING &&
      console.log(
        "getAvailabilityInfo driverInfo.lastAvailabilityAskTime == undefined."
      );
    return { needConfirmAvailability, availabilityResponse };
  }
  console.log("@@@", driverInfo.lastAvailabilityAskTime < prevEndTime());
  console.log("@@@", prevEndTime().valueOf());
  if (driverInfo.lastAvailabilityAskTime < prevEndTime()) {
    LOGGING &&
      console.log(
        "getAvailabilityInfo driverInfo.lastAvailabilityAskTime < prevEndTime():",
        prevEndTime().format("LLLL")
      );
    return { needConfirmAvailability, availabilityResponse };
  }
  if (
    driverInfo.lastAvailabilityAskTime < driverInfo.availabilityResponseTime
  ) {
    LOGGING &&
      console.log(
        "getAvailabilityInfo driverInfo.lastAvailabilityAskTime < driverInfo.availabilityResponseTime"
      );
    if (driverInfo.isDelivering === false) {
      availabilityResponse = false;
      return { needConfirmAvailability, availabilityResponse };
    } else {
      availabilityResponse = driverInfo.availabilityResponse;
      return { needConfirmAvailability, availabilityResponse };
    }
  }
  LOGGING && console.log("getAvailabilityInfo default");
  needConfirmAvailability = true;
  return { needConfirmAvailability, availabilityResponse };
};

const getNextOrders = (orders, currentStop) => {
  LOGGING &&
    console.log("getNextOrders called with currentStop: ", currentStop);
  // don't change org orders
  const copiedOrders = [...orders];
  // sort orders by stop
  copiedOrders.sort((o1, o2) => o1.stop - o2.stop);
  LOGGING && console.log("copiedOrders sorted: ", copiedOrders);
  // get the first order stop bigger than currentStop
  const nextOrders = copiedOrders
    .filter((o) => o.stop === currentStop + 1)
    .map((o) => o._id);
  LOGGING && console.log("copiedOrders found next orders: ", nextOrders);
  return nextOrders;
};

class PageDriver extends Component {
  constructor(props) {
    super();
    const { currentUser } = props;
    LOGGING && console.log("PageDriver constructor ", currentUser);
    const { needConfirmAvailability, availabilityResponse } =
      getAvailabilityInfo(currentUser.user);
    LOGGING &&
      console.log(
        "PageDriver constructor 1 ",
        needConfirmAvailability,
        availabilityResponse
      );
    this.state = {
      processingDeliveryId: null,
      isLunch: true,
      loading: false,
      ordersByRestaurant: null,
      showDriversForOrder: -1,
      showStopsForOrder: -1,
      displayDriverOnly: -1,
      viewAddress: true,
      viewDishes: true,
      message: null,
      isDelivered: false,
      isSummary: false,
      restaurant: {},
      needConfirmAvailability,
      availabilityResponse,
      showOrderDetails: {},
      processingStop: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleDeliverConfirm = this.handleDeliverConfirm.bind(this);
    this.handleDeliver = this.handleDeliver.bind(this);
    this.handleDispatch = this.handleDispatch.bind(this);
    this.handleBackToTop = this.handleBackToTop.bind(this);
    this.handleShowMessage = this.handleShowMessage.bind(this);
    this.handleToggleShowOrderDetails =
      this.handleToggleShowOrderDetails.bind(this);
    this.handleSelectDeliveryStatus =
      this.handleSelectDeliveryStatus.bind(this);
    this.handleStartFromRestaurant = this.handleStartFromRestaurant.bind(this);
    this.handleConfirmAvailability = this.handleConfirmAvailability.bind(this);
    this.handleDisconfirmAvailability =
      this.handleDisconfirmAvailability.bind(this);
    this.handleUploadDeliveryImage = this.handleUploadDeliveryImage.bind(this);
    this.handleDone = this.handleDone.bind(this);
  }

  handleToggleShowOrderDetails(orderId, e) {
    e.preventDefault();
    let { showOrderDetails } = this.state;
    showOrderDetails[orderId] = !showOrderDetails[orderId];
    this.setState({ showOrderDetails });
  }

  handleDone() {
    this.setState({ loading: true });
    this.props.endRoute();
    this.setState({
      loading: false,
      needConfirmAvailability: false,
      availabilityResponse: false,
      route: {},
    });
  }

  handleConfirmAvailability(e) {
    this.props.confirmDriverAvailability(true);
    this.setState({
      needConfirmAvailability: false,
      availabilityResponse: true,
    });
  }

  handleDisconfirmAvailability(e) {
    this.props.confirmDriverAvailability(false);
    this.setState({
      needConfirmAvailability: false,
      availabilityResponse: false,
    });
  }

  handleStartFromRestaurant(e) {
    e.preventDefault();
    this.setState({ processingStop: -1 }, () => {
      this.props.startRoute().then(() => {
        const orders = this.props.route.orders;
        const firstOrders = getNextOrders(orders, -1);
        LOGGING && console.log("firstOrders:", firstOrders);
        this.handleDispatch(0, firstOrders);
      });
      this.setState({
        needConfirmAvailability: false,
        availabilityResponse: true,
        processingStop: null,
      });
    });
  }

  handleSelectDeliveryStatus(isDelivered, isSummary, e) {
    e.preventDefault();
    this.setState({ isDelivered, isSummary });
  }

  handleShowMessage(message) {
    this.setState({ message });
    setTimeout(() => {
      this.setState({
        message: null,
      });
    }, 1000);
  }

  handleBackToTop(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
  }

  handleUploadDeliveryImage(stop, orderIds, picture, pictureData) {
    console.log(
      "handleUploadDeliveryImage",
      stop,
      orderIds,
      picture,
      pictureData
    );
    this.setState({ processingStop: stop }, () => {
      this.props.uploadOrderDeliveryPicture(orderIds, pictureData).then(() => {
        this.setState({ processingStop: null });
      });
    });
  }

  handleDeliverConfirm(stop, orderIds) {
    LOGGING &&
      console.log("PageDriver handleDeliverConfirm called with:", {
        orderIds,
        stop,
        route: this.props.route,
      });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={() => this.handleDeliver(stop, orderIds)}
            onClose={onClose}
            message={`Are you sure you have delivered the right order?`}
          />
        );
      },
    });
  }

  handleDeliver(stop, orderIds) {
    LOGGING &&
      console.log("PageDriver handleDeliver called with:", { stop, orderIds });
    this.setState({ processingStop: stop }, () => {
      this.props.markOrderAsDelivered(stop, orderIds).then(() => {
        this.setState({ processingStop: null }, () => {
          const { route } = this.props;
          const { orders, driverPaymentInfo, _id: routeId } = route;
          LOGGING && console.log("stop: ", stop);
          const nextOrders = getNextOrders(orders, stop);
          if (nextOrders.length > 0) {
            LOGGING && console.log("nextOrders: ", nextOrders);
            this.handleDispatch(stop + 1, nextOrders);
          } else {
            this.handleDone();
            const { currentUser } = this.props;
            LOGGING &&
              console.log(
                "handleDeliver got last order with currentUser: ",
                currentUser
              );
            if (currentUser?.user?.driverPaypalAccount) {
              this.props.payDriverForRoute({
                routeId,
                message: "",
              });
            }
          }
        });
      });
    });
  }

  handleDispatch(stop, orderIds) {
    LOGGING &&
      console.log("PageDriver handleDispatch called with:", {
        orderIds,
      });
    this.setState({ processingStop: stop }, () => {
      this.props.markOrderAsDispatched(stop, orderIds).then(() => {
        this.setState({ processingStop: null });
        window.scrollTo(0, 0);
      });
    });
  }

  handleClose() {
    LOGGING && console.log("PageDriver handleClose");
    this.props.history.goBack();
  }

  componentDidMount() {
    LOGGING && console.log("PageDriver componentdidmount");
    this.setState({ loading: true }, () => {
      this.props.getDriverInfo().then(() => {
        const { currentUser } = this.props;
        const { needConfirmAvailability, availabilityResponse } =
          getAvailabilityInfo(currentUser.user);
        this.setState({
          needConfirmAvailability,
          availabilityResponse,
        });
        const mealID = currentUser.user.driverInfo?.meal;
        // TODO: FetchRoute is deprecated. Use getRouteForDriver
        this.props.getRouteForDriver().then(() => {
          this.props.fetchRoute().then(() => {
            this.props.readDriverOrders().then(() => {
              const { currentUser, orders } = this.props;
              let showOrderDetails = {};
              const { restaurantName: name, restaurantAddress: address } =
                currentUser.user?.driverInfo || {};
              orders.forEach((o) => {
                showOrderDetails[o._id] = false;
              });
              this.setState({
                loading: false,
                restaurant: { name, address },
                showOrderDetails,
              });
            });
          });
        });
      });
    });

    // Make sure opts out PostHog
    posthog.opt_out_capturing();
  }

  render() {
    const { orders, route, routeV2, currentUser } = this.props;
    const {
      processingStop,
      loading,
      message,
      isDelivered,
      isSummary,
      needConfirmAvailability,
      availabilityResponse,
      showOrderDetails,
    } = this.state;
    const { driverInfo } = currentUser.user;
    let combinedStops = [];
    orders.forEach((stop) => {
      const { address, userGroup } = stop;
      const existingCombinedStopIndex = combinedStops.findIndex(
        (s) => s.address === address
      );
      if (existingCombinedStopIndex > -1) {
        combinedStops[existingCombinedStopIndex].nameList.push(stop);
      } else {
        combinedStops.push({
          ...stop,
          nameList: [stop],
          groupName: userGroup?.name,
        });
      }
    });
    LOGGING &&
      console.log("PageDriver rendering with", {
        combinedStops,
        orders,
        isDelivered,
        isSummary,
        loading,
        props: this.props,
        state: this.state,
        availabilityResponse,
        needConfirmAvailability,
        route,
      });

    if (!currentUser.isAuthenticated) {
      return <Redirect to="/signin" />;
    }
    if (!currentUser.user.isDriver) {
      return <Redirect to="/" />;
    }

    if (routeV2?._id != null) {
      return (
        <div className="page" style={{ padding: "50px 0px" }}>
          <TransientMessage />
          <RouteInProgressScreen />
        </div>
      );
    }

    const deliveredStops = combinedStops.filter((o) => o.deliveryTime);
    const undeliveredStops = combinedStops.filter(
      (o) => o.deliveryTime === undefined
    );
    const deliveredOrders = orders.filter((o) => o.deliveryTime);
    const undeliveredOrders = orders.filter(
      (o) => o.deliveryTime === undefined
    );
    LOGGING &&
      console.log("PageDriver rendering with:", {
        undeliveredOrders,
        undeliveredStops,
      });
    return (
      <div className="page">
        <Navbar driverView={true} numberOfStopsLeft={orders.length} />
        <DeliveryStatusBanner
          isDelivered={isDelivered}
          isSummary={isSummary}
          onSelectDeliveryStatus={this.handleSelectDeliveryStatus}
          numberOfDelivered={deliveredOrders.length}
          numberOfToDeliver={undeliveredStops.length}
        />
        {loading ? <Loading /> : null}
        {isDelivered ? (
          <DriverTasks
            orders={deliveredStops}
            onDeliver={this.handleDeliver}
            onDispatch={this.handleDispatch}
            processingStop={processingStop}
            onBackToTop={this.handleBackToTop}
            onCopied={this.handleShowMessage}
            message={message}
            isDelivered={isDelivered}
            onStartFromRestaurant={this.handleStartFromRestaurant}
            onUploadDeliveryImage={this.handleUploadDeliveryImage}
          />
        ) : (needConfirmAvailability ||
            availabilityResponse ||
            currentUser?.user?.driverInfo?.isDelivering) &&
          !isSummary ? (
          <DriverNotification
            needConfirmAvailability={needConfirmAvailability}
            availabilityResponse={availabilityResponse}
            onDisconfirmAvailability={this.handleDisconfirmAvailability}
            onConfirmAvailability={this.handleConfirmAvailability}
            driverInfo={currentUser.user.driverInfo}
            driverHourlyWage={driverHourlyWage}
            maxRouteTime={maxRouteTime}
            minRouteTime={minRouteTime}
            route={route}
          />
        ) : null}
        {!loading &&
          !isDelivered &&
          !isSummary &&
          Object.keys(route).length !== 0 && (
            <React.Fragment>
              {!route.startTime && (
                <>
                  {undeliveredOrders.length > 0 && (
                    <div className="driver-orders-stops">
                      <span>
                        {undeliveredOrders.length} orders{" "}
                        {undeliveredStops.length < undeliveredOrders.length
                          ? `[${undeliveredStops.length} addresses]`
                          : ""}
                      </span>
                    </div>
                  )}
                  {undeliveredOrders.length > 0 && (
                    <DriverStartingRestaurant
                      showOrderDetails={showOrderDetails}
                      onToggleShowOrderDetails={
                        this.handleToggleShowOrderDetails
                      }
                      restaurant={{
                        name: driverInfo.restaurantName,
                        address: driverInfo.restaurantAddress,
                      }}
                      onStartFromRestaurant={
                        route ? this.handleStartFromRestaurant : null
                      }
                      // onStartFromRestaurant={this.handleStartFromRestaurant}
                      orders={undeliveredOrders}
                      names={undeliveredOrders.map(
                        (order, i) => `${order.name || order.user.firstName}`
                      )}
                      processingStop={processingStop}
                    />
                  )}
                </>
              )}
              {route && (
                <DriverTasks
                  orders={undeliveredStops}
                  onDeliver={this.handleDeliverConfirm}
                  onDispatch={this.handleDispatch}
                  processingStop={processingStop}
                  onBackToTop={this.handleBackToTop}
                  onCopied={this.handleShowMessage}
                  onUploadDeliveryImage={this.handleUploadDeliveryImage}
                  message={message}
                  isDelivered={isDelivered}
                  onStartFromRestaurant={this.handleStartFromRestaurant}
                />
              )}
            </React.Fragment>
          )}
        {isSummary && <DriverSummary orders={deliveredOrders} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageDriver got redux state:", state);
  return {
    orders: state.orders,
    currentUser: state.currentUser,
    route: state.route,
    routeV2: state.routeV2,
  };
}

export default connect(mapStateToProps, {
  readDriverOrders,
  markOrderAsDelivered,
  markOrderAsDispatched,
  uploadOrderDeliveryPicture,
  createRoute,
  startFromRestaurant,
  startRoute,
  endRoute,
  fetchRoute,
  updateRoute,
  confirmDriverAvailability,
  getDriverInfo,
  payDriverForRoute,
  getRouteForDriver,
})(PageDriver);
