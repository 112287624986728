import React, { useEffect, useState } from "react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import facebookLogo from "../../../../facebooklogo.png";
import logo from "../../../../foodie.earth.black.png";
import googleLogo from "../../../../googlelogo.png";
import {
  CenteredByColumn,
  BorderGrey,
  text12300,
  Gold,
  Centered,
  textTitle,
  centered,
  NavHeight,
  TextTitle,
  CenteredByColumnLeft,
  WarningRed,
  Text12300,
} from "../Shared";
const ActionContainer = styled(Centered)`
  ${textTitle}
  font-size: 20px;
  font-weight: 400;
  /* text-transform: uppercase; */
  line-height: 1.8;
  margin-bottom: 10px;
  letter-spacing: 0.6px;
  z-index: 2;
  color: white;
`;
const NextActionContainer = styled(ActionContainer)`
  flex-direction: column;
  padding: 10px 20px;
  margin: 0px 20px 20px 20px;
  white-space: pre-wrap;
  flex-grow: 0;
  text-align: center;
  background-color: rgba(223, 44, 20, 0.6);
  line-height: 1.4;
  font-size: 14px;
  svg {
    margin-right: 10px;
  }
  align-self: stretch;
  @media only screen and (min-width: 800px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const NextAction = ({ action }) => (
  <NextActionContainer>
    <span>
      <FontAwesomeIcon icon={faExclamationCircle} />
      {action}
    </span>
  </NextActionContainer>
);
export const ButtonWrapper = styled(CenteredByColumn)`
  z-index: 2;
  margin-top: 10px;
  margin-bottom: 0px;
  position: relative;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 800px) {
    margin-top: 40px;
  }
  @media only screen and (min-width: 1000px) {
    /* margin-left: 20px; */
  }
`;
interface AnonymousActionsWrapperProps {
  small?: boolean;
  fixed?: boolean;
}
export const AnonymousActionsWrapper = styled(
  CenteredByColumn
)<AnonymousActionsWrapperProps>`
  width: 100%;
  /* min-height: calc(100vh - ${NavHeight}px); */
  justify-content: flex-start;
  margin-top: 0px;
  max-width: 400px;
  padding: 0px ${(props) => (props.small ? "0px" : "5px")};
  // if fixed, make it fixed
  ${({ fixed }) => fixed && "position: fixed; top: 0px; left: 0px;"}

  @media only screen and (min-width: 800px) {
  }
`;
const Button = styled(Link)`
  ${text12300}
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  padding: 0px 30px;
  height: 60px;
  line-height: 60px;
  flex-grow: 1;
  margin: 0px 20px;
  align-self: stretch;
  text-align: center;
  color: black;
  opacity: 0.8;
  @media only screen and (min-width: 800px) {
    width: 200px;
    align-self: auto;
    margin-left: 0px;
  }
`;

export const ButtonNext = styled.button`
  ${centered}
  ${text12300}
  font-size: 20px;
  letter-spacing: 1.5px;
  font-weight: 600;
  padding: 0px 40px;
  height: 80px;
  margin: 0px 20px;
  text-align: center;
  color: black;
  opacity: 0.8;
  background-color: white;
  position: relative;
  text-transform: uppercase;
  @media only screen and (min-width: 800px) {
    min-width: 200px;
    align-self: auto;
    margin-left: 0px;
  }
`;
export const EmailContainer = styled(CenteredByColumn)`
  align-self: stretch;
  /* padding: 0px 20px; */
`;
const WelcomeContainer = styled(CenteredByColumn)`
  ${textTitle}
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
  @media only screen and (min-height: 800px) {
    margin-top: 80px;
  }
`;
export const EnterCodeTitle = styled(TextTitle)`
  font-size: 14px;
  text-transform: none;
  margin-bottom: 20px;
`;
export const Welcome = ({ inviter = "", getPassword, loading = false }) => {
  return (
    <WelcomeContainer>
      {loading
        ? `Loading...`
        : inviter
        ? `welcome to Foodie.Earth, ${inviter.split(" ")[0]}'s friend`
        : `Wecome ${getPassword ? "back" : "to Foodie.Earth"}`}
      {/* <FoodieLogo src={logo} /> */}
    </WelcomeContainer>
  );
};

export const ButtonEmail = styled(ButtonNext)`
  background-color: ${Gold};
  /* width: calc(100% - 40px); */
  /* margin: 0px 20px; */
  width: 100%;
  margin: 0px;
  // when disabled make it grey
  ${({ disabled }) => disabled && "background-color: #f0f0f0;"}
`;
interface AuthFieldInputProps {
  valid?: boolean;
  hasErrorMessage?: boolean;
}
export const AuthFieldInput = styled.input<AuthFieldInputProps>`
  margin: 0px 0px ${({ hasErrorMessage }) => (hasErrorMessage ? "0px" : "10px")}
    0px;
  border-bottom: none;
  align-self: stretch;
  height: 60px;
  padding: 0px 15px;
  border: 1px solid ${({ valid }) => (valid ? BorderGrey : WarningRed)};

  font-size: 16px;
  &::placeholder {
    font-size: 16px;
  }
  // make readonly grey
  ${({ readOnly }) => readOnly && "background-color: #f0f0f0; color: #666666;"}
`;

const LogoImage = styled.img`
  margin-right: 10px;
  position: absolute;
  left: 15px;
  width: 30px;
  height: 30px;
`;
const FoodieLogo = styled.img`
  width: 140px;
  height: 85px;
`;
export const GoogleLogoImage = styled(LogoImage)`
  background-color: white;
  position: relative;
  left: 0px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
`;
const FacebookLogoImage = styled.img`
  width: 40px;
  height: 40px;
  /* left: 20px; */
`;
export const ErrorMessage = styled(Centered)`
  color: ${WarningRed};
  ${text12300}
  font-weight: 400;
  text-align: left;
  width: 100vw;
  box-sizing: border-box;
  padding-left: 20px;
  line-height: 2;
  justify-content: flex-start;
  margin: 5px 0px 10px 0px;
  max-width: 400px;
  svg {
    font-size: 13px;
    margin-right: 5px;
  }
`;
const FacebookLogoContainer = styled(CenteredByColumn)`
  /* position: relative; */
  min-width: 50px;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  margin-right: 5px;
  img {
    margin: 0px;
  }
`;
export const GoogleLogo = () => <GoogleLogoImage src={googleLogo} />;
export const FacebookLogo = () => (
  <FacebookLogoContainer>
    <FacebookLogoImage src={facebookLogo} />
  </FacebookLogoContainer>
);

export const ButtonSignUp = styled(ButtonNext)<{ newUserOnly?: boolean }>`
  background-color: ${Gold};
  align-self: stretch;
  font-size: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  margin-right: 20px;
  height: ${(props) => (props.newUserOnly ? "60px" : "80px")};
  @media only screen and (min-height: 800px) {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
export const ButtonContinueAsGuest = styled(ButtonNext)<{
  newUserOnly?: boolean;
  mode?: string;
}>`
  background-color: transparent;
  color: ${(props) => props.mode};
  border: 2px solid ${(props) => props.mode};
  align-self: stretch;
  font-size: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  margin-right: 20px;
  height: ${(props) => (props.newUserOnly ? "60px" : "80px")};
  span {
    line-height: 1.3;
  }
  @media only screen and (min-height: 800px) {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
// add pros that has boolean fixed
export const FormContainer = styled(CenteredByColumn)<{ fixed?: boolean }>`
  width: calc(100% - 40px);
  max-width: 400px;
  padding-top: 20px;
  //if fixed, make it fixed, top nav height, margin 20px, left is max(20px, 50% - 200px)
  ${({ fixed }) =>
    fixed &&
    `position: fixed; top: ${NavHeight}px; margin: 0px 0px; left: 50%; transform: translateX(-50%);`}
`;
export const ButtonCreate = styled(ButtonNext)`
  background-color: ${Gold};
  width: 100%;
  margin: 0px 10px;
  line-height: 1.4;
  // when disabled make it grey
  ${({ disabled }) => disabled && "background-color: #f0f0f0;"}
`;
const ShowPassword = styled.button`
  margin: 0px 15px;
  padding: 0px;
  ${text12300}
  font-weight: 400;
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  line-height: 20px;
  border-bottom: 1px solid black;
`;

const FieldContainer = styled(CenteredByColumnLeft)`
  position: relative;
  width: 100%;
`;
const Line = styled(Centered)`
  background-color: ${BorderGrey};
  height: 1px;
  width: 1px;
  flex-grow: 1;
`;

export const Container = styled(CenteredByColumn)``;
const SeparatorContainer = styled(Centered)`
  margin: 0px;
  padding: 20px 0px;
  align-self: stretch;
  align-items: center;
  position: relative;
  span {
    ${text12300}
    font-weight: 500;
    align-self: stretch;
    align-content: stretch;
    margin: 0px 10px;
  }
`;
export const Separator = () => (
  <SeparatorContainer>
    <Line />
    <span>or</span>
    <Line />
  </SeparatorContainer>
);

interface SignUpFieldProps {
  fieldName: string;
  fieldValue: string;
  isPassword?: boolean;
  loading?: boolean;
  errorMessage?: string;
  fieldValidator?: (field: string) => {};
  onChange?: (fieldValue: string) => {};
  onSetParentError?: (error: string) => {};
}
export const SignUpField: React.FC<SignUpFieldProps> = ({
  fieldName,
  fieldValue,
  isPassword,
  errorMessage,
  fieldValidator,
  onChange,
  onSetParentError,
  loading,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(errorMessage);
  const [warning, setWarning] = useState(errorMessage?.length > 0);

  const handleToggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    setError(errorMessage);
    setWarning(errorMessage?.length > 0);
  }, [errorMessage]);

  return (
    <FieldContainer>
      <AuthFieldInput
        onChange={(e) => {
          onChange(e.target.value);
          if (onSetParentError) {
            onSetParentError("");
          }
          if (fieldValidator && e.target.value?.length > 0) {
            const result = fieldValidator(e.target.value);
            setWarning(!result);
          }
          if (error?.length > 0) {
            setError("");
            setWarning(false);
          }
        }}
        value={fieldValue}
        placeholder={fieldName}
        valid={!warning}
        type={showPassword || !isPassword ? "text" : "password"}
        hasErrorMessage={error?.length > 0}
        readOnly={loading}
      />
      {isPassword ? (
        <ShowPassword onClick={handleToggleShowPassword}>
          {showPassword ? "hide" : "show"}
        </ShowPassword>
      ) : null}
      {error?.length ? (
        <ErrorMessage>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <span>{error}</span>
        </ErrorMessage>
      ) : null}
    </FieldContainer>
  );
};
