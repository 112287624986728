import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FoodieEvent, FoodieSource, TrackEvent } from "src/api/tracker";
import { Flex } from "src/components/Layout";
import styled from "styled-components";
import { LOGGING, AuthMode, Loading, ExistingUser, AnonymousFooter } from "./";
import { LoadingAuth } from "./Loading";
import { signIn } from "../../store/actions";
import { AnonymousBackground } from "../tasting_menu/components/home/AnonymousBackground";
import {
  Cover,
  AnonymousRestaurantSlides,
} from "../tasting_menu/components/home/Cover";

const HorizontalLine = styled.div`
  height: 1px;
  width: 20px;
  border-top: solid 1px black;
`;

class PageSignInByEmail extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      email: "",
      error: null,
      showPassword: false,
      loading: false,
    };
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleToggleShownPassword = this.handleToggleShownPassword.bind(this);
    this.handleSecondaryAction = this.handleSecondaryAction.bind(this);
  }

  handleToggleShownPassword(e) {
    e.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleCancel(e) {
    e.preventDefault();
    LOGGING && console.log("handleCancel called");
    this.props.history.goBack();
  }

  handleSignIn(e) {
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({ loading: true });
    this.props
      .signIn({ email, password })
      .then(() => {
        this.setState({ loading: false });
        const { location } = this.props;
        if (location.state && location.state.next) {
          const { next } = location.state;
          LOGGING && console.log("signIn done with next:", next);
          this.props.history.replace(next);
        } else {
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        LOGGING && console.log("sign in got error:", error);
        this.setState({ error, loading: false });
      });
  }

  handleSecondaryAction() {
    const next = this.props.location.state.secondary.next;

    let state = {};
    if (next === "/signup") {
      if (this.props.location.state.next === "/cart") {
        state.next = "/checkout";
      } else {
        state.next = this.props.location.state.next;
      }
    }

    this.props.history.push(next, state);
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  async trackPageSignInEvent() {
    await TrackEvent(
      FoodieEvent.PAGE_SIGN_IN,
      null,
      FoodieSource.UNKNOWN,
      null,
      null,
      0
    );
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    // await this.trackPageSignInEvent();
  }

  render() {
    const { email, password, showPassword, error, loading } = this.state;
    LOGGING &&
      console.log("PageSignInByEmail got", {
        state: this.state,
        props: this.props,
      });

    return (
      <div className="page no-nav">
        {/* <Back onClick={this.handleCancel} /> */}

        {this.props.location.state?.title ? (
          <span className="text-title" style={{ marginBottom: "36px" }}>
            {this.props.location.state.title}
          </span>
        ) : null}

        <AnonymousBackground
          mode={AuthMode.SIGNIN}
          heroComponentToDisplay={
            <>
              {loading ? <LoadingAuth text="signing in..." /> : null}
              {loading ? null : (
                <ExistingUser
                  email={email}
                  password={password}
                  showPassword={showPassword}
                  onSignIn={this.handleSignIn}
                  onToggleShowPassword={this.handleToggleShownPassword}
                  onEdit={this.handleEdit}
                  errorMessage={error?.message}
                />
              )}
              {this.props?.location?.state?.secondary != null ? (
                <>
                  <Flex align="center">
                    <HorizontalLine />
                    <span
                      style={{
                        margin: "10px 10px",
                        fontFamily: "Montserrat",
                        fontSize: "13px",
                      }}
                    >
                      or
                    </span>
                    <HorizontalLine />
                  </Flex>
                  <button
                    style={{
                      height: "50px",
                      width: "260px",
                      marginTop: "20px",
                      color: "black",
                    }}
                    type="submit"
                    onClick={this.handleSecondaryAction}
                  >
                    {this.props?.location?.state?.secondary?.title}
                  </button>
                </>
              ) : null}
            </>
          }
        />

        {/* <div className="logo-sign-in">
              {this.props?.location?.state?.title || "Welcome back!"}
            </div> */}
      </div>
    );
  }
}

export default connect(null, { signIn }, null, { forwardRef: true })(
  PageSignInByEmail
);
