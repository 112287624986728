import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ImageCarousel from "./ImageCarousel";
import NextDelivery, { LastDelivery } from "./NextDelivery";
import RatingStars from "./RatingStars";
import RestaurantComment from "./RestaurantComment";
import RestaurantOnMap from "./RestaurantOnMap";
import {
  RestaurantCity,
  RestaurantAward,
  ShimmerEffect,
} from "./RestaurantThumbnail";
import { WantToTry, Tried } from "../favorites/WantToTry";
import {
  BorderGrey,
  text12300,
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  LightShadow,
  Text12300,
  TextTitle,
} from "../Shared";
const RestaurantName = styled(TextTitle)``;
const RestaurantAddress = styled(Text12300)``;
const RestaurantContent = styled(CenteredByColumnLeft)`
  padding: 20px 20px 100px 20px;
`;

const RestaurantActions = styled(Centered)`
  width: 100%;
  justify-content: flex-start;
  margin: 30px 0px 30px 0px;
`;
const actionWidth = 50;
const ActionNote = styled(Text12300)`
  line-height: 1.3;
  text-transform: capitalize;
  margin-top: 5px;
`;
const YelpIcon = styled(Centered)`
  width: 30px;
  height: 30px;
  margin: 0px;
  svg {
    font-size: 18px;
    margin-left: 0px;
    color: "#888";
  }
`;
const RestaurantAction = styled(CenteredByColumn)`
  box-shadow: ${LightShadow};
  width: ${actionWidth * 2}px;
  /* height: ${actionWidth}px; */
  border-radius: 10px;
  border: 1px solid ${BorderGrey};
  padding: 8px 5px;
  align-self: stretch;
  button {
    margin-left: 0px;
  }
  margin-right: 20px;
`;

const RestaurantEssay = styled.p`
  ${text12300}
`;
const YelpLink = styled.a`
  ${text12300}
  line-height: 19px;
  border-bottom: 1px solid #888;
  svg {
    font-size: 10px;
    margin-bottom: 2px;
    color: #444;
  }
  svg:last-child {
    margin-left: 3px;
    font-size: 8px;
    display: none;
  }
`;

const UserFeedback = styled(CenteredByColumnLeft)`
  flex-grow: 1;
  align-self: stretch;
  margin: 20px 0px 20px 0px;
`;
const RestaurantGuide = ({
  restaurant,
  onStarRating,
  onReview,
  onOpenFullScreenCollage,
  source,
  fbclid,
}) => {
  return (
    <div className="restaurant-guide">
      <ImageCarousel
        images={
          restaurant.heroImages ||
          restaurant.bestSellers.map((d: any) => d.imageURL)
        }
        onOpenFullScreenCollage={onOpenFullScreenCollage}
      />
      <RestaurantContent>
        <RestaurantAward
          award={restaurant.awardLevel || 1}
          specials={restaurant.awards}
        />
        <RestaurantName>{restaurant.name}</RestaurantName>
        <RestaurantAddress>{restaurant.address}</RestaurantAddress>
        <RestaurantCity>
          <span>{"$".repeat(restaurant.priceLevel)}</span>
          <span>{restaurant?.awardCategory}</span>
          <YelpLink href={restaurant?.contact?.yelpUrl}>
            Yelp {restaurant?.yelp.rating} <FontAwesomeIcon icon={faStar} /> (
            {restaurant.yelp.reviews.toLocaleString()})
            <FontAwesomeIcon icon={faArrowRight} fontSize={10} />
          </YelpLink>
        </RestaurantCity>
        <RestaurantActions>
          <Tried restaurant={restaurant} />
          <WantToTry restaurant={restaurant} />
        </RestaurantActions>
        {restaurant.nextMealLink ? (
          <NextDelivery
            link={restaurant.nextMealLink}
            time={restaurant.nextMealWindowStart}
            source={source}
            fbclid={fbclid}
          />
        ) : restaurant.lastMealWindowStart ? (
          <LastDelivery time={restaurant.lastMealWindowStart} />
        ) : null}
        <UserFeedback>
          <RatingStars restaurant={restaurant} onStarRating={onStarRating} />
          <RestaurantComment
            restaurant={restaurant}
            onReview={onReview}
            customStyle={{}}
          />
        </UserFeedback>
        <RestaurantEssay>{restaurant.intro}</RestaurantEssay>
        <RestaurantOnMap map={restaurant?.map} userMap={restaurant?.userMap} />
      </RestaurantContent>
      {/* Add more restaurant details as needed */}
    </div>
  );
};

export default RestaurantGuide;
const EmtpyGuideContainer = styled(CenteredByColumnLeft)`
  width: 100vw;
  height: 100vh;
  justify-content: flex-start;
  max-width: 600px;
`;
const EmptyImage = styled.div`
  width: 100vw;
  max-width: 600px;
  height: 300px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EmptyRestaurantName = styled(Centered)`
  align-self: stretch;
  height: 80px;
  background-color: #f0f0f0;
  margin: 20px;
`;

const EmptyRestaurantDescription = styled(EmptyRestaurantName)`
  height: 20px;
  width: 60%;
  max-width: 400px;
  margin: 0px 20px 10px 20px;
`;
export const EmtpyGuide = () => {
  const items = Array.from({ length: 10 }); // Create an array of 10 undefined items

  return (
    <EmtpyGuideContainer>
      <EmptyImage>{/* <ShimmerEffect/> */}</EmptyImage>
      <EmptyRestaurantName>
        <ShimmerEffect />
      </EmptyRestaurantName>
      {items.map((_, index) => (
        <EmptyRestaurantDescription key={index}>
          <ShimmerEffect />
        </EmptyRestaurantDescription>
      ))}
    </EmtpyGuideContainer>
  );
};
