// import { update } from 'lodash';
import moment from "moment-timezone";
import toast from "react-hot-toast";
import { loadDishes } from "./";
import { setTopBlocks } from "./topBlocks";
import { LOGGING } from "../../constants";
import { SET_MEALS, SET_WEEKLY_MENU, RESET_WEEKLY_MENU } from "../actionTypes";
import { apiCall } from "../api";

export const setMeals = (meals) => ({
  type: SET_MEALS,
  meals,
});

export const setWeeklyMenu = (weeklyMenu) => ({
  type: SET_WEEKLY_MENU,
  weeklyMenu,
});

export const resetWeeklyMenu = () => ({
  type: RESET_WEEKLY_MENU,
});

export const fetchMeals = ({ start, end }) => {
  LOGGING && console.log("fetchMeals called with:", { start, end });

  return (dispatch, getState) => {
    return apiCall("GET", `/meals/${start}/${end}`, {}, getState().currentUser)
      .then((result) => {
        LOGGING && console.log("fetchMeals got result:", result);
        const { mealsWithOrders, windowStartsWithStats } = result;
        dispatch(setMeals(mealsWithOrders));
        return windowStartsWithStats;
      })
      .catch((err) => {
        LOGGING && console.log("fetchMeals got err", err);
      });
  };
};

export const fetchMealTime = (windowStart) => {
  LOGGING && console.log("fetchMealTime called with:", windowStart);

  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/meals/readMealTime/${windowStart}`,
      {},
      getState().currentUser
    )
      .then((foundMeals) => {
        LOGGING && console.log("fetchMealTime got result:", foundMeals);
        const { meals } = getState();
        const newMeals = foundMeals.filter(
          (m) => !meals.find((m2) => m2._id === m._id)
        );
        dispatch(setMeals([...meals, ...newMeals]));
      })
      .catch((err) => {
        LOGGING && console.log("fetchMeals got err", err);
      });
  };
};

export const swapMeals = (windowStart1, windowStart2) => {
  LOGGING &&
    console.log("swapMeals called with:", { windowStart1, windowStart2 });

  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/meals/swap/${windowStart1}/${windowStart2}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("swapMeals got result:", result);
        if (!result || result.length === 0) {
          toast.error("Could not swap meals due to schedule conflict.");
          return;
        }
        const { meals } = getState();
        LOGGING && console.log("swapMeals got meals:", meals);
        const udpatedMeals = meals.map((m) => {
          const foundMeal = result.find((meal) => meal._id === m._id);
          return foundMeal || m;
        });
        LOGGING && console.log("swapMeals got udpatedMeals:", udpatedMeals);
        dispatch(setMeals(udpatedMeals));
      })
      .catch((err) => {
        LOGGING && console.log("swapMeals got err", err);
      });
  };
};

export const fetchMealsStats = ({ start, end }) => {
  LOGGING && console.log("fetchMealsStats called with:", { start, end });

  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/meals/stats/${start}/${end}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("fetchMealsStats got result:", result);
        dispatch(setMeals(result));
      })
      .catch((err) => {
        LOGGING && console.log("fetchMeals got err", err);
      });
  };
};

export const fetchMealsCommission = ({ start, end }) => {
  LOGGING && console.log("fetchMealsCommission called with:", { start, end });

  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/meals/commission/${start}/${end}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("fetchMealsCommission got result:", result);
        // dispatch(setMeals(result));
        return result;
      })
      .catch((err) => {
        LOGGING && console.log("fetchMeals got err", err);
      });
  };
};

export const saveMeal = (meal) => {
  LOGGING && console.log("saveMeal called with:", meal);
  const { _id: mealId } = meal;
  return (dispatch, getState) => {
    return apiCall("post", `/meals`, meal, getState().currentUser)
      .then((savedMeal) => {
        LOGGING && console.log("save got savedMeal", savedMeal);
        let { meals } = getState();
        const { _id } = savedMeal;
        LOGGING && console.log("save got meals", meals);
        const foundMealIndex = meals.findIndex((m) => m._id === _id);
        if (foundMealIndex < 0) {
          meals.push(savedMeal);
        } else {
          meals[foundMealIndex] = savedMeal;
        }
        dispatch(setMeals(meals));
      })
      .catch((err) => {
        LOGGING && console.log("fetchMeals got err", err);
      });
  };
};

export const saveMealsBatch = ({ restaurantIds, windowStart }) => {
  LOGGING &&
    console.log("saveMealsQuick called with:", { restaurantIds, windowStart });
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/meals/batch`,
      { restaurantIds, windowStart },
      getState().currentUser
    )
      .then((savedMeals) => {
        LOGGING && console.log("save got savedMeals", savedMeals);
        let { meals } = getState();
        LOGGING && console.log("save got meals", meals);
        dispatch(setMeals([...meals, ...savedMeals]));
      })
      .catch((err) => {
        LOGGING && console.log("fetchMeals got err", err);
      });
  };
};

export const deleteMeal = (meal) => {
  LOGGING && console.log("deleteMeal called with:", meal);
  return (dispatch, getState) => {
    return apiCall("delete", `/meals/${meal._id}`, getState().currentUser)
      .then(() => {
        let { meals } = getState();
        dispatch(setMeals(meals.filter((m) => m._id !== meal._id)));
        return;
      })
      .catch((err) => {
        LOGGING && console.log("deleteMeal got err", err);
      });
  };
};

export const confirmMeal = (meal) => {
  LOGGING && console.log("confirmMeal called with meal:", meal);
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/meals/${meal._id}`,
      {
        confirmedWithRestaurant: meal.confirmedWithRestaurant,
        mealGroup: meal.mealGroup,
      },
      getState().currentUser
    )
      .then((udpatedMeal) => {
        const { meals } = getState();
        dispatch(
          setMeals(
            meals.map((m) => (m._id !== udpatedMeal._id ? m : udpatedMeal))
          )
        );
      })
      .catch((err) => {
        LOGGING && console.log("confirmMeal got err", err);
      });
  };
};

export const fetchWeeklyMeals = (windows, selectedDeliveryZone, userId) => {
  return (dispatch, getState) => {
    const { orders: ordersRaw, carts: cartsRaw } = getState();
    return apiCall(
      "post",
      "/meals/calendar/weekly",
      { windows, selectedDeliveryZone, userId },
      getState().currentUser
    )
      .then((weeklyMenu) => {
        const orders = {},
          carts = {};
        ordersRaw.forEach((i) => {
          if (i.meal?._id) {
            orders[i.meal._id] = (orders[i.meal._id] || []).concat({
              _id: i._id,
              meal: i.meal._id,
              payTime: i.payTime,
              isGroupOrder: i.groupOrderType > -1,
            });
          }
        });
        cartsRaw.forEach((i) => {
          if (i.meal?._id) {
            carts[i.meal._id] = (carts[i.meal._id] || []).concat({
              _id: i._id,
              meal: i.meal._id,
              payTime: i.payTime,
              isGroupOrder: i.groupOrderType > -1,
            });
          }
        });
        // LOGGING &&
        //   console.log("fetchWeeklyMeals summary got", { carts, orders });
        let weeklyMenuWithOrders = weeklyMenu;
        weeklyMenuWithOrders.forEach((dailyMenu) => {
          dailyMenu.lunch = dailyMenu.lunch.map((meal) => {
            if (orders.hasOwnProperty(meal?._id)) {
              meal["orders"] = orders[meal._id];
            }
            if (carts.hasOwnProperty(meal?._id)) {
              meal["carts"] = carts[meal._id];
            }
            return meal;
          });
          dailyMenu.earlyDinner = dailyMenu.earlyDinner.map((meal) => {
            if (orders.hasOwnProperty(meal?._id)) {
              meal["orders"] = orders[meal._id];
            }
            if (carts.hasOwnProperty(meal?._id)) {
              meal["carts"] = carts[meal._id];
            }
            return meal;
          });
          dailyMenu.dinner = dailyMenu.dinner.map((meal) => {
            if (orders.hasOwnProperty(meal?._id)) {
              meal["orders"] = orders[meal._id];
            }
            if (carts.hasOwnProperty(meal?._id)) {
              meal["carts"] = carts[meal._id];
            }
            return meal;
          });

          return dailyMenu;
        });

        LOGGING &&
          console.log(
            "fetchWeeklyMeals summary got weeklyMenuWithOrders",
            weeklyMenuWithOrders
          );
        dispatch(setWeeklyMenu(weeklyMenuWithOrders));
        return;
      })
      .catch((err) => {
        LOGGING && console.log("fetchWeeklyMeals got err", err);
      });
  };
};

export const fetchNextMealLink = ({ restaurantId }) => {
  LOGGING && console.log("fetchNextMeal called with:", { restaurantId });
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/meals/GetNextMeal`,
      { restaurantId },
      getState().currentUser
    )
      .then((mealLink) => {
        LOGGING &&
          console.log("fetchNextMeal got meal link from backend:", mealLink);
        return mealLink;
      })
      .catch((err) => {
        LOGGING && console.log("fetchNextMeal got err", err);
      });
  };
};

export const readTeamMeals = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readTeamMeals called with currentUser:", currentUser);
    const userGroupId = currentUser?.user?.isUserGroupAdmin
      ? currentUser?.user?.userGroup?._id
      : null;
    return apiCall("get", `/meals/team/${userGroupId}`, {}, currentUser)
      .then((meals) => {
        LOGGING && console.log("readTeamMeals got meals from backend:", meals);
        return meals;
      })
      .catch((err) => {
        LOGGING && console.log("readTeamMeals got err", err);
      });
  };
};

export const fetchWeeklyMealDetails = (windows, userId) => {
  return (dispatch, getState) => {
    return apiCall(
      "put",
      "/meals/calendar/weekly",
      { windows, userId },
      getState().currentUser
    )
      .then((newMeals) => {
        const { meals: oldMeals } = getState();
        dispatch(setMeals([...oldMeals, ...newMeals]));
      })
      .catch((err) => {
        LOGGING && console.log("fetchWeeklyMealDetails got err", err);
      });
  };
};

export const fetchWeeklyMealDetailsLight = (windowStarts, userId) => {
  return (dispatch, getState) => {
    return apiCall(
      "post",
      "/meals/GetMealDetailsByWindowStart",
      { windowStarts, userId },
      getState().currentUser
    )
      .then((newMeals) => {
        const { meals: oldMeals } = getState();
        // dispatch(setMeals([...oldMeals, ...newMeals]))
        const updatedOldMeals = oldMeals.map((m) => {
          const foundMeal = newMeals.find((nm) => nm._id === m._id);
          return foundMeal || m;
        });

        const updatedNewMeals = newMeals.filter((nm) => {
          return !oldMeals.find((m) => m._id === nm._id);
        });
        dispatch(setMeals([...updatedOldMeals, ...updatedNewMeals]));
      })
      .catch((err) => {
        LOGGING && console.log("fetchWeeklyMealDetailsLight got err", err);
      });
  };
};

export const fetchMeal = (mealId) => {
  LOGGING && console.log("fetchMeal called with mealId:", mealId);

  return (dispatch, getState) => {
    return apiCall("GET", `/meals/${mealId}`)
      .then((meal) => {
        return meal;
      })
      .catch((err) => {
        LOGGING && console.log("fetchMeal got err", err);
      });
  };
};

export const savePreOrderedDishQuantity = (
  mealId,
  preOrderedDishQuantity,
  options
) => {
  LOGGING &&
    console.log(
      "savePreOrderedDishQuantity called with:",
      mealId,
      preOrderedDishQuantity
    );

  return async () => {
    try {
      await apiCall("PUT", `/meals/preOrderedDishQuantity/${mealId}`, {
        preOrderedDishQuantity,
      });
      options?.success?.();
    } catch (err) {
      LOGGING && console.log("savePreOrderedDishQuantity got err", err);
      options?.error?.(err);
    }
  };
};

export const mealCancelDish = (mealId, dishId, options) => {
  LOGGING && console.log("mealCancelDish called with:", mealId, dishId);

  return async () => {
    try {
      await apiCall("PUT", `/meals/cancelDish/${mealId}`, { dishId });
      options?.success?.();
    } catch (err) {
      LOGGING && console.log("mealCancelDish got err", err);
      options?.error?.(err);
    }
  };
};

export const fetchSingleMealByName = ({
  city,
  date,
  restaurantName,
  mealType,
}) => {
  LOGGING &&
    console.log("fetchSingleMealByName called with:", {
      city,
      date,
      restaurantName,
      mealType,
    });
  return (dispatch, getState) => {
    return apiCall("get", `/meals/byName/${date}/${mealType}/${restaurantName}`)
      .then((meal) => {
        LOGGING && console.log("fetchSingleMealByName got from server:", meal);
        const { dishes, _id } = meal;
        let { meals } = getState();
        // const mealsOrig = meals[meal.windowStart];
        // meals[meal.windowStart] = mealsOrig.filter((m) => m._id !== meal._id);
        dispatch(loadDishes(dishes.map((d) => ({ ...d, meal: _id }))));
        dispatch(setMeals([...meals, meal]));
        return meal;
      })
      .catch((err) => {
        LOGGING && console.log("fetchMeal got err", err);
      });
  };
};

export const refreshCache = () => {
  LOGGING && console.log("refreshCache called at: ", moment().valueOf());
  return apiCall("GET", `/meals/refreshCache`);
};

// ===============
// TODO: Move all non-dispatch functions out of this file.
// ===============

export const getOrderLabelsPdfForMealBlob = async (mealId, processedType) => {
  const response = await apiCall(
    "post",
    "/meals/GetOrderLabelsPdfForMeal",
    { mealId: mealId, processedType: processedType },
    null,
    { responseType: "blob" }
  );
  // To open URL instead
  // var fileURL = URL.createObjectURL(response);
  // window.open(fileURL);
  return response;
};

// rewrite planoneweek
// export async function PlanOneWeek(
//   starting: {
//     lunchWindowStart: number;
//     dinnerWindowStart: number;
//     earlyDinnerWindowStart: number;
//   },
//   handpicked: [ObjectId]
// ): Promise<any> {
//   try {
//     return await apiCall("POST", `/meals/PlanOneWeek`, {
//       starting,
//       handpicked,
//     });
//   } catch (err) {
//     LOGGING && console.log("PlanOneWeek got err", err);
//     return null;
//   }
// }

export const handpickOneWeek = (starting, handpicked) => {
  return (dispatch, getState) => {
    const { meals } = getState();
    try {
      return apiCall("POST", `/meals/HandPickOneWeek`, {
        starting,
        handpicked,
      }).then((result) => {
        LOGGING && console.log("HandPickOneWeek got result:", result);
        dispatch(setMeals([...meals, ...result]));
      });
    } catch (err) {
      LOGGING && console.log("HandPickOneWeek got err", err);
      return null;
    }
  };
};

export const growOneWeek = (starting) => {
  return (dispatch, getState) => {
    const { meals } = getState();
    try {
      return apiCall("POST", `/meals/GrowOneWeek`, {
        starting,
      }).then((result) => {
        LOGGING && console.log("GrowOneWeek got result:", result);
        dispatch(setMeals([...meals, ...result]));
      });
    } catch (err) {
      LOGGING && console.log("GrowOneWeek got err", err);
      return null;
    }
  };
};

export const fillOneWeek = (starting) => {
  return (dispatch, getState) => {
    const { meals } = getState();
    try {
      return apiCall("POST", `/meals/FillOneWeek`, {
        starting,
      }).then((result) => {
        LOGGING && console.log("FillOneWeek got result:", result);
        dispatch(setMeals([...meals, ...result]));
      });
    } catch (err) {
      LOGGING && console.log("FillOneWeek got err", err);
      return null;
    }
  };
};

export const deleteMealByWindowStart = (windowStart) => {
  return (dispatch, getState) => {
    const { meals } = getState();
    try {
      return apiCall(
        "POST",
        `/meals/DeleteMealsByWindowStart/${windowStart}`
      ).then(() => {
        LOGGING && console.log("deleteMealByWindowStart got result");
        dispatch(setMeals(meals.filter((m) => m.windowStart !== windowStart)));
      });
    } catch (err) {
      LOGGING && console.log("deleteMealByWindowStart got err", err);
      return null;
    }
  };
};

export const resetOneWeek = (starting) => {
  return (dispatch, getState) => {
    const { meals } = getState();
    try {
      return apiCall("POST", `/meals/ResetOneWeek`, {
        starting,
      }).then(() => {
        LOGGING && console.log("ResetOneWeek got result");
        dispatch(
          setMeals(
            meals.filter(
              (m) => !Object.values(starting).includes(m.windowStart)
            )
          )
        );
      });
    } catch (err) {
      LOGGING && console.log("ResetOneWeek got err", err);
      return null;
    }
  };
};

export const readUpcomingFavorites = async (restaurantIds, userId, topN) => {
  try {
    const favoriteRestaurantIds = await apiCall(
      "post",
      `/meals/ReadUpcomingFavoriteMeals`,
      { restaurantIds, userId, topN }
    );
    return favoriteRestaurantIds;
  } catch (err) {
    LOGGING && console.log("ReadUpcomingFavoriteMeals got err", err);
  }
};

export const createMealTime = async ({ restaurants, windowStart }) => {
  LOGGING &&
    console.log("createMealTime called with:", { restaurants, windowStart });
  try {
    const newMeals = await apiCall("post", `/meals/createMealTime`, {
      restaurants,
      windowStart,
    });
    LOGGING && console.log("createMealTime got newMeal", newMeals);
    return newMeals;
  } catch (err) {
    LOGGING && console.log("createMealTime got err", err);
  }
};
export const fetchTopMeals = (days) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "POST",
      `/meals/GetTopBlocks`,
      {
        days,
      },
      currentUser
    )
      .then((result) => {
        LOGGING && console.log("fetchTopMeals got result:", result);
        dispatch(setTopBlocks(result));
      })
      .catch((err) => {
        LOGGING && console.log("toggleFavorite got err", err);
        throw err;
      });
  };
};
export const getLastMealTimes = (restaurantIds) => {
  LOGGING && console.log("getLastMealTime called with:", { restaurantIds });
  return (dispatch, getState) => {
    let { topBlocks } = getState();
    return apiCall("post", `/restaurants/CheckLastMeal`, { restaurantIds })
      .then((result) => {
        LOGGING && console.log("getLastMealTime got result:", result);
        const updatedTopBlocks = topBlocks.map((tb) => {
          const { restaurants } = tb;
          const updatedRestaurants = restaurants
            .filter((r) => r?.meal?.restaurant?._id)
            .map((r) => {
              const restaurantId = r.meal.restaurant._id;
              const lastMealTime = result[restaurantId];
              return { ...r, lastMealTime };
            });
          return { ...tb, restaurants: updatedRestaurants };
        });
        dispatch(setTopBlocks(updatedTopBlocks));
      })
      .catch((err) => {
        LOGGING && console.log("getLastMealTime got err", err);
      });
  };
};

export const saveMealReminder = (
  mealId,
  reminderTime,
  reminderFormats,
  dayIndex,
  mealType,
  mealIndex,
  enabled,
  restaurantName,
  windowStart
) => {
  LOGGING &&
    console.log("saveMealReminder called with:", {
      mealId,
      reminderTime,
      reminderFormats,
      dayIndex,
      mealType,
      mealIndex,
      enabled,
      restaurantName,
      windowStart,
    });
  const method = reminderFormats.reduce((acc, format) => {
    acc[`${format}On`] = true;
    return acc;
  }, {});
  return (dispatch, getState) => {
    const { user } = getState().currentUser;
    const userName = `${user?.firstName} ${user?.lastName}`;
    return apiCall(
      "post",
      `/users/mealReminder/${user?._id}`,
      {
        mealId,
        alertTimestamp: reminderTime,
        method,
        isActive: enabled,
        userName,
        restaurantName,
        windowStart,
      },
      getState().currentUser
    )
      .then(({ reminder }) => {
        LOGGING && console.log("setMealReminder got result:", reminder);
        const { weeklyMenu } = getState();
        let updatedWeeklyMenu = weeklyMenu.payLoad;
        updatedWeeklyMenu[dayIndex][mealType][mealIndex].reminder = reminder;
        // console.log({dayIndex, mealType, mealIndex, reminder});
        console.log(
          "updatedWeeklyMenu[dayIndex][mealType][mealIndex].reminder",
          updatedWeeklyMenu[dayIndex][mealType][mealIndex].reminder
        );
        dispatch(setWeeklyMenu(updatedWeeklyMenu));
      })
      .catch((err) => {
        LOGGING && console.log("setMealReminder got err", err);
      });
  };
};

export const getMealReminders = () => {
  return (dispatch, getState) => {
    const { user } = getState().currentUser;
    const { weeklyMenu } = getState();
    return apiCall(
      "get",
      `/users/mealReminder/${user?._id}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        let weeklyMenuWithReminders = weeklyMenu.payLoad;
        weeklyMenuWithReminders.forEach((dailyMenu) => {
          dailyMenu.lunch = dailyMenu.lunch.map((meal) => ({
            ...meal,
            reminder: result[meal?._id],
          }));
          dailyMenu.earlyDinner = dailyMenu.earlyDinner.map((meal) => ({
            ...meal,
            reminder: result[meal?._id],
          }));
          dailyMenu.dinner = dailyMenu.dinner.map((meal) => ({
            ...meal,
            reminder: result[meal?._id],
          }));
          return dailyMenu;
        });
        dispatch(setWeeklyMenu(weeklyMenuWithReminders));
        return result;
      })
      .catch((err) => {
        LOGGING && console.log("getMealReminders got err", err);
      });
  };
};
